import styled from "styled-components";
import { mobileBackground } from "../../assets/images";
import { forDesktop } from "../../helpers/breakpoints/viewports";

const LoginStyled = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(${mobileBackground});
  background-repeat: no-repeat;
  background-size: cover;

  .title {
    font: ${({ theme }) => theme.fonts.mobile.h1};
    text-transform: uppercase;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 40px;
    padding-top: 90px;
  }

  .social-login {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    margin: 40px 0;
    padding: 0 70px;
  }

  .social-login > div {
    flex-basis: 50%;
    max-width: 265px;
  }

  form {
    margin: 0 auto 40px;
    width: 100%;
    max-width: 600px;
  }

  .inputs {
    padding: 0 70px;
  }

  .inputs div {
    flex-basis: 50%;
  }

  .captcha-info {
    font: ${({ theme }) => theme.fonts.global.text8light};
    color: ${({ theme }) => theme.colors.whiteOpacity25};
    padding: 0 70px 32px;
    text-align: center;
  }

  .captcha-link {
    color: #3bbdf5;
    display: inline;
    padding: 0 3px;
  }

  .forget-password {
    font: ${({ theme }) => theme.fonts.global.text10};
    text-align: center;
  }

  .buttons {
    display: flex;
    padding: 0 16px;
    column-gap: 16px;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 32px;
  }

  @media (${forDesktop}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;

    .title {
      margin-bottom: 64px;
      font: ${({ theme }) => theme.fonts.desktop.h1};
    }

    .inputs {
      display: flex;
      column-gap: 20px;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      margin: 64px 0;
    }

    .forget-password {
      font: ${({ theme }) => theme.fonts.global.text12};
      text-align: center;
    }

    .buttons {
      column-gap: 16px;
      position: initial;
      margin-top: 64px;
      padding: 0;
    }

    .captcha-info {
      display: none;
    }
  }
`;

export default LoginStyled;
