import React, { useEffect, useState } from "react";
import Toast from "react-bootstrap/Toast";
import "./registration-toast.css";
import { notificationRingingBellIcon, closeIcon } from "../../../../assets/images";

export default function RegistrationToast({ show }: { show: boolean }) {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    setShowToast(show);
  }, [show]);

  const closeModal = () => {
    setShowToast(false);
  };

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      style={{
        position: "relative",
        minHeight: "100px",
      }}
    >
      <Toast
        style={{
          position: "fixed",
          bottom: "20px",
          left: "20px",
          borderRadius: "20px",
        }}
        show={showToast}
        delay={3000}
        autohide
        onClose={closeModal}
      >
        <Toast.Body>
          <div
            className="close-btn"
            tabIndex={0}
            role="button"
            onClick={closeModal}
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === " ") {
                closeModal();
              }
            }}
          >
            <img
              alt="close_icon"
              src={closeIcon}
            />
          </div>
          <div className="ringbell-background">
            <img
              alt="notify_bell"
              src={notificationRingingBellIcon}
            />
          </div>
          <div className="congrats-text">Gratulacje!</div>
          <div className="registered-text">Zostałeś zapisany na turniej Day of Duel Solo Build Cup</div>
        </Toast.Body>
      </Toast>
    </div>
  );
}
