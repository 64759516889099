import React from "react";
import { coming } from "../../assets/images";
import "./populargames.css";

function ComingSoonGameBox({ pageContent }) {
  return (
    <div className="col-md-3">
      <div className="popular-box soon">
        <img
          src={coming}
          alt=""
        />
        <div className="game-info">
          <h4>{pageContent && pageContent.soon}</h4>
          <p>{pageContent && pageContent.para4}</p>
        </div>
      </div>
    </div>
  );
}

export default ComingSoonGameBox;
