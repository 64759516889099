import styled from "styled-components";

const ChangeAccountNumberStage2Styled = styled.div`
  margin-top: 60px;

  .inputs {
    width: 350px;
    margin: auto;
  }

  .description {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.caption};
    margin-bottom: 30px;
  }

  .description p {
    margin: 0;
  }

  .resend {
    font-size: ${({ theme }) => theme.fontSizes.caption};
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    text-decoration: underline;
    text-align: center;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }

  .resend:hover {
    cursor: pointer;
  }

  .buttons {
    display: flex;
  }
`;

export default ChangeAccountNumberStage2Styled;
