import React from "react";
import { useTranslation } from "react-i18next";
import { UserAvailabilityStatus } from "../../../API/user/userActions.type";
import { useBlockUser } from "../../../hooks/useFriendAction/useFriendAction";
import { IntersectElementRef } from "../../../hooks/useInfinitePaginationWithIntersect/useInfinitePaginationWithIntersect";
import { useModal } from "../../../modals";
import { FriendModalOptions } from "../../../modals/ChooseOptionModal/ChooseOptionModal";
import PlayerBar from "../../common/Player/PlayerBar/PlayerBar";

type FriendProps = {
  playerId: string;
  username: string;
  avatarUrl: string | null;
  actionButtons?: JSX.Element | JSX.Element[];
  additionalOptions: FriendModalOptions[];
  innerRef?: IntersectElementRef | null;
  status: UserAvailabilityStatus;
  hasPendingInvitation: boolean;
};

function Friend({ innerRef, playerId, username, additionalOptions, avatarUrl, actionButtons, status, hasPendingInvitation }: FriendProps) {
  const { t } = useTranslation();
  const { openAcceptationModal, closeModal } = useModal();

  const blockUser = useBlockUser();

  const onBlockUser = async () => {
    await blockUser.mutateAsync({ userId: playerId });
    closeModal();
  };

  const generateOptions = () => {
    const options: FriendModalOptions[] = [
      {
        label: t("friends.block_friend"),
        onClick: () => {
          openAcceptationModal({
            type: "yes-no",
            question: t("friends.block_friend_confirmation"),
            onAcceptFunc: () => onBlockUser(),
            onRejectFunc: () => closeModal(),
          });
        },
      },
      {
        label: t("friends.report_friend"),
        onClick: () => {},
      },
    ];

    if (Array.isArray(additionalOptions)) {
      options.unshift(...additionalOptions);
    }
    return options;
  };

  return (
    <PlayerBar
      playerId={playerId}
      username={username}
      avatarUrl={avatarUrl}
      actionButtons={actionButtons}
      chooseModalOptions={generateOptions()}
      innerRef={innerRef}
      avatarType="basic"
      status={status}
      isPremium={false}
      hasPendingInvitation={hasPendingInvitation}
    />
  );
}

export default Friend;
