import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ForgetPasswordStyled from "./ForgetPassword.style";
import PageContent from "./pageContent.type";
import ForgetPasswordStage1 from "./ForgetPasswordStage1/ForgetPasswordStage1";
import ForgetPasswordStage2 from "./ForgetPasswordStage2/ForgetPasswordStage2";

function ForgetPassword() {
  const { t } = useTranslation();
  const [firstStageCompleted, setFirstStageCompleted] = useState(false);
  const [email, setEmail] = useState<string>();

  const translations: PageContent = t("forget", { returnObjects: true });

  return (
    <ForgetPasswordStyled>
      <div className="wrapper">
        <h1>{translations.heading}</h1>
        <ForgetPasswordStage1
          firstStageCompleted={firstStageCompleted}
          setFirstStageCompleted={setFirstStageCompleted}
          email={email}
          setEmail={setEmail}
        />
        {firstStageCompleted && email && <ForgetPasswordStage2 email={email} />}
      </div>
    </ForgetPasswordStyled>
  );
}

export default ForgetPassword;
