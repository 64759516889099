import React from "react";
import { Link } from "react-router-dom";
import Routes from "../../config/routes";
import NotFoundStyled from "./NotFound.style";

function NotFound() {
  return (
    <NotFoundStyled>
      <h1>Page Not Found</h1>
      <Link to={Routes.Home}>Go Home</Link>
    </NotFoundStyled>
  );
}

export default NotFound;
