import React, { useEffect, useState } from "react";
import { Wallet } from "../../../../API/user/userActions.type";
import useWallets from "../../../../hooks/useWallets/useWallets";
import WalletBox from "../../../header/WalletBox/WalletBox";
import WalletSwitchStyled from "../../../header/WalletSwitch/WalletSwitch.style";

function BonusWallet({ isMobile = false }) {
  const { bonusWallet } = useWallets();

  const [selectedWallet, setSelectedWallet] = useState<Wallet>();

  useEffect(() => {
    setSelectedWallet(bonusWallet);
  }, [bonusWallet]);

  return (
    <WalletSwitchStyled>
      <div className={`wallets-info ${isMobile ? "mobile-wallet-switch" : ""}`}>
        <div className="wallets-buttons-container">
          <button
            type="button"
            className={`bonus-btn ${selectedWallet?.type === "bonus" && "active"}`}
            disabled
          >
            <span>Bonus</span>
          </button>
          <button
            type="button"
            className={`wallet-btn ${selectedWallet?.type === "deposit" && "active"}`}
            disabled
          >
            <span>Wallet</span>
          </button>
        </div>
        {selectedWallet && (
          <WalletBox
            wallet={selectedWallet}
            isMobile={isMobile}
          />
        )}
      </div>
    </WalletSwitchStyled>
  );
}

export default BonusWallet;
