import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageContext from "../../context/languageContext/LanguageContext";
import useProfile from "../../hooks/useProfile/useProfile";
import useStorage from "../../hooks/useStorage/useStorage";
import languageStorage from "../../storage/lang/language.storage";
import { LanguageProviderProps } from "./LanguageProvider.types";

const DEFAULT_LANGUAGE = {
  language: "pl-PL",
  shortLanguage: "pl",
};

function LanguageProvider({ children }: LanguageProviderProps): React.ReactElement {
  const [languageProviderValue, setLanguageProviderValue] = useState(DEFAULT_LANGUAGE);
  const profile = useProfile();
  const { i18n } = useTranslation();

  const storageLanguage = useStorage(languageStorage);

  const updateLang = (firstLoad: boolean = false) => {
    let lang;
    if (profile?.data?.language) {
      lang = {
        language: profile.data.language,
        shortLanguage: profile.data.language.slice(0, 2),
      };
    } else if (storageLanguage) {
      lang = {
        language: storageLanguage,
        shortLanguage: storageLanguage.slice(0, 2),
      };
    } else {
      lang = DEFAULT_LANGUAGE;
    }
    if (firstLoad || lang !== languageProviderValue) {
      setLanguageProviderValue(lang);
      i18n.changeLanguage(lang.language);
      languageStorage.set(lang.language);
    }
  };

  useEffect(() => {
    updateLang(true);
  }, []);

  useEffect(() => {
    updateLang();
  }, [profile]);

  useEffect(() => {
    if (storageLanguage) {
      setLanguageProviderValue({
        language: storageLanguage,
        shortLanguage: storageLanguage.slice(0, 2),
      });
      i18n.changeLanguage(storageLanguage);
    }
  }, [storageLanguage]);

  return <LanguageContext.Provider value={languageProviderValue}>{children}</LanguageContext.Provider>;
}

export default LanguageProvider;
