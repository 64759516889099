import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../common/buttons/button/Button";
import Layout from "../layout";
import { matchIcon, fightIcon, payoutIcon, royalIcon, dateIcon, waitingIcon } from "../../assets/images";
import InfoBox from "./InfoBox/InfoBox";
import PageContent from "./pageContent.type";
import HowItWorksStyled from "./HowItWorks.style";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import Routes from "../../config/routes";

function HowItWorks() {
  const { t } = useTranslation();

  const translations: PageContent = t("howitworks", { returnObjects: true });

  return (
    <Layout>
      <HowItWorksStyled>
        <div className="container">
          <Breadcrumb
            linkTextCurrent={translations.heading}
            linkTextPrevious={translations.home}
            link={Routes.Home}
          />

          <div className="content">
            <div className="left-side">
              <div className="left-content">
                <p>{translations.heading_description1}</p>
                <p>{translations.heading_description2}</p>
                <p className="span-content">{translations.heading_description3}</p>
                <p>{translations.heading_description4}</p>
                <ul>
                  <li>{translations.step_1}</li>
                  <li>{translations.step_2}</li>
                  <li>{translations.step_3}</li>
                  <li>{translations.step_4}</li>
                  <li>{translations.step_5}</li>
                </ul>
              </div>
              <div className="left-content tournament-description">
                <p>{translations.tournament_description}</p>
                <div>
                  <h4 className="left-title">{translations.quick_torunament_heading}</h4>
                  <p>{translations.quick_torunament_description}</p>
                </div>
              </div>
              <div className="left-content players-rewards">
                <h4 className="left-title">{translations.players_rewards_title}</h4>
                <p>{translations.players_rewards_description1}</p>
                <p>{translations.players_rewards_description2}</p>
                <p>{translations.players_rewards_description3}</p>
              </div>
            </div>

            <div className="right-side">
              <div className="right-content vertical-line">
                <h2 className="title">{translations.heading_duel}</h2>
                <p className="duel-description">{translations.duel_description}</p>
                <InfoBox
                  src={matchIcon}
                  header={translations.duel_step1}
                  paragraph1={translations.duel_step1_description1}
                  paragraph2={translations.duel_step1_description2}
                  paragraph3={translations.duel_step1_description3}
                />
                <InfoBox
                  src={fightIcon}
                  header={translations.duel_step2}
                  paragraph1={translations.duel_step2_description}
                />
                <InfoBox
                  src={payoutIcon}
                  header={translations.duel_step3}
                  paragraph1={translations.duel_step3_description1}
                  paragraph2={translations.duel_step3_description2}
                />
                <Button
                  type="labelButton"
                  variant="red"
                  label={translations.start_duel_button}
                  onClick={() => {}}
                />
              </div>
              <div className="right-content vertical-line">
                <h2 className="title">{translations.heading_tournament}</h2>
                <InfoBox
                  src={matchIcon}
                  header={translations.tournament_step1}
                />
                <InfoBox
                  src={fightIcon}
                  header={translations.tournament_step2}
                  paragraph1={translations.tournament_step2_description}
                />
                <InfoBox
                  src={payoutIcon}
                  header={translations.tournament_step3}
                  paragraph1={translations.tournament_step3_description}
                />
                <InfoBox
                  src={payoutIcon}
                  header={translations.tournament_step4}
                  paragraph1={translations.tournament_step4_description}
                />
                <Button
                  type="labelButton"
                  variant="blue"
                  label={translations.tournament_button}
                  onClick={() => {}}
                />
              </div>
              <div className="right-content">
                <h2 className="title">{translations.heading_newtournament}</h2>
                <InfoBox
                  src={royalIcon}
                  header={translations.newtournament_step1}
                  paragraph1={translations.newtournament_step1_description}
                />
                <InfoBox
                  src={payoutIcon}
                  header={translations.newtournament_step2}
                  paragraph1={translations.newtournament_step2_description1}
                />
                <InfoBox
                  src={dateIcon}
                  header={translations.newtournament_step3}
                  paragraph1={translations.newtournament_step3_description1}
                  paragraph2={translations.newtournament_step3_description2}
                />
                <InfoBox
                  src={waitingIcon}
                  header={translations.newtournament_step4}
                />
              </div>
            </div>
          </div>
        </div>
      </HowItWorksStyled>
    </Layout>
  );
}

export default HowItWorks;
