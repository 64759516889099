import { Action, PaginatedAction } from "../action.type";
import { GetGameStakesResponse, GetPopularGamesResponse, GetBonusRoomsResponse, ActivateBonusRoomPayload, GetUserMatchesQuery, GetBonusHistoryQuery, BonusRoomActivated, MatchPayload, GetBonusStakesResponse, GameTraining } from "./gameActions.type";

export function getGameStakes(gameId: string): Action<GetGameStakesResponse> {
  return {
    method: "GET",
    endpoint: `/game/${gameId}/stakes`,
  };
}

export function getPopularGames(locale: string): Action<GetPopularGamesResponse> {
  return {
    method: "GET",
    endpoint: `/game/popular?locale=${locale}`,
  };
}

export function getBonusRooms(): Action<GetBonusRoomsResponse> {
  return {
    method: "GET",
    endpoint: `/bonus-room/active`,
  };
}

export function getHistoryBonusRooms(params?: GetBonusHistoryQuery): PaginatedAction<BonusRoomActivated> {
  const query: Record<string, unknown> & { pageSize: number } = {
    page: params?.page || 1,
    pageSize: params?.pageSize || 8,
    status: params?.status || undefined,
    activateFee: params?.activateFee || undefined,
  };

  if (params?.sort) {
    query.sort = `${params.sort.direction === "desc" ? "-" : ""}${params.sort.field}`;
  }

  return {
    method: "GET",
    endpoint: `/bonus-room/history`,
    query,
  };
}

export function getBonusRoomStakes(): Action<GetBonusStakesResponse[]> {
  return {
    method: "GET",
    endpoint: "/bonus-room/stakes",
  };
}

export function activateBonusRoom(payload: ActivateBonusRoomPayload): Action<void> {
  return {
    method: "POST",
    endpoint: `/bonus-room/activate`,
    payload,
  };
}

export function getUserMatches(params?: GetUserMatchesQuery): PaginatedAction<MatchPayload> {
  const query: Record<string, unknown> & { pageSize: number } = {
    page: params?.page || 1,
    pageSize: params?.pageSize || 10,
    gameId: params?.gameId || undefined,
    type: params?.type || undefined,
  };

  if (params?.sort) {
    query.sort = `${params.sort.direction === "desc" ? "-" : ""}${params.sort.field}`;
  }
  return {
    method: "GET",
    endpoint: "/user/match",
    query,
  };
}

export function getGameAutoComplete(locale: string, search?: string): Action<{ id: string; name: string }[]> {
  return {
    method: "GET",
    endpoint: "/game/auto-complete",
    query: {
      locale,
      search,
    },
  };
}

export function startTraining(gameId: string): Action<GameTraining> {
  return {
    method: "POST",
    endpoint: `/game/${gameId}/training`,
  };
}
