import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css";
import Layout from "../layout";
import { getPageContent } from "../../helpers/content";
import { LegalPageContent } from "./pageContent.type";
import useLanguage from "../../hooks/useLanguage/useLanguage";

function Legal() {
  const { i18n } = useTranslation();
  const [pageContent, setPageContent] = useState<LegalPageContent>();
  const page = "/legal";
  const { shortLanguage } = useLanguage();
  const onLanguageChanged = () => {
    getPageContent(page, shortLanguage).then((data) => setPageContent(data));
  };

  useEffect(() => {
    getPageContent(page, shortLanguage).then((data) => setPageContent(data));
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  if (!pageContent) {
    return <div>Not Found</div>;
  }
  return (
    <Layout>
      <div className="legal">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-page legal-title">
                <ul className="breadcrum">
                  <li>
                    <Link to="/">{pageContent.home}</Link>
                  </li>
                  <li>{pageContent.heading1}</li>
                </ul>
                <h2>{pageContent.heading1}</h2>
                <p>{pageContent.para1}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <div className="legal-content-box">
                <h2>{pageContent.heading2}</h2>

                <p>{pageContent.para3}</p>

                <p>{pageContent.para4}</p>

                <h3>{pageContent.heading3}</h3>

                <p>{pageContent.para5}</p>

                <h4>{pageContent.heading4}</h4>

                <ul className="legal-list">
                  <li>{pageContent.para6}</li>
                  <li>{pageContent.para7}</li>
                  <li>{pageContent.para8}</li>
                  <li>{pageContent.para9}</li>
                </ul>

                <ul className="legal-no-list">
                  <li>{pageContent.para6}</li>
                  <li>{pageContent.para7}</li>
                  <li>{pageContent.para8}</li>
                  <li>{pageContent.para9}</li>
                </ul>

                <h5>{pageContent.heading5}</h5>

                <p>{pageContent.para10}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Legal;
