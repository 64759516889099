import { createGlobalStyle } from "styled-components";
import { forDesktop } from "./helpers/breakpoints/viewports";

const globalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family:${({ theme }) => theme.fontFamilies.roboto};
    color: ${({ theme }) => theme.colors.white};
    box-sizing: border-box;
  }

  .bold-text {
    font-family: ${({ theme }) => theme.fontFamilies.robotoBold};
  }

  .grecaptcha-badge { visibility: hidden;}

  .scrollbar-global::-webkit-scrollbar {
    width: 2px;
  }

  .scrollbar-global::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.buttonSecondary};
  }

  .scrollbar-global::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.divider};
  }


  @media (${forDesktop}) {
    .grecaptcha-badge { visibility: visible;}
  }
`;

export default globalStyles;
