import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import "./header.css";
import { hamburgerMenu, logoHorizontalWhiteText, userProfile } from "../../assets/images";
import Routes from "../../config/routes";
import useProfile from "../../hooks/useProfile/useProfile";
import useAuth from "../../hooks/useAuth/useAuth";
import { ProfileResponse } from "../../API/user/userActions.type";
import PageContent from "./pageContent.type";
import WalletSwitch from "./WalletSwitch/WalletSwitch";
import NotificationsDropdown from "../common/notificationsDropdown/NotificationsDropdown";
import ProfileDropdown from "./ProfileDropdown/ProfileDropdown";
import LanguageDropdown from "./LanguageDropdown/LanguageDropdown";
import MobileHeader from "./MobileHeader/MobileHeader";

function Header() {
  const navigation = useNavigate();
  const [profile, setProfile] = useState<ProfileResponse | undefined>();
  const [profilePicture, setProfilePicture] = useState(userProfile);

  const [isMobileHeaderCollapsed, setIsMobileHeaderCollapsed] = useState(true);

  const { t } = useTranslation();

  const profileQuery = useProfile();
  const isAuth = useAuth();
  const translations: PageContent = t("header", { returnObjects: true });

  useEffect(() => {
    setProfile(profileQuery?.data);
    setProfilePicture(userProfile);
  }, []);

  useEffect(() => {
    setProfile(profileQuery?.data);
  }, [profileQuery]);

  useEffect(() => {
    localStorage.setItem("lastlocation", window.location.pathname);
  }, []);

  return (
    <header>
      <Navbar expand>
        <div className="desktop-header">
          <div className="navbar-left-container">
            <Link to={Routes.Home}>
              <div className="logo">
                <img
                  src={logoHorizontalWhiteText}
                  alt="logo"
                />
              </div>
            </Link>
            <Nav>
              <NavLink
                className="nav-link"
                to="/"
              >
                {translations.menu1}
              </NavLink>
              <NavLink
                className="nav-link"
                to="/populargames"
              >
                {translations.menu2}
              </NavLink>
              <NavLink
                className="nav-link"
                to="/external-tournaments-current"
              >
                {translations.menu3}
              </NavLink>
              <NavLink
                className="nav-link"
                to="/notifications"
              >
                {translations.menu4}
              </NavLink>
              <NavLink
                className="nav-link"
                to="/seriesGame"
              >
                {translations.menu5}
              </NavLink>
              <NavLink
                className="nav-link"
                to="/bonus"
              >
                {translations.menu6}
              </NavLink>
            </Nav>
          </div>
          {!isAuth && (
            <div className="auth-buttons-container">
              <button
                type="button"
                className="login-btn"
                onClick={() => navigation(Routes.Login)}
              >
                <span>{translations.login}</span>
              </button>
              <button
                type="button"
                className="signup-btn"
                onClick={() => navigation(Routes.SignUp)}
              >
                <span>{translations.signup}</span>
              </button>
            </div>
          )}
          {profile && (
            <div className="head-user-info">
              <WalletSwitch />

              <div className="separator-container">
                <div className="vertical-separator" />

                <NotificationsDropdown />
              </div>

              <ProfileDropdown
                profilePicture={profilePicture}
                profile={profile}
              />
              <LanguageDropdown />
            </div>
          )}
        </div>

        <div className="mobile-header">
          <Link to={Routes.Home}>
            <div className="logo">
              <img
                src={logoHorizontalWhiteText}
                alt="logo"
              />
            </div>
          </Link>

          <button
            type="button"
            className="hamburger-manu-btn"
            onClick={() => setIsMobileHeaderCollapsed(false)}
          >
            <img
              src={hamburgerMenu}
              alt="close button"
            />
          </button>

          {!isMobileHeaderCollapsed && (
            <MobileHeader
              profilePicture={profilePicture}
              profile={profile}
              isAuth={isAuth}
              setIsMobileHeaderCollapsed={setIsMobileHeaderCollapsed}
            />
          )}
        </div>
      </Navbar>
    </header>
  );
}

export default Header;
