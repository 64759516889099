import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import PageContent from "../PageContent.type";
import BonusItemActivated from "../BonusItemActivated/BonusItemActivated";
import { FilterInterface, Option } from "../../table/Filter/Filter";
import { BonusRoomActivated, GetBonusStakesResponse } from "../../../API/game/gameActions.type";
import { getBonusRoomStakes, getHistoryBonusRooms } from "../../../API/game/gameActions";
import { ActionSort, PaginatedResult } from "../../../API/action.type";
import useAppQuery from "../../../hooks/useQuery/useQuery";
import Loader from "../../loader/Loader";
import useInfinitePaginationWithIntersect from "../../../hooks/useInfinitePaginationWithIntersect/useInfinitePaginationWithIntersect";

const REQUIRED_MATCHES_TO_WIN = 3;
const DEFAULT_SORT: ActionSort = { field: "createdAt", direction: "desc" };

function useBonusHistory() {
  const translations: PageContent = useTranslation().t("bonus_room", { returnObjects: true });

  const [filters, setFilters] = useState<Record<string, unknown>>({});
  const [sort, setSort] = useState<ActionSort>(DEFAULT_SORT);

  const { data, isLoading, lastElementRef } = useInfinitePaginationWithIntersect(
    getHistoryBonusRooms({
      ...filters,
      sort,
      pageSize: 8,
    })
  );

  const { data: stakesData } = useAppQuery(getBonusRoomStakes());

  const mapResponseToOption = (response: GetBonusStakesResponse[]): Option[] =>
    response.map((e) => ({
      label: `${e.stake} ${e.currency}`,
      value: `${e.stake}`,
    }));

  const formik = useFormik({
    initialValues: {
      status: "",
      activateFee: "",
      sort: "",
    },
    onSubmit: (values: any) => {
      setFilters(values);
    },
  });

  useEffect(() => {
    formik.handleSubmit();
  }, [formik.values]);

  const formikFilters: FilterInterface[] = [
    {
      isAutoComplete: false,
      label: translations.filter_result_label,
      id: "bonus-history-filter-result",
      name: "status",
      value: formik.values.status,
      onChange: (option: Option | null) => formik.setFieldValue("status", option?.value || null),
      options: [
        { label: translations.filter_result_option_win, value: "won" },
        { label: translations.filter_result_option_lose, value: "lost" },
      ],
    },
    {
      isAutoComplete: false,
      label: translations.filter_stake_label,
      id: "bonus-history-filter-stake",
      name: "activateFee",
      value: formik.values.activateFee,
      onChange: (option: Option | null) => formik.setFieldValue("activateFee", option?.value || null),
      options: mapResponseToOption(stakesData || []),
    },
    {
      isAutoComplete: false,
      label: translations.filter_sort_label,
      id: "bonus-history-filter-sort",
      name: "sort",
      value: formik.values.sort,
      onChange: (option: Option | null) => {
        if (typeof option?.value === "string") {
          return;
        }

        if (!option) {
          return setSort(DEFAULT_SORT);
        }
        setSort(option.value);
      },
      options: [
        { label: translations.filter_sort_stake_asc, value: { field: "activateFee", direction: "asc" } },
        { label: translations.filter_sort_stake_desc, value: { field: "activateFee", direction: "desc" } },
        { label: translations.filter_sort_newest, value: { field: "createdAt", direction: "desc" } },
        { label: translations.filter_sort_oldest, value: { field: "createdAt", direction: "asc" } },
      ],
      defaultValue: translations.filter_sort_newest,
    },
  ];

  const buildBonusRoomItems = (pages?: PaginatedResult<BonusRoomActivated>[]) => {
    const bonusRooms = pages?.map((page) => page.items).flat();

    if (isLoading) {
      return (
        <div className="loader-container">
          <Loader />
        </div>
      );
    }
    if (!bonusRooms?.length) {
      return (
        <div className="no-history">
          <p>{translations.no_history}</p>
        </div>
      );
    }

    return (
      <div className="bonus-history-container">
        {bonusRooms.map((item, index) => (
          <BonusItemActivated
            key={`bonus-history-${item.id}`}
            item={item}
            requiredMatches={REQUIRED_MATCHES_TO_WIN}
            innerRef={bonusRooms.length === index + 1 ? lastElementRef : null}
          />
        ))}
      </div>
    );
  };

  return { bonusRoomCards: buildBonusRoomItems(data?.pages), filters: formikFilters };
}

export default useBonusHistory;
