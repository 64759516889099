import styled from "styled-components";

const DotIndicatorStyled = styled.div<{ isRead: boolean; width?: string }>`
  max-width: ${({ width }) => width};
  display: flex;
  align-items: center;

  .notification-dot {
    width: 7px;
    height: 7px;
    max-width: 7px;
    background-color: ${({ theme, isRead }) => (isRead ? "transparent" : theme.colors.blue)};
    border: 1px solid ${({ theme }) => theme.colors.blue};
    border-radius: 50%;
    margin: auto;
  }
`;

export default DotIndicatorStyled;
