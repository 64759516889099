import React from "react";
import Button from "../../components/common/buttons/button/Button";
import FriendOptionsModalStyled from "./ChooseOptionModal.style";

export type FriendModalOptions = { label: string; onClick: () => void };

interface FriendOptionsModalProps {
  options: FriendModalOptions[];
}

function FriendOptionsModal({ options }: FriendOptionsModalProps) {
  return (
    <FriendOptionsModalStyled>
      {options.map((option) => (
        <Button
          type="labelButton"
          label={option.label}
          variant="ghost"
          onClick={option.onClick}
          key={option.label}
        />
      ))}
    </FriendOptionsModalStyled>
  );
}

export default FriendOptionsModal;
