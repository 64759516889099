import React, { useEffect, useState } from "react";
import Layout from "../layout";

let interval;

function EnterTournament() {
  // const [timeLeft, setTimeLeft] = useState(null);
  const title = "";
  const [timerdays, setDays] = useState("");
  const [timerhours, setHours] = useState("");
  const [timerminutes, setMinutes] = useState("");
  const [timerseconds, setSeconds] = useState("");
  const countDouwnDate = null;

  const [delayed, setDelayed] = useState(false);
  const isLoading = false;

  const startTimer = () => {
    interval = setInterval(() => {
      const now = new Date().getTime();
      const milliseconds = Math.abs(now - countDouwnDate).toString();
      const sec = parseInt(milliseconds / 1000, 10);
      const min = parseInt(sec / 60, 10);
      const h = parseInt(min / 60, 10);
      const d = parseInt(h / 24, 10);
      if (countDouwnDate - now < 0) {
        clearInterval(interval);
        setDelayed(true);
      } else if (milliseconds >= 0 && milliseconds <= 30000) {
        clearInterval(interval);
      } else {
        setSeconds(parseInt(sec % 60, 10));
        setMinutes(parseInt(min, 10) % 60);
        setHours(parseInt(h % 24, 10));
        setDays(parseInt(d % 24, 10));
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval);
    };
  });

  // const check = async (values) => {
  //   const res = await userInstance.get(`/tournamentdetails/${values.tour_id}`);
  //   if (res.data.code === 200) {
  //     const tournamentDate = res.data.data.tournamentStart;
  //     setGame(res.data.data.gameType);
  //     if (res.data.data.tournamentStart) {
  //       setMsg("Sry ! Tournament Already started. You are late");
  //     }
  //     if (res.data.data.tournamentEnd) {
  //       setMsg("Tournament Finished.");
  //     }
  //     setTitle(res.data.data.title);

  //     const start = new Date(tournamentDate).getTime();
  //     setCountDownDate(new Date(tournamentDate).getTime());
  //     const currDate = new Date().toISOString();
  //     const today = new Date(currDate).getTime();

  //     const timediff = start - today;
  //     const milliseconds = Math.abs(today - start).toString();
  //     const seconds = parseInt(milliseconds / 1000, 10);
  //     const minutes = parseInt(seconds / 60, 10);
  //     const hours = parseInt(minutes / 60, 10);
  //     const days = parseInt(hours / 24, 10);
  //     const time = ` ${days} Days : ${hours % 24} hours : ${minutes % 60} Minutes : ${seconds % 60}  Seconds`;
  //     //  let obj = { timeDiff:timediff,leftTime:time,}
  //     setTimeLeft(time);
  //     obj = {
  //       tournamentId: values.tour_id,
  //     };
  //     // if(milliseconds<=599990 && milliseconds>=0 ){
  //     //     setRedirect(true);
  //     //     socket.emit("tournamentRoomDetail",obj);

  //     // }
  //     setIsLoading(true);
  //     return obj;
  //   }
  //   if (res.data.code === 400) {
  //     localStorage.clear();
  //     clearInterval(interval);
  //     window.location.href = "/login";
  //   }
  // };

  // const getRoomId = () => {
  //   localStorage.setItem("betAmt", 0);
  //   localStorage.setItem("opponent", "Opponent");
  //   const values = queryString.parse(window.location.search);
  //   setTourid(values.tour_id);
  //   return values;
  // };

  useEffect(() => {
    // const values = getRoomId();
    // const data = check(values);
  }, []);

  return (
    isLoading && (
      <Layout>
        {delayed ? (
          <div
            style={{
              width: "50vw",
              height: "50vh",
              margin: "auto",
              marginTop: "200px",
              alignItems: "center",
              color: "white",
            }}
          >
            <h3>msg</h3>
          </div>
        ) : (
          <div
            style={{
              width: "50vw",
              height: "50vh",
              margin: "auto",
              marginTop: "200px",
              alignItems: "center",
              color: "white",
            }}
          >
            <h3>{`Tournament Title : ${title}`}</h3>
            <br />
            {`Time Left : ${timerdays}  Days :  ${timerhours} Hours :  ${timerminutes} Minutes : ${timerseconds} Seconds`}
          </div>
        )}
      </Layout>
    )
  );
}

export default EnterTournament;
