import React, { useEffect, useState } from "react";
import useWallets from "../../../hooks/useWallets/useWallets";
import WalletInfoStyled from "./WalletInfo.style";

interface WalletInfoProps {
  type: "bonus" | "deposit";
}

function WalletInfo({ type }: WalletInfoProps) {
  const { depositWallet, bonusWallet } = useWallets();

  const [walletAmount, setWalletAmount] = useState<{ usd: string; local: string }>();

  const NUMBER_STYLE = { minimumFractionDigits: 2, maximumFractionDigits: 2 };

  useEffect(() => {
    const wallets = [depositWallet, bonusWallet];
    const wallet = wallets.find((item: any) => item?.type === type);
    const usd = new Intl.NumberFormat("pl-PL", NUMBER_STYLE).format(wallet?.amountInUSD || 0);
    const local = new Intl.NumberFormat("pl-PL", NUMBER_STYLE).format(wallet?.amount || 0);
    setWalletAmount({ usd, local });
  }, [depositWallet, bonusWallet, type]);

  if (!walletAmount) {
    return null;
  }

  return (
    <WalletInfoStyled>
      <p className="type">{type}:</p>
      <p className="amount">{walletAmount.usd}</p>
      <p>USD</p>
      <p className="amount">{walletAmount.local}</p>
      <p>{depositWallet?.currency}</p>
    </WalletInfoStyled>
  );
}

export default WalletInfo;
