/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChangeAccountNumberModalStyled from "./ChangeAccountNumberModal.style";
import PageContent from "./pageContent.type";
import useAppQuery from "../../hooks/useQuery/useQuery";
import { getAccountNumberChangeInProgress } from "../../API/user/userActions";
import Loader from "../../components/loader/Loader";
import ChangeAccountNumberStage1 from "./ChangeAccountNumberStage1/ChangeAccountNumberStage1";
import ChangeAccountNumberStage2 from "./ChangeAccountNumberStage2/ChangeAccountNumberStage2";
import CompleteAccountNumber from "./CompleteAccountNumber/CompleteAccountNumber";

interface ChangeAccountNumberModalProps {
  currentNumber: string | undefined;
}

function ChangeAccountNumberModal({ currentNumber }: ChangeAccountNumberModalProps) {
  const { t } = useTranslation();
  const translations: PageContent = t("change_account_number", { returnObjects: true });

  const checkChangeInProgressQuery = useAppQuery(getAccountNumberChangeInProgress(), { retry: false, refetchOnWindowFocus: false });

  const [firstStageCompleted, setFirstStageCompleted] = useState(false);
  const [newAccountNumber, setNewAccountNumber] = useState<string>();

  useEffect(() => {
    if (checkChangeInProgressQuery.isSuccess) {
      setFirstStageCompleted(true);
      setNewAccountNumber(checkChangeInProgressQuery.data.accountNumber);
    }
  }, [checkChangeInProgressQuery]);

  if (checkChangeInProgressQuery.isLoading) {
    return <Loader />;
  }

  return (
    <ChangeAccountNumberModalStyled>
      <div className="change-account-number-container">
        <h2 className="title">{currentNumber ? translations.heading_change : translations.heading_complete}</h2>
        {currentNumber ? (
          <ChangeAccountNumberStage1
            setFirstStageCompleted={setFirstStageCompleted}
            currentNumber={currentNumber}
            newNumber={newAccountNumber}
            setAccountNumber={setNewAccountNumber}
          />
        ) : (
          <CompleteAccountNumber
            setFirstStageCompleted={setFirstStageCompleted}
            newNumber={newAccountNumber}
            setAccountNumber={setNewAccountNumber}
          />
        )}
        {firstStageCompleted && newAccountNumber && <ChangeAccountNumberStage2 accountNumber={newAccountNumber} />}
      </div>
    </ChangeAccountNumberModalStyled>
  );
}

export default ChangeAccountNumberModal;
