import styled from "styled-components";

const ChooseGameModalStyled = styled.div`
  max-width: 1088px;
  margin: 30px auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title-container {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 15px;
  }

  .title {
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.h1};
    margin: 0;
  }

  .title-coin {
    width: 27px;
    height: 27px;
  }

  .bonus-room-status {
    font-size: ${({ theme }) => theme.fontSizes.p};
    display: flex;
    column-gap: 65px;
    padding: 15px 20px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.black};
    width: fit-content;
    margin-bottom: 20px;
  }

  .bonus-room-status p {
    margin: 0;
  }

  .flex-container {
    display: flex;
    align-items: center;
  }

  .wallet-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }

  .filters-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .choose-game {
    margin: 0;
  }

  .choose-game::first-letter {
    text-transform: uppercase;
  }

  .filters {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
  }
`;

export default ChooseGameModalStyled;
