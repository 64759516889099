import styled from "styled-components";

const ImageInputStyled = styled.div`
  input[type="file"] {
    display: none;
  }
  .file-input {
    text-transform: uppercase;
    transition: all 0.3s ease;
    padding: 12px 15px;
    font-size: ${({ theme }) => theme.fontSizes.buttonMobile};
    line-height: 1.5em;
    border-radius: 3px;
    font-weight: 400;
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid transparent;
    background-color: ${({ theme }) => theme.colors.red};
    display: block;
    margin: auto;
    width: 200px;
    text-align: center;
  }

  .file-input:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.red};
    border-color: ${({ theme }) => theme.colors.red};
    box-shadow: 0px 0px 50px 0px #ffffff9c;
    outline: none;
  }

  .error {
    color: ${({ theme }) => theme.colors.red};
    font-family: ${({ theme }) => theme.fontFamilies.robotoBold};
    font-size: ${({ theme }) => theme.fontSizes.caption};
    height: 20px;
  }
`;

export default ImageInputStyled;
