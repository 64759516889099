/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { closeIcon, filledArrowIcon } from "../../../assets/images";
import Button from "../buttons/button/Button";
import OptionsMenuStyled from "./OptionsMenu.style";

interface Option {
  label: string;
  onClick: () => void;
}

interface OptionsMenuProps {
  title?: string;
  onClose: () => void;
  options: Option[];
}

function OptionsMenu({ title, onClose, options }: OptionsMenuProps) {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [optionsHeight, setOptionsHeight] = useState(optionsRef.current?.scrollHeight || 0);
  const viewportHeight = window.innerHeight;

  useEffect(() => {
    setOptionsHeight(optionsRef.current?.scrollHeight || 0);
  }, [options]);

  const handleClose = useCallback(() => {
    const item = containerRef.current;
    item?.classList.add("close-animation");
    setTimeout(() => onClose?.(), 500); // 0.5s - duration of closing animation
  }, [onClose]);

  const handleEsc = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEsc, false);
    return () => {
      document.removeEventListener("keydown", handleEsc, false);
    };
  }, [handleEsc]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return createPortal(
    <OptionsMenuStyled
      onClick={handleClose}
      optionsHeight={optionsHeight}
      viewportHeight={viewportHeight}
      containerRef={containerRef}
    >
      <div
        className="container"
        ref={containerRef}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="top-bar">
          <p className="title">{title || t("options_menu.choose_action_title")}</p>
          <Button
            type="iconButton"
            variant="ghost"
            icon={closeIcon}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
          />
        </div>
        <div
          className="options"
          ref={optionsRef}
        >
          {options.map((option) => (
            <Button
              type="labelButton"
              variant="ghost"
              onClick={option.onClick}
              label={option.label}
              className="action-button"
              key={`options-menu-${option.label}`}
            />
          ))}
        </div>
        <div className="cancel">
          <Button
            type="labelAndIconButton"
            variant="ghost"
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
            label={t("common.cancel")}
            icon={filledArrowIcon}
            alt=""
            iconPosition="left"
            className="cancel-button"
          />
        </div>
      </div>
    </OptionsMenuStyled>,
    document.body
  );
}

export default OptionsMenu;
