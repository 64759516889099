import styled from "styled-components";
import { forDesktop } from "../../../../helpers/breakpoints/viewports";

const generatePadding = (staticTextLeft?: boolean, staticTextRight?: boolean) => {
  let padding;
  if (staticTextLeft) {
    padding = " 10px 10px 10px 50px";
  } else if (staticTextRight) {
    padding = "10px 50px 10px 10px";
  } else {
    padding = "8px 16px";
  }
  return padding;
};

const InputStyled = styled.div<{ staticTextLeft?: boolean; staticTextRight?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .form-label {
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.formLabelMobile};
  }

  .input-wrapper {
    width: 100%;
    position: relative;
  }

  .text-input {
    width: 100%;
    background: ${({ theme }) => theme.colors.blackBackgroundOpacity40};
    border: none;
    border-radius: 2px;
    padding: ${({ staticTextLeft, staticTextRight }) => generatePadding(staticTextLeft, staticTextRight)};
    font-size: ${({ theme }) => theme.fontSizes.formInputMobile};
    color: ${({ theme }) => theme.colors.whiteText};
    height: 30px;
    caret-color: ${({ theme }) => theme.colors.whiteText};
  }

  .text-input:focus {
    outline: none;
  }

  .text-input::placeholder {
    color: ${({ theme }) => theme.colors.whiteOpacity25};
    text-transform: capitalize;
  }

  .text-input:disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    color: ${({ theme }) => theme.colors.whiteOpacity25};
  }

  .static-text-left {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.colors.white};
    margin: 0;
  }

  .static-text-right {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.colors.white};
    margin: 0;
  }

  .red {
    border-color: ${({ theme }) => theme.colors.error};
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.formError};
    margin-top: 8px;
    text-align: left;
  }

  .error::first-letter {
    text-transform: uppercase;
  }

  // turn off chrome styling when autofill
  .text-input:-webkit-autofill,
  .text-input:-webkit-autofill:hover,
  .text-input:-webkit-autofill:focus,
  .text-input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 30px #000 inset !important;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.whiteText};
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: none;
  }

  @media ${forDesktop} {
    .form-label {
      font-size: ${({ theme }) => theme.fontSizes.formLabelDesktop};
    }
    .text-input {
      font-size: ${({ theme }) => theme.fontSizes.formInputDesktop};
      height: 40px;
    }
    .error {
      letter-spacing: 0.15em;
    }
  }
`;

export default InputStyled;
