import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Dropdown, ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import PageContent from "../pageContent.type";
import { ProfileResponse } from "../../../API/user/userActions.type";
import DropdownOption from "../DropdownOption";
import Routes from "../../../config/routes";
import useClickedOutsideEffect from "../../../hooks/useClickedOutsideEffect/useClickedOutsideEffect";
import { authStorage } from "../../../storage/auth/auth.storage";
import ProfileDropdownStyled from "./ProfileDropdown.style";
import { createTournamet, inviteFriend, logout, myTournaments, playerBadge, transactions, user, wallet } from "../../../assets/images";
import "react-circular-progressbar/dist/styles.css";

interface ProfileDropdownProps {
  profilePicture: string;
  profile: ProfileResponse;
}

function ProfileDropdown({ profilePicture, profile }: ProfileDropdownProps) {
  const navigation = useNavigate();
  const translations: PageContent = useTranslation().t("header", { returnObjects: true });
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>();

  useClickedOutsideEffect(dropdownRef, () => setIsDropdownOpen(false));

  const onLogOut = () => {
    authStorage.setTokens(null, false);
    NotificationManager.success(translations.notification3, "Message", 4000);
    navigation(Routes.Home);
  };

  return (
    <ProfileDropdownStyled>
      <div
        className="my-profile-menu"
        role="presentation"
        onClick={() => {
          setIsDropdownOpen(!isDropdownOpen);
        }}
      >
        <div className={`p-pic ${profile.countryOfResidence}`}>
          <img
            src={profilePicture}
            alt="profile"
            className="profile-image"
          />
        </div>
        <div className="p-name">
          <div className="p-nick">
            <img
              src={playerBadge}
              alt="player badge"
            />
            <h5>{profile.nickname}</h5>
          </div>
          {profile.status.Banned ? (
            <div>
              <Badge
                className="ac-banned"
                variant="danger"
              >
                <span className="text-light"> {translations.banner}</span>
              </Badge>
              <div>
                <Dropdown
                  show={isDropdownOpen}
                  ref={dropdownRef}
                >
                  <Dropdown.Toggle
                    variant="default"
                    id="dropdown-basic"
                  />
                  <Dropdown.Menu>
                    <div className="user-info">
                      <div className="profile-info">
                        <h5>{profile.nickname}</h5>
                        <div className="user-type">{translations.profile_dropdown_regular_user}</div>
                      </div>
                      <div>
                        <CircularProgressbar
                          value={60}
                          text="13"
                          strokeWidth={15}
                          circleRatio={0.75}
                          className="circular-progressbar"
                          styles={buildStyles({
                            rotation: 1 / 2 + 1 / 8,
                            strokeLinecap: "butt",
                            trailColor: "#fff",
                            pathColor: "#3BBDF5",
                            textColor: "#fff",
                            textSize: "35px",
                          })}
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="dropdown-options">
                      <DropdownOption
                        text={translations.profile_dropdown_notifications}
                        onClick={() => navigation(Routes.NotificationPage)}
                      />
                      <DropdownOption
                        text={translations.profile_dropdown_chat_helpdesk}
                        onClick={() => navigation(Routes.Home)}
                      />
                      <DropdownOption
                        text={translations.profile_dropdown_chat_admin}
                        onClick={() => navigation(Routes.Home)}
                      />
                      <DropdownOption
                        className="text-red"
                        text={translations.profile_dropdown_logout}
                        onClick={onLogOut}
                      />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          ) : (
            <div>
              <Dropdown
                show={isDropdownOpen}
                ref={dropdownRef}
              >
                <Dropdown.Toggle
                  variant="default"
                  id="dropdown-basic"
                />
                <Dropdown.Menu>
                  <div className="user-info">
                    <div className="profile-info">
                      <h5>{profile.nickname}</h5>
                      <div className="user-type">{translations.profile_dropdown_regular_user}</div>
                    </div>
                    <div>
                      <CircularProgressbar
                        value={60}
                        text="13"
                        strokeWidth={15}
                        circleRatio={0.75}
                        className="circular-progressbar"
                        styles={buildStyles({
                          rotation: 1 / 2 + 1 / 8,
                          strokeLinecap: "butt",
                          trailColor: "#fff",
                          pathColor: "#3BBDF5",
                          textColor: "#fff",
                          textSize: "35px",
                        })}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="dropdown-options">
                    <DropdownOption
                      text={translations.profile_dropdown_dashboard}
                      icon={user}
                      onClick={() => navigation(Routes.Profile)}
                    />

                    <DropdownOption
                      text={translations.profile_dropdown_invite_friend}
                      icon={inviteFriend}
                      onClick={() => navigation(Routes.PlayersList)}
                    />

                    <DropdownOption
                      text={translations.profile_dropdown_my_tournaments}
                      icon={myTournaments}
                      onClick={() => navigation(Routes.MyGames)}
                    />

                    <DropdownOption
                      text={translations.profile_dropdown_create_tournamet}
                      icon={createTournamet}
                      onClick={() => navigation(Routes.CreateTournament)}
                    />

                    <DropdownOption
                      text={translations.profile_dropdown_wallet}
                      icon={wallet}
                      onClick={() => navigation(Routes.Transactions)}
                    />

                    <DropdownOption
                      text={translations.profile_dropdown_transactions}
                      icon={transactions}
                      onClick={() => navigation(`${Routes.Transactions}?type=DEPOSIT_WITHDRAW`)}
                    />

                    <DropdownOption
                      className="text-red"
                      icon={logout}
                      text={translations.profile_dropdown_logout}
                      onClick={onLogOut}
                    />
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <div className="p-level">
                <p>Ranga: 13</p>
                <ProgressBar now={60} />
              </div>
            </div>
          )}
        </div>
      </div>
    </ProfileDropdownStyled>
  );
}

export default ProfileDropdown;
