/* eslint-disable import/no-cycle */
import React from "react";
import { useInView } from "react-intersection-observer";
import { Swiper, SwiperSlide } from "swiper/react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Pagination, Navigation, EffectCoverflow } from "swiper/modules";
import { getPopularGames } from "../../../API/game/gameActions";
import { Wallet } from "../../../API/user/userActions.type";
import { POPULAR_GAMES_REFETCH_MS } from "../../../config/time.const";
import useLanguage from "../../../hooks/useLanguage/useLanguage";
import useAppQuery from "../../../hooks/useQuery/useQuery";
import PopularGameBox from "../PopularGameBox/PopularGameBox";
import PopularMobileGameBox from "../PopularMobileGameBox/PopularMobileGameBox";
import GamesSectionStyled from "./GamesSection.style";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

interface GamesSectionProps {
  cardWidth?: string;
  depositWallet?: Wallet;
  gameButtonType: "bonus-duel" | "game-modes";
}

const pagination = {
  clickable: true,
};

function GamesSection({ cardWidth, gameButtonType, depositWallet }: GamesSectionProps) {
  const { language } = useLanguage();
  const { ref, inView } = useInView();

  const { data } = useAppQuery(getPopularGames(language), {
    refetchInterval: () => (inView ? POPULAR_GAMES_REFETCH_MS : false),
  });

  return (
    <GamesSectionStyled ref={ref}>
      {data ? (
        <Swiper
          navigation
          pagination={pagination}
          effect="coverflow"
          grabCursor
          centeredSlides
          slidesPerView="auto"
          loop
          coverflowEffect={{
            rotate: 20,
            stretch: 10,
            depth: 200,
            modifier: 1.5,
          }}
          modules={[EffectCoverflow, Navigation, Pagination]}
          className="mySwiper"
        >
          {data.items.map((game) => {
            if (game.type === "mobile") {
              return <PopularMobileGameBox game={game} />;
            }
            return (
              <SwiperSlide key={`popular-${game.gameId}}`}>
                <PopularGameBox
                  game={game}
                  width={cardWidth || "320px"}
                  depositWallet={depositWallet}
                  gameButtonType={gameButtonType}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : (
        ""
      )}
    </GamesSectionStyled>
  );
}

export default GamesSection;
