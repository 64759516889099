import React, { useEffect, useState } from "react";
import ProgressBarStyled from "./ProgressBar.style";
import { Match } from "../../../API/game/gameActions.type";

interface ProgressBarProps {
  matches: Match[];
}

function ProgressBar({ matches }: ProgressBarProps) {
  const matchesAmount = 3;

  const matchesArray = new Array(matchesAmount).fill(null);

  const [matchesWon, setMatchesWon] = useState(matchesArray);

  const checkMatches = () => {
    matches.forEach((match, index) => {
      matchesArray[index] = match.isWinner;
    });
    setMatchesWon(matchesArray);
  };

  useEffect(() => {
    checkMatches();
  }, []);

  const createColor = (match: boolean | null) => {
    if (match === null) {
      return;
    }
    if (match) {
      return "green";
    }
    return "red";
  };

  return (
    <ProgressBarStyled>
      {matchesWon.map((item) => (
        <div
          className={createColor(item)}
          key={`progress-bar-${Math.random()}`}
        />
      ))}
    </ProgressBarStyled>
  );
}

export default ProgressBar;
