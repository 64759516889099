import React from "react";
import Loader from "../../loader/Loader";

import { LazyContentProps } from "./LazyFragment.types";

const getError = (error: unknown): Error => {
  if (error instanceof Error) {
    return error;
  }
  const msg = typeof error === "string" ? error : "An error occurred when loading element";
  return new Error(msg);
};

export default function LazyFragment<T>({ error, errorObject, payload, loading, children, loaderElement, placeholderElement }: LazyContentProps<T>): React.ReactElement | null {
  if (error) {
    throw getError(errorObject);
  }

  if (loading)
    return (
      <>
        {payload ? children(payload!) : null}
        {loaderElement ?? <Loader />}
      </>
    );

  if (payload === undefined) return placeholderElement ?? null;

  return children(payload!);
}
