import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { twitterLandingPageIcon, youtubeLandingPageIcon, instagramLandingPageIcon, facebookLandingPageIcon, emailLandingPageIcon } from "../../../assets/images";
import DayOfDuelStyled from "./DayOfDuel.style";
import PageContent from "./pageContent.type";
import Button from "../../common/buttons/button/Button";

function DayOfDuel() {
  const translations: PageContent = useTranslation().t("day_of_duel", { returnObjects: true });

  return (
    <DayOfDuelStyled>
      <h2 className="heading">{translations.heading}</h2>
      <div className="flex-wrapper">
        <div className="column">
          <h4 className="column-title">{translations.heading_social_media}</h4>
          <p>{translations.social_media_follow}</p>
          <div className="icons">
            <Button
              type="iconButton"
              icon={twitterLandingPageIcon}
              onClick={() => {}}
              alt=""
              variant="ghost"
              position="left"
            />
            <Button
              type="iconButton"
              icon={youtubeLandingPageIcon}
              onClick={() => {}}
              alt=""
              variant="ghost"
              position="left"
            />
            <Button
              type="iconButton"
              icon={instagramLandingPageIcon}
              onClick={() => {}}
              alt=""
              variant="ghost"
              position="left"
            />
            <Button
              type="iconButton"
              icon={facebookLandingPageIcon}
              onClick={() => {}}
              alt=""
              variant="ghost"
              position="left"
            />
          </div>
        </div>

        <div className="column vertical-line">
          <h4 className="column-title">{translations.heading_features}</h4>
          <p>{translations.features_description}</p>
        </div>

        <div className="column">
          <h4 className="column-title">{translations.heading_join}</h4>
          <p>{translations.join_description}</p>
          <Link
            to="/"
            className="email-link"
          >
            <img
              src={emailLandingPageIcon}
              alt=""
            />
            {translations.join_mail}
          </Link>
        </div>
      </div>
      <div className="bottom-glow" />
    </DayOfDuelStyled>
  );
}

export default DayOfDuel;
