import styled from "styled-components";

const TextCardStyled = styled.div`
  background-color: #131625;
  max-width: 440px;
  min-height: 215px;
  border-radius: 20px;
  padding: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  position: relative;
  z-index: 10;

  .icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #3bbdf5;
    position: absolute;
    top: -50px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon::after {
    content: "";
    width: 360px;
    height: 150px;
    background: radial-gradient(at top, #3bbdf5 -15%, transparent 50%);
    position: absolute;
    top: 50px;
    left: -130px;
    z-index: -1;
  }

  .title {
    font-family: Teko;
    font-size: 30px;
    font-weight: 900;
    color: #fff;
    margin-top: 50px;
  }

  .content {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
    color: #606f97;
  }
`;

export default TextCardStyled;
