import styled from "styled-components";

const StatusStyled = styled.div`
  .status-duel-icon {
    background-color: ${({ theme }) => theme.colors.red};
    width: 15px;
    height: 15px;
    border-radius: 50%;
    padding: 2px;
    margin-right: 7px;
  }

  .status-coin-icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 7px;
  }

  .status-stake-container {
    display: flex;
    align-items: center;
    position: relative;
  }

  .status-stake-container::before {
    content: "";
    height: 20px;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
  }

  .status-stake {
    font-family: ${({ theme }) => theme.fontFamilies.robotoBold};
    color: ${({ theme }) => theme.colors.gold};
    font-size: ${({ theme }) => theme.fontSizes.h6};
    letter-spacing: 0.15em;
  }

  .checkboxes {
    display: flex;
    column-gap: 5px;
    margin-left: 10px;
  }

  .checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    border: 1px solid white;
  }

  .green {
    border-color: #52ff00;
    background-color: #005e09;
  }
`;

export default StatusStyled;
