import React, { useEffect, useRef, useState } from "react";
import { accordionIcon } from "../../../assets/images";
import AccordionItemStyled from "./AccordionItem.style";

interface AccordionItemProps {
  title: string;
  children: string | React.ReactElement | React.ReactElement[];
  additionalTitleInfo?: string | React.ReactElement;
  isOpen?: boolean;
  onClick?: () => void;
}

function AccordionItem({ title, children, additionalTitleInfo, isOpen, onClick }: AccordionItemProps) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(contentRef.current?.scrollHeight || 0);

  useEffect(() => {
    setContentHeight(contentRef.current?.scrollHeight || 0);
  }, [children]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => contentRef.current?.classList.remove("overflow-content"), 300);
    }
  }, [isOpen]);

  return (
    <AccordionItemStyled
      contentHeight={contentHeight}
      className={isOpen ? "open" : "closed"}
    >
      <button
        type="button"
        className="accordion-title"
        onClick={() => onClick?.()}
      >
        <span>{title}</span>
        <img
          src={accordionIcon}
          alt="icon"
          className={isOpen ? "accordion-icon-rotated accordion-icon" : "accordion-icon"}
        />
        {additionalTitleInfo && <div className="additional-title-info">{typeof additionalTitleInfo === "string" ? <span>{additionalTitleInfo}</span> : additionalTitleInfo}</div>}
      </button>
      <div
        ref={contentRef}
        className={isOpen ? "accordion-content overflow-content" : "accordion-content hidden"}
      >
        {children}
      </div>
    </AccordionItemStyled>
  );
}

export default AccordionItem;
