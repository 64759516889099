import styled from "styled-components";

const ColumnHeadingStyled = styled.div<{ width?: string }>`
  flex: 1 1 0px;
  font-size: ${({ theme }) => theme.fontSizes.caption};
  font-family: ${({ theme }) => theme.fontFamilies.robotoBold};
  text-align: center;
  max-width: ${({ width }) => width};

  .table-column-button {
    border: none;
    background-color: transparent;
  }

  .sort-icon {
    display: inline-block;
    width: 10px;
  }

  .sort-icon-hidden {
    color: transparent;
    width: 10px;
    display: inline-block;

    transition: color 0.3s;
  }

  .column-name-icon {
    font-family: ${({ theme }) => theme.fontFamilies.robotoBold};
  }

  .column-name-icon:hover .sort-icon-hidden {
    color: ${({ theme }) => theme.colors.white};
  }

  &::first-letter,
  .table-column-button::first-letter {
    text-transform: uppercase;
  }
`;

export default ColumnHeadingStyled;
