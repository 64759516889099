import React from "react";
import { format } from "date-fns";
import { SupportedNotifications } from "../../../../API/user/notifications.type";
import NotificationStyled from "./Notification.style";
import { createCustomText } from "../../../notifications/NotificationsTable/useNotificationsTable";
import NotificationDot from "../../../table/NotificationDot/NotificationDot";
import Tooltip from "../../Tooltip/Tooltip";
import useRelativeTime from "../../../../hooks/useRelativeTime/useRelativeTime";
import { friendIcon, notificationImagePlaceholder } from "../../../../assets/images";
import useNavigateToLink from "../../../../hooks/useNavigateToLink/useNavigateToLink";
import useMarkNotification from "../../../../hooks/useMarkNotification/useMarkNotification";
import FriendInvitationActionButtons from "../../../notifications/Actions/FriendInvitationActionButtons/FriendInvitationActionButtons";

interface NotificationProps {
  notification: SupportedNotifications;
  dataRefetch: () => Promise<any>;
}

function Notification({ notification, dataRefetch }: NotificationProps) {
  const navigate = useNavigateToLink(notification.type === "information_with_link" ? notification.link : undefined);

  const { markAsRead: markAsReadMutation } = useMarkNotification();

  const onNotificationClick = async () => {
    if (notification.status === "unread") {
      await markAsReadMutation.mutateAsync(notification.id);
      await dataRefetch();
    }
    navigate();
  };

  const time = useRelativeTime(notification.notificationDateTime);

  const createActionButtons = () => {
    if (notification.type !== "information_with_action") {
      return;
    }

    if (notification.actionType === "friend_request") {
      return (
        <FriendInvitationActionButtons
          invitationId={notification.componentData.invitationId}
          className="action-buttons"
        />
      );
    }
  };

  return (
    <NotificationStyled onClick={() => onNotificationClick()}>
      <div className="wrapper">
        <div className="image-wrapper">
          <img
            src={notificationImagePlaceholder}
            alt="notification"
            className="image"
          />
          <img
            src={friendIcon}
            alt="icon"
            className="notification-icon"
          />
        </div>
        <div className="title-message-wrapper">
          <div className="title">{createCustomText(notification.title, notification.id, "title")}</div>
          <div className="message">{createCustomText(notification.text, notification.id, "text")}</div>
          {notification.type === "information_with_action" && createActionButtons()}
        </div>
      </div>
      <div className="info">
        <NotificationDot
          status={notification.status}
          notificationId={notification.id}
          refetchData={dataRefetch}
        />
        <Tooltip
          label={time}
          tooltipText={format(new Date(notification.notificationDateTime), "yyyy-MM-dd, HH:mm")}
          labelClassName="date"
          tooltipClassName="date-tooltip"
        />
      </div>
    </NotificationStyled>
  );
}

export default Notification;
