import styled from "styled-components";

const PopularGamesStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;

  .section-headings {
    margin: 0 20px 30px;
  }

  .popular-games-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .headings-button {
    padding: 12px 20px !important;
  }
`;

export default PopularGamesStyled;
