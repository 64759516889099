import React from "react";
import { germanyFlag, polandFlag, placeholderFlag } from "../../../../assets/images";
import CheckBoxStyled from "./CheckBox.style";

export interface CheckBoxInterface {
  type: "checkbox";
  label: string;
  flag?: "PL" | "DE";
  id: string;
  name: string; // name has to be the same as formik value key
  value: boolean;
  onChange: (e: React.ChangeEvent) => void;
  error: string;
  onBlur?: (e: React.FocusEvent) => void;
}

interface CheckBoxProps {
  item: CheckBoxInterface;
  className?: string;
}

const countryFlagMap = {
  PL: polandFlag,
  GB: placeholderFlag,
  DE: germanyFlag,
};

function CheckBox({ item, className }: CheckBoxProps) {
  const { label, flag, name, id, onChange, error, onBlur, value } = item;

  const generateFlag = () => {
    const flagSrc = flag ? countryFlagMap[flag] : placeholderFlag;

    return (
      <img
        src={flagSrc}
        alt=""
        className="flag"
      />
    );
  };

  return (
    <CheckBoxStyled className={className}>
      <div className="wrapper">
        <input
          type="checkbox"
          name={name}
          id={id}
          onChange={onChange}
          className={error ? "checkbox-input checkbox-red" : "checkbox-input checkbox"}
          onBlur={onBlur ? (e) => onBlur(e) : undefined}
          checked={value}
        />
        <label
          className={error ? "input-label error" : "input-label"}
          htmlFor={id}
        >
          {flag && generateFlag()}
          <span>{label}</span>
        </label>
      </div>
    </CheckBoxStyled>
  );
}

export default CheckBox;
