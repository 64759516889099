import { markNotificationAsRead, markNotificationAsUnread, getUserNotificationsForDropdown } from "../../API/user/userActions";
import useAppMutation from "../useMutation/useMutation";
import useAppQuery from "../useQuery/useQuery";

function useMarkNotification() {
  const { refetch } = useAppQuery(getUserNotificationsForDropdown());
  const markAsRead = useAppMutation(markNotificationAsRead, { onSuccess: () => refetch() });
  const markAsUnread = useAppMutation(markNotificationAsUnread, { onSuccess: () => refetch() });
  return { markAsRead, markAsUnread };
}

export default useMarkNotification;
