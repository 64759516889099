import styled from "styled-components";

const WalletBoxStyled = styled.div`
  width: 150px;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;

  .mobile-wallet-box {
    width: 346px;
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: ${({ theme }) => theme.fontSizes.caption};
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    margin: 0;
    margin-right: 5px;
  }

  .mobile-wallet-box .title {
    font-size: 18px;
    font-weight: 700;
  }

  .currencies {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .wallet-amount {
    font-size: ${({ theme }) => theme.fontSizes.caption};
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    color: #fbbb33;
    margin: 0;
  }

  .currencies.deposit-color .wallet-amount {
    color: #14c77c;
  }

  .currency {
    padding-left: 3px;
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
  }

  .mobile-wallet-box .currency,
  .mobile-wallet-box .wallet-amount {
    font-size: 18px;
  }

  .add-money-btn {
    border-radius: 7px;
    background-color: #14c77c;
    padding: 4px 8px;
    border: none;
    font-size: 10px;
    font-weight: 700;
  }
`;

export default WalletBoxStyled;
