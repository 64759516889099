import React from "react";
import NotificationsTableStyled from "./NotificationsTable.style";
import Table from "../../table/Table";
import useNotificationsTable from "./useNotificationsTable";

function NotificationsTable() {
  const { data, inputs, onSort, formikFilters, fetchNextPage, hasNextPage, onRowClick, isLoading } = useNotificationsTable();

  return (
    <NotificationsTableStyled>
      <Table
        data={data}
        inputs={inputs}
        onSort={onSort}
        filters={formikFilters}
        includePagination
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        tableWithLinks
        onRowClick={onRowClick}
      />
    </NotificationsTableStyled>
  );
}

export default NotificationsTable;
