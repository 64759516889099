import React from "react";
import { useTranslation } from "react-i18next";
import NoContentStyled from "./NoContent.style";

interface NoContentProps {
  customText?: string;
  className?: string;
}

function NoContent({ customText, className }: NoContentProps) {
  const { t } = useTranslation();
  return <NoContentStyled className={className}>{customText || t("common.no_data")}</NoContentStyled>;
}

export default NoContent;
