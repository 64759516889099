import styled from "styled-components";
import { polandFlag, ukFlag } from "../../../assets/images";

const MobileHeaderStyled = styled.div`
  background-color: #0c101d;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  max-width: 393px;
  height: 100vh;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .logo {
    width: 324px !important;
    margin-top: 55px;
    margin-bottom: 55px;
  }

  hr {
    width: 100%;
    border: 1px solid #171d31;
    margin: 25px 0;
  }

  .mobile-nav {
    text-transform: uppercase;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .mobile-nav .nav-link {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin-left: 0 !important;
    padding: 0;
  }

  .mobile-nav .nav-link.active {
    color: #3bbdf5;
  }

  .my-profile-info-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .my-profile-info-container .my-profile-info {
    display: flex;
    margin-right: 30px;
    position: relative;
    align-items: center;
    gap: 5px;
  }

  .my-profile-info-container .my-profile-info .progress {
    height: 10px;
  }

  .my-profile-info-container .user-type {
    border-radius: 5px;
    background-color: #3bbdf5;
    padding: 7px 20px;
    font-size: 13px;
    font-weight: 600;
  }

  .p-pic img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    object-fit: cover;
    object-position: center;
    border: 1px solid #3bbdf5;
  }

  .p-pic::after {
    content: "";
    border: none;
    display: block;
    width: 19px;
    height: 19px;
    background-size: cover;
    position: absolute;
    left: 24px;
    top: 30px;
    border: 1px solid #3bbdf5;
    border-radius: 50%;
  }

  .p-pic.Poland::after {
    background-image: url(${polandFlag});
  }

  .p-pic.UnitedKingdom::after {
    background-image: url(${ukFlag});
  }

  .profile-image {
    height: 22px;
    width: 22px;
    border-radius: 3px;
  }

  .p-name {
    padding-left: 10px;
    position: relative;
  }

  .p-name h5 {
    font-weight: 600;
    color: #fff;
    font-size: 16px;
    margin-bottom: 0;
    text-transform: capitalize;
    letter-spacing: 1.5px;
    padding-bottom: 2px;
  }

  .p-name span {
    color: #696969;
    font-size: 12px;
  }

  .p-name .p-nick {
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .p-name .p-nick img {
    width: 14px;
    height: 14px;
  }

  span.ac-banned.badge.badge-danger {
    display: block;
    width: 100%;
  }

  .p-level {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .p-level p {
    font-size: 10px;
    font-weight: 400;
    color: #3bbdf5;
    margin: 0;
  }

  .p-level .progress {
    width: 100%;
    height: 2px;
    border-radius: 28px;
  }

  .p-level .progress-bar {
    background-color: #3bbdf5;
  }

  .logout-btn {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #293451;
    display: flex;
    align-items: center;
    gap: 7px;
    background-color: transparent;
    color: #df3232;
    border: none;
    font-size: 18px;
    font-weight: 600;
  }

  .logout-btn:focus,
  .logout-btn:hover {
    background: transparent;
  }

  .logout-btn .icon {
    mask-size: auto;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-position: center;
    width: 23px;
    height: 23px;
    background: #df3232;
  }

  .mobile-auth-buttons-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .mobile-auth-buttons-container button {
    width: 100%;
    height: 55px;
    transform: skewX(0);
    border-radius: 5px;
    box-shadow: none;
  }

  .mobile-auth-buttons-container button span {
    transform: skewX(0);
    font-size: 18px;
    font-weight: 600;
  }

  .mobile-auth-buttons-container button.signup-btn {
    border: 1px solid #019acb;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
  }
`;

export default MobileHeaderStyled;
