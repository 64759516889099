import styled from "styled-components";

const CreatingTournamentStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .section-container {
    max-width: 1640px;
  }

  .section-headings {
    text-align: center;
    margin: 0 20px 50px;
  }

  .btn {
    padding: 12px 20px !important;
  }

  .image-container {
    width: 100%;
    position: relative;
  }

  .image-container img {
    width: 100%;
  }

  .text {
    max-width: 205px;
    position: absolute;
  }

  .text1 {
    left: 10%;
    bottom: 65%;
  }

  .text2 {
    bottom: 75%;
    left: 78%;
  }

  .text h4 {
    font-family: Teko;
    font-size: 30px;
    font-weight: 900;
    line-height: 26.84px;
    letter-spacing: 0.02em;
    color: #3bbdf5;
  }

  .text ul li {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
    line-height: 21.47px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #606f97;
    margin-left: 7%;
  }

  .line1 {
    position: absolute;
    bottom: 50%;
    right: 65%;
    width: 20%;
    height: 15%;
    border-bottom-left-radius: 35px;
    border-left: 3px dashed #3bbdf5;
    border-bottom: 3px dashed #3bbdf5;
  }

  .line1::before {
    content: "";
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background-color: #3bbdf5;
    position: absolute;
    bottom: -10px;
    right: -10px;
  }

  .line2::before {
    content: "";
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background-color: #3bbdf5;
    position: absolute;
    bottom: -10px;
    left: -10px;
  }

  .line2 {
    position: absolute;
    top: 25%;
    right: 16%;
    width: 8%;
    height: 25%;
    border-bottom-right-radius: 35px;
    border-right: 3px dashed #3bbdf5;
    border-bottom: 3px dashed #3bbdf5;
  }

  @media screen and (max-width: 900px) {
    .text {
      display: none;
    }

    .line {
      display: none;
    }

    .mobile-dot {
      height: 45px;
      width: 45px;
      border-radius: 50%;
      border: 1px solid #3bbdf5;
      position: absolute;
    }

    .mobile-dot::before {
      content: "";
      height: 28px;
      width: 28px;
      border-radius: 50%;
      background-color: #3bbdf5;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .mobile-dot1 {
      bottom: 60%;
      left: 26%;
    }

    .mobile-dot2 {
      left: 73%;
      top: 50%;
    }
  }

  @media screen and (max-width: 500px) {
    .mobile-dot2 {
      left: 71%;
    }
  }
`;

export default CreatingTournamentStyled;
