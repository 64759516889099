import styled from "styled-components";

const ForgetPasswordStage1Styled = styled.div`
  .inputs {
    margin-bottom: 20px;
  }

  button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

export default ForgetPasswordStage1Styled;
