/* eslint-disable react/destructuring-assignment */
import React from "react";
import { SupportedCountry, UserAvailabilityStatus } from "../../../../API/user/userActions.type";
import { badgeIcon, germanyFlag, notificationImagePlaceholder, placeholderFlag, polandFlag, premiumStarBorderIcon, premiumStarIcon } from "../../../../assets/images";
import RankIcon from "../RankIcon/RankIcon";
import { StyledAvatar, StyledBottomIcons, StyledPremiumIcon } from "./PlayerAvatar.style";

interface BasicAvatar {
  type: "basic";
  avatarUrl: string | null;
  status: UserAvailabilityStatus;
  isPremium: boolean;
  hasPendingInvitation: boolean;
  className?: string;
}

interface AvatarWithIcons extends Omit<BasicAvatar, "type"> {
  type: "withIcons";
  hasBadge: boolean;
  rank: number;
  countryOfResidence?: SupportedCountry;
}

type PlayerAvatarProps = BasicAvatar | AvatarWithIcons;

const isAvatarWithIcons = (avatarData: PlayerAvatarProps): avatarData is AvatarWithIcons => avatarData.type === "withIcons";

const countryFlagMap = {
  Poland: polandFlag,
  UnitedKingdom: placeholderFlag,
  Germany: germanyFlag,
};

function PlayerAvatar(avatar: PlayerAvatarProps) {
  const { avatarUrl, status, isPremium, hasPendingInvitation, className } = avatar;

  return (
    <StyledAvatar
      isInvitationWaiting={hasPendingInvitation}
      status={status}
      className={className}
    >
      <img
        src={avatarUrl || notificationImagePlaceholder}
        alt=""
        className="user-img"
      />
      {isAvatarWithIcons(avatar) && (
        <StyledBottomIcons>
          <RankIcon rank={avatar.rank} />
          <img
            src={avatar.countryOfResidence ? countryFlagMap[avatar.countryOfResidence] : placeholderFlag}
            alt=""
          />
          <img
            src={badgeIcon}
            alt=""
          />
        </StyledBottomIcons>
      )}
      {status && (
        <div className="availability-dot">
          <div className="availability-dot-inner" />
        </div>
      )}
      {isPremium && (
        <StyledPremiumIcon>
          <img
            src={premiumStarBorderIcon}
            className="border-icon"
            alt=""
          />
          <img
            src={premiumStarIcon}
            className="inner-icon"
            alt=""
          />
        </StyledPremiumIcon>
      )}
    </StyledAvatar>
  );
}

export default PlayerAvatar;
