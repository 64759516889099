import styled from "styled-components";
import { arrowLeftNoStem, arrowRightNoStem } from "../../../assets/images";

const GamesSectionStyled = styled.div`
  width: 100%;
  max-width: 1400px;
  height: 600px;

  display: flex;
  justify-content: center;
  align-items: center;

  .games-container {
    width: 100%;
    max-width: 1400px;
    height: 500px;
  }

  .swiper {
    width: 100%;
    height: 100%;
    margin: auto;
  }

  .swiper-slide {
    width: 440px;
    height: 425px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide > div {
    width: 100%;
    height: 100%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: auto;
    bottom: 10%;
    left: 50%;
    width: 36px !important;
    height: 36px !important;
    z-index: 2;
  }

  .swiper-button-prev {
    transform: translateX(-56px);
  }
  .swiper-button-next {
    transform: translateX(20px);
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 0;
    display: inline-block;
    width: 36px;
    height: 36px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .swiper-button-prev::after {
    background-image: url(${arrowLeftNoStem});
  }
  .swiper-button-next::after {
    background-image: url(${arrowRightNoStem});
  }

  .swiper-pagination {
    display: none;
  }

  @media screen and (max-width: 1100px) {
    height: 300px;

    .swiper {
      max-width: 750px;
    }

    .swiper-slide {
      width: 250px;
      height: 240px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      bottom: 0;
    }

    .swiper-button-prev {
      transform: translateX(0);
      left: 5%;
    }
    .swiper-button-next {
      transform: translateX(0);

      left: auto;
      right: 5%;
    }

    .swiper-pagination {
      top: auto;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }

    .swiper-pagination-bullet {
      background: #687a9d;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background: white;
      width: 20px;
      height: 20px;
    }
  }
`;

export default GamesSectionStyled;
