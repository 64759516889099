import styled from "styled-components";

const TableStyled = styled.div`
  .filters {
    display: flex;
    column-gap: 20px;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 30px;
  }

  .title {
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.h1};
    margin-bottom: 35px;
  }

  .table-headings {
    display: flex;
    margin: auto;
  }

  .no-results {
    margin-top: 50px;
  }

  .no-results p {
    font-size: ${({ theme }) => theme.fontSizes.body};
    text-align: center;
    margin: 0;
    letter-spacing: 0.1em;
  }

  .table-loader {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .row-link-arrows {
    width: 37px;
    max-width: 37px;
    color: transparent;
    transition: color 0.3s;
  }
`;

export default TableStyled;
