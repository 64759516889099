import React from "react";
import MoneyAmountStyled from "./MoneyAmount.style";

interface MoneyAmountProps {
  money: {
    amount?: number;
    currency?: string;
    additionalPrize?: string;
  };
  width?: string;
}

function MoneyAmount({ money, width }: MoneyAmountProps) {
  const { amount, currency, additionalPrize } = money;
  return (
    <MoneyAmountStyled width={width}>
      {amount && `${amount.toFixed(2)} ${currency}`}
      {additionalPrize && additionalPrize}
    </MoneyAmountStyled>
  );
}

export default MoneyAmount;
