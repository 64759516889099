import React from "react";
import MoneyInputStyled from "./MoneyInput.style";
import Input, { InputInterface } from "../input/Input";

const generateAllowedChars = (locale: string) => {
  if (locale === "en-US" || locale === "en-GB") {
    return /^[\d,]*[.]?[\d]{0,2}$/;
  }
  return /^[\d\s]*[,]?[\d]{0,2}$/; // locale: pl-Pl and others
};

export interface MoneyInputInterface extends Omit<InputInterface, "type"> {
  type: "money";
  locale: string;
}

interface MoneyInputProps {
  item: MoneyInputInterface;
}

function MoneyInput({ item }: MoneyInputProps) {
  const ALLOWED_CHARS_REGEXP = generateAllowedChars(item.locale);
  const ALLOWED_KEYS = ["Backspace", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"];

  const regex = new RegExp(ALLOWED_CHARS_REGEXP);
  const onKeyDownTest = (e: React.KeyboardEvent) => {
    const target = e.target as HTMLInputElement;
    const cursorPosition = target.selectionStart || 0;

    if (ALLOWED_KEYS.includes(e.key)) {
      return;
    }
    const fieldValue = target.value.slice(0, cursorPosition) + e.key + target.value.slice(cursorPosition);
    if (!regex.test(fieldValue)) {
      e.preventDefault();
    }
  };

  return (
    <MoneyInputStyled>
      <Input item={{ ...item, type: "text", onKeyDown: onKeyDownTest }} />
    </MoneyInputStyled>
  );
}

export default MoneyInput;
