import styled from "styled-components";

const LoaderStyled = styled.div<{ width?: string }>`
  display: flex;
  height: ${(props) => (props.width ? props.width : "100vh")};
  align-items: center;
  justify-content: center;

  .loader svg {
    stroke: ${({ theme }) => theme.colors.blue};
    height: 60px;
    width: 60px;
  }
`;

export default LoaderStyled;
