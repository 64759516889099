import React, { useEffect, useRef, useState } from "react";
import AccordionItem from "../common/accordionItem/AccordionItem";
import NoContent from "../common/NoContent/NoContent";
import AccordionStyled from "./Accordion.style";

interface Item<T> {
  title: string;
  notificationDot: boolean;
  data: T[];
  componentCreator: (singleData: T, index: number) => React.ReactElement;
}

interface AccordionProps {
  accordionItems: Item<any>[];
  openedItem: string | null;
}

function Accordion<T>({ accordionItems, openedItem }: AccordionProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [openedBox, setOpenedBox] = useState<string | null>(openedItem);
  const [bottomItemsAmount, setBottomItemsAmount] = useState<number>(0);
  const [closedAccordionItemHeight, setClosedAccordionItemHeight] = useState<number>(containerRef.current?.querySelector("div.closed")?.scrollHeight || 0);

  useEffect(() => {
    setClosedAccordionItemHeight(containerRef.current?.querySelector("div.closed")?.scrollHeight || 0);
  }, [containerRef]);

  useEffect(() => {
    const openItemIndex = accordionItems.findIndex((item) => item.title === openedBox) || 0;
    const totalItems = accordionItems.length;
    const bottomItems = totalItems - (openItemIndex + 1);

    setBottomItemsAmount(bottomItems);
  }, [openedBox]);

  useEffect(() => {
    setOpenedBox(openedItem);
  }, [openedItem]);

  const onBoxClick = (title: string) => {
    if (openedBox === title) {
      setOpenedBox(null);
    } else {
      setOpenedBox(title);
    }
  };

  const createAccordionContent = (data: T[], componentCreator: (singleData: T, index: number) => React.ReactElement) => {
    if (!data.length) {
      return <NoContent />; // todo - when designs are ready
    }
    return <div className="content scrollbar-global">{data.map((singleData, index) => componentCreator(singleData, index))}</div>;
  };

  const createAccordionItem = ({ title, data, componentCreator }: Item<T>) => (
    <AccordionItem
      title={title}
      isOpen={title === openedBox}
      onClick={() => onBoxClick(title)}
      key={title}
    >
      {createAccordionContent(data, componentCreator)}
    </AccordionItem>
  );

  return (
    <AccordionStyled
      ref={containerRef}
      bottomItemsAmount={bottomItemsAmount}
      closedAccordionItemHeight={closedAccordionItemHeight}
    >
      {accordionItems.map((item) => createAccordionItem(item))}
    </AccordionStyled>
  );
}

export default Accordion;
