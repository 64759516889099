import { useTranslation } from "react-i18next";
import useLanguage from "../useLanguage/useLanguage";

function useRelativeTime(time: string) {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const createTime = () => {
    const start = new Date().getTime();
    const end = new Date(time).getTime();
    const duration = start - end;
    const minutes = duration / 1000 / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const months = days / 30;

    const timeFormat = new Intl.RelativeTimeFormat(language, { style: "narrow" });
    let formattedTime = "";
    if (minutes < 1) {
      formattedTime = t("common.now");
    } else if (minutes < 60) {
      formattedTime = timeFormat.format(Math.ceil(-minutes), "minute");
    } else if (hours >= 1 && hours < 24) {
      formattedTime = timeFormat.format(Math.ceil(-hours), "hour");
    } else if (hours < 48) {
      formattedTime = t("common.yesterday");
    } else if (days >= 2 && days < 7) {
      formattedTime = timeFormat.format(Math.ceil(-days), "day");
    } else if (days > 7 && months < 1) {
      formattedTime = timeFormat.format(Math.ceil(-1), "week");
    } else if (months >= 1 && months < 12) {
      formattedTime = timeFormat.format(Math.ceil(-months), "month");
    } else {
      formattedTime = timeFormat.format(Math.ceil(-1), "year");
    }
    return formattedTime;
  };

  return createTime();
}

export default useRelativeTime;
