import { useQueryClient } from "react-query";
import { acceptFriendInvitation, inviteUser, rejectFriendInvitation } from "../../API/friend/friendActions";
import useAppMutation from "../useMutation/useMutation";

function useInviteUser() {
  const client = useQueryClient();
  return useAppMutation(inviteUser, {
    onSuccess: () => {
      client.refetchQueries({ queryKey: ["/user"] });
    },
  });
}

function useAcceptInvitation() {
  const client = useQueryClient();
  return useAppMutation(acceptFriendInvitation, {
    onSuccess: () => {
      client.refetchQueries({ queryKey: ["/user/friends"] });
      client.refetchQueries({ queryKey: ["/user/friends/invitations"] });
      client.refetchQueries({ queryKey: ["/user/notification"] });
    },
  });
}

function useRejectInvitation() {
  const client = useQueryClient();
  return useAppMutation(rejectFriendInvitation, {
    onSuccess: () => {
      client.refetchQueries({ queryKey: ["/user/friends/invitations"] });
      client.refetchQueries({ queryKey: ["/user/notification"] });
    },
  });
}

export { useInviteUser, useAcceptInvitation, useRejectInvitation };
