import { Context, useContext } from "react";

type NonUndefined<T> = T extends undefined ? never : T;

export default function createContextHook<T>(context: Context<T>): () => NonUndefined<T> {
  return () => {
    const value = useContext(context);
    if (value === undefined) {
      const contextDisplayName = context.displayName || "context";
      throw new Error(`Missing provider component: value of ${contextDisplayName} is undefined!`);
    }
    return value as NonUndefined<T>;
  };
}
