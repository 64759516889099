import styled from "styled-components";
import { checkedIcon, uncheckedRedIcon, uncheckedIcon } from "../../../../assets/images";
import { forDesktop } from "../../../../helpers/breakpoints/viewports";

const CheckBoxStyled = styled.div`
  .wrapper {
    display: flex;
    margin-bottom: 16px;
  }

  .checkbox-input {
    height: 12px;
    width: 12px;
    -webkit-appearance: none;
    margin-top: 0;
    position: relative;
  }

  .checkbox:not(:checked):after {
    content: url(${uncheckedIcon});
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    line-height: 12px;
  }

  .checkbox-red:not(:checked):after {
    content: url(${uncheckedRedIcon});
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    line-height: 12px;
  }

  .checkbox:checked:after {
    content: url(${checkedIcon});
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    line-height: 12px;
  }

  .input-label {
    font: ${({ theme }) => theme.fonts.global.text10};
    padding-left: 10px;
    color: ${({ theme }) => theme.colors.lightGrey};
    max-width: 650px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .flag {
    height: 10px;
    width: 10px;
  }

  .error {
    color: ${({ theme }) => theme.colors.error};
  }

  @media ${forDesktop} {
    .input-label {
      font: ${({ theme }) => theme.fonts.global.text12};
    }
  }
`;

export default CheckBoxStyled;
