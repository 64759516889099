import React from "react";
import InputStyled from "./Input.style";

export interface InputInterface {
  type: "text" | "email" | "password" | "number";
  label: string;
  id: string;
  placeholder: string;
  name: string; // name has to be the same as formik value key
  value: string;
  onChange: (e: React.ChangeEvent) => void;
  error: string;
  staticTextLeft?: string;
  staticTextRight?: string;
  disabled?: boolean;
  formatFunc?: (fieldName: string, value: string) => void;
  onBlur?: (e: React.FocusEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}

interface InputProps {
  item: InputInterface;
  className?: string;
}
function Input({ item, className }: InputProps) {
  const { type, label, name, value, id, onChange, error, placeholder, staticTextLeft, staticTextRight, disabled = false, formatFunc, onBlur, onKeyDown } = item;

  const onBlurFunc = (e: React.FocusEvent) => {
    if (onBlur) {
      onBlur(e);
    }
    if (formatFunc) {
      formatFunc(name, value);
    }
  };

  return (
    <InputStyled
      staticTextLeft={!!staticTextLeft}
      className={className}
    >
      <label
        htmlFor={id}
        className="form-label"
      >
        {label}
      </label>
      <div className="input-wrapper">
        {staticTextLeft && <p className="static-text-left">{staticTextLeft}</p>}
        {staticTextRight && <p className="static-text-right">{staticTextRight}</p>}
        <input
          className={error ? "text-input red" : "text-input"}
          type={type}
          placeholder={placeholder}
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
          onBlur={(e) => onBlurFunc(e)}
          onKeyDown={(e) => onKeyDown && onKeyDown(e)}
        />
      </div>
      <p className="error">{error}</p>
    </InputStyled>
  );
}

export default Input;
