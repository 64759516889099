import styled from "styled-components";

const CompleteSignupStyled = styled.div`
  max-width: 700px;
  margin: 75px auto;

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.h1};
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 40px;
  }

  .inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-wrap: wrap;
  }

  .input-item {
    min-width: 300px;
    flex-basis: 45%;
  }
`;

export default CompleteSignupStyled;
