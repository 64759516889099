import React from "react";
import RangeInputStyled from "./RangeInput.style";

interface RangeInputProps {
  value: number;
  min: number;
  max: number;
  step?: number;
  onChange: (e: React.ChangeEvent) => void;
}

function RangeInput({ value, min, max, step = 1, onChange }: RangeInputProps) {
  return (
    <RangeInputStyled>
      <input
        type="range"
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={onChange}
        className="input-range"
      />
    </RangeInputStyled>
  );
}

export default RangeInput;
