import { AxiosInstance } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import { refreshTokenAsync } from "../utils/refresh-token.util";

// eslint-disable-next-line import/prefer-default-export
export const refreshTokenInterceptor = (axios: AxiosInstance, onError?: (error?: any) => void): number =>
  createAuthRefreshInterceptor(
    axios,
    () =>
      refreshTokenAsync(axios).catch((err) => {
        if (typeof onError === "function") {
          onError();
        }
        return Promise.reject(err);
      }),
    {
      statusCodes: [401, 403],
    }
  );
