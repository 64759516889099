import { useQueryClient } from "react-query";
import { removeFriend, removeInvitation } from "../../API/friend/friendActions";
import { blockUser, unblockUser } from "../../API/user/userActions";
import useAppMutation from "../useMutation/useMutation";

function useRemoveFriend() {
  const client = useQueryClient();
  return useAppMutation(removeFriend, {
    onSuccess: () => client.invalidateQueries({ queryKey: ["/user/friends"] }),
  });
}

function useRemoveInvitation() {
  const client = useQueryClient();
  return useAppMutation(removeInvitation, {
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["/user/friends"] });
      client.invalidateQueries({ queryKey: ["/user/friends/invitations"] });
    },
  });
}

function useBlockUser() {
  const client = useQueryClient();

  return useAppMutation(blockUser, {
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["/user/friends"] });
      client.invalidateQueries({ queryKey: ["/user/friends/invitations"] });
    },
  });
}

function useUnblockUser() {
  const client = useQueryClient();
  return useAppMutation(unblockUser, {
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["/user/friends"] });
      client.invalidateQueries({ queryKey: ["/user/friends/invitations"] });
    },
  });
}

export { useRemoveFriend, useBlockUser, useUnblockUser, useRemoveInvitation };
