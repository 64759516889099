import { AxiosError } from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { getBonusRooms, activateBonusRoom } from "../../API/game/gameActions";
import { BonusRoom, BonusRoomActivated } from "../../API/game/gameActions.type";
import useAppMutation from "../../hooks/useMutation/useMutation";
import useAppQuery from "../../hooks/useQuery/useQuery";
import PageContent from "./PageContent.type";

function useBonusRoomModal() {
  const { t } = useTranslation();
  const translation: PageContent = t("bonus_room", { returnObjects: true });

  const [urlParams, setUrlParams] = useSearchParams();
  const historyRef = useRef<HTMLDivElement | null>(null);

  const [isUserPremium, setIsUserPremium] = useState(false);
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const { data, refetch } = useAppQuery(getBonusRooms());
  const activateBonusRoomMutation = useAppMutation(activateBonusRoom);

  useEffect(() => {
    // todo: check if user is premium
    setIsUserPremium(false);
  }, []);

  const setHistoryRef = useCallback(
    (node: HTMLDivElement | null) => {
      historyRef.current = node;
      if (node !== null && urlParams.get("history") === "true") {
        setIsHistoryVisible(true);
      }
    },
    [urlParams]
  );

  useEffect(() => {
    if (historyRef.current && urlParams.get("history") === "true") {
      urlParams.delete("history");
      setUrlParams(urlParams, { replace: true });
      historyRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isHistoryVisible, urlParams]);

  const bonusRoomData = useMemo<(BonusRoom | BonusRoomActivated)[]>(() => data || [], [data]);

  const onActivateBonusRoom = async (bonusItem: BonusRoom) => {
    if (bonusItem.isPremium && !isUserPremium) {
      return;
    }
    if (activateBonusRoomMutation.isLoading) {
      return;
    }
    const payload = {
      activateFee: bonusItem.activateFee,
      currency: bonusItem.currency,
    };
    try {
      const response = await activateBonusRoomMutation.mutateAsync(payload);
      if (response.status === 201) {
        refetch();
        return;
      }
      const errorMessage = response.errorObject.response.data.message;
      NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
    } catch (error: any) {
      console.error((error as AxiosError).response?.data);
    }
  };

  return {
    translation,
    isUserPremium,
    onActivateBonusRoom,
    bonusRoomData,
    setHistoryRef,
    isHistoryVisible,
    toggleHistoryVisibility: () => setIsHistoryVisible((prev) => !prev),
  };
}

export default useBonusRoomModal;
