import { ActionSort } from "../action.type";

export enum UserAvailabilityStatus {
  Offline = "offline",
  Online = "online",
  Tournament = "tournament",
  Duel = "duel",
  Multiplayer = "multiplayer",
  ChampionshipBattle = "championship-battle",
  Matchmaking = "matchmaking",
}

export interface LoginPayload {
  email: string;
  password: string;
  referenceCode: string;
}

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  idToken: string;
  captchaToken: string;
  fullAccess: boolean;
}

export interface SignUpPayload {
  email: string;
  password: string;
  consents: Record<string, boolean>;
  captchaToken: string;
  referralCode?: string;
}

export interface SignUpResponse {
  userId: string;
}
export interface OauthPayload {
  provider: "google" | "facebook";
  consents?: Record<string, boolean>;
  captchaToken: string;
  referralCode?: string;
}

export interface OauthResponse {
  url: string;
}

export interface OauthConfirmPayload {
  code: string;
  state: string;
}

export type OauthConfirmResponse = LoginResponse;

export interface ProfileResponse {
  id: string;
  givenName: string;
  familyName: string;
  dateOfBirth: string;
  nickname: string;
  referralCode: string;
  countryOfResidence: string;
  avatarUrl: string;
  status: {
    ProfileCompleted: boolean;
    Banned: boolean;
  };
  language: SupportedLanguage;
}

export enum SupportedCountry {
  Poland = "Poland",
  UnitedKingdom = "UnitedKingdom",
}

export enum SupportedLanguage {
  pl_PL = "pl-PL",
  en_GB = "en-GB",
  en_US = "en-US",
}

export interface CompleteSignUpPayload {
  givenName: string;
  familyName: string;
  dateOfBirth: Date;
  nickname: string;
  countryOfResidence: SupportedCountry;
}

export interface ForgotPasswordPayload {
  email: string;
}

export interface ForgotPasswordConfirmPayload {
  email: string;
  code: string;
  newPassword: string;
}

export interface ChangePasswordPayload {
  password: string;
  newPassword: string;
}

export interface UploadAvatarPayload {
  avatar: File;
}

export enum WalletType {
  Bonus = "bonus",
  Deposit = "deposit",
  Match = "match",
  Tournament = "tournament",
  /**
   * When user wants to withdraw his money, we'll create temporary wallet to freeze his money.
   */
  Withdraw = "withdraw",
  /**
   * That's the bonus wallet where user can send $5 from bonus account.
   * On this wallet user stacks his wins with that 'bonus duels'.
   * Money from that is transfered do user's deposit wallet after 3 wins.
   * When he will lose - money is transfered to opponent (partially) and to system
   */
  BonusPrice = "bonus-price",
}

export interface Wallet {
  id: string;
  status: string;
  type: WalletType;
  currency: string;
  amount?: number;
  amountInUSD?: number;
}

export type WalletsResponse = Wallet[];

export interface ChangeLanguagePayload {
  language: string;
}

export interface ChangeBankAccountNumberPayload {
  bankAccountNumber: string;
}

export interface ConfirmChangeBankAccountNumberPayload {
  bankAccountNumber: string;
  confirmationCode: string;
}

export interface AccountNumberResponse {
  accountNumber: string;
  status: "Active" | "ChangeRequest" | "Historical";
  updatedAt: string;
}

export type UserTransactionStatus = "PROCESSED" | "IN_PROGRESS";
export type UserTransactionType = "DEPOSIT" | "WITHDRAW" | "ENTRY_FEE" | "WIN" | "BONUS" | "OTHER";
export type TransactionDirection = "INCOMING" | "OUTGOING";

export type GetUserTransactionsQuery = {
  page?: number;
  pageSize?: number;
  direction?: TransactionDirection;
  type?: "MATCH" | "DEPOSIT" | "WITHDRAW";
  sort?: ActionSort;
};

export interface Transaction {
  id: string;
  amount: number;
  currency: string;
  createdAt: Date;
  status: UserTransactionStatus;
  type?: UserTransactionType;
  direction?: TransactionDirection;
}

export interface WithdrawMoneyPayload {
  amount: number;
  currency: string;
  bankAccountNumber: string;
}

export interface GetUserNotificationsQuery {
  page?: number;
  pageSize?: number;
  status?: "READ" | "UNREAD";
  sort?: "priority";
}

export interface GetNewNotificationsAmountResponse {
  count: number;
}

export interface UserBlockActionPayload {
  userId: string;
}
