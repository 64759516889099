import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const ArrowIcon = styled.span<{ direction?: "INCOMING" | "OUTGOING" }>`
  color: ${({ direction, theme }) => {
    switch (direction) {
      case "INCOMING":
        return theme.colors.green;
      case "OUTGOING":
        return theme.colors.red;
      default:
        return theme.colors.white;
    }
  }};
  margin-right: 5px;
  font-weight: 800;
  font-size: 15px;
`;
