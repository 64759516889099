import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import PageContent from "../pageContent.type";
import { FormComponentInput } from "../../../common/formComponents/formComponents.types";
import Button from "../../../common/buttons/button/Button";
import renderFormField from "../../../common/formComponents/render-form-field";
import { SignUpAuthPayload } from "../Signup.type";
import useAppQuery from "../../../../hooks/useQuery/useQuery";
import { getRegistrationConsent } from "../../../../API/cms/cmsActions";
import useLanguage from "../../../../hooks/useLanguage/useLanguage";
import LazyFragment from "../../../common/lazyFragment/LazyFragment";
import { ConsentItem } from "../../../../API/cms/cmsActions.type";
import useAppMutation from "../../../../hooks/useMutation/useMutation";
import { oauth, signUp } from "../../../../API/user/userActions";
import httpStatusHelper from "../../../../helpers/http-status-helper";
import Routes from "../../../../config/routes";
import { authStorage } from "../../../../storage/auth/auth.storage";

export interface SignUpConsentStageProps {
  authStageState: SignUpAuthPayload;
}

const buildTermSchema = (key: string, required: boolean, missingMessage: string): Yup.BooleanSchema => {
  let schema = Yup.boolean();
  if (required) {
    schema = schema.isTrue(missingMessage);
  }

  return schema;
};

const buildValidationSchema = (consents: ConsentItem[], missingMessage: string) =>
  Yup.object().shape(
    consents.reduce(
      (acc, consent) => ({
        ...acc,
        [consent.key]: buildTermSchema(consent.key, consent.required, missingMessage),
      }),
      {}
    )
  );

type SignUpConsentContentProps = SignUpConsentStageProps & {
  consents: ConsentItem[];
};

function SignUpConsentContent({ authStageState, consents }: SignUpConsentContentProps) {
  const { t } = useTranslation();
  const translations: PageContent = t("signup", { returnObjects: true });
  const signUpMutation = useAppMutation(signUp);
  const oauthMutation = useAppMutation(oauth);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigation = useNavigate();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha();
    return token;
  }, [executeRecaptcha]);

  const validationSchema = buildValidationSchema(consents, translations.errorTerms);

  const handleOauth = async (formConsents: any, captchaToken: string, provider: "google" | "facebook"): Promise<void> => {
    try {
      const response = await oauthMutation.mutateAsync({
        captchaToken,
        provider,
        consents: formConsents,
        referralCode: authStageState.referralKey,
      });
      if (httpStatusHelper.isSuccess(response.status) && response.payload?.url) {
        window.location.href = response.payload?.url;
      } else if (response.errorObject) {
        const errorMessage = response.errorObject.response.data.message;
        NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
      } else {
        NotificationManager.error(t(`errors.ERROR_OCCURRED`), "Message", 10000);
      }
    } catch (error: any) {
      NotificationManager.error(t(`errors.ERROR_OCCURRED`), "Message", 10000);
    }
  };

  const handleEmail = async (formConsents: any, captchaToken: string): Promise<void> => {
    try {
      const response = await signUpMutation.mutateAsync({
        captchaToken,
        consents: formConsents,
        email: authStageState.emailPayload!.email,
        password: authStageState.emailPayload!.password,
        referralCode: authStageState.referralKey,
      });

      if (httpStatusHelper.isSuccess(response.status) && response.payload) {
        authStorage.setTokens(response.payload.accessToken, response.payload.fullAccess, response.payload.refreshToken);
        navigation(Routes.Home);
      } else if (response.errorObject) {
        NotificationManager.error(t("errors.USER_EXISTS"), "Message", 10000);
      } else {
        NotificationManager.error(t(`errors.ERROR_OCCURRED`), "Message", 10000);
      }
    } catch (error: any) {
      NotificationManager.error(t(`errors.ERROR_OCCURRED`), "Message", 10000);
    }
  };

  const onFormSubmit = async (formConsents: any): Promise<void> => {
    const captchaToken = await handleReCaptchaVerify();
    if (!captchaToken) {
      NotificationManager.error(translations.errorCaptcha, "Message", 10000);
      return;
    }

    if (authStageState.type === "email") {
      return handleEmail(formConsents, captchaToken);
    }

    return handleOauth(formConsents, captchaToken, authStageState.type);
  };

  const formik = useFormik({
    initialValues: consents.reduce(
      (acc, consent) => ({
        ...acc,
        [consent.key]: false,
      }),
      {} as any
    ),
    validationSchema,
    onSubmit: onFormSubmit,
  });

  const formInputs: FormComponentInput[] = consents.map((consent) => {
    const touchedError = (formik.touched as any)[consent.key];

    return {
      type: "checkbox",
      label: consent.description || "",
      id: `signup-${consent.key}`,
      name: consent.key,
      value: formik.values[consent.key],
      onChange: formik.handleChange,
      error: (touchedError && formik.errors[consent.key]) || "",
      onBlur: formik.handleBlur,
    };
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="inputs">
        <div className="form-checkboxes">{formInputs.map((input) => renderFormField(input))}</div>
      </div>

      <Button
        type="labelButton"
        submitButton
        label={translations.submitButton}
        variant="blue"
      />
    </form>
  );
}

export default function SignUpConsentStage({ authStageState }: SignUpConsentStageProps) {
  const { language } = useLanguage();
  const { data, isError } = useAppQuery(getRegistrationConsent(language));

  return (
    <LazyFragment
      error={isError}
      payload={data}
      loading={false}
    >
      {(response) => (
        <SignUpConsentContent
          authStageState={authStageState}
          consents={response.items}
        />
      )}
    </LazyFragment>
  );
}
