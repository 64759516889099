import axios from "axios";
// Functions
export const isauth = () => {
  let isauthorised = false;
  const { cookie } = document;

  if (/token/.test(document.cookie) && cookie) {
    isauthorised = true;
  }
  return isauthorised;
};

export const isAuthAdmin = async () => {
  let isauthorised = false;
  const { cookie } = document;
  if (/admintoken/.test(document.cookie) && cookie) {
    isauthorised = true;
  }
  return isauthorised;

  // let isAuthAdmin = false
  // const cookie = document.cookie;
  // if (/admintoken/.test(document.cookie) && cookie) {
  //     const response = await adminInstance.post("/validateAdmin");
  //     const { code } = response.data;
  //     if (code === 200) {
  //         isAuthAdmin = true
  //     } else {
  //         isAuthAdmin = false
  //     }
  // }
  // return isAuthAdmin
};

export const validateData = (data: []) => {
  let isValid = true;
  data.forEach((el) => {
    if (!el) {
      isValid = false;
    }
  });
  return isValid;
};

// @converting currency to other currency
export const convertToOtherCurrencies = async (deposit: number, bonus: number, otherCurrency: string) => {
  let result = 0;
  let bonusResult = 0;
  const response = await axios.get(`https://api.openrates.io/latest?base=USD&symbols=${otherCurrency}`);
  result = deposit * response.data.rates[otherCurrency];
  bonusResult = bonus * response.data.rates[otherCurrency];
  return { result, bonusResult };
};

// @converting payment amount to other currency
export const convertAmountToOtherCurrencies = async (deposit: number, otherCurrency: string) => {
  let result = 0;
  const response = await axios.get(`https://api.openrates.io/latest?base=${otherCurrency}&symbols=USD`);
  result = deposit * response.data.rates.USD;
  return result;
};
