import styled from "styled-components";

export type ValueColor = "red" | "blue" | "black" | "white";

const handleColorType = (color: string | undefined) => {
  switch (color) {
    case "red":
      return ({ theme }: any) => theme.colors.red;
    case "blue":
      return ({ theme }: any) => theme.colors.blue;
    case "black":
      return ({ theme }: any) => theme.colors.black;
    case "white":
    default:
      return ({ theme }: any) => theme.colors.white;
  }
};

const ProfileRowStyled = styled.div<{ valueColor?: string }>`
  display: flex;
  align-items: center;
  height: 40px;

  .row-title {
    font-size: ${({ theme }) => theme.fontSizes.p};
    color: ${({ theme }) => theme.colors.grey};
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    width: 40%;
  }

  .row-value {
    font-size: ${({ theme }) => theme.fontSizes.p};
    color: ${({ valueColor }) => handleColorType(valueColor)};
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    width: 60%;
    overflow-wrap: break-word;
  }

  .icon {
    margin-left: 10px;
  }

  .icon:hover {
    cursor: pointer;
  }

  a {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export default ProfileRowStyled;
