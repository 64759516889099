import React, { useEffect, useState } from "react";
import Select from "react-select";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import FilterStyled from "./Filter.style";
import Icon from "../../common/icon/Icon";

export interface Sort {
  field: string;
  direction: "asc" | "desc";
}

export interface Option {
  label: string;
  value: string | Sort;
}

interface BaseFilterInterface {
  isAutoComplete: boolean;
  label: string;
  id: string;
  name: string;
  value: string;
  onChange: (option: Option | null) => void;
  isDisabled?: boolean;
  defaultValue?: string;
}

export interface DefaultFilter extends BaseFilterInterface {
  isAutoComplete: false;
  options: Option[];
}

export interface AutoCompleteFilter extends BaseFilterInterface {
  isAutoComplete: true;
  loadOptions: (search?: string) => Promise<Option[]>;
}

export type FilterInterface = DefaultFilter | AutoCompleteFilter;

const isAutoCompleteFilter = (filter: FilterInterface): filter is AutoCompleteFilter => filter.isAutoComplete === true;

interface FilterProps {
  item: FilterInterface;
}

function Filter({ item }: FilterProps) {
  const { label, id, onChange, value } = item;
  const [selectedOption, setSelectedOption] = useState<Option | null>();

  useEffect(() => {
    if (!item.isAutoComplete) {
      setSelectedOption(item.options.find((option) => option.value === value) || null);
    }
  }, [value]);

  const onSelect = (option: Option | null) => {
    setSelectedOption(option);
    onChange(option);
  };

  const clearFilter = () => {
    if (item.isDisabled) {
      return;
    }
    onSelect(null);
  };

  const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
    if (isAutoCompleteFilter(item)) {
      item.loadOptions(inputValue).then((res) => callback(res));
    }
  };

  return (
    <FilterStyled>
      <label htmlFor={id}>
        {selectedOption && (
          <button
            type="button"
            onClick={() => clearFilter()}
            className="clear-filter"
          >
            &#215;
          </button>
        )}
        <Icon variant="filter" />
        <p className="label-text">{label}</p>
      </label>
      {isAutoCompleteFilter(item) ? (
        <AsyncSelect
          classNamePrefix="react-select"
          value={selectedOption}
          onChange={(option?: any) => onSelect(option || null)}
          loadOptions={debounce(loadOptions, 400)}
          defaultOptions
          isDisabled={item.isDisabled}
        />
      ) : (
        <Select<Option>
          classNamePrefix="react-select"
          value={selectedOption}
          onChange={(option: Option | null) => onSelect(option)}
          options={item.options}
          isDisabled={item.isDisabled}
          placeholder={item.defaultValue}
        />
      )}
    </FilterStyled>
  );
}

export default Filter;
