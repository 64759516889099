import React from "react";
import { useTranslation } from "react-i18next";
import AcceptOpponentModalStyled from "./AcceptOpponentModal.style";
import Button from "../../components/common/buttons/button/Button";
import PageContent from "../pageContent.type";
import useSocket from "../../hooks/useSocket/useSocket";

function AcceptOpponentModal() {
  const { acceptMatchmaking, rejectMatchmaking } = useSocket();
  const translations: PageContent = useTranslation().t("waitingopponent", { returnObjects: true });

  return (
    <AcceptOpponentModalStyled>
      <div className="wrapper">
        <div className="buttons">
          <Button
            type="labelButton"
            label={translations.opponent_outside_category_accept_button}
            width="180px"
            variant="blue"
            onClick={acceptMatchmaking}
          />
          <Button
            type="labelButton"
            label={translations.opponent_outside_category_reject_button}
            variant="blue"
            width="180px"
            onClick={rejectMatchmaking}
          />
        </div>
      </div>
    </AcceptOpponentModalStyled>
  );
}

export default AcceptOpponentModal;
