/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import GameListStyled from "./GameList.style";
import { matchNotFinished } from "../../../assets/images";
import { BonusRoomActivated, Match } from "../../../API/game/gameActions.type";

interface GameListProps {
  item: BonusRoomActivated;
  matchesAmount: number;
  wonMatchAnimation?: boolean;
}

function GameList({ item, matchesAmount, wonMatchAnimation = false }: GameListProps) {
  const matchesArray = new Array(matchesAmount).fill(null);
  const [matches, setMatches] = useState<(Match | null)[]>(matchesArray);

  useEffect(() => {
    if (!item.matches) {
      return;
    }
    item.matches.forEach((match, index) => {
      matchesArray[index] = match;
    });
    setMatches(matchesArray);
  }, []);

  const createIcon = (match: Match | null, index: number) => {
    const lostIndex = matches.findIndex((m) => m?.isWinner === false);
    if (lostIndex >= 0 && lostIndex < index) {
      return (
        <img
          src={matchNotFinished}
          alt="not available game"
          className="image"
        />
      );
    }

    if (!match || match.isWinner === null) {
      return <div className="box" />;
    }

    const classes = ["box"];
    if (!match.isWinner) {
      classes.push("red");
    } else if (index === item.matches!.length - 1) {
      classes.push("green", "won-animation");
    } else {
      classes.push("green");
    }

    return <div className={classes.join(" ")} />;
  };

  return (
    <GameListStyled wonMatchAnimation={wonMatchAnimation}>
      {matches.map((match, index) => (
        <div
          className="item"
          key={match?.id || Math.random()}
        >
          {createIcon(match, index)}
          <p className={match?.isWinner === null ? "game-name bold" : "game-name"}>
            {match?.gameName || "???"}
            {match?.isWinner === null && "..."}
          </p>
        </div>
      ))}
    </GameListStyled>
  );
}

export default GameList;
