import React from "react";
import { rankIcon } from "../../../../assets/images";
import RankIconStyled from "./RankIcon.style";

interface RankIconProps {
  rank: number;
}

const createRankNumber = (rank: number) => {
  if (rank < 1) {
    return null;
  }
  let value: string | number;
  if (rank <= 9) {
    value = rank;
  } else {
    value = "M";
  }
  return <p className="rank-value">{value}</p>;
};

function RankIcon({ rank }: RankIconProps) {
  return (
    <RankIconStyled>
      <img
        src={rankIcon}
        className="rank-icon"
        alt=""
      />
      {createRankNumber(rank)}
    </RankIconStyled>
  );
}

export default RankIcon;
