import React from "react";
import DateInputStyled from "./DateInput.style";

export interface DateInputInterface {
  type: "date" | "datetime";
  label: string;
  id: string;
  name: string; // name has to be the same as formik value key
  value: string;
  onChange: (e: React.ChangeEvent) => void;
  error: string;
}

interface DateInputProps {
  item: DateInputInterface;
}

function DateInput({ item }: DateInputProps) {
  const { type, label, name, value, id, onChange, error } = item;

  const applyClassNames = () => {
    let className = "text-input";
    if (error) {
      className += " red";
    }
    if (value) {
      className += " valid-value";
    }
    return className;
  };

  return (
    <DateInputStyled>
      <label
        className="form-label"
        htmlFor={id}
      >
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        className={applyClassNames()}
      />
      <p className="error">{error}</p>
    </DateInputStyled>
  );
}

export default DateInput;
