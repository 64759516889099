/* eslint-disable react/jsx-filename-extension */
import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";
import history from "./config/history";
import baseTheme from "./themes";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";
import AuthProvider from "./providers/authProvider/AuthProvider";
import ProfileProvider from "./providers/profileProvider/ProfileProvider";
import ClientProvider from "./providers/clientProvider/ClientProvider";
import LanguageProvider from "./providers/languageProvider/LanguageProvider";
import Router from "./components/router/Router";
import "./responsive.css";
import SocketProvider from "./providers/socketProvider/SocketProvider";
import { ModalProvider } from "./modals";
import GlobalStyles from "./globalStyles";

function App() {
  const queryClient = new QueryClient();
  return (
    <div className="App">
      <ModalProvider>
        <AuthProvider>
          <ClientProvider>
            <QueryClientProvider client={queryClient}>
              <ProfileProvider>
                <Suspense fallback={null}>
                  <LanguageProvider>
                    <BrowserRouter history={history}>
                      <SocketProvider>
                        <ThemeProvider theme={baseTheme}>
                          <GlobalStyles />
                          <NotificationContainer />
                          <Router />
                          <ModalProvider.ViewSwitch />
                        </ThemeProvider>
                      </SocketProvider>
                    </BrowserRouter>
                  </LanguageProvider>
                </Suspense>
              </ProfileProvider>
            </QueryClientProvider>
          </ClientProvider>
        </AuthProvider>
      </ModalProvider>
    </div>
  );
}

export default App;
