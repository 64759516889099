import React from "react";
import "./futureTournament.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FutureTournamentData from "../futureTournamentData.type";
import profileImg from "../../../../assets/images/external-tournaments/tournament-info-person.png";
import trophyImg from "../../../../assets/images/external-tournaments/tournament-info-trophy.png";
import SmallTournamentCounter from "./smallTournamentCounter";
import Routes from "../../../../config/routes";

interface FutureTournamentProps {
  tournamentData: FutureTournamentData;
}

export default function FutureTournament({ tournamentData }: FutureTournamentProps) {
  const navigation = useNavigate();

  const redirectToDetails = () => {
    navigation(`${Routes.FutureTournaments}/${tournamentData.id}`);
  };

  return (
    <div className="future-tournament">
      <div className="info">
        <div className="name">{tournamentData.name}</div>
        <div className="game-title">{tournamentData.info.gameTitle}</div>
        <div className="info-with-icons">
          <div className="info-tile">
            <img
              className="info-icon"
              src={profileImg}
              alt="profile icon"
            />
            <div className="info-text">
              <span className="label">Data</span>
              <span className="value">{tournamentData.info.dateRange}</span>
            </div>
          </div>
          <div className="info-tile">
            <img
              className="info-icon"
              src={trophyImg}
              alt="trophy icon"
            />
            <div className="info-text">
              <span className="label">Pula nagród</span>
              <span className="value">{tournamentData.info.pool}</span>
            </div>
          </div>
        </div>
        <div className="button-container">
          <Button onClick={redirectToDetails}>Szczegóły</Button>
        </div>
        <div className="end-date">
          <SmallTournamentCounter startDate={tournamentData.startDate} />
        </div>
      </div>
      <div className="banner-container">
        <img
          src={tournamentData.bannerImageUrl}
          alt="banner"
        />
      </div>
    </div>
  );
}
