import React from "react";
import { useTranslation } from "react-i18next";
import "./runningduels.css";
import Layout from "../layout";
import { player1, player2, player3, player4, player5, player6 } from "../../assets/images";

function Runningduels() {
  const { t } = useTranslation();
  const runningduelsData = [
    {
      title: "Pong Game",
      subtitle: "Day of Duel",
      badge: "Playing",
      entryfee: "10 €",
      plateform: "DOD",
      prize: "12 €",
      player1: "Abigail",
      player1img: player1,
      player2: "Bella",
      player2img: player2,
    },
    {
      title: "Pool",
      subtitle: "Day of Duel",
      badge: "Playing",
      entryfee: "25 €",
      plateform: "DOD",
      prize: "95 €",
      player1: "Claire",
      player1img: player3,
      player2: "Diana",
      player2img: player4,
    },
    {
      title: "Fifa 2020",
      subtitle: "Day of Duel",
      badge: "Playing",
      entryfee: "5 €",
      plateform: "XBOX",
      prize: "11 €",
      player1: "Emma",
      player1img: player5,
      player2: "Jasmine",
      player2img: player6,
    },
    {
      title: "Pong Game",
      subtitle: "Day of Duel",
      badge: "Playing",
      entryfee: "10 €",
      plateform: "DOD",
      prize: "12 €",
      player1: "Abigail",
      player1img: player1,
      player2: "Bella",
      player2img: player2,
    },
    {
      title: "Pool",
      subtitle: "Day of Duel",
      badge: "Playing",
      entryfee: "25 €",
      plateform: "DOD",
      prize: "95 €",
      player1: "Claire",
      player1img: player3,
      player2: "Diana",
      player2img: player4,
    },
    {
      title: "Fifa 2020",
      subtitle: "Day of Duel",
      badge: "Playing",
      entryfee: "5 €",
      plateform: "XBOX",
      prize: "11 €",
      player1: "Emma",
      player1img: player5,
      player2: "Jasmine",
      player2img: player6,
    },
    {
      title: "Pong Game",
      subtitle: "Day of Duel",
      badge: "Playing",
      entryfee: "10 €",
      plateform: "DOD",
      prize: "12 €",
      player1: "Abigail",
      player1img: player1,
      player2: "Bella",
      player2img: player2,
    },
    {
      title: "Pool",
      subtitle: "Day of Duel",
      badge: "Playing",
      entryfee: "25 €",
      plateform: "DOD",
      prize: "95 €",
      player1: "Claire",
      player1img: player3,
      player2: "Diana",
      player2img: player4,
    },
    {
      title: "Fifa 2020",
      subtitle: "Day of Duel",
      badge: "Playing",
      entryfee: "5 €",
      plateform: "XBOX",
      prize: "11 €",
      player1: "Emma",
      player1img: player5,
      player2: "Jasmine",
      player2img: player6,
    },
  ];

  return (
    <Layout>
      <section className="running-duels">
        <div className="container">
          <div className="page-title-dark">
            <h2>{t("runningduels.running_duels")}</h2>
            <h3>
              {t("runningduels.running")}
              <span>{t("runningduels.duels")}</span>
            </h3>
          </div>
          <div className="row">
            {runningduelsData.map((el) => (
              <div className="col-md-6 col-lg-4">
                <div className="card shadow-effect">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-8">
                        <h4 className="card-headline">
                          <strong>{el.title}</strong>
                        </h4>
                        <h4 className="card-subline">{el.subtitle}</h4>
                      </div>
                      <div className="col-4">
                        <div className="card-badge">
                          <span className="badge badge-info">{el.badge}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body row no-gutters">
                    <div className="card-body-item col-4">
                      <div className="card-body-item-inner">
                        <div className="card-body-headline">{el.entryfee}</div>
                        <div className="card-body-subline">{t("runningduels.entryfee")}</div>
                      </div>
                    </div>
                    <div className="card-body-item col-5">
                      <div className="card-body-item-inner">
                        <div className="card-body-headline">{el.plateform}</div>
                        <div className="card-body-subline">
                          <span className="translation_missing">{t("runningduels.platform")}</span>
                        </div>
                      </div>
                    </div>
                    <div className="card-body-item card-body-item-prize col-3">
                      <div className="card-body-item-inner">
                        <div className="card-body-headline">
                          <strong>{el.prize}</strong>
                        </div>
                        <div className="card-body-subline">{t("runningduels.prize")}</div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="vs-wrapper row no-gutters">
                      <div className="vs-item col-5">
                        <a
                          className="vs-name"
                          href="/"
                        >
                          {el.player1}
                        </a>
                        <a
                          className="avatar avatar- avatar-sm avatar-false"
                          href="/"
                        >
                          <div className="avatar-image-wrapper">
                            <img
                              className="avatar-image"
                              src={el.player1img}
                              alt=""
                            />
                          </div>
                        </a>
                      </div>
                      <div className="vs-vs col-2">
                        <span>vs.</span>
                      </div>
                      <div className="vs-item col-5">
                        <a
                          className="avatar avatar- avatar-sm avatar-false"
                          href="/"
                        >
                          <div className="avatar-image-wrapper">
                            <img
                              className="avatar-image"
                              src={el.player2img}
                              alt=""
                            />
                          </div>
                        </a>
                        <a
                          className="vs-name"
                          href="/"
                        >
                          {el.player2}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Runningduels;
