import { PaginatedAction } from "../../API/action.type";
import useElementIntersect from "../useElementIntersect/useElementIntersect";
import useInfinitePagination, { InfinitePaginationOptions, InfinitePaginationResult } from "../useInfinitePagination/useInfinitePagination";

export type IntersectElementRef = (node: HTMLElement | null) => void;

type InfinitePaginationWithIntersectResult<R> = InfinitePaginationResult<R> & {
  lastElementRef: IntersectElementRef;
};

function useInfinitePaginationWithIntersect<R>(action: PaginatedAction<R>, options?: InfinitePaginationOptions): InfinitePaginationWithIntersectResult<R> {
  const result = useInfinitePagination(action, options);
  const { isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = result;

  const { lastElementRef } = useElementIntersect({
    shouldBeSkipped: !hasNextPage || isLoading || isFetchingNextPage || isFetching,
    onIntersect: () => {
      if (hasNextPage) {
        fetchNextPage();
      }
    },
  });

  return {
    ...result,
    lastElementRef,
  };
}

export default useInfinitePaginationWithIntersect;
