import React from "react";
import { Accordion } from "react-accessible-accordion";
import { useTranslation } from "react-i18next";
import "./style.css";
import Layout from "../layout";
import AccordionContainer from "./AccordionContainer";
import PageContent from "./pageContent.type";
import useLanguage from "../../hooks/useLanguage/useLanguage";
import useAppQuery from "../../hooks/useQuery/useQuery";
import { getFAQContent } from "../../API/cms/cmsActions";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import Routes from "../../config/routes";

function Faqs() {
  const { language } = useLanguage();
  const { data } = useAppQuery(getFAQContent(language));
  const translations: PageContent = useTranslation().t("faq", { returnObjects: true });

  if (!data?.items) {
    return null;
  }

  return (
    <Layout>
      <div className="faq-bg">
        <section className="faqs">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="title-page">
                  <Breadcrumb
                    link={Routes.Home}
                    linkTextPrevious={translations.home}
                    linkTextCurrent={translations.faq}
                  />
                  <h2>{translations.faq}</h2>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Accordion allowZeroExpanded>
                  <div className="col-md-6">
                    {data.items
                      .filter((item, index) => index % 2 === 0)
                      .map((item) => (
                        <AccordionContainer
                          question={item.question}
                          answer={item.answer}
                          key={item.question}
                        />
                      ))}
                  </div>
                  <div className="col-md-6">
                    {data.items
                      .filter((item, index) => index % 2 === 1)
                      .map((item) => (
                        <AccordionContainer
                          question={item.question}
                          answer={item.answer}
                          key={item.question}
                        />
                      ))}
                  </div>
                </Accordion>
              </div>
            </div>

            <div className="row helpsection">
              <div className="col-md-6">
                <h3>{translations.help_heading}</h3>
                <p>{translations.help_description}</p>
              </div>

              <div className="col-md-6 text-center">
                <p>gethelp@dayofduel.com</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Faqs;
