import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { AxiosError } from "axios";
import ChangeAccountNumberStage2Styled from "./ChangeAccountNumberStage2.style";
import PageContent from "../pageContent.type";
import { CodeInputInterface } from "../../../components/common/formComponents/codeInput/codeInput";
import renderFormField from "../../../components/common/formComponents/render-form-field";
import Button from "../../../components/common/buttons/button/Button";
import useAppMutation from "../../../hooks/useMutation/useMutation";
import useAppQuery from "../../../hooks/useQuery/useQuery";
import { confirmChangeBankAccountNumber, resendBankAccountChangeCode, getAccountNumberChangeInProgress } from "../../../API/user/userActions";
import { ConfirmChangeBankAccountNumberPayload } from "../../../API/user/userActions.type";
import Loader from "../../../components/loader/Loader";
import Routes from "../../../config/routes";
import FormErrors from "../../../helpers/formErrors.type";
import httpStatusHelper from "../../../helpers/http-status-helper";
import CountdownButton from "../../../components/common/buttons/countdownButton/CountdownButton";

const RESEND_COUNTDOWN = 2 * 60; // seconds

interface ChangeAccountNumberStage2Props {
  accountNumber: string;
}

function ChangeAccountNumberStage2({ accountNumber }: ChangeAccountNumberStage2Props) {
  const { t } = useTranslation();
  const translations: PageContent = t("change_account_number", { returnObjects: true });
  const errorTranslations: FormErrors = t("form_errors", { returnObjects: true });
  const confirmChangeAccountMutation = useAppMutation(confirmChangeBankAccountNumber);
  const resendCodeMutation = useAppMutation(resendBankAccountChangeCode);
  const checkChangeInProgressQuery = useAppQuery(getAccountNumberChangeInProgress(), { retry: false });

  const [isResendBlocked, setIsResendBlocked] = useState(false);
  const [resendCodeCountdownTime, setResendCodeCountdownTime] = useState(0);

  const onFormSubmit = async (payload: ConfirmChangeBankAccountNumberPayload) => {
    try {
      const response = await confirmChangeAccountMutation.mutateAsync(payload);
      if (httpStatusHelper.isSuccess(response.status)) {
        NotificationManager.success(translations.success_number_changed, "Message", 4000);
        setTimeout(() => {
          window.location.href = Routes.Profile;
        }, 500);
      }
      const errorMessage = response.errorObject.response.data.message;
      NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
    } catch (error) {
      console.error((error as AxiosError).response?.data);
    }
  };

  const resendConfirmationCode = async () => {
    if (isResendBlocked) {
      NotificationManager.error(translations.resend_code_sent, "Message", 4000);
      return;
    }
    try {
      const response = await resendCodeMutation.mutateAsync({});
      if (httpStatusHelper.isSuccess(response.status)) {
        setIsResendBlocked(true);
        setResendCodeCountdownTime(RESEND_COUNTDOWN);
        NotificationManager.success(translations.success_sms_sent, "Message", 4000);
        return;
      }
      setResendCodeCountdownTime(response.errorObject.response.data.cooldown);
      const errorMessage = response.errorObject.response.data.message;
      NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
    } catch (error) {
      console.error((error as AxiosError).response?.data);
    }
  };

  const onCountdownEnd = () => {
    setIsResendBlocked(false);
    setResendCodeCountdownTime(RESEND_COUNTDOWN);
  };

  const validationSchema = Yup.object().shape({
    smsCode: Yup.string().required(errorTranslations.required),
  });

  const formik = useFormik({
    initialValues: {
      smsCode: "",
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      if (!accountNumber) {
        NotificationManager.error(translations.error_no_account_number, "Message", 10000);
        return;
      }
      const payload = {
        bankAccountNumber: accountNumber,
        confirmationCode: values.smsCode,
      };
      onFormSubmit(payload);
    },
  });

  const inputs: CodeInputInterface[] = [
    {
      type: "code",
      label: translations.sms_code || "",
      id: "change-account-sms-code",
      name: "smsCode",
      setValue: formik.setFieldValue,
      error: formik.errors.smsCode || "",
    },
  ];

  if (checkChangeInProgressQuery.isLoading) {
    return <Loader />;
  }

  return (
    <ChangeAccountNumberStage2Styled>
      <div className="description">
        <p>{translations.sms_info_1}</p>
        <p>{translations.sms_info_2}</p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="inputs">{inputs.map((item) => renderFormField(item))}</div>
        <CountdownButton
          type="labelButton"
          label={translations.resend_code}
          timeInSeconds={resendCodeCountdownTime}
          interval={1000}
          onClick={resendConfirmationCode}
          onCountdownEnd={() => onCountdownEnd()}
          variant="ghost"
        />
        <Button
          type="labelButton"
          label={translations.confirm_button}
          submitButton
          variant="blue"
          width="130px"
        />
      </form>
    </ChangeAccountNumberStage2Styled>
  );
}

export default ChangeAccountNumberStage2;
