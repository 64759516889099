import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { SupportedNotifications, Text } from "../../../API/user/notifications.type";
import { getUserNotifications } from "../../../API/user/userActions";
import useInfinitePagination from "../../../hooks/useInfinitePagination/useInfinitePagination";
import useMarkNotification from "../../../hooks/useMarkNotification/useMarkNotification";
import useSaveOpeningTime from "../../../hooks/useSaveOpeningTime/useSaveOpeningTime";
import { FilterInterface, Option } from "../../table/Filter/Filter";
import NotificationDot from "../../table/NotificationDot/NotificationDot";
import { TableInputs } from "../../table/TableRow/TableRow";
import FriendInvitationActionButtons from "../Actions/FriendInvitationActionButtons/FriendInvitationActionButtons";
import PageContent from "../pageContent.type";

type TranslatableParamProps = {
  children?: any | any[];
  prefix?: string;
};

function TranslatableParam({ children, prefix }: TranslatableParamProps) {
  const value = Array.isArray(children) ? children.join("") : children?.toString();

  return (
    <Trans
      i18nKey={`notifications_keys.${prefix}_${value}`}
      defaults={value}
    />
  );
}

type OptionalParamProps = {
  children?: any | any[];
  params: Record<string, unknown>;
  visibleOnParam?: string;
};

function OptionalParam({ children, params, visibleOnParam }: OptionalParamProps) {
  if (!visibleOnParam || !params[visibleOnParam]) {
    return null;
  }

  return children;
}

export const createCustomText = (text: Text, id: string, field: string, width?: string) => {
  if (!text) {
    return null;
  }
  return (
    <div
      style={{ maxWidth: width }}
      key={`notifications-table-${id}-${field}`}
    >
      {text.type === "message" ? (
        text.message
      ) : (
        <Trans
          i18nKey={`notifications_keys.${text.translationKey}`}
          values={text.translationVariables}
          components={{
            bold: <span className="bold-text" />,
            translatable: <TranslatableParam />,
            optional: <OptionalParam params={text.translationVariables} />,
          }}
        />
      )}
    </div>
  );
};

function useNotificationsTable() {
  const { t } = useTranslation();
  const translations: PageContent = t("notifications", { returnObjects: true, name: "" });
  const [filters, setFilters] = useState<Record<string, unknown>>({});

  const { data, isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfinitePagination(
    getUserNotifications({
      ...filters,
      pageSize: 10,
    }),
    {
      refetchOnWindowFocus: true,
    }
  );

  const { markAsRead: markAsReadMutation } = useMarkNotification();
  const saveOpeningTimeMutation = useSaveOpeningTime();

  useEffect(() => {
    const saveTime = async () => {
      await saveOpeningTimeMutation.mutateAsync({});
    };
    saveTime();
  }, []);

  const onSort = () => {};

  const formik = useFormik<{
    status: ("read" | "unread") | null;
  }>({
    initialValues: {
      status: null,
    },
    onSubmit: (values: any) => {
      setFilters(values);
    },
  });

  const onFilterSelect = (formikField: string, option: Option | null) => {
    formik.setFieldValue(formikField, option?.value || null);
  };

  const formikFilters: FilterInterface[] = [
    {
      label: translations.table_filter_status,
      id: "notifications-table-status",
      name: "status",
      value: formik.values.status || "",
      onChange: (option: Option | null) => onFilterSelect("status", option),
      isAutoComplete: false,
      options: [
        { label: translations.table_filter_status_read, value: "read" },
        { label: translations.table_filter_status_unread, value: "unread" },
      ],
      defaultValue: translations.table_filter_status_all,
    },
  ];

  useEffect(() => {
    formik.handleSubmit();
  }, [formik.values]);

  const inputs: TableInputs<SupportedNotifications> = [
    {
      id: "title",
      heading: translations.table_heading_title,
      component: {
        type: "custom",
        field: "title",
        creator: ({ title, id }) => createCustomText(title, id, "title", "180px"),
        width: "180px",
      },
    },
    {
      id: "text",
      heading: translations.table_heading_message,
      component: {
        type: "custom",
        field: "text",
        creator: ({ text, id }) => createCustomText(text, id, "text"),
      },
    },
    {
      id: "notificationDateTime",
      heading: translations.table_heading_time,
      component: {
        type: "date",
        field: "notificationDateTime",
      },
    },
    {
      id: "actions",
      heading: translations.table_heading_actions,
      component: {
        type: "custom",
        field: "actions",
        creator: (item) => {
          if (item.type === "information_with_action" && item.actionType === "friend_request") {
            return (
              <FriendInvitationActionButtons
                invitationId={item.componentData.invitationId}
                key={`notifications-table-actions-buttons-${item.id}`}
              />
            );
          }
          return <div key={`notifications-table-${item.id}`} />;
        },
      },
    },
    {
      id: "status",
      heading: "",
      component: {
        type: "custom",
        field: "status",
        creator: ({ status, id }) => (
          <NotificationDot
            status={status}
            notificationId={id}
            width="30px"
            refetchData={refetch}
            key={`notification-dot-${id}`}
          />
        ),
        width: "30px",
      },
    },
  ];

  const onRowClick = async (notification: SupportedNotifications) => {
    if (notification.status === "unread") {
      await markAsReadMutation.mutateAsync(notification.id);
      await refetch();
    }
  };

  return { data: data?.pages.map((page) => page.items).flat() || [], inputs, onSort, formikFilters, fetchNextPage, hasNextPage, onRowClick, isLoading: isLoading || isFetching || isFetchingNextPage };
}

export default useNotificationsTable;
