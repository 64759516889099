import styled from "styled-components";
import { forDesktop } from "../../../../helpers/breakpoints/viewports";
import { ButtonPosition, ButtonColorVariants, ButtonVariants } from "../Button.type";

const handleBackgroundColorType = (backgroundColor: string | undefined) => {
  switch (backgroundColor) {
    case "secondary":
      return ({ theme }: any) => theme.colors.mainBackground;
    case "training":
      return ({ theme }: any) => theme.colors.training;
    case "duel":
      return ({ theme }: any) => theme.colors.duel;
    case "tournament":
      return ({ theme }: any) => theme.colors.tournament;
    case "createTournament":
      return ({ theme }: any) => theme.colors.createTournament;
    case "multiplayer":
      return ({ theme }: any) => theme.colors.multiplayer;
    case "championship":
      return ({ theme }: any) => theme.colors.championship;
    case "ghost":
      return "transparent";
    case "red":
      return ({ theme }: any) => theme.colors.red;
    case "blue":
      return ({ theme }: any) => theme.colors.blue;
    case "lightGrey":
      return ({ theme }: any) => theme.colors.lightGrey;
    case "black":
      return ({ theme }: any) => theme.colors.black;
    case "primary":
    default:
      return ({ theme }: any) => theme.colors.mainBackground;
  }
};

const handleBorderColorType = (backgroundColor: string | undefined) => {
  switch (backgroundColor) {
    case "secondary":
      return ({ theme }: any) => theme.colors.mainBackground;
    case "training":
      return ({ theme }: any) => theme.colors.training;
    case "duel":
      return ({ theme }: any) => theme.colors.duel;
    case "tournament":
      return ({ theme }: any) => theme.colors.tournament;
    case "createTournament":
      return ({ theme }: any) => theme.colors.createTournament;
    case "multiplayer":
      return ({ theme }: any) => theme.colors.multiplayer;
    case "championship":
      return ({ theme }: any) => theme.colors.championship;
    case "ghost":
      return "transparent";
    case "red":
      return ({ theme }: any) => theme.colors.red;
    case "blue":
      return ({ theme }: any) => theme.colors.blue;
    case "black":
      return ({ theme }: any) => theme.colors.white;
    case "primary":
    default:
      return ({ theme }: any) => theme.colors.whiteText;
  }
};

const ButtonStyled = styled.button<{ variant?: ButtonColorVariants; width?: string; position: ButtonPosition; iconPosition?: "left" | "right"; typeVariant: ButtonVariants["type"] }>`
  text-transform: uppercase;
  transition: all 0.3s ease;
  margin: ${({ position }) => (position === "center" ? "auto" : "initial")};
  padding: ${({ typeVariant }) => (typeVariant === "iconButton" ? "8px" : "8px 16px")};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ iconPosition }) => (iconPosition === "left" ? "row" : "row-reverse")};
  column-gap: 8px;
  font: ${({ theme }) => theme.fonts.mobile.button};
  background-color: ${({ variant }) => handleBackgroundColorType(variant)};
  color: ${({ theme }) => theme.colors.whiteText};
  border: 1px solid ${({ variant }) => handleBorderColorType(variant)};
  border-radius: 2px;
  width: ${({ width }) => width};
  white-space: nowrap;

  &:disabled {
    background-color: ${({ theme, variant }) => (variant === "secondary" ? "transparent" : theme.colors.disabled)};
    border-color: ${({ theme, variant }) => (variant === "secondary" ? "transparent" : theme.colors.disabled)};
    color: ${({ theme, variant }) => (variant === "secondary" ? theme.colors.disabled : theme.colors.whiteOpacity25)};
  }

  .icon {
    height: 10px;
  }

  .label {
    margin: 0;
  }

  @media ${forDesktop} {
    letter-spacing: 0.15em;
    padding: ${({ typeVariant }) => (typeVariant === "iconButton" ? "12px" : "12px 30px")};
    padding: ${({ variant }) => variant === "ghost" && "0"};
    font: ${({ theme }) => theme.fonts.desktop.button};

    &:hover:not(:disabled) {
      cursor: pointer;
      box-shadow: ${({ variant }) => variant !== "ghost" && "0px 0px 50px 0px #ffffff9c"};
      outline: none;
    }

    .icon {
      height: 10px;
    }
  }
`;

export default ButtonStyled;
