import useAppQuery from "../useQuery/useQuery";
import { getWallets } from "../../API/user/userActions";

function useWallets() {
  const walletsQuery = useAppQuery(getWallets(), { cacheTime: 10000 });
  const wallets = { depositWallet: walletsQuery.data?.find((item) => item.type === "deposit"), bonusWallet: walletsQuery.data?.find((item) => item.type === "bonus") };
  return wallets;
}

export default useWallets;
