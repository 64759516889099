import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { NotificationManager } from "react-notifications";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AxiosError } from "axios";
import { SelectInterface } from "../../common/formComponents/selectInput/SelectInput";
import { CheckBoxInterface } from "../../common/formComponents/checkBox/CheckBox";
import { InputInterface } from "../../common/formComponents/input/Input";
import { DateInputInterface } from "../../common/formComponents/dateInput/DateInput";
import PageContent from "./pageContent.type";
import Routes from "../../../config/routes";
import COUNTRIES from "../../../config/countries";
import useAppMutation from "../../../hooks/useMutation/useMutation";
import { completeSignUp } from "../../../API/user/userActions";
import { CompleteSignUpPayload, SupportedCountry } from "../../../API/user/userActions.type";
import httpStatusHelper from "../../../helpers/http-status-helper";

function useCompleteSignup() {
  const { t } = useTranslation();
  const translations: PageContent = t("completeSignup", { returnObjects: true });

  const completeSignupMutation = useAppMutation(completeSignUp);

  const onFormSubmit = async (data: CompleteSignUpPayload) => {
    try {
      const response = await completeSignupMutation.mutateAsync(data);
      const isResponseSuccess = httpStatusHelper.isSuccess(response.status);

      if (isResponseSuccess) {
        NotificationManager.success(translations.success, "Message", 10000);
        setTimeout(() => (window.location.href = Routes.Home), 300);
        return;
      }
      const errorMessage = response.errorObject.response.data.message;
      NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
    } catch (error: any) {
      console.error((error as AxiosError).response?.data);
    }
  };

  const validationSchema = Yup.object().shape({
    nickname: Yup.string().min(3, translations.errorNickNameShort).required(translations.errorRequired),
    givenName: Yup.string().min(3, translations.errorNameShort).required(translations.errorRequired),
    familyName: Yup.string().min(3, translations.errorSurnameShort).required(translations.errorRequired),
    dateOfBirth: Yup.string().required("Field required"),
    country: Yup.string().required("Field required"),
  });

  const formik = useFormik({
    initialValues: {
      nickname: "",
      givenName: "",
      familyName: "",
      dateOfBirth: "",
      country: "",
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      const data = {
        givenName: values.givenName,
        familyName: values.familyName,
        dateOfBirth: new Date(values.dateOfBirth),
        nickname: values.nickname,
        countryOfResidence: SupportedCountry[values.country as keyof typeof SupportedCountry],
      };
      onFormSubmit(data);
    },
  });

  const setInviter = (inviter: string | null | (string | null)[]) => {
    if (!inviter) {
      return;
    }
    if (typeof inviter === "string") {
      return formik.setFieldValue("inviter", inviter);
    }
    inviter.forEach((item) => {
      if (item) {
        return formik.setFieldValue("inviter", item);
      }
    });
  };

  useEffect(() => {
    const inviter = queryString.parse(window.location.search);
    setInviter(inviter.ref);
  }, []);

  const formInputs: (InputInterface | SelectInterface | CheckBoxInterface | DateInputInterface)[] = [
    {
      type: "text",
      label: translations.nickName,
      id: "signup-gamename",
      placeholder: translations.nickName,
      name: "nickname",
      value: formik.values.nickname,
      onChange: formik.handleChange,
      error: formik.errors.nickname || "",
    },
    {
      type: "date",
      label: translations.birthDate,
      id: "signup-email",
      name: "dateOfBirth",
      value: formik.values.dateOfBirth,
      onChange: formik.handleChange,
      error: formik.errors.dateOfBirth || "",
    },
    {
      type: "text",
      label: translations.name,
      id: "signup-name",
      placeholder: translations.name,
      name: "givenName",
      value: formik.values.givenName,
      onChange: formik.handleChange,
      error: formik.errors.givenName || "",
    },

    {
      type: "text",
      label: translations.surname,
      id: "signup-family-name",
      placeholder: translations.surname,
      name: "familyName",
      value: formik.values.familyName,
      onChange: formik.handleChange,
      error: formik.errors.familyName || "",
    },
    {
      type: "select",
      label: translations.country,
      id: "signup-country",
      placeholder: "",
      name: "country",
      value: formik.values.country,
      onChange: (option) => formik.setFieldValue("country", option.value),
      options: COUNTRIES,
      error: formik.errors.country || "",
    },
  ];

  return { onSubmit: formik.handleSubmit, formInputs };
}

export default useCompleteSignup;
