/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import ImageInputStyled from "./ImageInput.style";

interface ImageInputProps {
  label: string;
  onChange: (e: React.ChangeEvent) => void;
  error?: string;
  extensions?: string;
}

function ImageInput({ label, onChange, error, extensions }: ImageInputProps) {
  return (
    <ImageInputStyled>
      <label
        htmlFor={label}
        className="file-input"
      >
        Upload File
      </label>
      <input
        type="file"
        id={label}
        onChange={(e) => onChange(e)}
        name={label}
        accept={extensions}
      />
      <p className="error">{error}</p>
    </ImageInputStyled>
  );
}

export default ImageInput;
