import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { ActionSort } from "../../../API/action.type";
import { getUserTransactions } from "../../../API/user/userActions";
import { Transaction, TransactionDirection } from "../../../API/user/userActions.type";
import unicodeLibrary from "../../../helpers/unicode-library";
import useInfinitePagination from "../../../hooks/useInfinitePagination/useInfinitePagination";
import { FilterInterface, Option } from "../../table/Filter/Filter";
import { TableInputs } from "../../table/TableRow/TableRow";
import { ArrowIcon } from "./TransactionsTable.style";

interface TableTranslations {
  id: string;
  type: string;
  amount: string;
  status: string;
  datetime: string;
  filter_transaction_direction: {
    heading: string;
    incoming: string;
    outgoing: string;
  };
  filter_transaction_type: {
    heading: string;
    match: string;
    deposit_withdraw: string;
  };
  statuses: Record<string, string>;
  types: Record<string, string>;
}

interface UseTransactionsTableProps {
  pageSize: number;
  urlParams: URLSearchParams;
  setUrlParams: (obj: {}) => void;
}

function useTransactionsTable({ pageSize, urlParams, setUrlParams }: UseTransactionsTableProps) {
  const translations: TableTranslations = useTranslation().t("transaction", { returnObjects: true });
  const [filters, setFilters] = useState<Record<string, unknown>>({});
  const [sort, setSort] = useState<ActionSort>();

  const { data, isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfinitePagination(
    getUserTransactions({
      ...filters,
      sort,
      pageSize,
    })
  );

  const isLoadingData = isLoading || isFetching || isFetchingNextPage;

  const formik = useFormik<{
    direction: TransactionDirection | null;
    type: ("MATCH" | "DEPOSIT_WITHDRAW") | null;
  }>({
    initialValues: {
      direction: null,
      type: null,
    },
    onSubmit: (values: any) => {
      setFilters(values);
    },
  });

  useEffect(() => {
    formik.handleSubmit();
  }, [formik.values]);

  const onFilterSelect = (formikField: string, option: Option | null) => {
    formik.setFieldValue(formikField, option?.value || null);
    if (!option) {
      urlParams.delete(formikField);
      setUrlParams(urlParams);
    } else if (typeof option?.value === "string") {
      urlParams.set(formikField, option.value);
      setUrlParams(urlParams);
    }
  };

  const formikFilters: FilterInterface[] = [
    {
      label: translations.filter_transaction_direction.heading,
      id: "user-transactions-direction",
      name: "direction",
      value: formik.values.direction || "",
      onChange: (option: Option | null) => onFilterSelect("direction", option),
      isAutoComplete: false,
      options: [
        { label: translations.filter_transaction_direction.incoming, value: "INCOMING" },
        { label: translations.filter_transaction_direction.outgoing, value: "OUTGOING" },
      ],
      isDisabled: isLoadingData,
    },
    {
      label: translations.filter_transaction_type.heading,
      id: "user-transactions-type",
      name: "type",
      value: formik.values.type || "",
      onChange: (option: Option | null) => onFilterSelect("type", option),
      isAutoComplete: false,
      options: [
        { label: translations.filter_transaction_type.match, value: "MATCH" },
        { label: translations.filter_transaction_type.deposit_withdraw, value: "DEPOSIT_WITHDRAW" },
      ],
      isDisabled: isLoadingData,
    },
  ];

  useEffect(() => {
    formik.handleSubmit();
  }, [formik.values]);

  useEffect(() => {
    const type = urlParams.get("type");
    const direction = urlParams.get("direction");

    formik.setFieldValue("type", type);
    formik.setFieldValue("direction", direction);
  }, [urlParams]);

  const inputs: TableInputs<Transaction> = [
    {
      id: "id",
      heading: translations.id,
      component: {
        type: "tooltip",
        field: "id",
      },
      isSortable: true,
    },
    {
      id: "type",
      heading: translations.type,
      component: {
        type: "translatedKey",
        field: "type",
        translations: translations.types,
      },
    },
    {
      id: "amount",
      heading: translations.amount,
      component: {
        field: "amount",
        type: "custom",
        creator: ({ amount, currency, direction, id }) => (
          <div key={`transaction-amount-${id}`}>
            {direction && <ArrowIcon direction={direction}>{direction === "INCOMING" ? unicodeLibrary.upwardsArrow : unicodeLibrary.downwardsArrow}</ArrowIcon>}
            {`${amount.toFixed(2)} ${currency}`}
          </div>
        ),
      },
      isSortable: true,
    },
    {
      id: "status",
      heading: translations.status,
      component: {
        type: "translatedKey",
        field: "status",
        translations: translations.statuses,
      },
    },
    {
      id: "createdAt",
      heading: translations.datetime,
      component: {
        type: "date",
        field: "createdAt",
      },
      isSortable: true,
    },
  ];

  return { data: data?.pages.map((page) => page.items).flat() || [], inputs, onSort: setSort, filters: formikFilters, fetchNextPage, hasNextPage, isLoading: isLoadingData };
}

export default useTransactionsTable;
