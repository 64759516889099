import { Action, PaginatedAction } from "../action.type";
import { SupportedNotifications } from "./notifications.type";
import {
  ForgotPasswordPayload,
  ChangePasswordPayload,
  LoginPayload,
  LoginResponse,
  ProfileResponse,
  CompleteSignUpPayload,
  SignUpPayload,
  UploadAvatarPayload,
  WalletsResponse,
  ChangeLanguagePayload,
  ChangeBankAccountNumberPayload,
  ConfirmChangeBankAccountNumberPayload,
  AccountNumberResponse,
  OauthPayload,
  OauthResponse,
  OauthConfirmPayload,
  OauthConfirmResponse,
  Transaction,
  GetUserTransactionsQuery,
  WithdrawMoneyPayload,
  GetUserNotificationsQuery,
  GetNewNotificationsAmountResponse,
  UserBlockActionPayload,
} from "./userActions.type";

export function loginUser(payload: LoginPayload): Action<LoginResponse> {
  return {
    method: "POST",
    endpoint: "/auth/signIn",
    payload,
    skipAuthRefresh: true,
  };
}

export function signUp(payload: SignUpPayload): Action<LoginResponse> {
  return {
    method: "POST",
    endpoint: "/auth/signUp",
    payload,
    skipAuthRefresh: true,
  };
}

export function oauth(payload: OauthPayload): Action<OauthResponse> {
  const { provider, ...dto } = payload;
  return {
    method: "POST",
    endpoint: `/auth/oauth/${provider}`,
    payload: dto,
    skipAuthRefresh: true,
  };
}

export function oauthConfirm(payload: OauthConfirmPayload): Action<OauthConfirmResponse> {
  return {
    method: "POST",
    endpoint: `/auth/oauth/confirm`,
    payload,
    skipAuthRefresh: true,
  };
}

export function completeSignUp(payload: CompleteSignUpPayload): Action<any> {
  return {
    method: "POST",
    endpoint: "/user/profile/complete",
    payload,
  };
}

export function forgotPassword(payload: ForgotPasswordPayload): Action<void> {
  return {
    method: "POST",
    endpoint: "/auth/forgot-password",
    payload,
  };
}

export function forgotPasswordConfirm(payload: ForgotPasswordPayload): Action<void> {
  return {
    method: "POST",
    endpoint: "/auth/forgot-password/confirm",
    payload,
  };
}

export function changePassword(payload: ChangePasswordPayload): Action<void> {
  return {
    method: "POST",
    endpoint: "/auth/changePassword",
    payload,
  };
}

export function getProfile(): Action<ProfileResponse> {
  return {
    method: "GET",
    endpoint: "/user/profile",
  };
}

export function uploadAvatar(payload: UploadAvatarPayload): Action<void> {
  const { avatar } = payload;
  const bodyFormData = new FormData();
  bodyFormData.append(avatar.name, avatar);

  return {
    method: "POST",
    endpoint: "/user/profile/uploadAvatar",
    payload: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  };
}

export function getWallets(): Action<WalletsResponse> {
  return {
    method: "GET",
    endpoint: "/user/wallet",
    staleTime: 5000,
  };
}

export function changeLanguage(payload: ChangeLanguagePayload): Action<void> {
  return {
    method: "PUT",
    endpoint: "/user/profile/language",
    payload,
  };
}

export function changeBankAccountNumber(payload: ChangeBankAccountNumberPayload): Action<void> {
  return {
    method: "POST",
    endpoint: "/user/bank-account/change",
    payload,
  };
}

export function confirmChangeBankAccountNumber(payload: ConfirmChangeBankAccountNumberPayload): Action<void> {
  return {
    method: "POST",
    endpoint: "/user/bank-account/change/confirm",
    payload,
  };
}

export function resendBankAccountChangeCode(): Action<void> {
  return {
    method: "POST",
    endpoint: "/user/bank-account/change/resend-code",
  };
}

export function cancelBankAccountChange(): Action<void> {
  return {
    method: "POST",
    endpoint: "/user/bank-account/change/cancel",
  };
}

export function getAccountNumberChangeInProgress(): Action<AccountNumberResponse> {
  return {
    method: "GET",
    endpoint: "/user/bank-account/change",
  };
}

export function getBankAccountNumber(): Action<AccountNumberResponse> {
  return {
    method: "GET",
    endpoint: "/user/bank-account/active",
  };
}

export function getUserTransactions(params?: GetUserTransactionsQuery): PaginatedAction<Transaction> {
  const query: Record<string, unknown> & { pageSize: number } = {
    page: params?.page || 1,
    pageSize: params?.pageSize || 10,
    type: params?.type || undefined,
    direction: params?.direction || undefined,
  };

  if (params?.sort) {
    query.sort = `${params.sort.direction === "desc" ? "-" : ""}${params.sort.field}`;
  }
  return {
    method: "GET",
    endpoint: "/user/transaction",
    query,
  };
}

export function withdrawMoney(payload: WithdrawMoneyPayload): Action<void> {
  return {
    method: "POST",
    endpoint: "/user/wallet/withdraw-money",
    payload,
  };
}

export function getUserNotifications(params?: GetUserNotificationsQuery): PaginatedAction<SupportedNotifications> {
  const query: Record<string, unknown> & { pageSize: number } = {
    page: params?.page || 1,
    pageSize: params?.pageSize || 10,
    status: params?.status || undefined,
    sort: params?.sort || undefined,
  };

  return {
    method: "GET",
    endpoint: "/user/notification",
    query,
  };
}

export function getUserNotificationsForDropdown(): Action<SupportedNotifications[]> {
  return {
    method: "GET",
    endpoint: "/user/notification/new",
  };
}

export function getNewNotificationsAmount(): Action<GetNewNotificationsAmountResponse> {
  return {
    method: "GET",
    endpoint: "/user/notification/new/count",
    refetchInterval: 15000, // milliseconds
  };
}

export function markNotificationAsRead(id: string): Action<void> {
  return {
    method: "PUT",
    endpoint: `/user/notification/${id}/read`,
  };
}

export function markNotificationAsUnread(id: string): Action<void> {
  return {
    method: "PUT",
    endpoint: `/user/notification/${id}/unread`,
  };
}

export function saveNotificationsOpeningTime(): Action<void> {
  return {
    method: "PUT",
    endpoint: `/user/notification/open`,
  };
}

export function blockUser(payload: UserBlockActionPayload): Action<void> {
  return {
    method: "POST",
    endpoint: `/user/${payload.userId}/block`,
  };
}

export function unblockUser(payload: UserBlockActionPayload): Action<void> {
  return {
    method: "POST",
    endpoint: `/user/${payload.userId}/unblock`,
  };
}
