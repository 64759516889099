import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import Modal from "react-bootstrap/Modal";
// import UserAdminChat from "../userchat/userchat";
import { appStoreHome, playStore, logoHorizontalWhiteText, payment, chatIcon, sponsorImg1, sponsorImg2, sponsorImg3, sponsorImg4, sponsorImg5, sponsorImg6, sponsorImg7, sponsorImg8 } from "../../assets/images";
import { server } from "../../config/config";
import { getPageContent } from "../../helpers/content";
import PageContent from "./pageContent.type";
import useLanguage from "../../hooks/useLanguage/useLanguage";

interface FooterProps {
  noSponsors?: boolean;
}

function Footer(noSponsors: FooterProps) {
  const { i18n } = useTranslation();
  const [pageContent, setPageContent] = useState<PageContent>();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const page = "/footer";
  const { shortLanguage } = useLanguage();

  const onLanguageChanged = () => {
    getPageContent(page, shortLanguage).then((data) => setPageContent(data));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPageContent(page, shortLanguage).then((data) => setPageContent(data));
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  if (!pageContent) {
    return <div> </div>;
  }
  return (
    <footer>
      {!noSponsors && (
        <div className="sponsors-container">
          <div className="container">
            <div className="sponsors-box">
              <Slider>
                <div className="sponser-item">
                  <img
                    src={sponsorImg1}
                    alt="Sponser"
                  />
                </div>
                <div className="sponser-item">
                  <img
                    src={sponsorImg2}
                    alt="Sponser"
                  />
                </div>
                <div className="sponser-item">
                  <img
                    src={sponsorImg3}
                    alt="Sponser"
                  />
                </div>
                <div className="sponser-item">
                  <img
                    src={sponsorImg4}
                    alt="Sponser"
                  />
                </div>
                <div className="sponser-item">
                  <img
                    src={sponsorImg5}
                    alt="Sponser"
                  />
                </div>
                <div className="sponser-item">
                  <img
                    src={sponsorImg7}
                    alt="Sponser"
                  />
                </div>
                <div className="sponser-item">
                  <img
                    src={sponsorImg6}
                    alt="Sponser"
                  />
                </div>
                <div className="sponser-item">
                  <img
                    src={sponsorImg8}
                    alt="Sponser"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      )}

      <div className="top-footer">
        <div className="container">
          <div className="row logo-container">
            <div className="footer-one">
              <img
                src={logoHorizontalWhiteText}
                alt=""
                className="logo"
              />
            </div>
          </div>

          <div className="row footer-links">
            <div className="col-md-2">
              <h4>{pageContent.socialmedia}</h4>
              <ul className="footer-list">
                <li>
                  <a
                    href="https://www.facebook.com/Day-of-Duel-101086868042585"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {pageContent.link1}
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/dayofduel"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {pageContent.link2}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/dayofduel/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {pageContent.link3}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCnpovMBD9Y8iagQw84R0_ng"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {pageContent.link4}
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-md-2">
              <h4>{pageContent.howto}</h4>
              <ul className="footer-list">
                <li>
                  <Link to="/">{pageContent.link5}</Link>
                </li>
                <li>
                  <Link to="/tournament">{pageContent.link6}</Link>
                </li>
                <li>
                  <Link to="/faqs">{pageContent.link7}</Link>
                </li>
                <li>
                  <Link to="/help">{pageContent.link8}</Link>
                </li>
                <li>
                  <Link to="/payment">{pageContent.link9}</Link>
                </li>
              </ul>
            </div>

            <div className="col-md-2">
              <h4>{pageContent.legalnotes}</h4>
              <ul className="footer-list">
                <li>
                  <Link to="/legal">{pageContent.link10}</Link>
                </li>
                <li>
                  <Link to="/terms">{pageContent.link11}</Link>
                </li>
                <li>
                  <Link to="/privacy">{pageContent.link12}</Link>
                </li>
                <li>
                  <Link to="/transactionsecurity">{pageContent.link13}</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-2">
              <h4>{pageContent.about}</h4>
              <ul className="footer-list">
                <li>
                  <Link to="/about">{pageContent.link14}</Link>
                </li>
                <li>
                  <Link to="/ourteam">{pageContent.link15}</Link>
                </li>
                <li>
                  <Link to="/ourvision">{pageContent.link16}</Link>
                </li>
                <li>
                  <Link to="/responsiblegaming">{pageContent.link17}</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="row footer-info-container">
            <div className="footer-info-text">{pageContent.text1}</div>

            <div className="app-stores">
              <a href={`${server}/user/appDownload`}>
                <img
                  src={playStore}
                  alt=""
                />
              </a>

              <a href="https://testflight.apple.com/join/URzIgwRg">
                <img
                  src={appStoreHome}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="copy-right">
                {/* <span>{new Date().toLocaleString()}</span> */}
                {/* <p>{content.copyright")}</p> */}
                <p>{pageContent.copyright}</p>
                <img
                  className="payment"
                  src={payment}
                  alt="Payment"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {localStorage.getItem("token") && (
        <button
          type="button"
          className="chat-icon"
          onClick={handleShow}
        >
          <img
            src={chatIcon}
            alt="Chat"
          />
        </button>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="dod-popup chatbox-popup"
        backdropClassName="chatbox-overlay"
      >
        <Modal.Body>
          <Modal.Header closeButton />
          {/* <UserAdminChat /> */}
        </Modal.Body>
      </Modal>
    </footer>
  );
}

export default Footer;
