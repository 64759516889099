import styled from "styled-components";

const ShowMoreBarStyled = styled.div`
  .show-more {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.caption};
    letter-spacing: 0.15em;
    padding-bottom: 11px;
    margin-bottom: 15px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 11px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
    text-transform: uppercase;
  }

  .show-more-button {
    border: none;
    background-color: transparent;
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.caption};
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }

  .expand-arrows {
    color: ${({ theme }) => theme.colors.blue};
    padding-left: 10px;
  }
`;

export default ShowMoreBarStyled;
