import React from "react";
import NotificationModalStyled from "./NotificationModal.style";
import Button from "../../components/common/buttons/button/Button";
import { ButtonColorVariants } from "../../components/common/buttons/Button.type";

interface NotificationModalProps {
  title: string;
  description1?: string;
  description2?: string;
  buttonText: string;
  buttonVariant?: ButtonColorVariants;
  onClick?: (e: React.MouseEvent) => void;
}

function NotificationModal({ title, description1, description2, buttonText, buttonVariant, onClick }: NotificationModalProps) {
  return (
    <NotificationModalStyled>
      <div className="modal-container">
        <div className="title">{title}</div>
        <div className="description">
          <div>{description1}</div>
          <div>{description2}</div>
        </div>
        <Button
          type="labelButton"
          label={buttonText}
          width="180px"
          variant={buttonVariant}
          onClick={onClick}
        />
      </div>
    </NotificationModalStyled>
  );
}

export default NotificationModal;
