/* eslint-disable react/destructuring-assignment */
import React from "react";
import ButtonStyled from "./Button.style";
import { ButtonVariants, IconButton, LabelAndIconButton } from "../Button.type";

const isIconButton = (object: ButtonVariants): object is IconButton => object.type === "iconButton";
const isLabelAndIconButton = (object: ButtonVariants): object is LabelAndIconButton => object.type === "labelAndIconButton";

function Button(button: ButtonVariants) {
  const { type, submitButton, onClick, className, variant = "primary", disabled = false, width, position = "center", innerRef } = button;

  const isElementWithIcon = isIconButton(button) || isLabelAndIconButton(button);

  return (
    <ButtonStyled
      type={submitButton ? "submit" : "button"}
      typeVariant={type}
      onClick={onClick}
      className={className}
      variant={variant}
      width={width}
      position={position}
      disabled={disabled}
      iconPosition={type === "labelAndIconButton" ? button.iconPosition : undefined}
      ref={innerRef}
    >
      {isElementWithIcon && (
        <img
          src={button.icon}
          alt={button.alt}
          className="icon"
        />
      )}
      {type !== "iconButton" && <p className="label">{button.label}</p>}
    </ButtonStyled>
  );
}

export default Button;
