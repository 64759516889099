import styled from "styled-components";
import { blueArrowIcon } from "../../../../assets/images";

const calculateSingleStepWidth = (rangeMax: number, rangeMin: number, step: number) => {
  const elementsAmount = (rangeMax - rangeMin) / step + 1;
  return 100 / elementsAmount;
};

interface DoubleRangeInputStyledProps {
  rangeMin: number;
  rangeMax: number;
  step: number;
  singleValueWidth: number;
  minValueIndex: number;
  maxValueIndex: number;
}

const DoubleRangeInputStyled = styled.div<DoubleRangeInputStyledProps>`
  .range-container {
    position: relative;
  }

  .rank-title {
    text-align: center;
    font: ${({ theme }) => theme.fonts.mobile.h2};
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  .slider-track {
    width: 100%;
    height: 28px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    display: flex;
    flex-basis: ${({ rangeMax, rangeMin, step }) => `${calculateSingleStepWidth(rangeMax, rangeMin, step)}%`};
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
  }

  .slider-track::before {
    content: "";
    position: absolute;
    height: 100%;
    left: ${({ minValueIndex, singleValueWidth }) => `${minValueIndex * singleValueWidth}px`};
    width: ${({ minValueIndex, maxValueIndex, singleValueWidth }) => `${(maxValueIndex - minValueIndex + 1) * singleValueWidth}px`};
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colors.buttonSecondary};
  }

  .slider-track-value {
    width: 100%;
    text-align: center;
    z-index: ${({ theme }) => theme.zIndex.rankRangeInputValues};
    font: ${({ theme }) => theme.fonts.global.text10};
  }

  input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
  }

  input[type="range"]::-moz-range-track {
    -moz-appearance: none;
  }

  input[type="range"]::-ms-track {
    appearance: none;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 10px;
    cursor: pointer;
    pointer-events: auto;
    margin-top: 40px;
    width: ${({ singleValueWidth }) => `${singleValueWidth}px`};
    background-image: url(${blueArrowIcon});
    background-repeat: no-repeat;
    background-position: center;
  }

  input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    height: 10px;
    cursor: pointer;
    pointer-events: auto;
    width: ${({ singleValueWidth }) => `${singleValueWidth}px`};
    background-image: url(${blueArrowIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;
    transform: translateY(20px);
  }

  input[type="range"]::-ms-thumb {
    appearance: none;
    height: 10px;
    cursor: pointer;
    pointer-events: auto;
    margin-top: 40px;
    width: ${({ singleValueWidth }) => `${singleValueWidth}px`};
    background-image: url(${blueArrowIcon});
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export default DoubleRangeInputStyled;
