import React from "react";
import { ReactI18NextChild } from "react-i18next";
import Header from "../header/Header";
import Footer from "../footer/Footer";

interface LayoutProps {
  children: ReactI18NextChild;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <div className="main-wrapper">
      <Header />
      <main>
        <div className="main_content">{children}</div>
      </main>
      <Footer />
    </div>
  );
}
