/* eslint-disable import/no-cycle,react/jsx-no-constructed-context-values */
import React, { ComponentProps, createContext, PropsWithChildren, useCallback, useContext, useReducer } from "react";
import Modal from "../components/common/modal/Modal";
import ChooseStakeModal from "./ChooseStakeModal/ChooseStakeModal";
import WaitingOpponentModal from "./WaitingOpponentModal/WaitingOpponentModal";
import GameLoadingModal from "./GameLoadingModal/GameLoadingModal";
import AcceptOpponentModal from "./AcceptOpponentModal/AcceptOpponentModal";
import FoundOpponentModal from "./FoundOpponentModal/FoundOpponentModal";
import NotEnoughMoneyModal from "./NotEnoughMoneyModal/NotEnoughMoneyModal";
import OpponentNotFoundModal from "./OpponentNotFoundModal/OpponentNotFoundModal";
import OpponentOutsideCategoryModal from "./OpponentOutsideCategoryModal/OpponentOutsideCategoryModal";
import UploadAvatarModal from "./UploadAvatarModal/UploadAvatarModal";
import ChooseGameModal from "./ChooseGameModal/ChooseGameModal";
import BonusRoomWinModal from "./BonusRoomWinModal/BonusRoomWinModal";
import WithdrawMoneyModal from "./WithdrawMoneyModal/WithdrawMoneyModal";
import ChangePasswordModal from "./ChangePasswordModal/ChangePasswordModal";
import ChangeAccountNumberModal from "./changeAccountNumberModal/ChangeAccountNumberModal";
import NotificationModal from "./NotificationModal/NotificationModal";
import RegistrationSuccessModal from "./RegistrationSuccessModal/RegistrationSuccessModal";
import ChooseOptionModal from "./ChooseOptionModal/ChooseOptionModal";
import AcceptationModal from "./AcceptationModal/AcceptationModal";
import PlayersListFiltersModal from "./PlayersListFiltersModal/PlayersListFiltersModal";

type CustomOnClose = { onClose: () => void };

export type ModalView =
  | { view: "closed"; data: {} }
  | { view: "accept-opponent"; data: ComponentProps<typeof AcceptOpponentModal> }
  | { view: "choose-stake"; data: ComponentProps<typeof ChooseStakeModal> }
  | { view: "found-opponent"; data: ComponentProps<typeof FoundOpponentModal> }
  | { view: "game-loading"; data: ComponentProps<typeof GameLoadingModal> }
  | { view: "not-enough-money"; data: ComponentProps<typeof NotEnoughMoneyModal> }
  | { view: "opponent-not-found"; data: ComponentProps<typeof OpponentNotFoundModal> }
  | { view: "opponent-outside-category"; data: ComponentProps<typeof OpponentOutsideCategoryModal> }
  | { view: "waiting-opponent"; data: CustomOnClose & ComponentProps<typeof WaitingOpponentModal> }
  | { view: "upload-avatar"; data: ComponentProps<typeof UploadAvatarModal> }
  | { view: "choose-game"; data: ComponentProps<typeof ChooseGameModal> }
  | { view: "bonus-room-win"; data: ComponentProps<typeof BonusRoomWinModal> }
  | { view: "withdraw-money"; data: ComponentProps<typeof WithdrawMoneyModal> }
  | { view: "change-password"; data: ComponentProps<typeof ChangePasswordModal> }
  | { view: "change-account-number"; data: ComponentProps<typeof ChangeAccountNumberModal> }
  | { view: "notification"; data: ComponentProps<typeof NotificationModal> }
  | { view: "registration-success"; data: ComponentProps<typeof RegistrationSuccessModal> }
  | { view: "choose-option"; data: ComponentProps<typeof ChooseOptionModal> }
  | { view: "acceptation"; data: ComponentProps<typeof AcceptationModal> }
  | { view: "players-list-filters"; data: ComponentProps<typeof PlayersListFiltersModal> };

type ModalViewAction =
  | { type: "close" }
  | { type: "open-accept-opponent-modal"; payload: ComponentProps<typeof AcceptOpponentModal> }
  | { type: "open-choose-stake-modal"; payload: ComponentProps<typeof ChooseStakeModal> }
  | { type: "open-found-opponent-modal"; payload: ComponentProps<typeof FoundOpponentModal> }
  | { type: "open-game-loading-modal"; payload: ComponentProps<typeof GameLoadingModal> }
  | { type: "open-not-enough-money-modal"; payload: ComponentProps<typeof NotEnoughMoneyModal> }
  | { type: "open-opponent-not-found-modal"; payload: ComponentProps<typeof OpponentNotFoundModal> }
  | { type: "open-opponent-outside-category-modal"; payload: ComponentProps<typeof OpponentOutsideCategoryModal> }
  | { type: "open-waiting-opponent-modal"; payload: CustomOnClose & ComponentProps<typeof WaitingOpponentModal> }
  | { type: "open-upload-avatar-modal"; payload: ComponentProps<typeof UploadAvatarModal> }
  | { type: "open-choose-game-modal"; payload: ComponentProps<typeof ChooseGameModal> }
  | { type: "open-bonus-room-win-modal"; payload: ComponentProps<typeof BonusRoomWinModal> }
  | { type: "open-withdraw-money-modal"; payload: ComponentProps<typeof WithdrawMoneyModal> }
  | { type: "open-change-password-modal"; payload: ComponentProps<typeof ChangePasswordModal> }
  | { type: "open-change-account-number-modal"; payload: ComponentProps<typeof ChangeAccountNumberModal> }
  | { type: "open-notification-modal"; payload: ComponentProps<typeof NotificationModal> }
  | { type: "open-registration-success-modal"; payload: ComponentProps<typeof RegistrationSuccessModal> }
  | { type: "open-choose-option-modal"; payload: ComponentProps<typeof ChooseOptionModal> }
  | { type: "open-acceptation-modal"; payload: ComponentProps<typeof AcceptationModal> }
  | { type: "open-players-list-filters-modal"; payload: ComponentProps<typeof PlayersListFiltersModal> };

interface IModalContext {
  currentModalView: ModalView;
  closeModal: () => void;
  openAcceptOpponentModal: (payload: ComponentProps<typeof AcceptOpponentModal>) => void;
  openChooseStakeModal: (payload: ComponentProps<typeof ChooseStakeModal>) => void;
  openFoundOpponentModal: (payload: ComponentProps<typeof FoundOpponentModal>) => void;
  openGameLoadingModal: (payload: ComponentProps<typeof GameLoadingModal>) => void;
  openNotEnoughMoneyModal: (payload: ComponentProps<typeof NotEnoughMoneyModal>) => void;
  openOpponentNotFoundModal: (payload: ComponentProps<typeof OpponentNotFoundModal>) => void;
  openOpponentOutsideCategoryModal: (payload: ComponentProps<typeof OpponentOutsideCategoryModal>) => void;
  openWaitingOpponentModal: (payload: CustomOnClose & ComponentProps<typeof WaitingOpponentModal>) => void;
  openUploadAvatarModal: (payload: ComponentProps<typeof UploadAvatarModal>) => void;
  openChooseGameModal: (payload: ComponentProps<typeof ChooseGameModal>) => void;
  openBonusRoomWinModal: (payload: ComponentProps<typeof BonusRoomWinModal>) => void;
  openWithdrawMoneyModal: (payload: ComponentProps<typeof WithdrawMoneyModal>) => void;
  openChangePasswordModal: (payload: ComponentProps<typeof ChangePasswordModal>) => void;
  openChangeAccountNumberModal: (payload: ComponentProps<typeof ChangeAccountNumberModal>) => void;
  openNotificationModal: (payload: ComponentProps<typeof NotificationModal>) => void;
  openRegistrationSuccessModal: (payload: ComponentProps<typeof RegistrationSuccessModal>) => void;
  openChooseOptionModal: (payload: ComponentProps<typeof ChooseOptionModal>) => void;
  openAcceptationModal: (payload: ComponentProps<typeof AcceptationModal>) => void;
  openPlayersListFiltersModal: (payload: ComponentProps<typeof PlayersListFiltersModal>) => void;
}

const emptyStub = () => {};
const ModalContext = createContext<IModalContext>({
  currentModalView: { view: "closed", data: {} },
  closeModal: emptyStub,
  openAcceptOpponentModal: emptyStub,
  openChooseStakeModal: emptyStub,
  openFoundOpponentModal: emptyStub,
  openGameLoadingModal: emptyStub,
  openNotEnoughMoneyModal: emptyStub,
  openOpponentNotFoundModal: emptyStub,
  openOpponentOutsideCategoryModal: emptyStub,
  openWaitingOpponentModal: emptyStub,
  openUploadAvatarModal: emptyStub,
  openChooseGameModal: emptyStub,
  openBonusRoomWinModal: emptyStub,
  openWithdrawMoneyModal: emptyStub,
  openChangePasswordModal: emptyStub,
  openChangeAccountNumberModal: emptyStub,
  openNotificationModal: emptyStub,
  openRegistrationSuccessModal: emptyStub,
  openChooseOptionModal: emptyStub,
  openAcceptationModal: emptyStub,
  openPlayersListFiltersModal: emptyStub,
});

const reducer = (view: ModalView, action: ModalViewAction): ModalView => {
  switch (action.type) {
    case "close":
      if (view.view === "closed") return view;
      return { view: "closed", data: {} };
    case "open-accept-opponent-modal":
      return { view: "accept-opponent", data: action.payload };
    case "open-choose-stake-modal":
      return { view: "choose-stake", data: action.payload };
    case "open-found-opponent-modal":
      return { view: "found-opponent", data: action.payload };
    case "open-game-loading-modal":
      return { view: "game-loading", data: action.payload };
    case "open-not-enough-money-modal":
      return { view: "not-enough-money", data: action.payload };
    case "open-opponent-not-found-modal":
      return { view: "opponent-not-found", data: action.payload };
    case "open-opponent-outside-category-modal":
      return { view: "opponent-outside-category", data: action.payload };
    case "open-waiting-opponent-modal":
      return { view: "waiting-opponent", data: action.payload };
    case "open-upload-avatar-modal":
      return { view: "upload-avatar", data: action.payload };
    case "open-choose-game-modal":
      return { view: "choose-game", data: action.payload };
    case "open-bonus-room-win-modal":
      return { view: "bonus-room-win", data: action.payload };
    case "open-withdraw-money-modal":
      return { view: "withdraw-money", data: action.payload };
    case "open-change-password-modal":
      return { view: "change-password", data: action.payload };
    case "open-change-account-number-modal":
      return { view: "change-account-number", data: action.payload };
    case "open-notification-modal":
      return { view: "notification", data: action.payload };
    case "open-registration-success-modal":
      return { view: "registration-success", data: action.payload };
    case "open-choose-option-modal":
      return { view: "choose-option", data: action.payload };
    case "open-acceptation-modal":
      return { view: "acceptation", data: action.payload };
    case "open-players-list-filters-modal":
      return { view: "players-list-filters", data: action.payload };
    default:
      return view;
  }
};

const modalConfigMap: Record<ModalView["view"], ModalConfig> = {
  closed: { size: "md", isClosable: false },
  "accept-opponent": { size: "full", isClosable: true },
  "choose-stake": { size: "full", isClosable: true },
  "found-opponent": { size: "full", isClosable: true },
  "game-loading": { size: "full", isClosable: false },
  "not-enough-money": { size: "full", isClosable: true },
  "opponent-not-found": { size: "full", isClosable: true },
  "opponent-outside-category": { size: "full", isClosable: true },
  "waiting-opponent": { size: "full", isClosable: true },
  "upload-avatar": { size: "full", isClosable: true },
  "choose-game": { size: "full", isClosable: true },
  "bonus-room-win": { size: "lg", isClosable: true },
  "withdraw-money": { size: "full", isClosable: true },
  "change-password": { size: "full", isClosable: true },
  "change-account-number": { size: "full", isClosable: true },
  notification: { size: "lg", isClosable: false },
  "registration-success": { size: "full", isClosable: true },
  "choose-option": { size: "full", isClosable: true },
  acceptation: { size: "full", isClosable: true },
  "players-list-filters": { size: "full", isClosable: true },
};

interface ModalConfig {
  size: ComponentProps<typeof Modal>["size"];
  isClosable: ComponentProps<typeof Modal>["isClosable"];
}

export function ModalProvider({ children }: PropsWithChildren<void>) {
  const [modalView, dispatch] = useReducer(reducer, { view: "closed", data: {} });

  const closeModal = () => dispatch({ type: "close" });

  const openAcceptOpponentModal = useCallback(
    (payload: ComponentProps<typeof AcceptOpponentModal>) =>
      dispatch({
        type: "open-accept-opponent-modal",
        payload,
      }),
    []
  );

  const openChooseStakeModal = useCallback(
    (payload: ComponentProps<typeof ChooseStakeModal>) =>
      dispatch({
        type: "open-choose-stake-modal",
        payload,
      }),
    []
  );

  const openFoundOpponentModal = useCallback(
    (payload: ComponentProps<typeof FoundOpponentModal>) =>
      dispatch({
        type: "open-found-opponent-modal",
        payload,
      }),
    []
  );

  const openGameLoadingModal = useCallback(
    (payload: ComponentProps<typeof GameLoadingModal>) =>
      dispatch({
        type: "open-game-loading-modal",
        payload,
      }),
    []
  );

  const openNotEnoughMoneyModal = useCallback(
    (payload: ComponentProps<typeof NotEnoughMoneyModal>) =>
      dispatch({
        type: "open-not-enough-money-modal",
        payload,
      }),
    []
  );

  const openOpponentNotFoundModal = useCallback(
    (payload: ComponentProps<typeof OpponentNotFoundModal>) =>
      dispatch({
        type: "open-opponent-not-found-modal",
        payload,
      }),
    []
  );

  const openOpponentOutsideCategoryModal = useCallback(
    (payload: ComponentProps<typeof OpponentOutsideCategoryModal>) =>
      dispatch({
        type: "open-opponent-outside-category-modal",
        payload,
      }),
    []
  );

  const openWaitingOpponentModal = useCallback(
    (payload: CustomOnClose & ComponentProps<typeof WaitingOpponentModal>) =>
      dispatch({
        type: "open-waiting-opponent-modal",
        payload,
      }),
    []
  );

  const openUploadAvatarModal = useCallback(
    (payload: ComponentProps<typeof UploadAvatarModal>) =>
      dispatch({
        type: "open-upload-avatar-modal",
        payload,
      }),

    []
  );

  const openChooseGameModal = useCallback(
    (payload: ComponentProps<typeof ChooseGameModal>) =>
      dispatch({
        type: "open-choose-game-modal",
        payload,
      }),

    []
  );

  const openBonusRoomWinModal = useCallback(
    (payload: ComponentProps<typeof BonusRoomWinModal>) =>
      dispatch({
        type: "open-bonus-room-win-modal",
        payload,
      }),

    []
  );

  const openWithdrawMoneyModal = useCallback(
    (payload: ComponentProps<typeof WithdrawMoneyModal>) =>
      dispatch({
        type: "open-withdraw-money-modal",
        payload,
      }),

    []
  );

  const openChangePasswordModal = useCallback(
    (payload: ComponentProps<typeof ChangePasswordModal>) =>
      dispatch({
        type: "open-change-password-modal",
        payload,
      }),

    []
  );

  const openChangeAccountNumberModal = useCallback(
    (payload: ComponentProps<typeof ChangeAccountNumberModal>) =>
      dispatch({
        type: "open-change-account-number-modal",
        payload,
      }),

    []
  );

  const openNotificationModal = useCallback(
    (payload: ComponentProps<typeof NotificationModal>) =>
      dispatch({
        type: "open-notification-modal",
        payload,
      }),

    []
  );

  const openRegistrationSuccessModal = useCallback(
    (payload: ComponentProps<typeof RegistrationSuccessModal>) =>
      dispatch({
        type: "open-registration-success-modal",
        payload,
      }),

    []
  );

  const openChooseOptionModal = useCallback(
    (payload: ComponentProps<typeof ChooseOptionModal>) =>
      dispatch({
        type: "open-choose-option-modal",
        payload,
      }),

    []
  );

  const openAcceptationModal = useCallback(
    (payload: ComponentProps<typeof AcceptationModal>) =>
      dispatch({
        type: "open-acceptation-modal",
        payload,
      }),

    []
  );

  const openPlayersListFiltersModal = useCallback(
    (payload: ComponentProps<typeof PlayersListFiltersModal>) =>
      dispatch({
        type: "open-players-list-filters-modal",
        payload,
      }),

    []
  );

  return (
    <ModalContext.Provider
      value={{
        currentModalView: modalView,
        closeModal,
        openAcceptOpponentModal,
        openChooseStakeModal,
        openFoundOpponentModal,
        openGameLoadingModal,
        openNotEnoughMoneyModal,
        openOpponentNotFoundModal,
        openOpponentOutsideCategoryModal,
        openWaitingOpponentModal,
        openUploadAvatarModal,
        openChooseGameModal,
        openBonusRoomWinModal,
        openWithdrawMoneyModal,
        openChangePasswordModal,
        openChangeAccountNumberModal,
        openNotificationModal,
        openRegistrationSuccessModal,
        openChooseOptionModal,
        openAcceptationModal,
        openPlayersListFiltersModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => useContext(ModalContext);

function ModalContextViewSwitch() {
  const { currentModalView: modalView, closeModal } = useModal();

  if (!modalView || modalView?.view === "closed") return null;

  const { isClosable, size } = modalConfigMap[modalView.view];

  const handleClose = () => {
    closeModal();
    // @ts-ignore
    modalView?.data?.onClose?.();
  };

  return (
    <Modal
      onClose={handleClose}
      isClosable={modalView && isClosable}
      size={modalView && size}
    >
      {modalView?.view === "accept-opponent" && <AcceptOpponentModal />}
      {modalView?.view === "choose-stake" && <ChooseStakeModal {...modalView.data} />}
      {modalView?.view === "found-opponent" && <FoundOpponentModal {...modalView.data} />}
      {modalView?.view === "game-loading" && <GameLoadingModal {...modalView.data} />}
      {modalView?.view === "not-enough-money" && <NotEnoughMoneyModal {...modalView.data} />}
      {modalView?.view === "opponent-not-found" && <OpponentNotFoundModal {...modalView.data} />}
      {modalView?.view === "opponent-outside-category" && <OpponentOutsideCategoryModal />}
      {modalView?.view === "waiting-opponent" && <WaitingOpponentModal />}
      {modalView?.view === "upload-avatar" && <UploadAvatarModal {...modalView.data} />}
      {modalView?.view === "choose-game" && <ChooseGameModal {...modalView.data} />}
      {modalView?.view === "bonus-room-win" && <BonusRoomWinModal {...modalView.data} />}
      {modalView?.view === "withdraw-money" && <WithdrawMoneyModal />}
      {modalView?.view === "change-password" && <ChangePasswordModal />}
      {modalView?.view === "change-account-number" && <ChangeAccountNumberModal {...modalView.data} />}
      {modalView?.view === "notification" && <NotificationModal {...modalView.data} />}
      {modalView?.view === "registration-success" && <RegistrationSuccessModal {...modalView.data} />}
      {modalView?.view === "choose-option" && <ChooseOptionModal {...modalView.data} />}
      {modalView?.view === "acceptation" && <AcceptationModal {...modalView.data} />}
      {modalView?.view === "players-list-filters" && <PlayersListFiltersModal {...modalView.data} />}
    </Modal>
  );
}

ModalProvider.ViewSwitch = ModalContextViewSwitch;
