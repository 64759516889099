import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AxiosError } from "axios";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import PageContent from "./pageContent.type";
import { InputInterface } from "../common/formComponents/input/Input";
import Routes from "../../config/routes";
import { authStorage } from "../../storage/auth/auth.storage";
import useAuth from "../../hooks/useAuth/useAuth";
import useAppMutation from "../../hooks/useMutation/useMutation";
import { loginUser, oauth } from "../../API/user/userActions";
import { LoginPayload } from "../../API/user/userActions.type";
import httpStatusHelper from "../../helpers/http-status-helper";

const useLoginHook = () => {
  const navigation = useNavigate();
  const isAuthorized = useAuth();
  const loginMutation = useAppMutation(loginUser);
  const oauthMutation = useAppMutation(oauth);
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const translations: PageContent = t("login", { returnObjects: true });

  useEffect(() => {
    if (isAuthorized) {
      navigation(Routes.Home);
    }
  }, []);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha();
    return token;
  }, [executeRecaptcha]);

  const onFormSubmit = async (payloadData: LoginPayload) => {
    const captchaToken = await handleReCaptchaVerify();
    if (!captchaToken) {
      NotificationManager.error(translations.errorCaptcha, "Message", 10000);
      return;
    }
    if (!loginMutation || !payloadData) {
      return;
    }
    const payload = { ...payloadData, captchaToken };
    try {
      const response = await loginMutation.mutateAsync(payload);
      if (httpStatusHelper.isSuccess(response.status) && response.payload) {
        authStorage.setTokens(response.payload.accessToken, response.payload.fullAccess, response.payload.refreshToken);

        if (response.payload.fullAccess) {
          navigation(Routes.Home);
        } else {
          navigation(Routes.CurrentTournament);
        }
      } else if (response.status === 401) {
        NotificationManager.error(t(`errors.NOT_AUTHORIZED`), "Message", 10000);
      } else {
        const errorMessage = response.errorObject.response.data.message;
        NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
      }
    } catch (error: any) {
      console.error((error as AxiosError).response?.data);
    }
  };

  const onOauthSubmit = (provider: "google" | "facebook") => async () => {
    const captchaToken = await handleReCaptchaVerify();
    if (!captchaToken) {
      NotificationManager.error(translations.errorCaptcha, "Message", 10000);
      return;
    }

    try {
      const response = await oauthMutation.mutateAsync({
        provider,
        captchaToken,
      });

      if (httpStatusHelper.isSuccess(response.status) && response.payload?.url) {
        window.location.href = response.payload?.url;
      } else {
        const errorMessage = response.errorObject.response.data.message;
        NotificationManager.error(t([`errors.${errorMessage}`, "errors.ERROR_OCCURRED"]), "Message", 10000);
      }
    } catch (error: any) {
      console.error((error as AxiosError).response?.data);
      NotificationManager.error(t(`errors.ERROR_OCCURRED`), "Message", 10000);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(translations.errorEmailValid).required(translations.errorRequired),
    password: Yup.string().required(translations.errorRequired),
    referenceCode: Yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      referenceCode: "",
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      onFormSubmit(values);
    },
  });

  const onRecoverPasswordClick = useCallback(() => navigation(Routes.ForgetPassword), [navigation]);
  const onSignUpClick = useCallback(() => navigation(Routes.SignUp), [navigation]);

  const formInputs: InputInterface[] = [
    {
      type: "email",
      label: translations.email,
      id: "login-email",
      placeholder: translations.emailPlaceholder,
      name: "email",
      value: formik.values.email,
      onChange: formik.handleChange,
      error: formik.errors.email || "",
    },
    {
      type: "password",
      label: translations.password || "",
      id: "login-password",
      placeholder: translations.passwordPlaceholder,
      name: "password",
      value: formik.values.password,
      onChange: formik.handleChange,
      error: formik.errors.password || "",
    },
    {
      type: "text",
      label: translations.referenceCode || "",
      id: "login-reference-code",
      placeholder: translations.referenceCodePlaceholder,
      name: "referenceCode",
      value: formik.values.referenceCode,
      onChange: formik.handleChange,
      error: formik.errors.referenceCode || "",
    },
  ];

  return {
    translations,
    formInputs,
    onOauthSubmit,
    onFormSubmit: formik.handleSubmit,
    onRecoverPasswordClick,
    onSignUpClick,
  };
};

export default useLoginHook;
