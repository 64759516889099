import React, { Ref, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BonusItemActivatedStyled from "./BonusItemActivated.style";
import { coin25 } from "../../../assets/images";
import PageContent from "../PageContent.type";
import Button from "../../common/buttons/button/Button";
import GameList from "../GameListItem/GameList";
import ProgressBar from "../ProgressBar/ProgressBar";
import { BonusRoomActivated } from "../../../API/game/gameActions.type";
import { useModal } from "../../../modals/PageModal.context";

interface BonusItemActivatedProps {
  item: BonusRoomActivated;
  requiredMatches: number;
  innerRef?: Ref<HTMLDivElement>;
}

function BonusItemActivated({ item, requiredMatches, innerRef }: BonusItemActivatedProps) {
  const [matchesWonNumber, setMatchesWonNumber] = useState(0);
  const [isBetFinished, setIsBetFinished] = useState(false);
  const [isGameCurrentlyPlaying, setIsGameCurrentlyPlaying] = useState(false);
  const translation: PageContent = useTranslation().t("bonus_room", { returnObjects: true });
  const { openChooseGameModal } = useModal();

  const checkIfBetFinished = () => {
    if (!item.matches) {
      return;
    }
    if (item.matches.length === requiredMatches && !item.matches.find((match) => match.isWinner === null)) {
      setIsBetFinished(true);
    } else if (item.matches.find((match) => match.isWinner === false)) {
      setIsBetFinished(true);
    }
  };

  const checkMatches = () => {
    if (!item.matches) {
      return;
    }
    setMatchesWonNumber(item.matches.filter((match) => match.isWinner).length);
    checkIfBetFinished();
    if (item.matches[item.matches.length - 1] && item.matches[item.matches.length - 1].isWinner === null) {
      setIsGameCurrentlyPlaying(true);
    }
  };

  useEffect(() => {
    checkMatches();
  }, []);

  const checkColor = () => {
    if (isBetFinished && matchesWonNumber === requiredMatches) {
      return "green";
    }
    if (isBetFinished && matchesWonNumber !== requiredMatches) {
      return "red";
    }
  };

  const createLastMatchDate = () => {
    if (!item.matches || !item.matches[item.matches.length - 1]) {
      return;
    }
    const date = item.matches[item.matches.length - 1].finishDate;
    if (!date) {
      return;
    }
    return new Intl.DateTimeFormat("pl-PL", { dateStyle: "short", timeStyle: "short" }).format(new Date(date));
  };

  if (!item.matches) {
    return null;
  }

  return (
    <BonusItemActivatedStyled>
      <div
        className={`item-wrapper-active ${checkColor()}`}
        ref={innerRef}
      >
        <p className="title-to-win-active">{translation.jackpot_to_win}</p>
        <div className="amount-to-win-wrapper">
          <p className={`amount-to-win-activated ${checkColor()}`}>{`${item.toWinAmount.toFixed(item.toWinAmount >= 10 ? 0 : 1)} ${item.currency}`}</p>
        </div>
        <div className="matches">
          <p className="won-amount">
            {translation.won_number_title}
            <span className="won-amount-bold">{`${matchesWonNumber} ${translation.won_number_of} ${requiredMatches}`}</span>
          </p>
          <div className="matches-list">
            <GameList
              item={item}
              matchesAmount={requiredMatches}
              wonMatchAnimation={!isBetFinished}
            />
          </div>
          {!isBetFinished && !isGameCurrentlyPlaying && (
            <Button
              type="labelButton"
              label={translation.choose_game}
              variant="blue"
              onClick={() =>
                openChooseGameModal({
                  bonusRoom: item,
                  duelNumber: (item.matches?.length || 0) + 1,
                  coinImageUrl: item.coinImageUrls.availableUrl,
                })
              }
            />
          )}
          {isGameCurrentlyPlaying && <p className="win-loose-title">{translation.currently_playing}</p>}
          {isBetFinished && (
            <div>
              <p className="win-loose-title">{matchesWonNumber === requiredMatches ? `${translation.won}` : `${translation.lost}`}</p>
              {item.matches && <p className="win-loose-date">{createLastMatchDate()}</p>}
            </div>
          )}
        </div>
        {item.matches && <ProgressBar matches={item.matches} />}
      </div>
      <div className="coin">
        <img
          src={item.coinImageUrls.availableUrl || coin25}
          alt="coin"
          className="coin-img"
        />
      </div>
    </BonusItemActivatedStyled>
  );
}

export default BonusItemActivated;
