import styled from "styled-components";

const GalleryStyled = styled.div`
  color: ${({ theme }) => theme.colors.white};
  max-width: 1400px;
  margin: auto;
  padding: 0 30px;

  .heading {
    font-size: ${({ theme }) => theme.fontSizes.caption};
    font-family: ${({ theme }) => theme.fontFamilies.robotoLight};
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 10px;
  }

  .gallery-container {
    display: flex;
    flex-direction: column;
    gap: 60px;
    width: 100%;
  }

  .main-slider {
    display: flex;
  }

  .images {
    display: flex;
    gap: 25px;
    width: 100%;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 768px) {
    .gallery-container {
      gap: 80px;
    }

    .images {
      gap: 10px;
    }
  }
`;

export default GalleryStyled;
