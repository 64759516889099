/* eslint-disable no-return-assign */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./populargames.css";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Layout from "../layout";
import { userInstance } from "../../axios/axiosconfig";
import Bet from "../home/Bet/Bet";
import Popup from "../home/popup";
import PopularGameBox from "../common/PopularGameBox/PopularGameBox";
import PopularMobileGameBox from "../common/PopularMobileGameBox/PopularMobileGameBox";
import ComingSoonGameBox from "./ComingSoonGameBox";
import Routes from "../../config/routes";
import { getPageContent } from "../../helpers/content";
import useLanguage from "../../hooks/useLanguage/useLanguage";
import { getPopularGames } from "../../API/game/gameActions";
import useAppQuery from "../../hooks/useQuery/useQuery";
import PopularGamesStyled from "./PopularGames.style";

function Populargames() {
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [view, setViewShow] = useState(false);
  const { i18n } = useTranslation();
  const [pageContent, setPageContent] = useState({});
  const [matched, setMatched] = useState(false);
  const roomId = null;
  const [playerOne, setPlayerOne] = useState(null);
  const playerTwo = null;
  const { language } = useLanguage();
  const gamesQuery = useAppQuery(getPopularGames(language));
  const [games, setGames] = useState();

  const [bet, setBet] = useState(false);
  const [betAmount, setBetAmount] = useState(null);
  const [wallettype, setWallettype] = useState(null);

  const [gametype, setGameType] = useState("Pong");

  const handleViewShow = () => setViewShow(true);

  const handleClose = () => setShow(false);
  const handleViewClose = () => setViewShow(false);

  const CloseBetComponent = () => {
    setBet(false);
  };

  const getUserProfile = async () => {
    const response = await userInstance.get("/getprofile");
    if (response.data.code === 200) {
      setPlayerOne(response.data.profile_data);
    }
  };
  const page = window.location.pathname.replace("/cms/", "");
  const { shortLanguage } = useLanguage();

  useEffect(() => {
    if (gamesQuery.data) {
      setGames(gamesQuery.data);
    }
  }, [gamesQuery.data]);

  const onLanguageChanged = () => {
    getPageContent(page, shortLanguage).then((data) => setPageContent(data));
  };
  useEffect(() => {
    getPageContent(page, shortLanguage).then((data) => setPageContent(data));
    getUserProfile();
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  const matchRoom = async (amount, walletType) => {
    // const res = await adminInstance.post("/getCommission");
    // const cutOff = ((res.data.rate * amount) / 100).toFixed(2);
    // const response = await userInstance.post("/getprofile");

    setBetAmount(amount);
    setBet(false);
    setWallettype(walletType);
    setMatched(true);
  };

  const checkLogin = (game) => {
    if (localStorage.getItem("token")) {
      setBet(true);
      setGameType(game);
    } else {
      navigation(Routes.Login);
    }
  };

  return (
    <PopularGamesStyled>
      <Layout>
        {matched && (
          <Popup
            roomId={roomId}
            setMatched={setMatched}
            playerOne={playerOne}
            playerTwo={playerTwo}
            amount={betAmount}
            wallettype={wallettype}
            gameType={gametype}
          />
        )}

        {bet && (
          <Bet
            matchRoom={matchRoom}
            CloseBetComponent={CloseBetComponent}
          />
        )}

        <div className="popular-game-bg">
          <div className="popular-game-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="title-page">
                    <ul className="breadcrum">
                      <li>
                        <Link to="/">{pageContent.home}</Link>
                      </li>
                      <li>{pageContent.games}</li>
                    </ul>
                    <h2>{pageContent.games}</h2>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="game-tile">
                    <div className="home-tile-box">
                      <div className="home-tile-info">
                        <span>{pageContent.play}</span>
                        <h4>{pageContent.poolgame}</h4>

                        <h2>100$</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="games">
                  {games?.items.map((game) => {
                    if (game.type === "mobile") {
                      <PopularMobileGameBox game={game} />;
                    }
                    return (
                      <PopularGameBox
                        game={game}
                        duelButtonClick={() => checkLogin("Pong")}
                        tournamentButtonClick={handleViewShow}
                        // todo - remove math.random()
                        key={`popular-${game.gameId}-${Math.random()}`}
                        width="320px"
                      />
                    );
                  })}
                </div>
                <ComingSoonGameBox pageContent={pageContent} />
                <ComingSoonGameBox pageContent={pageContent} />
                <ComingSoonGameBox pageContent={pageContent} />
                <ComingSoonGameBox pageContent={pageContent} />
                <ComingSoonGameBox pageContent={pageContent} />
              </div>
            </div>
          </div>
        </div>

        <Modal
          dialogClassName="dod-popup"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <Bet />
          </Modal.Body>
        </Modal>

        <Modal
          dialogClassName="dod-popup"
          show={view}
          onHide={handleViewClose}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <Bet />
          </Modal.Body>
        </Modal>
      </Layout>
    </PopularGamesStyled>
  );
}

export default Populargames;
