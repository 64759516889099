import styled from "styled-components";

const ActionButtonsStyled = styled.div`
  display: flex !important;
  column-gap: 14px;
  justify-content: center;
  align-items: center;
  overflow: visible !important;

  & > button {
    margin: 0;
  }
`;

export default ActionButtonsStyled;
