import styled from "styled-components";

const NotificationStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 13px;
  min-height: 80px;
  border: 1px solid ${({ theme }) => theme.colors.blue};
  border-image-slice: 1 !important;
  border-image-source: ${({ theme }) => theme.borderImages.blue};
  padding: 8px 12px 8px 16px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .wrapper {
    display: flex;
    column-gap: 13px;
  }

  .image-wrapper {
    position: relative;
    min-width: 46px;
    width: 46px;
    height: 46px;
  }

  .image {
    width: 100%;
    border-radius: 2px;
  }

  .title {
    font-size: ${({ theme }) => theme.fontSizes.p};
    font-weight: 700;
  }

  .notification-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(30%, 30%);
  }

  .title-message-wrapper {
    max-width: 220px;
  }

  .title {
    font-size: ${({ theme }) => theme.fontSizes.p};
    font-family: ${({ theme }) => theme.fontFamilies.robotoBold};
    font-weight: 700;
  }

  .title div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }

  .title::first-letter {
    text-transform: uppercase;
  }

  .message {
    font-size: ${({ theme }) => theme.fontSizes.caption};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 14px;
    word-wrap: break-word;
  }

  .message::first-letter {
    text-transform: uppercase;
  }

  .action-buttons {
    margin-top: 12px;
  }

  .info {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    right: 0;
    padding: 5px;
  }

  .date {
    font-size: ${({ theme }) => theme.fontSizes.notification};
    color: ${({ theme }) => theme.colors.grey};
    white-space: nowrap;
  }

  .date-tooltip {
    font-size: ${({ theme }) => theme.fontSizes.notification} !important;
    color: ${({ theme }) => theme.colors.grey} !important;
    background-color: black;
    white-space: nowrap;
    transform: translate(-50%, -70%);
  }

  .action-buttons {
    justify-content: flex-start;
  }

  .action-buttons > button {
    height: 22px;
    width: 93px;
    font-size: 9px;
    margin: 0;
  }
`;

export default NotificationStyled;
