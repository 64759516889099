import styled from "styled-components";

const ProfileStyled = styled.div`
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    padding-bottom: 30px;
    padding-top: 10px;
  }

  ul li {
    padding-right: 15px;
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.breadcrumb};
    letter-spacing: 1px;
    position: relative;
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
  }

  li::first-letter,
  a::first-letter {
    text-transform: uppercase;
  }

  ul li a {
    color: ${({ theme }) => theme.colors.lightGrey};
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
  }

  ul li a:hover {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.blue};
  }

  ul li:first-child:after {
    content: ">";
    position: absolute;
    right: 0;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export default ProfileStyled;
