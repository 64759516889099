/* eslint-disable import/no-cycle */
import React from "react";
import { useTranslation } from "react-i18next";
import { filterIcon, logoHorizontalWhiteText } from "../../assets/images";
import PlayersListStyled from "./PlayersList.style";
import SearchBar from "../common/searchBar/SearchBar";
import PlayerBar from "../common/Player/PlayerBar/PlayerBar";
import Button from "../common/buttons/button/Button";
import NoContent from "../common/NoContent/NoContent";
import { useModal } from "../../modals";
import usePlayersList from "./usePlayersList.hook";
import { PublicUser } from "../../API/friend/friendActions.type";
import useProfile from "../../hooks/useProfile/useProfile";

function PlayersList() {
  const { t } = useTranslation();
  const { users, lastElementRef, filters, setFilters, inviteFriend, translations, isSearchBarActive, setIsSearchBarActive, setSearchValue, defaultFiltersValues } = usePlayersList();
  const { openPlayersListFiltersModal } = useModal();
  const profile = useProfile();

  const generateActionButton = (user: PublicUser) => {
    if (user.id !== profile?.data?.id) {
      return (
        <Button
          type="labelButton"
          label={translations.invite_user}
          onClick={() => inviteFriend(user.id)}
          variant="primary"
          disabled={!user.isFriendshipInviteAllowed || user.hasPendingInvitation}
        />
      );
    }
  };

  return (
    <PlayersListStyled>
      <img
        src={logoHorizontalWhiteText}
        alt="logo"
        className="logo-img"
      />
      <div className="header">
        <h2 className="title">{translations.header}</h2>
        <div className="filters">
          <Button
            type="iconButton"
            icon={filterIcon}
            alt=""
            onClick={() => openPlayersListFiltersModal({ filters, setFilters })}
            variant="ghost"
            className={isSearchBarActive ? "icon icon-hidden" : "icon"}
          />
          <SearchBar
            placeholder={t("common.nickname")}
            checkIsActive={(val: boolean) => setIsSearchBarActive(val)}
            setValue={setSearchValue}
          />
        </div>
      </div>
      <div className="players">
        {users?.length ? (
          users.map((user, index) => (
            <PlayerBar
              playerId={user.id}
              username={user.nickname}
              actionButtons={generateActionButton(user)}
              key={user.id}
              innerRef={users.length === index + 1 ? lastElementRef : null}
              avatarType="basic"
              status={user.status}
              isPremium={false}
              avatarUrl={user.avatarUrl}
              hasPendingInvitation={user.hasPendingInvitation}
            />
          ))
        ) : (
          <NoContent
            customText={JSON.stringify(defaultFiltersValues) === JSON.stringify(filters) ? t("table.no_results") : t("table.no_results_filters")}
            className="no-content-info"
          />
        )}
      </div>
    </PlayersListStyled>
  );
}

export default PlayersList;
