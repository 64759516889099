/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-cycle */
import React from "react";
import { useTranslation } from "react-i18next";
import StakeItemStyled from "./StakeItem.style";
import Button from "../../../components/common/buttons/button/Button";
import Routes from "../../../config/routes";
import { StakeItemType } from "../chooseStake.type";

interface StakeItemProps {
  item: StakeItemType;
  isUserPremium: boolean;
  onClick: (item: StakeItemType) => void;
}

function StakeItem({ item, isUserPremium, onClick }: StakeItemProps) {
  const { t } = useTranslation();
  const translation: any = t("choosestake", { returnObjects: true, count: 0 });
  const { playersCount, isAvailable, isPremium, coinImageUrls } = item;

  let coinUrl;
  if ((isPremium && !isUserPremium) || !isAvailable) {
    coinUrl = coinImageUrls.unavailableUrl;
  } else {
    coinUrl = coinImageUrls.availableUrl;
  }

  const createStakeItem = () => {
    if (!isAvailable) {
      return <div className="unavailable-info">{translation.not_available}</div>;
    }

    if (isPremium && !isUserPremium) {
      return (
        <div>
          <p className="no-premium">{translation.no_premium}</p>
          <Button
            type="labelButton"
            label={translation.activate}
            onClick={() => (window.location.href = Routes.HowItWorks)}
            variant="blue"
          />
        </div>
      );
    }

    return (
      <div>
        <Button
          type="labelButton"
          label={translation.choose_stake}
          onClick={() => onClick(item)}
          variant="blue"
        />
        <div className="opponents-number">{t("choosestake.gamer", { count: playersCount || 0 })}</div>
      </div>
    );
  };

  return (
    <StakeItemStyled>
      <div className="wrapper">
        <div className="border">
          <img
            className="coin"
            src={coinUrl}
            alt="coin"
          />
          {createStakeItem()}
        </div>
      </div>
    </StakeItemStyled>
  );
}

export default StakeItem;
