import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChooseStakeStyled from "./ChooseStakeModal.style";
import StakeItem from "./stakeItem/StakeItem";
import { StakeItemType } from "./chooseStake.type";
import useAppQuery from "../../hooks/useQuery/useQuery";
import { getGameStakes } from "../../API/game/gameActions";
// eslint-disable-next-line import/no-cycle
import { useModal } from "../PageModal.context";
import useSocket from "../../hooks/useSocket/useSocket";
import { Wallet } from "../../API/user/userActions.type";
import { STAKE_REFETCH_MS } from "../../config/time.const";

interface ChooseStakeModalProps {
  gameId: string;
  wallet: Wallet;
}

function ChooseStakeModal({ gameId, wallet }: ChooseStakeModalProps) {
  const { joinMatchmaking } = useSocket();
  const { openNotEnoughMoneyModal, openChooseStakeModal } = useModal();
  const [isUserPremium, setIsUserPremium] = useState(false);
  const gameName = "winter game";
  const translation: any = useTranslation().t("choosestake", { returnObjects: true, count: 0 });
  // const profileQuery = useProfile();

  const gameStakes = useAppQuery(getGameStakes(gameId), {
    refetchInterval: STAKE_REFETCH_MS,
  });

  useEffect(() => {
    // get page content
    // get wallet

    // to do - check if profile is premium
    setIsUserPremium(false);
  }, []);

  const onStakeClick = (stake: StakeItemType) => {
    if (!wallet.amountInUSD || stake.amount > wallet.amountInUSD) {
      // eslint-disable-next-line no-console
      return openNotEnoughMoneyModal({
        onClose: () => {
          openChooseStakeModal({ gameId, wallet });
        },
      });
    }
    joinMatchmaking({
      gameId,
      stake: {
        currency: stake.currency,
        stake: stake.amount,
      },
      walletId: wallet.id,
    });
  };

  return (
    <ChooseStakeStyled>
      <div className="choose-stake-container">
        <div className="info-tab">
          <div className="game-name">
            {translation.game} {gameName}
          </div>
          <div className="wallet-info">
            <p>{translation.deposit} </p>
            <p>
              {(wallet.amount || 0).toFixed(2)} {wallet.currency}
            </p>
            <p>{(wallet.amountInUSD || 0).toFixed(2)} USD</p>
          </div>
        </div>
        <h2 className="premium-title">{translation.entry_fee}</h2>
        <div className="stake-wrapper">
          {gameStakes.data
            ?.filter((item) => !item.isPremium)
            .map((item) => (
              <StakeItem
                item={item}
                isUserPremium={isUserPremium}
                onClick={onStakeClick}
                key={item.id}
              />
            ))}
        </div>
        <h2 className="premium-title">{translation.entry_fee_premium}</h2>
        <div className="stake-wrapper">
          {gameStakes.data
            ?.filter((item) => item.isPremium)
            .map((item) => (
              <StakeItem
                item={item}
                isUserPremium={isUserPremium}
                onClick={onStakeClick}
                key={item.id}
              />
            ))}
        </div>
      </div>
    </ChooseStakeStyled>
  );
}

export default ChooseStakeModal;
