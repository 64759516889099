import styled from "styled-components";

const TRANSITION_TIME = "0.7s";

const GameModesButtonStyled = styled.div<{ modeButtonsVisible: boolean }>`
  height: 250px;
  width: 130px;

  .component-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .main-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 62px !important;
    width: 62px !important;
    padding: 0;
    border-radius: 50%;
    background-color: #3bbdf5;
    border: none;
  }

  .main-icon img {
    height: 65% !important;
  }

  .buttons {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .circle-hidden {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: top ${TRANSITION_TIME}, left ${TRANSITION_TIME}, transform ${TRANSITION_TIME};
  }

  .circle-hidden:hover {
    z-index: ${({ theme, modeButtonsVisible }) => (modeButtonsVisible ? theme.zIndex.popularGameButtonHover : 0)};
  }

  .circle:nth-child(1) {
    top: 50%;
    left: 100%;
    transform: translate(-50px, -25px);
  }

  .circle:nth-child(2) {
    top: 25%;
    left: 63%;
    transform: translate(-25px, -25px);
  }

  .circle:nth-child(3) {
    top: 75%;
    left: 63%;
    transform: translate(-25px, -25px);
  }

  .circle:nth-child(4) {
    top: 0;
    left: 0;
    transform: none;
  }

  .circle:nth-child(5) {
    top: 100%;
    left: 0;
    transform: translate(0, -50px);
  }
`;

export default GameModesButtonStyled;
