/* eslint-disable import/no-cycle */
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/common/buttons/button/Button";
import { useModal } from "../PageModal.context";
import CheckBox, { CheckBoxInterface } from "../../components/common/formComponents/checkBox/CheckBox";
import RankInput from "../../components/common/formComponents/rankInput/RankInput";
import ToggleInput, { ToggleInputInterface } from "../../components/common/formComponents/toggleInput/ToggleInput";
import { COUNTRY_CODES } from "../../config/countries";
import PlayersListFiltersModalStyled from "./PlayersListFiltersModal.style";
import { SupportedCountry } from "../../API/user/userActions.type";
import { Filters } from "../../components/playersList/usePlayersList.hook";

const DEFAULT_VALUES = {
  rankFrom: 1,
  rankTo: 10,
  country: [],
  ignoreOffline: false,
};

interface PlayersListFiltersModalProps {
  filters: Filters;
  setFilters: (values: Filters) => void;
}

function PlayersListFiltersModal({ filters, setFilters }: PlayersListFiltersModalProps) {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  const formik = useFormik<Filters>({
    initialValues: filters,
    validateOnChange: false,
    onSubmit: (values) => {
      setFilters(values);
      closeModal();
    },
  });

  const createCheckbox = (country: any) => {
    const countryFilter: CheckBoxInterface = {
      type: "checkbox",
      label: t(`countries.${country.code}`),
      flag: country.code,
      id: country.country,
      name: country.code,
      value: formik.values.country.includes(country.country),
      onChange: (e) => {
        const target = e.target as HTMLInputElement;
        const arr = [...formik.values.country];
        if (target.checked) {
          arr.push(target.id as SupportedCountry);
          formik.setFieldValue("country", arr);
        } else {
          const filteredArr = arr.filter((item) => item !== target.id);
          formik.setFieldValue("country", filteredArr);
        }
      },
      error: "",
    };
    return (
      <CheckBox
        item={countryFilter}
        className="checkbox"
        key={`filters-country-${country.code}`}
      />
    );
  };

  const rankFilter = {
    id: "string",
    minValue: formik.values.rankFrom,
    maxValue: formik.values.rankTo,
    onChange: (e: React.ChangeEvent) => {
      const target = e.target as HTMLInputElement;
      if (target.name === "rank-min") {
        formik.setFieldValue("rankFrom", Math.min(Number(target.value), formik.values.rankTo - 1));
      } else if (target.name === "rank-max") {
        formik.setFieldValue("rankTo", Math.max(Number(target.value), formik.values.rankFrom + 1));
      }
    },
  };

  const onlyOnlineFilter: ToggleInputInterface = {
    type: "toggle",
    id: "ignoreOffline",
    name: "ignoreOffline",
    label: t("players_list.show_only_online_players"),
    onChange: formik.handleChange,
    checked: formik.values.ignoreOffline,
  };

  return (
    <PlayersListFiltersModalStyled>
      <div className="filters-title">{t("common.filters")}</div>
      <form
        className="filters"
        onSubmit={formik.handleSubmit}
      >
        <RankInput item={rankFilter} />
        <div className="countries">
          <p className="countries-title">{t("countries.country")}</p>
          <div className="countries-wrapper">{COUNTRY_CODES.map((country) => createCheckbox(country))}</div>
        </div>
        <div className="online-players">
          <ToggleInput item={onlyOnlineFilter} />
        </div>
        <div className="buttons">
          <Button
            type="labelButton"
            variant="secondary"
            label={t("common.reset")}
            onClick={() => {
              formik.setValues(DEFAULT_VALUES);
              setFilters(DEFAULT_VALUES);
            }}
          />
          <Button
            submitButton
            type="labelButton"
            variant="primary"
            label={t("common.filter_action")}
          />
        </div>
      </form>
    </PlayersListFiltersModalStyled>
  );
}

export default PlayersListFiltersModal;
