/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import BonusRoomModalStyled from "./BonusRoomModal.style";
import BonusItem from "./BonusItem/BonusItem";
import BonusItemActivated from "./BonusItemActivated/BonusItemActivated";
import PageContent from "./PageContent.type";
import { star, expandDownIcon, expandRightIcon, expandUpIcon } from "../../assets/images";
import { BonusRoom, BonusRoomActivated } from "../../API/game/gameActions.type";
import BonusHistory from "./BonusHistory/BonusHistory";
import Layout from "../layout";
import Loader from "../loader/Loader";
import useBonusRoomModal from "./BonusRoomModal.hook";

const REQUIRED_MATCHES_AMOUNT_TO_WIN = 3;

function BonusRoomModal() {
  const { t } = useTranslation();
  const translation: PageContent = t("bonus_room", { returnObjects: true });

  const { isUserPremium, onActivateBonusRoom, bonusRoomData, toggleHistoryVisibility, isHistoryVisible, setHistoryRef } = useBonusRoomModal();

  const createBonusTile = (item: BonusRoom | BonusRoomActivated) => {
    if (item.type === "empty-room") {
      return (
        <BonusItem
          item={item}
          isUserPremium={isUserPremium}
          key={item.activateFee}
          onActivateBonusRoom={onActivateBonusRoom}
        />
      );
    }
    return (
      <BonusItemActivated
        item={item}
        requiredMatches={REQUIRED_MATCHES_AMOUNT_TO_WIN}
        key={item.activateFee}
      />
    );
  };

  // todo: add LazyFragment
  if (!bonusRoomData) {
    return <Loader />;
  }

  return (
    <Layout>
      <BonusRoomModalStyled>
        <div className="container-bonus">
          <div className="info-tab">
            <p className="title">{translation.title}</p>
            <p className="description">
              <Trans
                i18nKey={translation.description}
                components={{
                  bold: <span className="bold-text" />,
                }}
              />
            </p>
          </div>
          <h2 className="bonus-title">{translation.bonus_title}</h2>
          <div className="items-wrapper">{bonusRoomData.filter((item) => !item.isPremium).map((item) => createBonusTile(item))}</div>
          <h2 className="bonus-title">
            <img
              src={star}
              alt="star"
              className="title-image"
            />
            <span className="title-span">{translation.bonus_premium_title}</span>
            <img
              src={star}
              alt="star"
              className="title-image"
            />
          </h2>
          <div className="items-wrapper">{bonusRoomData.filter((item) => item.isPremium).map((item) => createBonusTile(item))}</div>

          <div className="history-container desktop">
            <div className="history-title">{translation.history}</div>
            <div
              className="history-expand-desktop"
              onClick={toggleHistoryVisibility}
            >
              <span>{isHistoryVisible ? translation.history_collapse : translation.history_expand}</span>
              <span className="expand-arrows">{isHistoryVisible ? "<<" : ">>"}</span>
            </div>
          </div>
          <div
            className="history-container mobile"
            onClick={toggleHistoryVisibility}
          >
            <div className="history-title">{translation.history}</div>
            <div className="history-expand-mobile">
              <img
                src={isHistoryVisible ? expandUpIcon : expandDownIcon}
                alt="expand history"
              />
            </div>
          </div>
          <div ref={setHistoryRef}>
            {!isHistoryVisible && (
              <div className="history-container mobile">
                <div className="history-title">{translation.history_profile}</div>
                <div className="history-expand-mobile">
                  <img
                    src={expandRightIcon}
                    alt="expand history in profile"
                  />
                </div>
              </div>
            )}
            {isHistoryVisible && <BonusHistory />}
          </div>
        </div>
      </BonusRoomModalStyled>
    </Layout>
  );
}

export default BonusRoomModal;
