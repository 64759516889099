import React from "react";
import Tooltip, { TooltipProps } from "../../common/Tooltip/Tooltip";

function TableTooltip({ label, tooltipText, tooltipTextFinish, onLabelClick, width, icon }: TooltipProps) {
  return (
    <Tooltip
      label={`...${label.slice(-4)}`}
      tooltipText={tooltipText}
      tooltipTextFinish={tooltipTextFinish}
      onLabelClick={onLabelClick}
      width={width}
      icon={icon}
    />
  );
}

export default TableTooltip;
