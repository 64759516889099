import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import Navbar from "react-bootstrap/Navbar";
import { NotificationManager } from "react-notifications";
import { authStorage } from "../../../storage/auth/auth.storage";
import useAppMutation from "../../../hooks/useMutation/useMutation";
import { changeLanguage } from "../../../API/user/userActions";
import useAuth from "../../../hooks/useAuth/useAuth";
import { logoHorizontalWhiteText } from "../../../assets/images";
import useLanguage from "../../../hooks/useLanguage/useLanguage";
import languageStorage from "../../../storage/lang/language.storage";
import "./comingSoon.css";
import Routes from "../../../config/routes";
import LANGUAGES from "../../../config/languages";
import PageContent from "./pageContent.type";

export default function ComingSoon() {
  const translations: PageContent = useTranslation().t("comingSoon", { returnObjects: true });
  const { shortLanguage } = useLanguage();
  const { i18n } = useTranslation();
  const changeLanguageMutation = useAppMutation(changeLanguage);
  const isAuth = useAuth();

  const onLogOut = () => {
    authStorage.setTokens(null, false);
    NotificationManager.success("Wylogowano", "Message", 4000);
  };

  const changeAppLanguage = async (language: string) => {
    languageStorage.set(language);
    i18n.changeLanguage(language);
    await changeLanguageMutation.mutateAsync({ language });
  };

  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Navbar expand="lg">
                <Link to={Routes.Home}>
                  <div className="logo">
                    <img
                      src={logoHorizontalWhiteText}
                      alt="logo"
                    />
                  </div>
                </Link>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav>
                    {!isAuth && (
                      <NavLink
                        className="nav-link"
                        to="/"
                      >
                        {translations.home}
                      </NavLink>
                    )}
                    {isAuth && (
                      <NavLink
                        className="nav-link"
                        to="/"
                        onClick={onLogOut}
                      >
                        {translations.logout}
                      </NavLink>
                    )}
                  </Nav>
                </Navbar.Collapse>
                <div className="language-dropdown">
                  <Dropdown id="dropdown-language">
                    <Dropdown.Toggle
                      variant="default"
                      id="dropdown-basic"
                    >
                      {shortLanguage}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {LANGUAGES.length &&
                        LANGUAGES.map((el) => (
                          <Dropdown.Item
                            onClick={() => changeAppLanguage(el.value)}
                            key={el.value}
                          >
                            {el.label}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Navbar>
            </div>
          </div>
        </div>
      </header>
      <div className="home">
        <div className="home-banner">
          <div className="container">
            <div className="coming-soon-cont">
              <div className="col-md-12">
                <div className="coming-text">
                  <h2>
                    {translations.welcome1}
                    <br /> {translations.welcome2}! 🎮🔥
                  </h2>
                  <p>{translations.youJoinTo}</p>
                  <p />
                  <ul>
                    <li>
                      <b>{translations.reward1}</b>
                    </li>
                    <li>
                      <b>{translations.reward2}</b>
                    </li>
                  </ul>
                  <p>
                    {translations.thatsNotAll1} <a href="https://discord.gg/ggdab">{translations.thatsNotAll2}</a> {translations.thatsNotAll3}
                  </p>
                  <p />
                  <p />
                  <p>{translations.thankYou}</p>
                  <p>{translations.seeYou} 🚀💥 GGDAB</p>
                  <p />
                  <a
                    className="social-media"
                    href="https://www.facebook.com/GGdab"
                    title="Facebook"
                  >
                    <img
                      src="https://ggdab.com/wp-content/uploads/2023/12/fb.png"
                      alt="Facebook"
                      title="Facebook"
                    />
                  </a>
                  <a
                    className="social-media"
                    href="https://twitter.com/ggdab_com"
                    title="X"
                  >
                    <img
                      src="https://ggdab.com/wp-content/uploads/2023/12/x.png"
                      alt="X"
                      title="X"
                    />
                  </a>
                  <a
                    className="social-media"
                    href="https://www.instagram.com/ggdab_com/"
                    title="Instagram"
                  >
                    <img
                      src="https://ggdab.com/wp-content/uploads/2023/12/ig-2.png"
                      alt="Instagram"
                      title="Instagram"
                    />
                  </a>
                  <a
                    className="social-media"
                    href="https://www.linkedin.com/company/ggdab/"
                    title="LinkedIn"
                  >
                    <img
                      src="https://ggdab.com/wp-content/uploads/2023/12/ln.png"
                      alt="LinkedIn"
                      title="LinkedIn"
                    />
                  </a>
                  <a
                    className="social-media"
                    href="https://www.youtube.com/channel/UC1kVK6HPqqec1uTuPFBtGCg"
                    title="YouTube"
                  >
                    <img
                      src="https://ggdab.com/wp-content/uploads/2023/12/yt.png"
                      alt="YouTube"
                      title="YouTube"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
