import React from "react";
import { useTranslation } from "react-i18next";
import NotificationsStyled from "./Notifications.style";
import Layout from "../layout";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import Routes from "../../config/routes";
import PageTitle from "../common/PageTitle/PageTitle";
import NotificationsTable from "./NotificationsTable/NotificationsTable";

function Notificationpage() {
  const { t } = useTranslation();

  return (
    <Layout>
      <NotificationsStyled>
        <div className="notifications-container">
          <Breadcrumb
            link={Routes.Home}
            linkTextCurrent={t("notifications.title")}
            linkTextPrevious={t("common.home")}
          />
          <PageTitle title={t("notifications.title")} />
          <NotificationsTable />
        </div>
      </NotificationsStyled>
    </Layout>
  );
}

export default Notificationpage;
