import React from "react";
import { useTranslation } from "react-i18next";
import WaitingOpponentModalStyled from "./WaitingOpponentModal.style";
import Opponents from "../Opponents/Opponents";
import CountdownTimer from "../Opponents/CountdownTimer/CountdownTimer";

function WaitingOpponentModal() {
  const translation: any = useTranslation().t("waitingopponent", { returnObjects: true });

  return (
    <WaitingOpponentModalStyled>
      <div>
        <h2 className="heading">
          {translation.heading1} <span className="heading-blue">{translation.heading2}</span>
        </h2>
        <div className="requirements">
          <p className="req1">{translation.requirements}</p>
          <p>{translation.requirements2}</p>
        </div>
        <Opponents />
        <CountdownTimer
          initialMinutes={4}
          initialSeconds={59}
        />
      </div>
    </WaitingOpponentModalStyled>
  );
}

export default WaitingOpponentModal;
