import React, { useState } from "react";
import Select from "react-select";
import { selectInputIcon } from "../../../../assets/images";
import SelectInputStyled from "./SelectInput.style";

interface Option {
  label: string;
  value: string;
}

export interface SelectInterface {
  type: "select";
  label: string;
  id: string;
  placeholder: string;
  name: string; // name has to be the same as formik value key
  value: string | boolean;
  onChange: (option: Option) => void;
  options: Option[];
  error: string;
}

interface SelectInputProps {
  item: SelectInterface;
}

function SelectInput({ item }: SelectInputProps) {
  const { label, id, options, error, onChange } = item;
  const [selectedOption, setSelectedOption] = useState<Option>();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  function CustomIcon() {
    return (
      <img
        src={selectInputIcon}
        alt=""
        className={menuIsOpen ? "dropdown-arrow rotate-arrow" : "dropdown-arrow"}
      />
    );
  }

  const onSelect = (option: Option) => {
    setSelectedOption(option);
    onChange(option);
  };

  return (
    <SelectInputStyled error={error}>
      <label
        className="form-label"
        htmlFor={id}
      >
        {label}
      </label>
      <Select
        components={{ DropdownIndicator: () => CustomIcon() }}
        classNamePrefix="react-select"
        defaultValue={selectedOption}
        onChange={(option: any) => onSelect(option)}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        options={options}
      />
      <p className="error">{error}</p>
    </SelectInputStyled>
  );
}

export default SelectInput;
