import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "../layout";
import { getPageContent } from "../../helpers/content";
import useLanguage from "../../hooks/useLanguage/useLanguage";

function Help() {
  const { i18n } = useTranslation();
  const [pageContent, setPageContent] = useState({});
  const page = "/help";
  const { shortLanguage } = useLanguage();

  const onLanguageChanged = () => {
    getPageContent(page, shortLanguage).then((data) => setPageContent(data));
  };

  useEffect(() => {
    getPageContent(page, shortLanguage).then((data) => setPageContent(data));
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  return (
    <Layout>
      <div className="legal">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-page help-title">
                <ul className="breadcrum">
                  <li>
                    <Link to="/">{pageContent.home}</Link>
                  </li>
                  <li>{pageContent.help}</li>
                </ul>
                <h2>{pageContent.help}</h2>
                <p>{pageContent.para1}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <div className="legal-content-box">
                <h2>{pageContent.para2}</h2>
                <h3>{pageContent.help}</h3>
                <p>{pageContent.para3}</p>
                <h4>{pageContent.help}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Help;
