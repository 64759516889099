/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
import React, { useState } from "react";
import { SupportedCountry, UserAvailabilityStatus } from "../../../../API/user/userActions.type";
import { threeDotsIcon } from "../../../../assets/images";
import { IntersectElementRef } from "../../../../hooks/useInfinitePaginationWithIntersect/useInfinitePaginationWithIntersect";
import { FriendModalOptions } from "../../../../modals/ChooseOptionModal/ChooseOptionModal";
import OptionsMenu from "../../OptionsMenu/OptionsMenu";
import PlayerAvatar from "../PlayerAvatar/PlayerAvatar";

import PlayerStyled from "./PlayerBar.style";

interface PlayerBarBasicAvatar {
  avatarType: "basic";
  playerId: string;
  username: string;
  actionButtons?: JSX.Element | JSX.Element[];
  chooseModalOptions?: FriendModalOptions[];
  innerRef?: IntersectElementRef | null;
  avatarUrl: string | null;
  status: UserAvailabilityStatus;
  isPremium: boolean;
  hasPendingInvitation: boolean;
}

interface PlayerBarAvatarWithIcons extends Omit<PlayerBarBasicAvatar, "avatarType"> {
  avatarType: "withIcons";
  hasBadge: boolean;
  rank: number;
  countryOfResidence: SupportedCountry;
}

type PlayerBarProps = PlayerBarBasicAvatar | PlayerBarAvatarWithIcons;

const isAvatarWithIcons = (player: PlayerBarProps): player is PlayerBarAvatarWithIcons => player.avatarType === "withIcons";

function PlayerBar(player: PlayerBarProps) {
  const { username, avatarUrl, actionButtons, chooseModalOptions, innerRef, avatarType, status, isPremium, hasPendingInvitation } = player;

  const [optionsOpen, setOptionsOpen] = useState(false);

  return (
    <PlayerStyled ref={innerRef}>
      <div className="user">
        <PlayerAvatar
          type={avatarType}
          avatarUrl={avatarUrl}
          status={status}
          isPremium={isPremium}
          hasBadge={isAvatarWithIcons(player) && player.hasBadge}
          rank={isAvatarWithIcons(player) ? player.rank : 0}
          countryOfResidence={isAvatarWithIcons(player) ? player.countryOfResidence : undefined}
          hasPendingInvitation={hasPendingInvitation}
          className="user-avatar"
        />
        <p className="username">{username}</p>
      </div>
      <div className="actions">
        {chooseModalOptions && chooseModalOptions.length > 0 && (
          <button
            type="button"
            onClick={() => setOptionsOpen(true)}
            className="more-options"
          >
            <img
              src={threeDotsIcon}
              alt=""
            />
          </button>
        )}
        {actionButtons && actionButtons}
        {optionsOpen && (
          <OptionsMenu
            onClose={() => setOptionsOpen(false)}
            options={chooseModalOptions || []}
          />
        )}
      </div>
    </PlayerStyled>
  );
}

export default PlayerBar;
