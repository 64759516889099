import React from "react";
import TextStyled from "./Text.style";

interface TextProps {
  text: string;
  width?: string;
}

function Text({ text, width }: TextProps) {
  return <TextStyled width={width}>{text}</TextStyled>;
}

export default Text;
