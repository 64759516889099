import styled from "styled-components";
import { placeholderFlag, polandFlag, ukFlag } from "../../assets/images";

const OpponentsStyled = styled.div`
  .opponents-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
  }

  .versus-container {
    width: 390px;
    height: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .versus-container .vertical-separator,
  .versus-container .timer {
    display: none;
  }

  .versus-text {
    text-align: center;
    line-height: 240px;
    margin: 0;
    font-family: Orbitron;
    font-size: 60px;
    font-weight: 500;
  }

  .gamer {
    position: relative;
    height: 240px;
    width: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .gamer div {
    position: relative;
    z-index: 10;
  }

  .gamer:after {
    content: "";
    height: 700px;
    width: 700px;
    border-radius: 35%;
    z-index: 1;
    position: absolute;
  }

  .gamer.left:after {
    left: -580px;
    top: -225px;
    background-image: radial-gradient(farthest-corner at 0px 0px, #3bbdf5 10%, #101421 50%);
    transform: scaleX(1.5) rotate(135deg);
  }

  .gamer .p-pic img {
    border-radius: 50%;
    width: 190px;
    height: 190px;
    object-fit: cover;
    object-position: center;
    border: 1px solid #3bbdf5;
    position: relative;
    background-color: #101421;
  }

  .gamer .p-pic::after {
    content: "";
    display: block;
    width: 45px;
    height: 45px;
    background-size: cover;
    position: absolute;
    left: 75px;
    bottom: -20px;
    border: 1px solid #3bbdf5;
    border-radius: 50%;
    background-color: white;
    background-image: url(${placeholderFlag});
  }

  .gamer .p-pic.Poland::after {
    background-image: url(${polandFlag});
  }

  .gamer .p-pic.UnitedKingdom::after {
    background-image: url(${ukFlag});
  }

  .vertical-separator {
    border-right: 0.5px solid #ffffff29;
    height: 188px;
  }

  .gamer.left .gamer-info {
    margin-right: 30px;
    display: flex;
    flex-direction: column;
  }

  .gamer.right .gamer-info {
    margin-left: 30px;
  }

  .gamer-info .p-nick {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 10px;
    width: 100px;
  }

  .gamer-info .p-nick img {
    width: 28px;
    height: 28px;
  }

  .gamer-info .p-nick .text {
    font-size: 25px;
    font-weight: 600;
    margin: 0;
  }

  .no-opponent-text {
    display: block;
    width: 100px;
  }

  .p-level {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    width: 100px;
  }

  .p-level p {
    font-size: 14px;
    font-weight: 400;
    color: #3bbdf5;
    margin: 0;
  }

  .p-level .progress {
    width: 100%;
    height: 2px;
    border-radius: 28px;
  }

  .p-level .progress-bar {
    background-color: #3bbdf5;
  }

  .gamer.right:after {
    right: -580px;
    top: -225px;
    background-image: radial-gradient(farthest-corner at 0px 0px, #e00033 10%, #101421 50%);
    transform: scaleX(1.5) rotate(-45deg);
  }

  @media screen and (max-width: 1200px) {
    .opponents-wrapper {
      gap: 20px;
      flex-direction: column;
      margin-top: 50px;
    }

    .gamer {
      gap: 15px;
    }

    .gamer.left {
      margin-right: 70px;
    }
    .gamer.right {
      margin-left: 70px;
    }

    .gamer .p-pic img {
      width: 100px;
      height: 100px;
    }

    .gamer .p-pic::after {
      width: 24px;
      height: 24px;
      left: 40px;
      bottom: -10px;
    }

    .vertical-separator {
      border-right: 0.5px solid #ffffff29;
      height: 100px;
    }

    .gamer.left .gamer-info {
      margin-right: 10px;
    }

    .gamer.right .gamer-info {
      margin-left: 10px;
    }

    .gamer-info .p-nick {
      margin-bottom: 5px;
    }

    .gamer-info .p-nick img {
      width: 14px;
      height: 14px;
    }

    .gamer-info .p-nick .text {
      font-size: 18px;
    }

    .p-level {
      gap: 2px;
    }

    .p-level p {
      font-size: 10px;
    }

    .p-level .progress {
      width: 100%;
      height: 10px;
      border-radius: 28px;
    }

    .p-level .progress-bar {
      background-color: #3bbdf5;
    }

    .versus-container {
      height: 50px;
      position: relative;
      z-index: 10;
      gap: 20px;
    }

    .versus-text {
      text-align: center;
      margin: 0;
      font-family: Orbitron;
      font-size: 35px;
      font-weight: 500;
      line-height: normal;
    }

    .versus-container .vertical-separator,
    .versus-container .timer {
      display: block;
    }

    .versus-container .vertical-separator {
      height: 46px;
      border-right: 0.5px solid #fff;
    }

    .versus-container .timer {
      margin-top: 0;
    }

    .gamer:after {
      content: "";
      height: 300px;
      width: 300px;
      border-radius: 40%;
      z-index: 1;
      position: absolute;
    }

    .gamer.left:after {
      left: -125px;
      top: -30px;
      background-image: radial-gradient(farthest-corner at 50px 50px, #3bbdf5 10%, #101421 40%);
      transform: scaleX(1.5) rotate(135deg);
    }

    .gamer.right:after {
      right: -125px;
      top: -30px;
      background-image: radial-gradient(farthest-corner at 50px 50px, #e00033 10%, #101421 40%);
      transform: scaleX(1.5) rotate(-45deg);
    }
  }
`;

export default OpponentsStyled;
