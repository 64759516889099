import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import ExternalTournamentsHeader from "./header/external-tournaments-header";
import CurrentTournament from "./currentTournament/currentTournament";
import FutureTournaments from "./futureTournaments/futureTournaments";
import FinishedTournaments from "./finishedTournaments/finishedTournaments";
import FinishedTournamentDetails from "./finishedTournaments/finishedTournamentDetails/finishedTournamentDetails";
import { userInstance } from "../../axios/axiosconfig";

export default function ExternalTournaments() {
  const location = useLocation();
  const params = useParams();
  const [key, setKey] = useState(0);
  const [hasCurrentTournament, setHasCurrentTournament] = useState(false);

  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [location, params]);

  useEffect(() => {
    const fetchCurrentTournament = async () => {
      try {
        const response = await userInstance.get("external-tournaments/current");
        const { data } = response;

        if (data && data.length > 0) {
          setHasCurrentTournament(true);
        } else {
          setHasCurrentTournament(false);
        }
      } catch (err) {
        console.error("Error fetching current tournaments:", err);
        setHasCurrentTournament(false);
      }
    };

    fetchCurrentTournament();
  }, []);

  const getSubPage = () => {
    const route = location.pathname.split("-")[2];

    if (params.id) {
      const routeName = route.split("/")[0];
      if (routeName === "future") {
        return <CurrentTournament key={key} id={params.id} />;
      }
      if (routeName === "finished") {
        return <FinishedTournamentDetails key={key} id={params.id} />;
      }
    }

    switch (route) {
      case "future":
        return <FutureTournaments key={key} />;
      case "current":
        return <CurrentTournament key={key} />;
      case "finished":
        return <FinishedTournaments key={key} />;
      default:
        return <FutureTournaments key={key} />;
    }
  };

  return (
    <div>
      <ExternalTournamentsHeader hasCurrentTournament={hasCurrentTournament} />
      {getSubPage()}
    </div>
  );
}
