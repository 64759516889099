import React from "react";
import { Button } from "react-bootstrap";
import CreatingTournamentStyled from "./CreatingTournament.style";
import { laptopPhone } from "../../../assets/images";

function CreatingTournament() {
  return (
    <CreatingTournamentStyled>
      <div className="section-container">
        <div className="section-headings">
          <h3>
            <span>Tworzenie</span> turniejów
          </h3>
          <p>Zostań organizatorem turnieju e-sportowego - wszystko, co musisz wiedzieć, aby stworzyć własne emocjonujące wydarzenie.</p>
          <Button className="blue-btn">Zobacz wszystkie</Button>
        </div>
        <div className="image-container">
          <img
            src={laptopPhone}
            alt=""
          />
          <div className="text text1">
            <h4>Konfiguracja Podstawowa:</h4>
            <ul>
              <li>Ustawienie liczby uczestników</li>
              <li>Wybór daty i godziny rozpoczęcia turnieju</li>
            </ul>
          </div>
          <div className="text text2">
            <h4>Nagrody i Kwalifikacje:</h4>
            <ul>
              <li>Określenie nagród dla zwycięzców</li>
              <li>Ustawienie kryteriów kwalifikacyjnych dla uczestników, takich jak minimalny i maksymalny poziom doświadczenia graczy</li>
            </ul>
          </div>
          <div className="line line1" />
          <div className="line line2" />
          <div className="mobile-dot mobile-dot1" />
          <div className="mobile-dot mobile-dot2" />
        </div>
      </div>
    </CreatingTournamentStyled>
  );
}

export default CreatingTournament;
