/* eslint-disable import/no-cycle */
import React from "react";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AxiosError } from "axios";
import { useModal } from "../PageModal.context";
import PageContent from "./pageContent.type";
import Input, { InputInterface } from "../../components/common/formComponents/input/Input";
import Button from "../../components/common/buttons/button/Button";
import passwordRegExp from "../../helpers/password-regex";
import ChangePasswordModalStyled from "./ChangePasswordModal.style";
import useAppMutation from "../../hooks/useMutation/useMutation";
import { changePassword } from "../../API/user/userActions";
import { ChangePasswordPayload } from "../../API/user/userActions.type";

function ChangePasswordModal() {
  const { t } = useTranslation();
  const translations: PageContent = t("changepassword", { returnObjects: true });
  const changePasswordMutation = useAppMutation(changePassword);

  const { closeModal } = useModal();

  const onFormSubmit = async (payload: ChangePasswordPayload) => {
    try {
      const response = await changePasswordMutation.mutateAsync(payload);
      if (response.status === 204) {
        NotificationManager.success(translations.success, "Message", 4000);
        closeModal();
      }
      const errorMessage = response.errorObject.response.data.message;
      NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
    } catch (error) {
      console.error((error as AxiosError).response?.data);
    }
  };

  const yupRegisterPasswordValidation = Yup.string().required(translations.errorRequired).min(8, translations.errorPasswordValid).max(24, translations.errorPasswordValid).matches(passwordRegExp, translations.errorPasswordValid);

  const validationSchema = Yup.object().shape({
    oldpassword: Yup.string().required(translations.errorRequired),
    password: yupRegisterPasswordValidation,
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], translations.errorConfirmPasswordMatch)
      .required(translations.errorRequired),
  });

  const formik = useFormik({
    initialValues: {
      oldpassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      const payload = {
        password: values.oldpassword,
        newPassword: values.password,
      };
      onFormSubmit(payload);
    },
  });

  const formInputs: InputInterface[] = [
    {
      type: "password",
      label: translations.oldPassword || "",
      id: "change-password-oldpassword",
      placeholder: translations.oldPasswordPlaceholder || "",
      name: "oldpassword",
      value: formik.values.oldpassword,
      onChange: formik.handleChange,
      error: formik.errors.oldpassword || "",
    },
    {
      type: "password",
      label: translations.newPassword || "",
      id: "change-password-password",
      placeholder: translations.newPasswordPlaceholder || "",
      name: "password",
      value: formik.values.password,
      onChange: formik.handleChange,
      error: formik.errors.password || "",
    },
    {
      type: "password",
      label: translations.confirmNewPassword || "",
      id: "change-password-confirmpassword",
      placeholder: translations.confirmPasswordPlaceholder || "",
      name: "confirmPassword",
      value: formik.values.confirmPassword,
      onChange: formik.handleChange,
      error: formik.errors.confirmPassword || "",
    },
  ];

  return (
    <ChangePasswordModalStyled>
      <div className="wrapper">
        <h1>
          <span>{translations.heading}</span>
        </h1>
        <form onSubmit={formik.handleSubmit}>
          <div className="inputs">
            {formInputs.map((item) => (
              <Input
                item={item}
                key={item.id}
              />
            ))}
          </div>
          <Button
            type="labelButton"
            submitButton
            label={translations.submitButton}
            variant="blue"
          />
        </form>
      </div>
    </ChangePasswordModalStyled>
  );
}

export default ChangePasswordModal;
