import React from "react";
import { ProgressBar } from "react-bootstrap";
import OpponentsStyled from "./Opponents.style";
import useProfile from "../../hooks/useProfile/useProfile";
import { userProfile, playerBadge } from "../../assets/images";
import { ProfileResponse } from "../../API/user/userActions.type";
import CountdownTimer from "./CountdownTimer/CountdownTimer";

interface OpponentsProps {
  opponent?: ProfileResponse;
}

function Opponents({ opponent }: OpponentsProps) {
  const profileQuery = useProfile();

  return (
    <OpponentsStyled>
      <div className="opponents-wrapper">
        <div className="gamer left">
          <div className="gamer-info">
            <div className="p-nick">
              <img
                src={playerBadge}
                alt="player badge"
              />
              <h5 className="text">{profileQuery?.data?.nickname}</h5>
            </div>
            <div className="p-level">
              <p>Ranga: 13</p>
              <ProgressBar now={60} />
            </div>
          </div>
          <div className="vertical-separator" />

          <div>
            <div className={`p-pic ${profileQuery?.data?.countryOfResidence}`}>
              {profileQuery?.data?.avatarUrl ? (
                <img
                  src={profileQuery.data.avatarUrl}
                  alt="user"
                  className="profile-image"
                />
              ) : (
                <img
                  src={userProfile}
                  alt="profile"
                  className="profile-image"
                />
              )}
            </div>
          </div>
        </div>
        <div className="versus-container">
          <p className="versus-text">vs</p>
          <div className="vertical-separator" />
          <CountdownTimer
            initialMinutes={4}
            initialSeconds={59}
          />
        </div>
        <div className="gamer right">
          <div className="p-pic">
            {opponent?.avatarUrl ? (
              <img
                src={opponent.avatarUrl}
                alt="user"
                className="profile-image"
              />
            ) : (
              <img
                src={userProfile}
                alt="profile"
                className="profile-image"
              />
            )}
          </div>
          <div className="vertical-separator" />

          <div className="gamer-info">
            {opponent ? (
              <>
                <div className="p-nick">
                  <img
                    src={playerBadge}
                    alt="player badge"
                  />
                  <h5 className="text">{opponent.nickname}</h5>
                </div>
                <div className="p-level">
                  <p>Ranga: 13</p>
                  <ProgressBar now={60} />
                </div>
              </>
            ) : (
              <h5 className="no-opponent-text">???</h5>
            )}
          </div>
        </div>
      </div>
    </OpponentsStyled>
  );
}

export default Opponents;
