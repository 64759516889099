import React from "react";

export default function TournamentList(props) {
  const { values, content, handleUpdate, handleDelete, handleDetails } = props;
  const Item = values.list.map((el) => (
    <tr key={el}>
      <td>{el.tournamentStart}</td>
      <td>{el.title}</td>
      <td>
        <button
          type="button"
          className="btn-primary-inverse"
          data-id={el._id}
          onClick={handleUpdate.bind(this, el._id)}
        >
          <i
            className="fa fa-edit"
            aria-hidden="true"
          />
        </button>
        <button
          type="button"
          className="btn-primary-inverse"
          data-id={el._id}
          onClick={handleDelete.bind(this, el._id)}
        >
          <i
            className="fa fa-trash"
            aria-hidden="true"
          />
        </button>
        <button
          type="button"
          className="btn-primary-inverse"
          data-id={el._id}
          onClick={handleDetails.bind(this, el._id)}
        >
          <i
            className="fa fa-eye"
            aria-hidden="true"
          />
        </button>
      </td>
    </tr>
  ));
  return (
    <div className="home-table">
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>{content.date}</th>
              <th>{content.title}</th>
              <th>{content.action}</th>
            </tr>
          </thead>
          <tbody>
            {values.list.length !== 0 ? (
              Item
            ) : (
              <tr>
                <td colSpan={3}>No data found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
