import React, { useEffect, useState } from "react";
import CounterStyled from "./Counter.style";
import { counter } from "../../../assets/images";

interface CounterProps {
  startingNumber: number;
  finishNumber: number;
  timeInterval: number; // miliseconds
  onFinish: () => void;
}

function Counter({ startingNumber, finishNumber, timeInterval, onFinish }: CounterProps) {
  const [counterNumber, setCounterNumber] = useState(startingNumber);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counterNumber === finishNumber) {
        clearInterval(interval);
        setTimeout(() => onFinish(), 1000);
        return;
      }

      if (startingNumber > finishNumber) {
        setCounterNumber((prev) => prev - 1);
      }

      if (finishNumber > startingNumber) {
        setCounterNumber((prev) => prev + 1);
      }
    }, timeInterval);

    return () => clearInterval(interval);
  }, [counterNumber]);

  return (
    <CounterStyled animationDuration={timeInterval}>
      <div className="countdown">
        <div className="countdown-wrapper">
          <img
            src={counter}
            alt="counter"
            className="spinner"
          />
        </div>
        <div className="counter-number">{counterNumber}</div>
      </div>
    </CounterStyled>
  );
}

export default Counter;
