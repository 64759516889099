import styled from "styled-components";

const PageTitleStyled = styled.div`
  .title {
    margin-bottom: 40px;
    position: relative;
  }

  .title-text {
    font-size: ${({ theme }) => theme.fontSizes.h1};
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    display: flex;
    align-items: center;
    column-gap: 40px;
  }

  .title-text::after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export default PageTitleStyled;
