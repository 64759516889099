import React from "react";
import { useTranslation } from "react-i18next";
import { coin25, duelIcon } from "../../../assets/images";
import StatusStyled from "./Status.style";
import PageContent from "../../../components/bonusRoom/PageContent.type";
import { StakeType } from "../../../types/stake";

const TOTAL_DUELS_TO_WIN = 3;

interface StatusProps {
  duelNumber: number;
  stake: StakeType;
  coinImageUrl?: string;
}

function Status({ duelNumber, coinImageUrl, stake }: StatusProps) {
  const translations: PageContent = useTranslation().t("bonus_room", { returnObjects: true });
  const createCheckboxes = () => {
    const arr = new Array(TOTAL_DUELS_TO_WIN).fill(undefined);
    arr[duelNumber - 1] = "?";

    return arr.map((item, index) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={`choose-game-checkbox-${index}`}
        className={index < duelNumber - 1 ? "checkbox green" : "checkbox"}
      >
        {item}
      </div>
    ));
  };

  return (
    <StatusStyled>
      <div className="bonus-room-status">
        <div className="flex-container">
          <img
            src={duelIcon}
            alt="duel"
            className="status-duel-icon"
          />
          <p>
            {translations.duels_status} {duelNumber} {translations.duels_status_of} {TOTAL_DUELS_TO_WIN}
          </p>
          <div className="checkboxes">{createCheckboxes()}</div>
        </div>
        <div className="status-stake-container">
          <img
            src={coinImageUrl || coin25}
            alt="duel"
            className="status-coin-icon"
          />
          <p>
            {translations.duels_status_stake}{" "}
            <span className="status-stake">
              {stake.stake.toFixed(stake.stake >= 10 ? 0 : 1)} {stake.currency}
            </span>
          </p>
        </div>
      </div>
    </StatusStyled>
  );
}

export default Status;
