import React from "react";
import { Puff } from "react-loader-spinner";
import LoaderStyled from "./Loader.style";

interface LoaderProps {
  width?: string;
}

function Loader({ width }: LoaderProps) {
  return (
    <LoaderStyled width={width}>
      <Puff wrapperClass="loader" />
    </LoaderStyled>
  );
}

export default Loader;
