import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth/useAuth";
import "./currentTournament.css";
import Routes from "../../../config/routes";
import PageContent from "./pageContent.type";
import player from "../../../assets/images/icons/current-tournament/player.svg";
import joystick from "../../../assets/images/icons/current-tournament/joystick.svg";
import calendar from "../../../assets/images/icons/current-tournament/calendar.svg";
import registration from "../../../assets/images/icons/current-tournament/registration.svg";
import people from "../../../assets/images/icons/current-tournament/people.svg";
import CurrentTournamentCounter from "./counter/currentTournamentCounter";
import RegistrationForm from "./registrationForm/registrationForm";
import Sponsors from "./sponsors/sponsors";
import { userInstance } from "../../../axios/axiosconfig";
import CurrentTournamentData from "./currentTournamentData.type";

interface CurrentTournamentProps {
  id?: string;
}

export default function CurrentTournament(data: CurrentTournamentProps) {
  const { id } = data;
  const [currentTournamentData, setCurrentTournamentData] = useState<CurrentTournamentData>();
  const translations: PageContent = useTranslation().t("currentTournament", { returnObjects: true });
  const navigation = useNavigate();
  const isAuth = useAuth();

  const loadFutureTournamentById = async () => {
    if (isAuth) {
      try {
        return id ? await userInstance.get(`external-tournaments/${id}/upcoming`) : await userInstance.get(`external-tournaments/upcoming`);
      } catch (err) {
        navigation(Routes.FinishedTournaments);
      }
    }
    return {} as any;
  };

  const loadUpcomingTournamentData = async () => {
    if (isAuth) {
      try {
        return await userInstance.get("external-tournaments/upcoming");
      } catch (err) {

        navigation(Routes.FutureTournaments);
      }
    }
    return {} as any;
  };

  useEffect(() => {
    if (!isAuth) {
      navigation(Routes.Login);
    }

    if (id) {
      loadFutureTournamentById().then((tournamentData) => {
        setCurrentTournamentData(tournamentData.data);
      });
    } else {
      loadUpcomingTournamentData().then((tournamentData) => {
        setCurrentTournamentData(tournamentData.data);
      });
    }
  }, []);

  return (
    <>
      {" "}
      {!!currentTournamentData && (
        <div className="current-tournament">
          <img
            alt="banner"
            className="banner"
            src={currentTournamentData.bannerImageUrl}
          />
          <div className="container">
            <div className="compete-section">
              <h3>{translations.dayOfDuel}</h3>
              <h4>{currentTournamentData.name}</h4>
            </div>
            <div className="tournament-details">
              <div className="detail-tile">
                <div className="tile-icon">
                  <img
                    alt="joystick"
                    src={joystick}
                  />
                </div>
                <div className="tile-text">
                  <p className="label">{translations.game}</p>
                  <p className="info game-title">{currentTournamentData.info.gameTitle}</p>
                </div>
              </div>
              <div className="detail-tile">
                <div className="tile-icon">
                  <img
                    alt="player"
                    src={player}
                  />
                </div>
                <div className="tile-text">
                  <p className="label">{translations.entryFee}</p>
                  <p className="info">{currentTournamentData.info.fee}</p>
                </div>
              </div>
              <div className="detail-tile">
                <div className="tile-icon">
                  <img
                    alt="people"
                    src={people}
                  />
                </div>
                <div className="tile-text">
                  <p className="label">{translations.slots}</p>
                  <p className="info">{currentTournamentData.info.slots}</p>
                </div>
              </div>
              <div className="detail-tile">
                <div className="tile-icon">
                  <img
                    alt="registration"
                    src={registration}
                  />
                </div>
                <div className="tile-text">
                  <p className="label">{translations.registration}</p>
                  <p className="info">{currentTournamentData.info.registrationPeriod}</p>
                </div>
              </div>
              <div className="detail-tile">
                <div className="tile-icon">
                  <img
                    alt="calendar"
                    src={calendar}
                  />
                </div>
                <div className="tile-text">
                  <p className="label">{translations.tournamentDate}</p>
                  <p className="info">{currentTournamentData.info.dateRange}</p>
                </div>
              </div>
            </div>

            <RegistrationForm
              gameName={currentTournamentData.info.gameTitle}
              tournamentId={currentTournamentData.id}
              sideImageUrl={currentTournamentData.sideImageUrl}
              redirectUrl={currentTournamentData.redirectUrl}
              userRegistered={currentTournamentData.userRegistered}
              tournamentName={currentTournamentData.name}
            />
          </div>
          {currentTournamentData.sponsors.length > 0 && (
            <Sponsors
              sectionHeader={translations.sponsors}
              list={currentTournamentData.sponsors}
            />
          )}
          <CurrentTournamentCounter startDate={currentTournamentData.startDate} />
        </div>
      )}
    </>
  );
}
