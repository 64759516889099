import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PageContent from "./pageContent.type";
import SignupFormStyled from "./Signup.style";
import { SignUpAuthPayload } from "./Signup.type";
import SignUpAuthStage from "./SignUpAuthStage/SignUpAuthStage";
import SignUpConsentStage from "./SignUpConsentStage/SignUpConsentStage";

export default function SignupForm() {
  const { t } = useTranslation();
  const [authStageState, setAuthStageState] = useState<SignUpAuthPayload>();
  const [stageOneFinished, setStageOneFinished] = useState(false);

  const translations: PageContent = t("signup", { returnObjects: true });

  return (
    <>
      <SignupFormStyled>
        <h1>{translations.heading}</h1>
        <SignUpAuthStage
          authStageState={authStageState}
          setAuthStageState={setAuthStageState}
          stageOneFinished={stageOneFinished}
          setStageOneFinished={setStageOneFinished}
        />
        {stageOneFinished && authStageState && <SignUpConsentStage authStageState={authStageState} />}
      </SignupFormStyled>
    </>
  );
}
