import React, { useEffect, useState } from "react";
import "./futureTournaments.css";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth/useAuth";
import FutureTournamentData from "./futureTournamentData.type";
import { userInstance } from "../../../axios/axiosconfig";
import Routes from "../../../config/routes";
import Footer from "../../footer/Footer";
import FutureTournament from "./futureTournament/futureTournament";

export default function FutureTournaments() {
  const [futureTournamentData, setFutureTournamentData] = useState<FutureTournamentData[]>();
  const currentTournamentData = futureTournamentData?.find((tournament) => tournament.current);
  const navigation = useNavigate();
  const isAuth = useAuth();

  const loadFutureTournamentsData = async () => {
    if (isAuth) {
      try {
        return await userInstance.get("external-tournaments/future");
      } catch (err) {
        //   console.log(err);
      }
    }
    return {} as any;
  };

  useEffect(() => {
    if (!isAuth) {
      navigation(Routes.Login);
    }

    loadFutureTournamentsData().then((tournamentsData) => {
      setFutureTournamentData(tournamentsData.data);
    });
  }, []);

  return (
    <div className="future-tournaments-subpage">
      <div className="future-tournaments-header">
        <h1 className="header">Nadchodzace turnieje</h1>
        <p className="header-text">Rywalizuj i triumfuj w różnorodnych turniejach e-sportowych, przeznaczonych dla graczy na każdym poziomie umiejętności.</p>
        {currentTournamentData && (
          <div className="current-tournament-list">
            <div className="container">
              <p className="section-label">Aktualny</p>
              <FutureTournament tournamentData={currentTournamentData} />
            </div>
          </div>
        )}
      </div>
      <div className="future-tournaments-list">
        <div className="container">
          <p className="section-label">Nadchodzące</p>
          {futureTournamentData?.filter((tournamentData) => !tournamentData.current).length === 0 && <p className="no-future-tournaments">Brak nadchodzących turniejów</p>}

          {futureTournamentData?.filter((tournamentData) => !tournamentData.current).map((tournamentData) => (
            <FutureTournament
              key={tournamentData.id}
              tournamentData={tournamentData}
            />
          ))}
        </div>
      </div>
      <Footer noSponsors />
    </div>
  );
}
