import React, { useEffect, useRef, useState } from "react";
import DoubleRangeInputStyled from "./DoubleRangeInput.style";

export interface DoubleRangeInputInterface {
  minRange: number; // range
  maxRange: number;
  step: number;
  minValue: number; // actual value
  maxValue: number;
  onChange: (e: React.ChangeEvent) => void;
  label: string;
}

interface DoubleRangeInputProps {
  item: DoubleRangeInputInterface;
  className?: string;
}

function DoubleRangeInput({ item, className }: DoubleRangeInputProps) {
  const { minRange, maxRange, step, minValue, maxValue, onChange, label } = item;

  const trackRef = useRef<HTMLDivElement>(null);
  const [singleValueWidth, setSingleValueWidth] = useState(trackRef.current?.querySelector("div")?.scrollWidth || 0);
  const [valuesArray, setValuesArray] = useState<number[]>([]);

  useEffect(() => {
    const arr = [];
    for (let i = minRange; i <= maxRange; i += step) {
      arr.push(i);
    }
    setValuesArray(arr);
  }, [minRange, maxRange, step]);

  useEffect(() => {
    setSingleValueWidth(trackRef.current?.querySelector("div")?.scrollWidth || 0);
  }, [trackRef.current]);

  const createNumbers = () =>
    valuesArray.map((val) => (
      <div
        className="slider-track-value"
        key={val}
      >
        {val}
      </div>
    ));

  return (
    <DoubleRangeInputStyled
      rangeMin={minRange}
      rangeMax={maxRange}
      step={step}
      singleValueWidth={singleValueWidth}
      minValueIndex={valuesArray.findIndex((e) => e === minValue)}
      maxValueIndex={valuesArray.findIndex((e) => e === maxValue)}
      className={className}
    >
      <p className="rank-title">{label}</p>
      <div className="range-container">
        <div
          className="slider-track"
          ref={trackRef}
        >
          {createNumbers()}
        </div>
        <input
          type="range"
          name="rank-min"
          min={minRange}
          max={maxRange}
          step={step}
          value={item.minValue}
          onChange={(e) => onChange(e)}
        />
        <input
          type="range"
          name="rank-max"
          min={minRange}
          max={maxRange}
          step={step}
          value={item.maxValue}
          onChange={(e) => onChange(e)}
        />
      </div>
    </DoubleRangeInputStyled>
  );
}

export default DoubleRangeInput;
