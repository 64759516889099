import styled from "styled-components";

const RegistrationSuccessModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;

  .wrapper {
    max-width: 700px;
    margin: 75px auto;
  }

  .registration-success-description {
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.1em;
    margin: 20px;
  }

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.h1};
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 40px;
  }
`;

export default RegistrationSuccessModalStyled;
