import styled from "styled-components";

const BonusHistoryStyled = styled.div`
  .bonus-history-container {
    max-width: 1366px;
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    column-gap: 88px;
    row-gap: 44px;
  }

  .filters {
    display: flex;
    column-gap: 15px;
    justify-content: flex-end;
    margin-top: 30px;
  }

  .no-history {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.body};
    text-transform: uppercase;
    text-align: center;
    margin: 15px;
    letter-spacing: 0.15em;
    margin-top: 100px;
  }

  .loader-container {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default BonusHistoryStyled;
