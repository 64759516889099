import styled from "styled-components";

const MoneyInputStyled = styled.div`
  /* hide arrows */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export default MoneyInputStyled;
