import styled from "styled-components";
import { forDesktop } from "../../helpers/breakpoints/viewports";

const BonusRoomModalStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.white};

  .container-bonus {
    max-width: 344px;
    margin: 45px auto;
    padding: 0;
  }

  .title {
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.h2};
  }

  .description {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.h6};
    letter-spacing: 0.15em;
  }

  .bonus-title {
    width: 100%;
    height: 40px;
    margin: 30px 0;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.h4};
    line-height: 40px;
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    border-image-slice: 1 !important;
    border-image-source: linear-gradient(to left, #3d4253, #ffffff, #3d4253) !important;
    border: 1px solid ${({ theme }) => theme.colors.white};
  }

  .title-span {
    padding: 0 17px;
    font-family: inherit;
  }

  .title-image {
    width: 25px;
    height: 25px;
  }

  .history-container {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.h6};
    padding-bottom: 11px;
    background-color: ${({ theme }) => theme.colors.darkGrey};
    text-align: center;
    letter-spacing: 0.15em;
    padding: 10px;
    margin-bottom: 15px;
  }

  .history-title {
    max-width: 250px;
    margin: auto;
  }

  .history-expand-desktop {
    display: none;
  }

  .history-expand-mobile {
    width: 34px;
    height: 34px;
    margin: 5px auto 0;
  }

  .desktop {
    display: none;
  }

  @media ${forDesktop} {
    .desktop {
      display: initial;
    }

    .container-bonus {
      max-width: 1366px;
      margin: 120px auto 50px;
    }

    .title {
      font-size: ${({ theme }) => theme.fontSizes.h1};
    }

    .bonus-title {
      margin: 40px 0;
    }

    .items-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 44px;
      margin-bottom: 66px;
    }

    .history-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 11px 0;
      border-bottom: 1px solid ${({ theme }) => theme.colors.white};
      background-color: transparent;
    }

    .history-expand-mobile {
      display: none;
    }

    .history-expand-desktop {
      display: initial;
      cursor: pointer;
    }

    .history-title {
      max-width: 100%;
      margin-left: 0;
    }

    .expand-arrows {
      color: ${({ theme }) => theme.colors.blue};
      padding-left: 10px;
    }

    .mobile {
      display: none;
    }
  }
`;

export default BonusRoomModalStyled;
