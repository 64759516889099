import React from "react";
import ProfileRowStyle from "./ProfileRow.style";
import Icon from "../../common/icon/Icon";

interface RowProps {
  title: string;
  value: React.ReactNode;
  valueIcon?: "edit";
  valueColor?: string;
  iconOnClick?: () => void;
}

function Row({ title, value, valueIcon, valueColor, iconOnClick }: RowProps) {
  return (
    <ProfileRowStyle valueColor={valueColor}>
      <div className="row-title">{title}</div>
      <div className="row-value">
        <span className="text">{value || "-"}</span>
        {valueIcon && (
          <Icon
            variant={valueIcon}
            color={valueColor || "white"}
            className="icon"
            onClick={iconOnClick}
          />
        )}
      </div>
    </ProfileRowStyle>
  );
}
export default Row;
