import React from "react";
import "./header.css";

interface LogoutButtonProps {
  text: string;
  onClick: () => void;
  className?: string;
  icon?: string;
}

function DropdownOption({ text, onClick, className, icon }: LogoutButtonProps) {
  return (
    <button
      type="button"
      className={`logout-btn dropdown-item ${className}`}
      onClick={onClick}
    >
      {icon && (
        <div
          className="icon"
          style={{ maskImage: `url(${icon})`, WebkitMaskImage: `url(${icon})` }}
        />
      )}
      {text}
    </button>
  );
}

export default DropdownOption;
