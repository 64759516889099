import React, { ReactElement, useEffect, useRef, useState } from "react";
import useClickedOutsideEffect from "../../../hooks/useClickedOutsideEffect/useClickedOutsideEffect";
import DropdownStyled, { OptionsPosition } from "./Dropdown.style";

interface TextOption {
  type: "text";
  label: string;
  onClick: () => void;
}

export interface ComponentOption {
  type: "component";
  component: ReactElement;
}

export type Option = TextOption | ComponentOption;

interface DropdownProps {
  title?: string | ReactElement;
  icon?: string;
  options: Option[];
  borderVisible?: boolean;
  optionsPosition?: OptionsPosition;
  onOpenFunc?: () => void;
}

function Dropdown({ title, icon, options, borderVisible = true, optionsPosition = "left", onOpenFunc }: DropdownProps) {
  const [optionsVisible, setOptionsVisible] = useState(false);

  const dropdownRef = useRef(null);

  useClickedOutsideEffect(dropdownRef, () => setOptionsVisible(false));

  useEffect(() => {
    if (optionsVisible && onOpenFunc) {
      onOpenFunc();
    }
  }, [optionsVisible]);

  const onOptionClick = (option: Option) => {
    if (option.type === "text") {
      option.onClick();
    }
    setOptionsVisible(false);
  };

  return (
    <DropdownStyled
      borderVisible={borderVisible}
      optionsPosition={optionsPosition}
      ref={dropdownRef}
    >
      <button
        className="dropdown-title"
        type="button"
        onClick={() => setOptionsVisible((prev) => !prev)}
      >
        {icon && (
          <img
            src={icon}
            alt="dropdown icon"
            className="icon"
          />
        )}
        {title && <div>{title}</div>}
      </button>
      {optionsVisible && (
        <div className="options">
          {options.map((item) => {
            if (item.type === "text") {
              return (
                <button
                  type="button"
                  onClick={() => onOptionClick(item)}
                  className="option"
                  key={item.label}
                >
                  {item.label}
                </button>
              );
            }
            return item.component;
          })}
        </div>
      )}
    </DropdownStyled>
  );
}

export default Dropdown;
