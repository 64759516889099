import React, { useEffect, useState } from "react";
import "./features.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "../layout";
import FeatureBox from "./FetureBox";
import EmptyRow from "../common/EmptyRow";
import { getPageContent } from "../../helpers/content";
import useLanguage from "../../hooks/useLanguage/useLanguage";

function Features() {
  const { i18n } = useTranslation();
  const [pageContent, setPageContent] = useState({});

  const page = window.location.pathname.replace("/cms/", "");
  const { shortLanguage } = useLanguage();

  const onLanguageChanged = () => {
    getPageContent(page, shortLanguage).then((data) => setPageContent(data));
  };

  useEffect(() => {
    getPageContent(page, shortLanguage).then((data) => setPageContent(data));
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  return (
    <Layout>
      <div className="features-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-page">
                <ul className="breadcrum">
                  <li>
                    <Link to="/">{pageContent.home}</Link>
                  </li>
                  <li>{pageContent.features}</li>
                </ul>
                <h2>{pageContent.features}</h2>

                <p>{pageContent.heading1}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <FeatureBox
              header={pageContent.heading2}
              paragraph={pageContent.para1}
            />
            <EmptyRow />
            <FeatureBox
              header={pageContent.para2}
              paragraph={pageContent.para6}
            />
            <FeatureBox
              header={pageContent.para3}
              paragraph={pageContent.para7}
            />
            <EmptyRow />
            <FeatureBox
              header={pageContent.para4}
              paragraph={pageContent.para8}
            />
            <FeatureBox
              header={pageContent.para5}
              paragraph={pageContent.para9}
            />
            <EmptyRow />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Features;
