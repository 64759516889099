import React from "react";
import MobileAppLink from "./MobileAppLink";
import { manyIcon, tryIcon, duel, tournament, newTournament } from "../../../assets/images";
import "../../home/home.css";
import { PopularGame } from "../../../API/game/gameActions.type";

interface PopularMobileGameBoxProps {
  game: PopularGame;
}

function PopularMobileGameBox({ game }: PopularMobileGameBoxProps) {
  return (
    <div className="col-md-3">
      <div className="popular-box">
        <div className="game-img">
          <img
            src={game.imageUrl}
            alt="pool"
          />
          <div className="try trynew">
            <img
              src={tryIcon}
              alt=""
            />
            {game.playStoreUrl && game.appleStoreUrl && (
              <MobileAppLink
                playstoreURL={game.playStoreUrl}
                appstoreURL={game.appleStoreUrl}
              />
            )}
          </div>
        </div>
        <div className="many-players">
          <img
            src={manyIcon}
            alt=""
          />
          {game.players}
        </div>

        <div className="game-action mobile-app-tile">
          <div className="duel-action newduel-action">
            <img
              src={duel}
              alt="duel"
            />
            {game.playStoreUrl && game.appleStoreUrl && (
              <MobileAppLink
                playstoreURL={game.playStoreUrl}
                appstoreURL={game.appleStoreUrl}
              />
            )}
          </div>
          <div className="tournament-action newtournament">
            <img
              src={tournament}
              alt=""
            />
            {game.playStoreUrl && game.appleStoreUrl && (
              <MobileAppLink
                playstoreURL={game.playStoreUrl}
                appstoreURL={game.appleStoreUrl}
              />
            )}
          </div>
          <div className="newtournament-action">
            <img
              src={newTournament}
              alt=""
            />
            {game.playStoreUrl && game.appleStoreUrl && (
              <MobileAppLink
                playstoreURL={game.playStoreUrl}
                appstoreURL={game.appleStoreUrl}
              />
            )}
          </div>
        </div>

        <div className="game-info">
          <h4>{game.name}</h4>
          <p>{game.description}</p>
        </div>
      </div>
    </div>
  );
}

export default PopularMobileGameBox;
