import styled from "styled-components";
import { background } from "../../assets/images";

const HowItWorksStyled = styled.div`
  color: ${({ theme }) => theme.colors.white};
  padding-top: 150px;
  background: url(${background});
  background-size: cover;
  background-repeat: no-repeat;

  .container {
    width: 1070px;
  }

  .content {
    display: flex;
    column-gap: 50px;
  }

  .left-side {
    width: 40%;
    font-size: ${({ theme }) => theme.fontSizes.caption};
  }

  .left-title {
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.h4};
  }

  .tournament-description {
    margin-top: 250px;
  }

  .players-rewards {
    margin-top: 100px;
  }

  .right-side {
    width: 60%;
  }

  .right-content {
    padding-left: 50px;
    padding-bottom: 70px;
  }

  .right-content h2:before {
    content: "";
    width: 40px;
    height: 1px;
    background: ${({ theme }) => theme.colors.white};
    display: block;
    position: absolute;
    left: -50px;
    top: 20px;
  }

  .title {
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.h1};
    position: relative;
    position: relative;
    top: -20px;
    margin: 0;
  }

  .duel-description {
    font-size: ${({ theme }) => theme.fontSizes.caption};
  }

  .vertical-line {
    border-left: 1px solid #fff;
  }
`;

export default HowItWorksStyled;
