import React from "react";
import { useTranslation } from "react-i18next";
import BonusRoomWinModalStyled from "./BonusRoomWinModal.style";
import PageContent from "../../components/bonusRoom/PageContent.type";

interface BonusRoomWinModalProps {
  amount: number;
  currency: string;
  walletType: "bonus" | "deposit";
}

function BonusRoomWinModal({ amount, currency, walletType }: BonusRoomWinModalProps) {
  const translations: PageContent = useTranslation().t("bonus_room", { returnObjects: true });

  return (
    <BonusRoomWinModalStyled>
      <h2 className="title">{translations.won_heading}</h2>
      <div className="win">{translations.won_title}</div>
      <div className="text">
        {walletType === "deposit" && <span>{translations.won_description_deposit}</span>}
        {walletType === "bonus" && <span>{translations.won_description_bonus}</span>}
        <span className="bold"> {amount}</span>
        <span>{currency}</span>
      </div>
    </BonusRoomWinModalStyled>
  );
}

export default BonusRoomWinModal;
