import styled from "styled-components";
import { ModalSize } from "./ModalSize.type";
import { forDesktop } from "../../../helpers/breakpoints/viewports";

const ModalStyled = styled("div")<{ size: ModalSize }>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: ${({ theme }) => theme.zIndex.modal};
  color: ${({ theme }) => theme.colors.white};

  .container {
    position: relative;
    background-color: ${({ theme }) => theme.colors.background};
    width: 100vw;
    height: 100vh;
    border-radius: 8px;
    overflow-y: scroll;
    padding: 0;
  }

  .exit-icon {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px 40px;
    box-sizing: content-box;
  }

  .exit-icon:hover {
    cursor: pointer;
  }

  .modal-header {
    width: 100%;
    padding-bottom: 16px;
    border: none;
  }

  .modal-text {
    font-size: ${({ theme }) => theme.fontSizes.h4};
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    white-space: nowrap;
  }

  .modal-description {
    margin-top: 8px;
    width: 100%;
    font-size: ${({ theme }) => theme.fontSizes.h6};
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
  }

  .modal-header-top-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media ${forDesktop} {
    .container {
      max-height: ${({ size, theme }) => (size ? theme.modalHeights[size] : theme.modalHeights.md)};
      max-width: ${({ size, theme }) => (size ? theme.modalWidths[size] : theme.modalWidths.md)};
    }
  }
`;

export default ModalStyled;
