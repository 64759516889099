/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { AxiosError } from "axios";
import ChangeAccountNumberStage1Styled from "./ChangeAccountNumberStage1.style";
import PageContent from "../pageContent.type";
import { InputInterface } from "../../../components/common/formComponents/input/Input";
import { SelectInterface } from "../../../components/common/formComponents/selectInput/SelectInput";
import renderFormField from "../../../components/common/formComponents/render-form-field";
import Button from "../../../components/common/buttons/button/Button";
import useAppMutation from "../../../hooks/useMutation/useMutation";
import { changeBankAccountNumber, cancelBankAccountChange } from "../../../API/user/userActions";
import { ChangeBankAccountNumberPayload } from "../../../API/user/userActions.type";
import { COUNTRY_CODES } from "../../../config/countries";
import FormErrors from "../../../helpers/formErrors.type";
import useProfile from "../../../hooks/useProfile/useProfile";
import { formatIBANWithoutCountryCode } from "../../../helpers/iban-format";
import httpStatusHelper from "../../../helpers/http-status-helper";
import CountdownButton from "../../../components/common/buttons/countdownButton/CountdownButton";
import { useModal } from "../../index";

interface ChangeAccountNumberStage1Props {
  setFirstStageCompleted: (state: boolean) => void;
  currentNumber: string;
  setAccountNumber: (num: string | undefined) => void;
  newNumber: string | undefined;
}

function ChangeAccountNumberStage1({ setFirstStageCompleted, currentNumber, setAccountNumber, newNumber }: ChangeAccountNumberStage1Props) {
  const { t } = useTranslation();
  const translations: PageContent = t("change_account_number", { returnObjects: true });
  const errorTranslations: FormErrors = t("form_errors", { returnObjects: true });
  const changeAccountMutation = useAppMutation(changeBankAccountNumber);
  const cancelAccountChangeMutation = useAppMutation(cancelBankAccountChange);
  const profileQuery = useProfile();
  const { closeModal } = useModal();

  const [countryCode, setCountryCode] = useState<string>();
  const [changeCountdown, setChangeCountdown] = useState(0);

  useEffect(() => {
    const code = COUNTRY_CODES.find((item) => item.country === profileQuery?.data?.countryOfResidence);
    setCountryCode(code?.code);
  }, [profileQuery]);

  const onFormSubmit = async (payload: ChangeBankAccountNumberPayload) => {
    try {
      const response = await changeAccountMutation.mutateAsync(payload);

      if (httpStatusHelper.isSuccess(response.status)) {
        NotificationManager.success(translations.success_sms_sent, "Message", 4000);
        setFirstStageCompleted(true);
        setAccountNumber(payload.bankAccountNumber);
        return;
      }

      const { message: errorMessage, cooldown } = response.errorObject.response.data;
      if (errorMessage === "USER_BANK_ACCOUNT_CHANGE_COOLDOWN") {
        NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
        setChangeCountdown(cooldown);
        setAccountNumber(undefined);
        return;
      }
      NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
    } catch (error) {
      console.error((error as AxiosError).response?.data);
    }
  };

  const validationSchema = Yup.object().shape({
    accountNumber: Yup.string().required(errorTranslations.required),
    newAccountNumber: Yup.string().required(errorTranslations.required),
  });

  const formik = useFormik({
    initialValues: {
      accountNumber: formatIBANWithoutCountryCode(currentNumber) || "",
      newAccountNumber: newNumber ? formatIBANWithoutCountryCode(newNumber) : "",
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      const payload = {
        bankAccountNumber: countryCode + values.newAccountNumber.replace(/\s/g, ""),
      };
      onFormSubmit(payload);
    },
  });

  const formatField = (fieldName: string, value: string | number) => {
    if (typeof value === "number") {
      return;
    }
    const formattedValue = formatIBANWithoutCountryCode(value);
    formik.setFieldValue(fieldName, formattedValue);
  };

  const inputs: (InputInterface | SelectInterface)[] = [
    {
      type: "text",
      label: translations.account_number,
      id: "change-account-number-old",
      placeholder: translations.account_number || "",
      name: "accountNumber",
      value: formik.values.accountNumber || "",
      onChange: formik.handleChange,
      error: formik.errors.accountNumber || "",
      staticTextLeft: countryCode,
      disabled: true,
    },
    {
      type: "text",
      label: translations.new_account_number || "",
      id: "change-account-number-new",
      placeholder: formatIBANWithoutCountryCode("00000000000000000000000000") || "",
      name: "newAccountNumber",
      value: formik.values.newAccountNumber,
      onChange: formik.handleChange,
      error: formik.errors.newAccountNumber || "",
      staticTextLeft: countryCode,
      disabled: !!newNumber,
      formatFunc: formatField,
    },
  ];

  const onCancelAccountChange = async () => {
    try {
      const response = await cancelAccountChangeMutation.mutateAsync({});
      if (httpStatusHelper.isSuccess(response.status)) {
        NotificationManager.success(translations.cancel_success, "Message", 4000);
        formik.setFieldValue("newAccountNumber", "");
        setFirstStageCompleted(false);
        setAccountNumber(undefined);
        closeModal();
        return;
      }
      const errorMessage = response.errorObject.response.data.message;
      NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
    } catch (error) {
      console.error((error as AxiosError).response?.data);
    }
  };

  useEffect(() => {
    formik.setFieldValue("newAccountNumber", formatIBANWithoutCountryCode(newNumber || ""));
  }, [newNumber]);

  return (
    <ChangeAccountNumberStage1Styled>
      <form onSubmit={formik.handleSubmit}>
        <div className="inputs">{inputs.map((item) => renderFormField(item))}</div>
        {newNumber ? (
          <Button
            type="labelButton"
            label={translations.cancel_button}
            variant="red"
            width="180px"
            onClick={onCancelAccountChange}
          />
        ) : (
          <CountdownButton
            type="labelButton"
            label={translations.submit_button}
            submitButton
            variant="blue"
            width="130px"
            timeInSeconds={changeCountdown}
            interval={1000}
            timeFormatted
            onCountdownEnd={() => setChangeCountdown(0)}
          />
        )}
      </form>
    </ChangeAccountNumberStage1Styled>
  );
}

export default ChangeAccountNumberStage1;
