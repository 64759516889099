import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css";
import Layout from "../layout";
import { mac, playstation } from "../../assets/images";
import ContentBox from "../common/ContentBox/ContentBox";
import { getPageContent } from "../../helpers/content";
import PageContent from "./pageContent.type";
import useLanguage from "../../hooks/useLanguage/useLanguage";

function OurVision() {
  const { i18n } = useTranslation();
  const [pageContent, setPageContent] = useState<PageContent>();
  const page = "/ourvision";
  const { shortLanguage } = useLanguage();

  const onLanguageChanged = () => {
    getPageContent(page, shortLanguage).then((data) => setPageContent(data));
  };
  useEffect(() => {
    getPageContent(page, shortLanguage).then((data) => setPageContent(data));
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  if (!pageContent) {
    return <div>Not Found</div>;
  }
  return (
    <Layout>
      <div className="default-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-page vision-title">
                <ul className="breadcrum">
                  <li>
                    <Link to="/">{pageContent.home}</Link>
                  </li>
                  <li>{pageContent.ourvision}</li>
                </ul>
                <h2>{pageContent.ourvision}</h2>
              </div>
            </div>
          </div>
          <ContentBox
            header={pageContent.ourvision}
            paragraph={pageContent.para1}
            imageSrc={mac}
          />
          <ContentBox
            header={pageContent.ourvision}
            paragraph={pageContent.para2}
            imageSrc={playstation}
            reversed
          />
        </div>
      </div>
    </Layout>
  );
}

export default OurVision;
