import React from "react";
import { Badge, ProgressBar } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import MobileHeaderStyled from "../../../header/MobileHeader/MobileHeader.style";
import { logout, playerBadge, xButton } from "../../../../assets/images";
import { ProfileResponse } from "../../../../API/user/userActions.type";
import { authStorage } from "../../../../storage/auth/auth.storage";
import Routes from "../../../../config/routes";
import BonusWallet from "../wallet/BonusWallet";

interface MobileHeaderProps {
  profilePicture: string;
  profile: ProfileResponse | undefined;
  setIsMobileHeaderCollapsed: (value: boolean) => void;
  showCurrentTournament: boolean;
  fullAccess: boolean;
}

function ExternalTournamentsMobileHeader({ profilePicture, profile, setIsMobileHeaderCollapsed, showCurrentTournament, fullAccess }: MobileHeaderProps) {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const translations: any = t("header", { returnObjects: true });

  const onLogOut = () => {
    authStorage.setTokens(null, false);
    NotificationManager.success(translations.notification3, "Message", 4000);
    navigation(Routes.Home);
  };

  return (
    <MobileHeaderStyled>
      {profile && (
        <>
          <div>
            <div className="my-profile-info-container">
              <div
                className="my-profile-info"
                role="presentation"
              >
                <div className={`p-pic ${profile.countryOfResidence}`}>
                  <img
                    src={profilePicture}
                    alt="profile"
                    className="profile-image"
                  />
                </div>
                <div className="p-name">
                  <div className="p-nick">
                    <img
                      src={playerBadge}
                      alt="player badge"
                    />
                    <h5>{profile.nickname}</h5>
                  </div>
                  {profile.status.Banned ? (
                    <div>
                      <Badge
                        className="ac-banned"
                        variant="danger"
                      >
                        <span className="text-light"> {translations.banner}</span>
                      </Badge>
                    </div>
                  ) : (
                    <div>
                      <div className="p-level">
                        <p>Ranga: 13</p>
                        <ProgressBar now={60} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="user-type">{translations.profile_dropdown_regular_user}</div>
            </div>
            <hr />

            <BonusWallet isMobile />

            <hr />

            <div className="mobile-nav">
              {fullAccess && (
                      <NavLink
                        className="nav-link"
                        to={Routes.Home}
                      >
                        START
                      </NavLink>
              )}
              {showCurrentTournament && (
                <NavLink
                  className="nav-link"
                  to="/external-tournaments-current"
                  onClick={() => setIsMobileHeaderCollapsed(true)}
                >
                  AKTUALNY
                </NavLink>
              )}
              <NavLink
                className="nav-link"
                to="/external-tournaments-future"
                onClick={() => setIsMobileHeaderCollapsed(true)}
              >
                NADCHODZĄCE
              </NavLink>
              <NavLink
                className="nav-link"
                to="/external-tournaments-finished"
                onClick={() => setIsMobileHeaderCollapsed(true)}
              >
                ZAKOŃCZONE
              </NavLink>
            </div>
          </div>

          <div>
            <button
              type="button"
              className="logout-btn"
              onClick={onLogOut}
            >
              <div
                className="icon"
                style={{ maskImage: `url(${logout})`, WebkitMaskImage: `url(${logout})` }}
              />
              {translations.profile_dropdown_logout}
            </button>
          </div>
        </>
      )}

      <button
        type="button"
        className="close-btn"
        onClick={() => setIsMobileHeaderCollapsed(true)}
      >
        <img
          src={xButton}
          alt="close button"
        />
      </button>
    </MobileHeaderStyled>
  );
}

export default ExternalTournamentsMobileHeader;
