import { AxiosError } from "axios";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";

import { closeIcon } from "../../../assets/images";
import httpStatusHelper from "../../../helpers/http-status-helper";
import { useRejectInvitation, useAcceptInvitation } from "../../../hooks/useFriendInvitationAction/useFriendInvitationAction";
import Button from "../../common/buttons/button/Button";

type ReceivedInvitationButtonsProps = {
  invitationId: string;
};

function ReceivedInvitationButtons({ invitationId }: ReceivedInvitationButtonsProps) {
  const { t } = useTranslation();
  const rejectInvitation = useRejectInvitation();
  const acceptInvitation = useAcceptInvitation();

  const onAcceptFriendInvitation = useCallback(async () => {
    try {
      const response = await acceptInvitation.mutateAsync(invitationId);
      if (!httpStatusHelper.isSuccess(response.status)) {
        const errorMessage = response.errorObject.response.data.message;
        NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
      }
    } catch (error: any) {
      console.error((error as AxiosError).response?.data);
    }
  }, [invitationId]);

  const onRejectFriendInvitation = useCallback(async () => {
    try {
      const response = await rejectInvitation.mutateAsync(invitationId);
      if (!httpStatusHelper.isSuccess(response.status)) {
        const errorMessage = response.errorObject.response.data.message;
        NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
      }
    } catch (error: any) {
      console.error((error as AxiosError).response?.data);
    }
  }, [invitationId]);

  return (
    <div className="receive-invitation-actions">
      <Button
        type="iconButton"
        icon={closeIcon}
        alt=""
        onClick={() => onRejectFriendInvitation()}
        variant="primary"
        className="cancel-button"
      />
      <Button
        type="labelButton"
        label={t("common.accept")}
        onClick={() => onAcceptFriendInvitation()}
        variant="primary"
      />
    </div>
  );
}

export default ReceivedInvitationButtons;
