import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./aboutus.css";
import Layout from "../layout";
import { about } from "../../assets/images";
import { getPageContent } from "../../helpers/content";
import PageContent from "./pageContent.type";
import useLanguage from "../../hooks/useLanguage/useLanguage";

function AboutUs() {
  const { i18n } = useTranslation();
  const [pageContent, setPageContent] = useState<PageContent>();
  const page = "/about";
  const { shortLanguage } = useLanguage();

  const onLanguageChanged = () => {
    getPageContent(page, shortLanguage).then((data) => setPageContent(data));
  };

  useEffect(() => {
    getPageContent(page, shortLanguage).then((data) => setPageContent(data));
    i18n.on("languageChanged", onLanguageChanged);
  }, []);

  if (!pageContent) {
    return <div>Not Found</div>;
  }
  return (
    <Layout>
      <div className="aboutus">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title-page">
                <ul className="breadcrum">
                  <li>
                    <Link to="/">{pageContent.home}</Link>
                  </li>
                  <li>{pageContent.aboutus}</li>
                </ul>
                <h2>{pageContent.aboutus}</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="aboutus-descp">
                <h4>{pageContent.subheading}</h4>
                <p>{pageContent.aboutheading}</p>
              </div>
              <div className="about-list">
                <ul className="about-f">
                  <li>{pageContent.stringcreate}</li>
                  <li>{pageContent.stringdescription}</li>
                </ul>
              </div>
            </div>

            <div className="col-md-6">
              <img
                className="about-image"
                src={about}
                alt="aboutprofile"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AboutUs;
