import React from "react";
import ToggleInputStyled from "./ToggleInput.style";

export interface ToggleInputInterface {
  type: "toggle";
  id: string;
  name: string;
  label: string;
  onChange: (e: React.ChangeEvent) => void;
  checked: boolean;
}

interface ToggleInputProps {
  item: ToggleInputInterface;
  className?: string;
}

function ToggleInput({ item, className }: ToggleInputProps) {
  const { id, name, label, onChange, checked } = item;
  return (
    <ToggleInputStyled className={className}>
      <div>
        <label
          className="switch-label"
          htmlFor={id}
        >
          {label}
        </label>
      </div>
      <label
        className="switch"
        htmlFor={id}
      >
        <input
          type="checkbox"
          onChange={onChange}
          name={name}
          id={id}
          checked={checked}
          className="hidden-input"
        />
        <span className="toggle" />
      </label>
    </ToggleInputStyled>
  );
}

export default ToggleInput;
