import React from "react";
import { useTranslation } from "react-i18next";
import OpponentOutsideCategoryModalStyled from "./OpponentOutsideCategoryModal.style";
import Button from "../../components/common/buttons/button/Button";
import PageContent from "../pageContent.type";

function OpponentOutsideCategoryModal() {
  const translations: PageContent = useTranslation().t("waitingopponent", { returnObjects: true });

  return (
    <OpponentOutsideCategoryModalStyled>
      <div className="wrapper">
        <h2 className="title">{translations.opponent_outside_category_title}</h2>
        <div className="description">{translations.opponent_outside_category_description}</div>
        <div className="buttons">
          <Button
            type="labelButton"
            label={translations.opponent_outside_category_accept_button}
            width="180px"
            variant="blue"
          />
          <Button
            type="labelButton"
            label={translations.opponent_outside_category_reject_button}
            variant="blue"
            width="180px"
          />
        </div>
      </div>
    </OpponentOutsideCategoryModalStyled>
  );
}

export default OpponentOutsideCategoryModal;
