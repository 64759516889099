import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../common/buttons/button/Button";
import PageContent from "./pageContent.type";
import CompleteSignupStyled from "./CompleteSignup.style";
import renderFormField from "../../common/formComponents/render-form-field";
import useCompleteSignup from "./useCompleteSignup";

export default function CompleteSignup() {
  const translations: PageContent = useTranslation().t("completeSignup", { returnObjects: true });
  const { onSubmit, formInputs } = useCompleteSignup();

  return (
    <CompleteSignupStyled>
      <h1>{translations.heading}</h1>
      <form onSubmit={onSubmit}>
        <div className="inputs">{formInputs.map((input) => renderFormField(input, "input-item"))}</div>
        <Button
          type="labelButton"
          label={translations.submitButton}
          variant="blue"
          submitButton
        />
      </form>
    </CompleteSignupStyled>
  );
}
