import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/common/buttons/button/Button";
import AcceptationModalStyled from "./AcceptationModal.style";

interface AcceptationModalProps {
  type: "accept-reject" | "yes-no";
  question: string;
  onAcceptFunc: () => void;
  onRejectFunc: () => void;
}

function AcceptationModal({ type, question, onAcceptFunc, onRejectFunc }: AcceptationModalProps) {
  const { t } = useTranslation();
  return (
    <AcceptationModalStyled>
      <div className="question">{question}</div>
      <div className="buttons">
        <Button
          type="labelButton"
          label={type === "accept-reject" ? t("common.reject") : t("common.no")}
          variant="secondary"
          onClick={() => onRejectFunc()}
          width="50%"
        />
        <Button
          type="labelButton"
          label={type === "accept-reject" ? t("common.accept") : t("common.yes")}
          variant="primary"
          onClick={() => onAcceptFunc()}
          width="50%"
        />
      </div>
    </AcceptationModalStyled>
  );
}

export default AcceptationModal;
