import React from "react";
import { server } from "../../config/config";
import "./tournamentdetails.css";

function RoundContainer({ round, rooms }) {
  return (
    <div className="col-md-3">
      <div className="card-body">
        <div className="row mb-3">
          <h4>{round}</h4>
          {rooms.map((el) => (
            <div
              className="col-md-12"
              style={{
                height: "100px",
                border: "1px solid black",
                marginBottom: "10px",
              }}
            >
              <div
                className="row"
                style={{ height: "50px", border: "1px solid black", padding: "1px" }}
              >
                {el.players.length >= 1 ? (
                  <div>
                    <div style={{ height: "22px", width: "22px", display: "inline" }}>
                      <img
                        src={`${server}/${el.players[0].photo}`}
                        alt=""
                        style={{ height: "25px", width: "25px" }}
                      />
                    </div>
                    <div style={{ height: "26px", width: "26px", display: "inline" }}>{el.players[0].firstName}</div>
                    <div style={{ height: "24px", width: "50px", display: "inline" }}>
                      <img
                        src={`${server}/public/country/${el.players[0].country}.png`}
                        style={{ height: "25px", width: "25px" }}
                        alt=""
                      />
                      {`Level : ${el.players[0].Level}`}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={{ height: "22px", width: "22px", display: "inline" }} />
                    <div style={{ height: "26px", width: "26px", display: "inline" }}>Name : Player 1</div>
                    <div style={{ height: "24px", width: "50px", display: "inline" }}>Coutry Level :</div>
                  </div>
                )}
              </div>
              <div
                className="row"
                style={{ height: "50px", border: "1px solid black", padding: "1px" }}
              >
                {el.players.length >= 2 ? (
                  <div>
                    <div style={{ height: "22px", width: "22px", display: "inline" }}>
                      <img
                        src={`${server}/${el.players[1].photo}`}
                        alt=""
                        style={{ height: "25px", width: "25px" }}
                      />
                    </div>
                    <div style={{ height: "26px", width: "26px", display: "inline" }}>{el.players[1].firstName}</div>
                    <div style={{ height: "24px", width: "50px", display: "inline" }}>
                      <img
                        src={`${server}/public/country/${el.players[1].country}.png`}
                        style={{ height: "25px", width: "25px" }}
                        alt=""
                      />
                      {` Level : ${el.players[1].Level}`}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={{ height: "22px", width: "22px", display: "inline" }} />
                    <div style={{ height: "26px", width: "26px", display: "inline" }}>Name : Player 2</div>
                    <div style={{ height: "24px", width: "50px", display: "inline" }}>Coutry Level :</div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default RoundContainer;
