import { AxiosInstance } from "axios";
import { AxiosAuthRefreshRequestConfig } from "axios-auth-refresh";
import { authStorage } from "../../../storage/auth/auth.storage";

// eslint-disable-next-line import/prefer-default-export
export const refreshTokenAsync = (axios: AxiosInstance) => {
  const refreshToken = authStorage.get()?.refreshToken;
  const fullAccess = authStorage.get()?.fullAccess;

  if (!refreshToken) {
    throw new Error("Missing refresh token");
  }

  const config: AxiosAuthRefreshRequestConfig = {
    skipAuthRefresh: true,
  };

  return axios
    .post("/auth/refreshToken", { refreshToken, referenceCode: fullAccess ? "demoPresentation123" : "invalid" }, config)
    .then((res) => {
      const { data } = res;
      authStorage.setTokens(data.accessToken, data.fullAccess, refreshToken);
      return Promise.resolve(res);
    })
    .catch((err) => {
      authStorage.set(null);
      return Promise.reject(err);
    });
};
