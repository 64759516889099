import styled from "styled-components";

const ChangeAccountNumberModalStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .change-account-number-container {
    width: 500px;
  }

  .title {
    font-size: ${({ theme }) => theme.fontSizes.h1};
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 60px;
  }
`;

export default ChangeAccountNumberModalStyled;
