import styled from "styled-components";
import { forDesktop } from "../../../helpers/breakpoints/viewports";

const BonusItemStyled = styled.div`
  width: 100%;
  padding-top: 60px;
  position: relative;
  margin-bottom: 40px;

  .item-wrapper {
    height: 330px;
    width: 100%;
    background: linear-gradient(to left bottom, rgba(0, 0, 0, 0.8) 50%, rgba(87, 189, 245, 0.15) 50%);
    border: 1px solid ${({ theme }) => theme.colors.gold};
  }

  .title-to-win {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.body};
    padding-top: 78px;
    text-align: center;
    letter-spacing: 0.15em;
    margin-bottom: 7px;
  }

  .amount-to-win-wrapper {
    position: relative;
    max-width: 150px;
    margin: auto;
  }

  .amount-to-win {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.h4};
    letter-spacing: 0.15em;
    text-align: center;
    margin: 0;
  }

  .amount-to-win:before {
    content: "";
    display: block;
    width: 15px;
    height: 1px;
    background: ${({ theme }) => theme.colors.gold};
    left: 0px;
    top: 50%;
    position: absolute;
  }

  .amount-to-win:after {
    content: "";
    display: block;
    width: 15px;
    height: 1px;
    background: ${({ theme }) => theme.colors.gold};
    right: 0px;
    top: 50%;
    position: absolute;
  }

  .activate {
    width: 142px;
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.h3};
    line-height: 28px;
    text-align: center;
    margin: 20px auto;
  }

  .no-premium {
    margin-top: 40px;
  }

  .no-premium-title {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.body};
    letter-spacing: 0.15em;
    text-align: center;
    margin-bottom: 10px;
  }

  .no-premium-look {
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.h3};
    text-align: center;
  }

  .coin {
    height: 108px;
    width: 108px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .coin-img {
    width: 100%;
  }

  @media ${forDesktop} {
    width: 275px;
    margin-bottom: 0;

    .item-wrapper:hover {
      box-shadow: 0 0 10px ${({ theme }) => theme.colors.gold};
      cursor: pointer;
    }
  } ;
`;
export default BonusItemStyled;
