/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useCallback } from "react";
import Slider from "react-slick";
import GallerySliderStyled from "./GallerySlider.style";

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

interface Slide {
  link: string;
  imageUrl: string;
  text?: string;
}

interface GallerySliderProps {
  slides: Slide[];
  isMainSlider?: boolean;
  minWidth: string;
  flexGrow?: number;
  flexShrink?: number;
}

function GallerySlider({ slides, isMainSlider = false, minWidth, flexGrow = 1, flexShrink = 1 }: GallerySliderProps) {
  const [dragging, setDragging] = useState(false);

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const onItemClick = useCallback(
    (e: React.MouseEvent, url: string) => {
      if (dragging) {
        e.stopPropagation();
        return;
      }
      window.location.href = url;
    },
    [dragging]
  );

  return (
    <GallerySliderStyled
      isMainSlider={isMainSlider}
      minWidth={minWidth}
      flexGrow={flexGrow}
      flexShrink={flexShrink}
    >
      <Slider
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
        {...settings}
      >
        {slides.map((slide) => (
          <div
            className="slide"
            key={slide.imageUrl}
          >
            <img
              src={slide.imageUrl}
              alt={slide.text}
              onClick={(e) => onItemClick(e, slide.link)}
            />
          </div>
        ))}
      </Slider>
    </GallerySliderStyled>
  );
}

export default GallerySlider;
