import React from "react";
// import { useTranslation } from "react-i18next";
import GalleryStyled from "./Gallery.style";
// import PageContent from "./pageContent.type";
import GallerySlider from "../GallerySlider/GallerySlider";
import useAppQuery from "../../../hooks/useQuery/useQuery";
import { getHomePageContent } from "../../../API/cms/cmsActions";
import useLanguage from "../../../hooks/useLanguage/useLanguage";

function Gallery() {
  // const translations: PageContent = useTranslation().t("home_gallery", { returnObjects: true });
  const { language } = useLanguage();
  const galleriesQuery = useAppQuery(getHomePageContent(language));

  if (!galleriesQuery.data) {
    return null;
  }

  return (
    <GalleryStyled>
      <div className="section-headings">
        <h3>
          <span>E-sportowe Wieści:</span> Najnowsze Trendy i Wydarzenia
        </h3>
        <p>Śledź najświeższe aktualności ze świata e-sportu, nadchodzące turnieje, i ekskluzywne wglądy w branżę.</p>
      </div>
      <div className="gallery-container">
        <div className="main-slider">
          <GallerySlider
            slides={galleriesQuery.data.galleryBig}
            isMainSlider
            minWidth="1350px"
          />
        </div>
        <div className="images">
          <GallerySlider
            slides={galleriesQuery.data.gallerySmallRight}
            minWidth="200px"
            flexGrow={2}
            flexShrink={2}
          />
          <GallerySlider
            slides={galleriesQuery.data.gallerySmallLeft}
            minWidth="120px"
            flexGrow={1}
            flexShrink={3}
          />
          <GallerySlider
            slides={galleriesQuery.data.galleryMedium}
            minWidth="250px"
            flexGrow={3}
            flexShrink={1}
          />
        </div>
      </div>
    </GalleryStyled>
  );
}

export default Gallery;
