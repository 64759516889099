import styled from "styled-components";
import { dayOfDuelBackground } from "../../../assets/images";

const DayOfDuelStyled = styled.div`
  color: ${({ theme }) => theme.colors.white};
  background: url(${dayOfDuelBackground});
  background-color: #02081a;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 100px 0 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .bottom-glow {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30%;
    background: radial-gradient(at bottom, #3bbdf5 -40%, transparent 60%);
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
  }

  .heading {
    font-family: Teko;
    font-size: 55px;
    font-weight: 900;
    line-height: 46.96px;
    letter-spacing: 0.02em;
    text-align: center;
    text-transform: uppercase;
    color: #3bbdf5;
    margin-bottom: 50px;
  }
  .flex-wrapper {
    max-width: 1400px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .column {
    width: 33%;
    font-size: ${({ theme }) => theme.fontSizes.caption};
    letter-spacing: 1.5px;
    text-align: center;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .column p {
    max-width: 330px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
    line-height: 21.47px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #606f97;
  }

  .column-title {
    font-family: Teko;
    font-size: 35px;
    font-weight: 900;
    line-height: 26.84px;
    letter-spacing: 0.02em;
    text-align: center;
    text-transform: uppercase;
  }

  .email-link {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
    color: #3bbdf5;
  }

  .email-link img {
    margin-right: 10px;
  }

  .icons {
    display: flex;
    justify-content: center;
    column-gap: 25px;
  }

  .icons button img {
    width: 33px;
    height: 33px;
  }

  .vertical-line {
    border-left: 2px solid #48516b;
    border-right: 2px solid #48516b;
  }

  @media screen and (max-width: 1100px) {
    padding: 30px 0 80px;

    .heading {
      margin-bottom: 0px;
      font-size: 40px;
    }

    .flex-wrapper {
      flex-direction: column;
      align-items: center;
    }

    .icons {
      column-gap: 10px;
    }

    .vertical-line {
      border-top: 2px solid #48516b;
      border-bottom: 2px solid #48516b;
      border-left: none;
      border-right: none;
    }

    .column {
      width: auto;
      margin: 0 20px;
      padding: 20px 0;
    }

    .bottom-glow {
      height: 25%;
      background: linear-gradient(0deg, rgba(59, 189, 245, 1) 1%, rgba(59, 189, 245, 0.5) 10%, rgba(0, 0, 0, 0) 20%);
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;

export default DayOfDuelStyled;
