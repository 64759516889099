import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { captchaSiteKey } from "../../config/config";

import { ReCaptchaProviderProps } from "./ReCaptchaProvider.types";

function ReCaptchaProvider({ children }: ReCaptchaProviderProps): React.ReactElement {
  return <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey || ""}>{children}</GoogleReCaptchaProvider>;
}

export default ReCaptchaProvider;
