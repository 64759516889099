import styled from "styled-components";

const RangeInputStyled = styled.div`
  .input:hover {
    cursor: pointer;
  }

  input[type="range"] {
    height: 2px;
    cursor: pointer;
  }
`;

export default RangeInputStyled;
