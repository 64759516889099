import styled from "styled-components";

const CounterStyled = styled.div<{ animationDuration: number }>`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .countdown {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #0d111ee6;
    z-index: 10;
  }

  .countdown-wrapper {
    height: 150px;
    width: 150px;
    position: relative;
  }

  .spinner {
    width: 150px;
    padding: 11px 0 11px 11px;
    animation-name: spin;
    animation-duration: ${(props) => `${props.animationDuration}ms`};
    animation-iteration-count: 3;
    animation-timing-function: ease;
  }

  .counter-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: 96px;
  }
`;

export default CounterStyled;
