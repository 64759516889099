import React, { useRef } from "react";
import Slider from "react-slick";
import { Button } from "react-bootstrap";
import { registerIcon, checkIn2Icon, fightWinIcon } from "../../../assets/images";
import HowToTournamentStyled from "./HowToTournamentStyled.style";
import TextCard from "../TextCard/TextCard";

function HowToTournament() {
  const slider = useRef<Slider>(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <HowToTournamentStyled>
      <Slider
        ref={slider}
        {...settings}
        className="slider"
      >
        <div className="slide-container slide-container-duel">
          <div className="slider-content">
            <div className="section-headings">
              <h3>
                <span>Jak</span> zagrać?
              </h3>
              <p>Wykorzystaj swoje umiejętności w rywalizacji z innymi graczami i stań się mistrzem turnieju e-sportowego.</p>
              <Button className="blue-btn">Zobacz wszystkie</Button>
            </div>
            <div className="content-wrapper">
              <TextCard
                icon={registerIcon}
                title="Wyszykaj"
                content="Wybierz grę, przeciwnika oraz kwotę, o którą będziecie rywalizować."
              />
              <TextCard
                icon={checkIn2Icon}
                title="Walcz"
                content="Rozpocznijcie grę na! PS4, Xbox One, PC lub mobilnie - niech wygra lepszy!"
              />
              <TextCard
                icon={fightWinIcon}
                title="Wypłata"
                content="Wygraj i odbierz pieniądze od razu."
              />
            </div>
          </div>
        </div>

        <div className="slide-container slide-container-tournament">
          <div className="slider-content">
            <div className="section-headings">
              <h3>
                <span>Jak</span> zagrać?
              </h3>
            </div>
            <div className="content-wrapper">
              <TextCard
                icon={registerIcon}
                title="Rejestracja"
                content="Rejestracja do turnieju, zazwyczaj poprzez platformę online, i spełnienie wymagań."
              />
              <TextCard
                icon={checkIn2Icon}
                title="Check-in"
                content="Przed rozpoczęciem turnieju, zazwyczaj konieczne jest potwierdzenie obecności (check-in) w wyznaczonym czasie, co potwierdza gotowość do gry."
              />
              <TextCard
                icon={fightWinIcon}
                title="Walcz i wygrywaj"
                content="Uczestnictwo w meczach zgodnie z harmonogramem turnieju, rywalizacja z innymi graczami lub drużynami, stosowanie się do zasad i regulaminu turnieju."
              />
            </div>
          </div>
        </div>
      </Slider>
    </HowToTournamentStyled>
  );
}

export default HowToTournament;
