import styled from "styled-components";
import { dropdownArrowIcon } from "../../../assets/images";

const LanguageDropdownStyled = styled.div`
  .language-dropdown {
    width: 96px;
    height: 42px;
    border: 1px solid #fff;
    border-radius: 8px;
    background-color: transparent;
  }

  .language-dropdown.mobile-dropdown {
    width: 345px;
    height: 55px;
    border-radius: 5px;
  }

  .language-dropdown img {
    border: 1px solid #fff;
    border-radius: 50%;
  }

  .language-dropdown.show {
    background-color: #fff;
    border: 1px solid #000;
  }

  .language-dropdown.mobile-dropdown.show {
    border: none;
    border-radius: 5px 5px 0 0;
  }

  .language-dropdown.show img {
    border: 1px solid #000;
  }

  .language-dropdown.show button {
    color: #000;
  }

  .language-dropdown .dropdown-toggle {
    width: 100%;
    height: 100%;
    background: transparent;
    color: #fff;
    border: none;
    padding: 8px;
    font-size: 12px;
    text-transform: uppercase;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-size: 14px;
  }

  .language-dropdown.mobile-dropdown .dropdown-toggle {
    text-transform: none;
    gap: 10px;
  }

  .language-dropdown .dropdown-toggle img {
    width: 26px;
    height: 26px;
  }

  .language-dropdown .dropdown-toggle:hover,
  .language-dropdown .dropdown-toggle:focus {
    box-shadow: none;
  }

  .language-dropdown .dropdown-toggle::after {
    -webkit-mask: url(${dropdownArrowIcon}) no-repeat center;
    mask: url(${dropdownArrowIcon}) no-repeat center;
    mask-size: 11px;
    display: block;
    font-size: 25px;
    position: absolute;
    right: 4px;
  }

  .language-dropdown.show .dropdown-toggle::after {
    transform: rotate(180deg);
  }

  .language-dropdown .dropdown-menu.show {
    width: 128px;
    margin-top: 10px !important;
    border-radius: 15px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .language-dropdown.mobile-dropdown .dropdown-menu.show {
    width: 100%;
    height: 55px;
    border-radius: 0;
    margin-top: 0 !important;
    border: none;
    border-top: 1px solid #efefef;
    padding: 12px 15px;
  }

  .language-dropdown.mobile-dropdown .dropdown-menu.show a {
    height: 100%;
  }

  .language-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: transparent;
    text-decoration: none;
  }

  .language-dropdown .dropdown-menu .dropdown-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    font-size: 14px;
    font-weight: 700;
    padding: 0;
  }

  .language-dropdown .dropdown-menu .dropdown-item img {
    width: 26px;
    height: 26px;
  }
`;

export default LanguageDropdownStyled;
