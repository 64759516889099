import styled from "styled-components";

const ChangePasswordModalStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .wrapper {
    width: 350px;
  }

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.h1};
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 30px;
  }

  .inputs {
    margin-bottom: 30px;
  }
`;

export default ChangePasswordModalStyled;
