import styled from "styled-components";

const UploadAvatarModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 50px;
  height: 100vh;

  .cropper {
    position: relative;
    width: 100%;
    height: 400px;
  }

  .cropper-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 30px;
  }

  .image-output {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 30px;
  }

  .picture {
    width: 400px;
    height: auto;
  }
`;

export default UploadAvatarModalStyled;
