/* eslint-disable jsx-a11y/no-static-element-interactions,react/no-unused-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import ModalStyled from "./Modal.style";
import { closeIcon } from "../../../assets/images";
import { ModalSize } from "./ModalSize.type";

export interface ModalProps {
  isClosable: boolean;
  onClose?: () => void;
  size?: ModalSize;
}

function Modal({ children, onClose, isClosable = false, size = "md" }: PropsWithChildren<ModalProps>) {
  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleEsc = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEsc, false);
    return () => {
      document.removeEventListener("keydown", handleEsc, false);
    };
  }, [handleEsc]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return createPortal(
    <ModalStyled
      size={size}
      onClick={handleClose}
    >
      <div
        className="container"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
      {isClosable && (
        <button
          type="button"
          className="exit-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}
        >
          <img
            src={closeIcon}
            alt="exit"
          />
        </button>
      )}
    </ModalStyled>,
    document.body
  );
}

export default Modal;
