import styled from "styled-components";

const WithdrawMoneyModalStyled = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.white};

  .heading {
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.h0};
    margin-bottom: 36px;
  }

  .text {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.p};
    text-align: center;
  }

  .account-number {
    font-family: ${({ theme }) => theme.fontFamilies.robotoBold};
    font-size: ${({ theme }) => theme.fontSizes.p};
    text-align: center;
  }

  .form {
    margin: 60px auto 150px;
  }

  .change-account {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.body};
    letter-spacing: 0.15em;
    text-align: center;
  }

  .change-account:hover {
    cursor: pointer;
  }

  .arrows {
    color: ${(props) => props.theme.colors.blue};
    padding-left: 10px;
  }

  .wallets {
    position: absolute;
    right: 150px;
    top: 23px;
    font-size: ${({ theme }) => theme.fontSizes.body};
  }

  .wallet-title {
    padding-right: 7px;
  }

  .wallet-amount {
    color: ${(props) => props.theme.colors.blue};
    padding: 0 8px;
  }
`;

export default WithdrawMoneyModalStyled;
