import React, { ReactNode, useEffect, useState } from "react";
import { UseQueryResult } from "react-query";
import ProfileContext from "../../context/profileContext/ProfileContext";
import useAuth from "../../hooks/useAuth/useAuth";
import useAppQuery from "../../hooks/useQuery/useQuery";
import { getProfile } from "../../API/user/userActions";
import { ProfileResponse } from "../../API/user/userActions.type";

interface ProfileProviderProps {
  children: ReactNode;
}

function ProfileProvider({ children }: ProfileProviderProps): React.ReactElement {
  const isAuth = useAuth();
  const [providerValue, setProviderValue] = useState<UseQueryResult<ProfileResponse, unknown> | null>(null);
  const options = {
    cacheTime: 1 * 60 * 1000, // 1 min
    enabled: false,
  };

  const profileQuery = useAppQuery(getProfile(), options);

  const refetch = async () => {
    if (!isAuth) {
      setProviderValue(null);
      return;
    }
    const response = await profileQuery.refetch();
    setProviderValue(response);
  };

  useEffect(() => {
    refetch();
  }, [isAuth]);

  return <ProfileContext.Provider value={providerValue}>{children}</ProfileContext.Provider>;
}

export default ProfileProvider;
