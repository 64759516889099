import { useMemo } from "react";
import { useInfiniteQuery, UseInfiniteQueryResult } from "react-query";
import { PaginatedAction, PaginatedResult } from "../../API/action.type";
import useClient from "../useClient/useClient";

export interface InfinitePaginationOptions {
  enabled?: boolean;
  cacheTime?: number;
  staleTime?: number;
  retry?: number | boolean;
  refetchInterval?: number | (() => number | false);
  refetchOnWindowFocus?: boolean | "always";
}

export type InfinitePaginationResult<R> = UseInfiniteQueryResult<PaginatedResult<R>> & {
  items: R[] | undefined;
};

function useInfinitePagination<R>(data: PaginatedAction<R>, options?: InfinitePaginationOptions): InfinitePaginationResult<R> {
  const client = useClient();

  const getUserData = async (page: number): Promise<PaginatedResult<R>> => {
    const res = await client({
      method: data.method,
      url: data.endpoint,
      params: {
        ...data.query,
        page,
      },
    });
    return res.data as PaginatedResult<R>;
  };
  const { page, ...rest } = data.query;

  const result = useInfiniteQuery([data.endpoint, data.method, rest], ({ pageParam = 1 }) => getUserData(pageParam), {
    getNextPageParam: ({ page: currentPage, pagesCount }) => (pagesCount !== currentPage ? currentPage + 1 : undefined),
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? false,
    enabled: options?.enabled,
    cacheTime: data.cacheTime ?? options?.cacheTime, // miliseconds
    staleTime: data.staleTime ?? options?.staleTime, // miliseconds
    retry: options?.retry,
    refetchInterval: options?.refetchInterval,
  });

  const items = useMemo(() => result.data?.pages?.flatMap((p) => p.items), [result]);

  return {
    ...result,
    items,
  };
}

export default useInfinitePagination;
