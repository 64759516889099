import React from "react";
import { useTranslation } from "react-i18next";
import DoubleRangeInput from "../doubleRangeInput/DoubleRangeInput";

export interface RankInputInterface {
  minValue: number;
  maxValue: number;
  onChange: (e: React.ChangeEvent) => void;
}

interface RankInputProps {
  item: RankInputInterface;
  className?: string;
}

function RankInput({ item, className }: RankInputProps) {
  const { t } = useTranslation();
  const { minValue, maxValue, onChange } = item;

  const rankItem = {
    id: "rank-input",
    minValue,
    maxValue,
    onChange,
    minRange: 1,
    maxRange: 10,
    step: 1,
    label: t("common.rank"),
  };

  return (
    <DoubleRangeInput
      item={rankItem}
      className={className}
    />
  );
}

export default RankInput;
