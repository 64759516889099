import styled from "styled-components";

const ForgetPasswordStage1Styled = styled.div`
  margin-top: 60px;

  .inputs {
    width: 350px;
    margin: auto;
  }

  .description {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.caption};
    margin-bottom: 30px;
  }

  .description p {
    margin: 0;
  }
`;

export default ForgetPasswordStage1Styled;
