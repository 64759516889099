import { SupportedLanguage } from "../API/user/userActions.type";
import { polandFlag, ukFlag } from "../assets/images";

interface Language {
  label: string;
  labelLonger: string;
  value: SupportedLanguage;
  image: string;
  fullName: string;
}

const LANGUAGES: Language[] = [
  { label: "PL", labelLonger: "POL", value: SupportedLanguage.pl_PL, image: polandFlag, fullName: "Polski" },
  { label: "EN", labelLonger: "ENG", value: SupportedLanguage.en_GB, image: ukFlag, fullName: "English" },
];

export default LANGUAGES;
