import styled from "styled-components";
import { howTournamentDuelBackground, howTournamentTournamentBackground, darkArrowRight, arrowRightNoStem, arrowLeftNoStem } from "../../../assets/images";

const HowToTournamentStyled = styled.div`
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .slider {
    width: 100%;
  }

  .slide-container-duel {
    background: url(${howTournamentDuelBackground});
  }

  .slide-container-tournament {
    background: url(${howTournamentTournamentBackground});
  }

  .slide-container {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 50px 200px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .slider-content {
    max-width: 1400px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .heading {
    font-size: ${({ theme }) => theme.fontSizes.h1};
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    margin-bottom: 40px;
  }

  .next-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
  }

  .prev-container {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }

  .next-prev-button {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.blue};
    font-size: ${({ theme }) => theme.fontSizes.body};
    color: ${({ theme }) => theme.colors.white};
  }

  .arrows {
    color: ${({ theme }) => theme.colors.blue};
    padding-left: 5px;
    padding-right: 5px;
  }

  .content-wrapper {
    display: flex;
    justify-content: center;
    gap: 25px;
  }

  .content-wrapper > :not(:first-child)::before {
    content: "";
    width: 55px;
    height: 55px;
    background-image: url(${darkArrowRight});
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: -40px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 11;
  }

  .content-wrapper > div {
    flex: 1 1 33%;
  }

  .slick-next,
  .slick-prev {
    top: auto;
    bottom: 5%;
    left: 50%;
    width: 36px !important;
    height: 36px !important;
    z-index: 1;
  }

  .slick-prev {
    transform: translateX(-56px);
  }
  .slick-next {
    transform: translateX(20px);
  }

  .slick-next::before,
  .slick-prev::before {
    content: "";
    display: inline-block;
    width: 36px;
    height: 36px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .slick-prev::before {
    background-image: url(${arrowLeftNoStem});
  }
  .slick-next::before {
    background-image: url(${arrowRightNoStem});
  }

  .slick-dots {
    display: none !important;
  }

  .btn {
    padding: 12px 20px !important;
  }

  @media screen and (max-width: 1100px) {
    .slider-content {
      gap: 60px;
    }

    .content-wrapper {
      flex-direction: column;
      gap: 60px;
    }

    .slide-container {
      padding: 35px 10px 80px;
    }

    .content-wrapper > :not(:first-child)::before {
      width: 42px;
      height: 42px;
      top: -80px;
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
    }

    .slick-next,
    .slick-prev {
      bottom: 2%;
      transform: translateY(0);
    }

    .slick-next {
      left: auto;
      right: 5%;
    }

    .slick-prev {
      left: 5%;
    }

    .slick-dots {
      display: block !important;
      bottom: 2.5%;
    }

    .slick-dots li {
      margin: 0;
    }

    .slick-dots li button:before {
      color: white;
      font-size: 10px;
      opacity: 0.35;
    }

    .slick-dots li.slick-active button:before {
      font-size: 20px;
      opacity: 1;
    }
  }
`;

export default HowToTournamentStyled;
