import React from "react";
import { useTranslation } from "react-i18next";

import MyGamesStyled from "./MyGames.style";
import GamesTable from "./GamesTable/GamesTable";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import Routes from "../../config/routes";
import PageContent from "./pageContent.type";
import PageTitle from "../common/PageTitle/PageTitle";
import Layout from "../layout";

function MyGames() {
  const translations: PageContent = useTranslation().t("my_games", { returnObjects: true });

  return (
    <Layout>
      <MyGamesStyled>
        <div className="my-games-container">
          <Breadcrumb
            link={Routes.Home}
            linkTextCurrent={translations.heading}
            linkTextPrevious={translations.home}
          />
          <PageTitle title={translations.heading} />
          <GamesTable />
        </div>
      </MyGamesStyled>
    </Layout>
  );
}

export default MyGames;
