import React from "react";
import Table from "../../table/Table";
import useTransactionsTable from "./useTransactionsTable";

interface TransactionsTableProps {
  pageSize?: number;
  urlParams: URLSearchParams;
  setUrlParams: (obj: {}) => void;
}

function TransactionsTable({ pageSize = 10, urlParams, setUrlParams }: TransactionsTableProps) {
  const { data, inputs, onSort, filters: formikFilters, fetchNextPage, hasNextPage, isLoading } = useTransactionsTable({ pageSize, urlParams, setUrlParams });

  return (
    <Table
      data={data}
      inputs={inputs}
      onSort={onSort}
      filters={formikFilters}
      includePagination
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isLoading={isLoading}
    />
  );
}

export default TransactionsTable;
