import styled from "styled-components";
import { chooseStakeBackground } from "../../assets/images";

const ChooseStakeModalStyled = styled.div`
  background-image: url(${chooseStakeBackground});
  background-size: cover;
  width: 100%;
  padding: 30px;

  .choose-stake-container {
    min-height: 100vh;
    width: 100vw;
    max-width: 820px;
    margin: auto;
    background-size: auto;
  }

  .info-tab {
    display: flex;
    justify-content: space-between;
  }

  .game-name {
    font-size: ${({ theme }) => theme.fontSizes.h4};
    font-family: ${({ theme }) => theme.fontFamilies.molot};
  }

  .wallet-info {
    display: flex;
    column-gap: 10px;
    font-size: ${({ theme }) => theme.fontSizes.caption};
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
  }

  .stake-wrapper {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 30px;
  }

  .premium-title {
    width: 100%;
    height: 40px;
    margin: 40px 0;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.h4};
    line-height: 40px;
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    color: ${({ theme }) => theme.colors.lightGrey};
    border-image-slice: 1 !important;
    border-image-source: linear-gradient(to left, #3d4253, #ffffff, #3d4253) !important;
    border: 1px solid white;
  }
`;

export default ChooseStakeModalStyled;
