import styled from "styled-components";
import { forDesktop } from "../../../helpers/breakpoints/viewports";

const PopularGameBoxStyled = styled.div<{ width?: string }>`
  width: 100%;
  height: 285px;
  position: relative;
  border-image-slice: 1 !important;
  border-width: 1px !important;
  border-image-source: linear-gradient(to left, #3d4253, #ffffff, #3d4253) !important;
  background-color: #131625;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .image-container {
    height: 50%;
    width: 100%;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
  }

  .image {
    width: 100%;
    height: 100%;
  }

  .content-container {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 18px;
  }

  .game-info {
    padding: 20px 0 10px;
    width: 100%;
  }

  .title-game-name {
    font-family: Teko;
    font-size: 30px;
    font-weight: 900;
    line-height: 27px;
    letter-spacing: 0.02em;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .game-modes-button {
    position: absolute;
    top: 50%;
    left: 3%;
    transform: translateY(-50%);
  }

  .logo-icon {
    margin-right: 10px;
  }

  .game-description {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #606f97;
  }

  .blue-btn {
    border-color: #3bbdf5 !important;
    color: #3bbdf5;
  }

  .border-btn:focus,
  .border-btn:active {
    background-color: transparent !important;
    color: #3bbdf5 !important;
  }
  @media ${forDesktop} {
    width: ${({ width }) => width || "260px"};
  }

  @media screen and (max-width: 1100px) {
    .content-container {
      padding: 10px;
    }

    .game-modes-button {
      transform: translateY(-50%) scale(0.5);
      left: -10%;
    }

    .game-info {
      padding: 10px 0 5px;
    }

    .title-game-name {
      font-size: 18px;
      line-height: 15px;
    }

    .game-description {
      font-size: 10px;
      line-height: 13px;
    }

    .blue-btn {
      width: 88px;
      height: 24px;
      font-size: 7px;
      display: flex;
      padding: 0;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default PopularGameBoxStyled;
