import styled from "styled-components";

const StakeItemStyled = styled.div`
  .wrapper {
    position: relative;
    padding: 0;
    margin-top: 55px; //to compensate the outstanding coin
    width: 175px;
  }

  .border {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSizes.formInputDesktop};
    color: ${({ theme }) => theme.colors.grey};
    border-image-slice: 1 !important;
    border-image-source: linear-gradient(to left, #3d4253, #ffffff, #3d4253) !important;
    padding: 70px 10px 10px;
    height: 150px;
  }

  .coin {
    position: absolute;
    width: 110px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .opponents-number {
    margin-top: 8px;
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.body};
    text-align: center;
  }

  .unavailable-info {
    text-align: center;
    padding: 20px;
  }

  .no-premium {
    text-align: center;
  }
`;

export default StakeItemStyled;
