import React from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import httpStatusHelper from "../../../helpers/http-status-helper";
import { useRemoveInvitation } from "../../../hooks/useFriendAction/useFriendAction";
import Button from "../../common/buttons/button/Button";

type SentInvitationButtonsProps = {
  invitationId: string;
};

function SentInvitationButtons({ invitationId }: SentInvitationButtonsProps) {
  const { t } = useTranslation();

  const cancelMutation = useRemoveInvitation();

  const onCancel = async () => {
    const response = await cancelMutation.mutateAsync({ invitationId });
    if (httpStatusHelper.isResponseSuccessful(response)) {
      NotificationManager.success(t("friends.toaster_invitation_removed"), "Message", 10000);
    } else {
      NotificationManager.error(t("common.action_failed"), "Message", 10000);
    }
  };

  return (
    <Button
      type="labelButton"
      label={t("common.cancel")}
      onClick={onCancel}
      variant="primary"
    />
  );
}

export default SentInvitationButtons;
