/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { useTranslation } from "react-i18next";
import BonusItemStyled from "./BonusItem.style";
import { coin25 } from "../../../assets/images";
import PageContent from "../PageContent.type";
import { BonusRoom } from "../../../API/game/gameActions.type";

interface BonusItemProps {
  item: BonusRoom;
  isUserPremium: boolean;
  onActivateBonusRoom: (item: BonusRoom) => void;
}

function BonusItem({ item, isUserPremium, onActivateBonusRoom }: BonusItemProps) {
  const { t } = useTranslation();
  const translation: PageContent = t("bonus_room", { returnObjects: true });

  const setImage = () => {
    let imageSrc = item.coinImageUrls.availableUrl;
    if (item.isPremium && !isUserPremium) {
      imageSrc = item.coinImageUrls.unavailableUrl;
    }
    return imageSrc || coin25;
  };

  const createInnerText = () => {
    if (!item.isPremium) {
      return <p className="activate">{`${translation.click_activate} ${item.activateFee} ${item.currency}!`}</p>;
    }

    if (!isUserPremium) {
      return (
        <div className="no-premium">
          <p className="no-premium-title">{translation.no_premium}</p>
          <p className="no-premium-look">{translation.look}</p>
        </div>
      );
    }

    if (isUserPremium) {
      return <p className="activate">{`${translation.click_activate} ${item.activateFee} ${item.currency}!`}</p>;
    }
  };

  return (
    <BonusItemStyled>
      <div
        className="item-wrapper"
        onClick={() => onActivateBonusRoom(item)}
      >
        <p className="title-to-win">{translation.jackpot_to_win}</p>
        <div className="amount-to-win-wrapper">
          <p className="amount-to-win">{`${item.toWinAmount.toFixed(item.toWinAmount >= 10 ? 0 : 1)} ${item.currency}`}</p>
        </div>
        {createInnerText()}
      </div>
      <div className="coin">
        <img
          src={setImage()}
          alt="coin"
          className="coin-img"
        />
      </div>
    </BonusItemStyled>
  );
}

export default BonusItem;
