import React from "react";
import { FormComponentInput } from "./formComponents.types";
import CheckBox from "./checkBox/CheckBox";
import DateInput from "./dateInput/DateInput";
import Input from "./input/Input";
import SelectInput from "./selectInput/SelectInput";
import SmsCodeInput from "./codeInput/codeInput";
import MoneyInput from "./moneyInput/MoneyInput";
import ToggleInput from "./toggleInput/ToggleInput";

const renderFormField = (input: FormComponentInput, className?: string) => {
  if (input.type === "code") {
    return (
      <div
        className={className}
        key={input.id}
      >
        <SmsCodeInput
          item={input}
          key={input.id}
        />
      </div>
    );
  }
  if (input.type === "select") {
    return (
      <div
        className={className}
        key={input.id}
      >
        <SelectInput
          item={input}
          key={input.id}
        />
      </div>
    );
  }
  if (input.type === "checkbox") {
    return (
      <div
        className={className}
        key={input.id}
      >
        <CheckBox
          item={input}
          key={input.id}
        />
      </div>
    );
  }
  if (input.type === "date" || input.type === "datetime") {
    return (
      <div
        className={className}
        key={input.id}
      >
        <DateInput
          item={input}
          key={input.id}
        />
      </div>
    );
  }
  if (input.type === "money") {
    return (
      <div
        className={className}
        key={input.id}
      >
        <MoneyInput
          item={input}
          key={input.id}
        />
      </div>
    );
  }
  if (input.type === "email" || input.type === "text" || input.type === "password" || input.type === "number") {
    return (
      <div
        className={className}
        key={input.id}
      >
        <Input
          item={input}
          key={input.id}
        />
      </div>
    );
  }
  if (input.type === "toggle") {
    return (
      <div
        className={className}
        key={input.id}
      >
        <ToggleInput
          item={input}
          key={input.id}
        />
      </div>
    );
  }
};

export default renderFormField;
