import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LastGamesStyled from "./LastGames.style";
import PageContent from "../pageContent.type";
import ShowMoreBar from "../../common/showMoreBar/ShowMoreBar";
import GamesTable from "../../MyGames/GamesTable/GamesTable";
import Routes from "../../../config/routes";

function LastGames() {
  const navigation = useNavigate();
  const translations: PageContent = useTranslation().t("profile", { returnObjects: true });

  return (
    <LastGamesStyled>
      <h2 className="title">{translations.last_games_heading}</h2>
      <GamesTable
        includeFilters={false}
        includeSort={false}
        pageSize={10}
        includePagination={false}
      />
      <ShowMoreBar
        title={translations.last_games_full_history}
        onClick={() => navigation(Routes.MyGames)}
      />
    </LastGamesStyled>
  );
}

export default LastGames;
