import styled from "styled-components";

const NotificationsIconStyled = styled.div`
  .notification-wrapper {
    position: relative;
    background-color: transparent;
    border: none;
  }

  .notification-icon {
    border-radius: 2px;
    padding: 2px;
    width: 24px;
    height: 24px;
  }

  .notification-wrapper::before {
    content: attr(data-notifications-number);
    position: absolute;
    font-size: ${({ theme }) => theme.fontSizes.notification};
    line-height: 18px;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
    top: 0;
    right: 0;
    transform: translate(55%, -55%);
  }

  .notifications {
    position: absolute;
  }
`;

export default NotificationsIconStyled;
