import React from "react";
import { AccordionItem, AccordionItemHeading, AccordionItemPanel, AccordionItemButton } from "react-accessible-accordion";

interface AccordionProps {
  answer: string;
  question: string;
}

export default function AccordionContainer({ question, answer }: AccordionProps) {
  return (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>{question}</AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <p>{answer}</p>
      </AccordionItemPanel>
    </AccordionItem>
  );
}
