import React from "react";
import { Link } from "react-router-dom";
import BreadcrumbStyled from "./Breadcrumb.style";

interface BreadcrumbProps {
  link: string;
  linkTextPrevious: string;
  linkTextCurrent: string;
}

function Breadcrumb({ link, linkTextPrevious, linkTextCurrent }: BreadcrumbProps) {
  return (
    <BreadcrumbStyled>
      <ul>
        <li>
          <Link to={link}>{linkTextPrevious}</Link>
        </li>
        <li>{linkTextCurrent}</li>
      </ul>
    </BreadcrumbStyled>
  );
}

export default Breadcrumb;
