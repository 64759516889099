import React, { useEffect, useState, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import { ProfileResponse } from "../../../API/user/userActions.type";
import { userProfile, hamburgerMenu } from "../../../assets/images";
import useProfile from "../../../hooks/useProfile/useProfile";
import useAuth from "../../../hooks/useAuth/useAuth";
import "./external-tournaments-header.css";
import ProfileDropdown from "../../header/ProfileDropdown/ProfileDropdown";
import NotificationsDropdown from "../../common/notificationsDropdown/NotificationsDropdown";
import BonusWallet from "./wallet/BonusWallet";
import ExternalTournamentsMobileHeader from "./mobile-header/external-tournaments-mobile-header";
import Routes from "../../../config/routes";

interface PageContent {
  home: string;
  logout: string;
}

interface ExternalTournamentsHeaderProps {
  hasCurrentTournament: boolean;
}

export default function ExternalTournamentsHeader({ hasCurrentTournament }: ExternalTournamentsHeaderProps) {
  const [profile, setProfile] = useState<ProfileResponse | undefined>();
  const [profilePicture, setProfilePicture] = useState(userProfile);
  const profileQuery = useProfile();
  const location = useLocation();
  const isAuth = useAuth();
  const translations: PageContent = useTranslation().t("currentTournament", { returnObjects: true });
  const [isMobileHeaderCollapsed, setIsMobileHeaderCollapsed] = useState(true);
  const currentTournamentLink = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    setProfile(profileQuery?.data);
    setProfilePicture(userProfile);
  }, [profileQuery]);

  useEffect(() => {
    if (location.pathname.split("-")[2] !== "current") {
      const element = currentTournamentLink.current as HTMLAnchorElement;
      if (element) {
        element.blur();
      }
    }
  }, [location]);

  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Navbar expand="lg">
              <div className="desktop-header">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav>
                    {isAuth?.fullAccess && (
                      <NavLink
                        className="nav-link"
                        to={Routes.Home}
                        ref={currentTournamentLink}
                      >
                        START
                      </NavLink>
                    )}
                    {hasCurrentTournament && (
                      <NavLink
                        className="nav-link"
                        to={Routes.CurrentTournament}
                        ref={currentTournamentLink}
                      >
                        AKTUALNY
                      </NavLink>
                    )}
                    <NavLink className="nav-link" to={Routes.FutureTournaments}>
                      NADCHODZĄCE
                    </NavLink>
                    <NavLink className="nav-link" to={Routes.FinishedTournaments}>
                      ZAKOŃCZONE
                    </NavLink>

                    {!isAuth && (
                      <NavLink className="nav-link" to={Routes.Home}>
                        {translations.home}
                      </NavLink>
                    )}

                    <div className="user-info-section">
                      <BonusWallet />
                      <div className="notification-section">
                        <NotificationsDropdown />
                      </div>
                      {profile && profile.status.ProfileCompleted && (
                        <ProfileDropdown profilePicture={profilePicture} profile={profile} />
                      )}
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </div>
              <div className="mobile-header">
                <button
                  type="button"
                  className="hamburger-manu-btn"
                  onClick={() => setIsMobileHeaderCollapsed(false)}
                >
                  <img src={hamburgerMenu} alt="menu button" />
                </button>
                {!isMobileHeaderCollapsed && (
                  <ExternalTournamentsMobileHeader
                    profilePicture={profilePicture}
                    profile={profile}
                    setIsMobileHeaderCollapsed={setIsMobileHeaderCollapsed}
                    showCurrentTournament={hasCurrentTournament}
                    fullAccess={!!isAuth?.fullAccess}
                  />
                )}
              </div>
            </Navbar>
          </div>
        </div>
      </div>
    </header>
  );
}

