import styled from "styled-components";

const PlayerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  column-gap: 16px;

  &:last-child {
    border: none;
  }

  .user {
    display: flex;
    column-gap: 16px;
    align-items: center;
  }

  .user-avatar {
    flex-shrink: 0;
  }

  .username {
    margin: 0;
    font: ${({ theme }) => theme.fonts.global.text12};
    word-break: break-word;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .more-options {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: none;
    background-color: rgba(255, 255, 255, 0.11);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
  }

  .receive-invitation-actions {
    display: flex;
    column-gap: 6px;
  }

  .cancel-button {
    border: 1px solid ${({ theme }) => theme.colors.mainBackground};
    height: 30px;
    width: 30px;
  }

  .cancel-button img {
    width: 10px;
    height: 10px;
  }
`;

export default PlayerStyled;
