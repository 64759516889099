import React from "react";
import { playStore, appStore } from "../../../assets/images";
import "../../home/home.css";

interface MobileAppLinkProps {
  playstoreURL: string | undefined;
  appstoreURL: string | undefined;
}

function MobileAppLink({ playstoreURL, appstoreURL }: MobileAppLinkProps) {
  return (
    <div className="mobile-app-link">
      <span>Pobierz na urządzenie mobilne</span>
      {playstoreURL && (
        <div className="mobile-app-img">
          <a href={playstoreURL}>
            <img
              src={playStore}
              alt="playstore"
            />
          </a>
        </div>
      )}
      {appstoreURL && (
        <div className="mobile-app-img">
          <a href={appstoreURL}>
            <img
              src={appStore}
              alt="appstore"
            />
          </a>
        </div>
      )}
    </div>
  );
}

export default MobileAppLink;
