import React from "react";
import InfoBoxStyled from "./InfoBox.style";

interface InfoBoxProps {
  src: string;
  header: string;
  paragraph1?: string;
  paragraph2?: string;
  paragraph3?: string;
}

function InfoBox({ src, header, paragraph1, paragraph2, paragraph3 }: InfoBoxProps) {
  return (
    <InfoBoxStyled>
      <img
        src={src}
        alt="match"
      />
      <div className="info-box">
        {header && <h4 className="info-box-title">{header}</h4>}
        {paragraph1 && <p>{paragraph1}</p>}
        {paragraph2 && <p>{paragraph2}</p>}
        {paragraph3 && <p>{paragraph3}</p>}
      </div>
    </InfoBoxStyled>
  );
}

export default InfoBox;
