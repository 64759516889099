import { RefObject } from "react";
import styled from "styled-components";
import { forDesktop } from "../../../helpers/breakpoints/viewports";

const setScrollbarVisibility = (optionsHeight: number, viewportHeight: number, containerRef: RefObject<HTMLDivElement>) => {
  const topBarHeight = containerRef.current?.querySelector(".top-bar")?.scrollHeight ?? 44;
  const cancelButtonHeight = containerRef.current?.querySelector(".cancel")?.scrollHeight ?? 54;
  // 62px is the top-bar height
  if (optionsHeight + topBarHeight + cancelButtonHeight > viewportHeight - 62) {
    return "undefined";
  }
  return "none";
};

const calculateHeight = (optionsHeight: number, containerRef: RefObject<HTMLDivElement>) => {
  const topBarHeight = containerRef.current?.querySelector(".top-bar")?.scrollHeight ?? 44;
  const cancelButtonHeight = containerRef.current?.querySelector(".cancel")?.scrollHeight ?? 54;
  const totalHeight = optionsHeight + topBarHeight + cancelButtonHeight;
  return `${totalHeight}px`;
};

const OptionsMenuStyled = styled.div<{ optionsHeight: number; viewportHeight: number; containerRef: RefObject<HTMLDivElement> }>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: ${({ theme }) => theme.zIndex.modal};
  color: ${({ theme }) => theme.colors.white};

  @keyframes animation {
    from {
      height: 0px;
    }
    to {
      height: ${({ optionsHeight, containerRef }) => calculateHeight(optionsHeight, containerRef)};
    }
  }

  .container {
    position: relative;
    background-color: ${({ theme }) => theme.colors.background};
    margin: 0 16px;
    height: ${({ optionsHeight, containerRef }) => calculateHeight(optionsHeight, containerRef)};
    max-height: calc(100vh - 62px);
    border-radius: 8px;
    overflow: hidden;
    padding: 0;
    animation: animation 0.5s 1;
  }

  @keyframes closeAnimation {
    from {
      height: ${({ optionsHeight, containerRef }) => calculateHeight(optionsHeight, containerRef)};
    }
    to {
      height: 0px;
    }
  }

  .close-animation {
    animation: closeAnimation 0.5s 1;
    height: 0px;
  }

  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 16px 8px 32px;
  }

  .title {
    margin: 0;
    font: ${({ theme }) => theme.fonts.mobile.formLabel};
  }

  .top-bar > button {
    margin: 0;
  }

  .options {
    font: ${({ theme }) => theme.fonts.mobile.formInput};
    height: calc(100% - 44px - 54px);
    overflow: scroll;
    /* Hide scrollbar */
    -ms-overflow-style: ${({ optionsHeight, viewportHeight, containerRef }) => setScrollbarVisibility(optionsHeight, viewportHeight, containerRef)}; /* IE and Edge */
    scrollbar-width: ${({ optionsHeight, viewportHeight, containerRef }) => setScrollbarVisibility(optionsHeight, viewportHeight, containerRef)}; /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .options::-webkit-scrollbar {
    display: ${({ optionsHeight, viewportHeight, containerRef }) => setScrollbarVisibility(optionsHeight, viewportHeight, containerRef)};
  }

  .action-button {
    padding: 8px 32px;
    margin: 0;
    text-transform: lowercase;
    font: ${({ theme }) => theme.fonts.mobile.formInput};
  }

  .action-button > p::first-letter {
    text-transform: uppercase;
  }

  .cancel {
    padding: 8px 0 16px;
  }

  .cancel-button > img {
    transform: rotate(180deg);
  }

  @media ${forDesktop} {
    .title {
      font: ${({ theme }) => theme.fonts.desktop.formLabel};
    }

    .options {
      font: ${({ theme }) => theme.fonts.desktop.formInput};
    }

    .action-button {
      font: ${({ theme }) => theme.fonts.desktop.formInput};
    }
  }
`;

export default OptionsMenuStyled;
