import styled, { DefaultTheme } from "styled-components";
import { UserAvailabilityStatus } from "../../../../API/user/userActions.type";

const busyStatuses = ["tournament", "duel", "multiplayer", "championship-battle", "matchmaking"];

const generateBoxShadowColor = (status: UserAvailabilityStatus, isInvitationWaiting: boolean) => {
  if (isInvitationWaiting) {
    return "#5A1AE2";
  }
  if (status === "online") {
    return "rgba(20, 199, 124, 0.9)";
  }
  if (busyStatuses.includes(status)) {
    return "rgba(242, 15, 0, 0.9)";
  }
  if (status === "offline") {
    return "rgba(217, 217, 217, 0.9)";
  }
  return undefined;
};

const generateDotColor = (status: UserAvailabilityStatus, theme: DefaultTheme) => {
  let color;
  if (status === "online") {
    color = theme.colors.available;
  } else if (busyStatuses.includes(status)) {
    color = theme.colors.occupied;
  } else if (status === "offline") {
    color = theme.colors.unavailable;
  }
  return color;
};

export const StyledAvatar = styled.div<{ isInvitationWaiting: boolean; status: UserAvailabilityStatus }>`
  position: relative;
  height: 55px;
  width: 55px;

  .availability-dot {
    position: absolute;
    box-sizing: content-box;
    right: -3px;
    top: -3px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({ isInvitationWaiting }) => (isInvitationWaiting ? `1px solid transparent` : null)};
    background-repeat: no-repeat;
    background-origin: content-box, border-box, border-box, border-box, border-box;
    background-clip: content-box, border-box, border-box, border-box, border-box;
    background-size: 100% 100%, 50% 50%, 50% 50%, 50% 50%, 50% 50%;
    background-image: linear-gradient(transparent, transparent), linear-gradient(0deg, #57bdf5 60%, transparent 60%), linear-gradient(120deg, transparent 36%, transparent 30%), linear-gradient(300deg, #57bdf5 20%, #57bdf5 20%),
      linear-gradient(270deg, transparent 40%, #57bdf5 40%);
    background-position: center center, left top, right top, left bottom, right bottom;
  }

  .availability-dot-inner {
    box-sizing: content-box;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 3px solid ${({ theme }) => theme.colors.mainBackground};
    background-color: ${({ status, theme }) => generateDotColor(status, theme)};
  }

  .user-img {
    width: 100%;
    filter: ${({ status, isInvitationWaiting }) => `drop-shadow(0px 0px 4px ${generateBoxShadowColor(status, isInvitationWaiting)})`};
    border: ${({ isInvitationWaiting, theme }) => (isInvitationWaiting ? `1px solid ${theme.colors.waiting}` : null)};
    border-radius: 2px;
  }
`;

export const StyledBottomIcons = styled.div`
  position: absolute;
  width: 100%;
  height: 20px;
  bottom: 0;
  left: 0;
  background: rgba(13, 17, 30, 0.8);
  display: flex;
  column-gap: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 2px 2px;

  & > img {
    width: 12px;
    height: 12px;
  }
`;

export const StyledPremiumIcon = styled.div`
  position: absolute;
  box-sizing: content-box;
  left: -4px;
  top: -4px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  .border-icon {
    position: absolute;
    width: 100%;
  }

  .inner-icon {
    position: absolute;
    width: 10px;
    height: 10px;
  }
`;
