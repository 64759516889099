import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import queryString from "query-string";
// import { CANVAS } from "../../data/canvas";
import { userInstance } from "../../axios/axiosconfig";
import { server } from "../../config/config";

const DEFAULT_STATE = {
  promoted: false,
  nextRoundPromo: false,
  roomId: "",
  gameType: "",
  loserPanel: false,
  winnerPanel: false,
};

function Pong() {
  const [gameState, setGameState] = useState(DEFAULT_STATE);

  const findRoomIdInArray = (roomId: string | (string | null)[]) => {
    if (typeof roomId === "string") {
      return roomId;
    }
    roomId.forEach((item: string | null) => {
      if (item) {
        return item;
      }
    });
    return null;
  };

  const getRoomId = () => {
    const values = queryString.parse(window.location.search);
    if (values.roomId) {
      localStorage.setItem("offline", values.offline ? "true" : "false");
      const foundRoomId = findRoomIdInArray(values.roomId);
      if (foundRoomId) {
        localStorage.setItem("roomId", foundRoomId);
      }
      localStorage.setItem("dodAdress", `${server}/socket.io/`);
      return values;
    }
  };

  const check = (values: queryString.ParsedQuery<string>) => userInstance.post("/checkRoom", values).then((data) => data);

  useEffect(() => {
    const values = getRoomId();
    if (values) {
      window.unityInstance = window.UnityLoader.instantiate("unityContainer", "Pong Web/Build/Pong Web.json");
    }
    if (values?.tour_id) {
      check(values);
    }
  }, []);

  return (
    <>
      <div
        id="unityContainer"
        style={{ height: "100vh" }}
      />
      <Modal
        show={gameState.nextRoundPromo}
        onHide={() => {
          setGameState({ ...gameState, nextRoundPromo: true });
        }}
      >
        <ModalBody>
          <div>
            <h1>Winner Promoted to Next Round</h1>
            <h3>Next Round will start after all game on round 1 is finished</h3>
            <p>Do not close this popup</p>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={gameState.promoted}
        onHide={() => {
          setGameState({ ...gameState, promoted: true });
        }}
      >
        <ModalBody>
          <div>
            <h1>Promoted to Next Round </h1>
            <h3>Next Round will start after all game on round 1 is finished</h3>
            <p>Do not close this popup</p>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={gameState.loserPanel}
        onHide={() => {
          setGameState({ ...gameState, loserPanel: true });
        }}
      >
        <ModalBody>
          <div>
            <h1>You Lose this Round </h1>
            <h3>Will redirect to Home page in 1 sec</h3>
            <p>Do not close this popup</p>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={gameState.winnerPanel}
        onHide={() => {
          setGameState({ ...gameState, winnerPanel: true });
        }}
      >
        <ModalBody>
          <div>
            <h1>Tournament Final Match Finished </h1>
            <h3>Will redirect to Home page in 1 sec</h3>
            <p>Do not close this popup</p>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Pong;
