import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { RetryValue } from "react-query/types/core/retryer";
import { Action } from "../../API/action.type";
import useClient from "../useClient/useClient";

interface AppQueryOptions {
  enabled?: boolean;
  cacheTime?: number;
  staleTime?: number;
  retry?: number | boolean;
  refetchInterval?: number | (() => number | false);
  refetchOnWindowFocus?: boolean | "always";
}

const notRetryableStatusCodes = [401, 403, 404];

// we should retry only 3 times, and only if status code is not in notRetryableStatusCodes
const shouldRetry: RetryValue<AxiosError> = (failureCount: number, error: AxiosError): boolean => failureCount < 3 && !!error.response?.status && !notRetryableStatusCodes.includes(error.response?.status);

function useAppQuery<R>(data: Action<R>, options?: AppQueryOptions): UseQueryResult<R> {
  const client = useClient();
  const getUserData = async () => {
    const res = await client({
      method: data.method,
      url: data.endpoint,
      params: data.query,
    });
    return res.data;
  };
  return useQuery([data.endpoint, data.method, data.query], () => getUserData(), {
    refetchOnWindowFocus: options?.refetchOnWindowFocus ?? true,
    enabled: options?.enabled,
    cacheTime: data.cacheTime ?? options?.cacheTime, // miliseconds
    staleTime: data.staleTime ?? options?.staleTime, // miliseconds
    retry: options?.retry || shouldRetry,
    refetchInterval: data.refetchInterval ?? options?.refetchInterval, // milliseconds
  });
}

export default useAppQuery;
