import styled from "styled-components";

const CompleteAccountNumberStyled = styled.div`
  .title {
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.h1};
    text-align: center;
    margin-bottom: 36px;
  }

  .inputs {
    width: 350px;
    margin: auto;
  }

  .inputs div {
    width: 100%;
  }
`;

export default CompleteAccountNumberStyled;
