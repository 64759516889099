import React from "react";
import DotIndicatorStyled from "./DotIndicator.style";

interface DotIndicatorProps {
  isRead: boolean;
  onClick?: (e: React.MouseEvent) => void;
  width?: string;
}

function DotIndicator({ isRead, onClick, width }: DotIndicatorProps) {
  return (
    <DotIndicatorStyled
      isRead={isRead}
      width={width}
      onClick={(e) => onClick?.(e)}
    >
      <div className="notification-dot" />
    </DotIndicatorStyled>
  );
}

export default DotIndicator;
