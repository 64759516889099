import { getNewNotificationsAmount, saveNotificationsOpeningTime } from "../../API/user/userActions";
import useAppMutation from "../useMutation/useMutation";
import useAppQuery from "../useQuery/useQuery";

function useSaveOpeningTime() {
  const { refetch } = useAppQuery(getNewNotificationsAmount());
  return useAppMutation(saveNotificationsOpeningTime, { onSuccess: () => refetch() });
}

export default useSaveOpeningTime;
