import { AxiosAuthRefreshRequestConfig } from "axios-auth-refresh";
import { useMutation, UseMutationResult } from "react-query";
import { AxiosError } from "axios";
import useClient from "../useClient/useClient";
import { ActionCreator, ActionResponse } from "../../API/action.type";
import isErrorType from "../../helpers/is-error-type";

interface AppMutationOptions {
  onSuccess?: () => void;
  skipAuthRefresh?: boolean;
}

function useAppMutation<T, R>(creator: ActionCreator<T, R>, options?: AppMutationOptions): UseMutationResult<ActionResponse<R>, undefined, T> {
  const client = useClient();

  const getUserData = async (payload: T): Promise<ActionResponse<R>> => {
    const action = creator(payload);
    try {
      const config: AxiosAuthRefreshRequestConfig = {
        method: action.method,
        url: action.endpoint,
        data: action.payload,
        skipAuthRefresh: options?.skipAuthRefresh ?? action.skipAuthRefresh ?? false,
      };

      const res = await client(config);
      return {
        payload: res.data,
        status: res.status,
        error: false,
        headers: res.headers,
      };
    } catch (err) {
      let status: number | undefined;
      let headers: Record<string, string> | undefined;

      if (isErrorType(err, AxiosError)) {
        status = err.response?.status;
        headers = err.response?.headers;
      }
      return {
        error: true,
        errorObject: err,
        status,
        headers,
      };
    }
  };

  return useMutation((payload: T) => getUserData(payload), {
    onSuccess: () => options?.onSuccess && options.onSuccess(),
  });
}

export default useAppMutation;
