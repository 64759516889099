import styled from "styled-components";
import { forDesktop } from "../../helpers/breakpoints/viewports";

const BonusRoomWinModalStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    font-family: ${(props) => props.theme.fontFamilies.molot};
    font-size: ${(props) => props.theme.fontSizes.h4};
    text-align: center;
    margin-bottom: 60px;
  }

  .win,
  .text {
    font-family: ${(props) => props.theme.fontFamilies.roboto};
    font-size: ${(props) => props.theme.fontSizes.body};
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    max-width: 270px;
    margin: 0 auto;
  }

  .bold {
    font-family: ${(props) => props.theme.fontFamilies.robotoBold};
  }

  @media ${forDesktop} {
    .text,
    .win {
      max-width: 100%;
    }
  }
`;

export default BonusRoomWinModalStyled;
