import React from "react";
import { Dropdown } from "react-bootstrap";
import i18next from "i18next";
import LanguageDropdownStyled from "./LanguageDropdown.style";
import useLanguage from "../../../hooks/useLanguage/useLanguage";
import LANGUAGES from "../../../config/languages";
import languageStorage from "../../../storage/lang/language.storage";
import useAppMutation from "../../../hooks/useMutation/useMutation";
import { changeLanguage } from "../../../API/user/userActions";

function LanguageDropdown({ isMobile = false }) {
  const { shortLanguage } = useLanguage();
  const changeLanguageMutation = useAppMutation(changeLanguage);

  const changeAppLanguage = async (language: string) => {
    languageStorage.set(language);
    i18next.changeLanguage(language);
    await changeLanguageMutation.mutateAsync({ language });
  };

  const language = LANGUAGES.find((lang) => lang.label === shortLanguage.toUpperCase());

  return (
    <LanguageDropdownStyled>
      <Dropdown
        id="dropdown-language"
        className={`language-dropdown ${isMobile ? "mobile-dropdown" : ""}`}
      >
        <Dropdown.Toggle
          variant="default"
          id="dropdown-basic"
        >
          <img
            src={language?.image}
            alt={`${language?.value} Flag`}
          />
          {isMobile ? language?.fullName : language?.labelLonger}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {LANGUAGES.length &&
            LANGUAGES.filter((el) => el.label !== shortLanguage.toUpperCase()).map((el) => (
              <Dropdown.Item
                onClick={() => changeAppLanguage(el.value)}
                key={el.value}
              >
                {el.image && (
                  <img
                    src={el.image}
                    alt={el.label}
                  />
                )}
                {el.fullName}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </LanguageDropdownStyled>
  );
}

export default LanguageDropdown;
