import styled from "styled-components";

const WaitingOpponentModalStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 100vh;
  background: #101421;
  overflow: hidden;

  .heading {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-family: Orbitron;
    font-size: 35px;
    font-weight: 900;
  }

  .heading span.heading-blue {
    font-family: Orbitron;
    color: #3bbdf5;
  }

  .timer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .timer p {
    margin: 0;
  }

  .timer .timer-time {
    font-family: Orbitron;
    font-size: 25px;
    font-weight: 900;
    letter-spacing: 3px;
  }

  .timer .timer-text {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    color: #48516b;
  }

  .requirements {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    width: 600px;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 400;
  }

  .requirements .req1 {
    color: #48516b;
  }

  .opponents-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .versus {
    background-size: cover;
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    line-height: 235px;
    align-self: flex-start;
    width: 570px;
    text-align: center;
    align-self: stretch;
    font-size: 200px;
  }

  .opponents-names {
    display: flex;
    justify-content: space-between;
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.h2};
  }

  .opponents-names div {
    width: 240px;
    text-align: center;
  }

  .loading-text {
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.h2};
    text-align: center;
  }

  .progress-bar-border {
    border: 1px solid ${({ theme }) => theme.colors.white};
    height: 26px;
    width: 820px;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 0 4px;
  }

  .progress-bar {
    height: 18px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  @media screen and (max-width: 1200px) {
    .heading {
      margin: auto;
      width: 100vw;
      font-size: 18px;
      padding: 0 20px;
      margin-bottom: 10px;
      position: relative;
      z-index: 10;
    }

    .requirements {
      font-size: 13px;
      margin: auto;
      width: 100vw;
      padding: 0 20px;
      position: relative;
      z-index: 10;
    }

    .timer {
      display: none;
    }
  }
`;

export default WaitingOpponentModalStyled;
