import { NotificationManager } from "react-notifications";
import { cmsInstance } from "../axios/axiosconfig";

// eslint-disable-next-line import/prefer-default-export
export const getPageContent = async (page: string, language: string) => {
  try {
    const {
      data: { content, msg },
      status,
    } = await cmsInstance.get(`/content${page}/${language}`);
    if (status === 200) {
      return content;
    }
    NotificationManager.error(msg, "Message", 10000);
  } catch (error) {
    console.error("Error", (error as Error).message);
  }
};
