/* eslint-disable import/no-cycle */
import React from "react";
import { Button } from "react-bootstrap";
import { coming, logoIcon } from "../../../assets/images";
import { PopularGame } from "../../../API/game/gameActions.type";
import PopularGameBoxStyled from "./PopularGameBox.style";
import GameModesButton from "./GameModesButton/GameModesButton";
import { Wallet } from "../../../API/user/userActions.type";

interface PopularGameBoxProps {
  game: PopularGame;
  width?: string;
  depositWallet?: Wallet;
  gameButtonType: "bonus-duel" | "game-modes";
}

function PopularGameBox({ game, width, depositWallet, gameButtonType }: PopularGameBoxProps) {
  const createGameName = () => (
    <h3 className="title-game-name">
      {game.isDodGame && (
        <img
          src={logoIcon}
          alt="icon"
          className="logo-icon"
        />
      )}
      {game.name}
    </h3>
  );
  return (
    <PopularGameBoxStyled width={width}>
      <div className="image-container">
        <img
          src={game.imageUrl || coming}
          alt="pool"
          className="image"
        />
      </div>
      <div className="content-container">
        {gameButtonType === "game-modes" && (
          <div className="game-modes-button">
            <GameModesButton
              depositWallet={depositWallet}
              gameId={game.gameId}
            />
          </div>
        )}

        <div className="game-info">
          {createGameName()}
          <p className="game-description">{game.description}</p>
        </div>
        <Button className="border-btn blue-btn">Wypróbuj</Button>
      </div>
    </PopularGameBoxStyled>
  );
}

export default PopularGameBox;
