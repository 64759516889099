import styled from "styled-components";
import { forDesktop } from "../../../../helpers/breakpoints/viewports";

const SelectInputStyled = styled.div<{ error: string }>`
  display: flex;
  flex-direction: column;

  .form-label {
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.formLabelMobile};
  }

  .react-select__control {
    height: 30px;
    min-height: 30px;
    box-sizing: border-box;
    background: ${({ theme }) => theme.colors.blackBackgroundOpacity40};
    border: 1px solid;
    border-color: ${({ theme }) => theme.colors.blackBackgroundOpacity40};
    border-radius: 2px;
    padding: 0 16px;
    font-size: ${({ theme }) => theme.fontSizes.formInputMobile};
    box-shadow: none;
  }

  .react-select__placeholder {
    color: ${({ theme }) => theme.colors.whiteOpacity25};
    padding: 0;
    margin: 0;
  }

  .react-select__input-container,
  .react-select__single-value,
  .react-select__value-container {
    color: ${({ theme }) => theme.colors.whiteText};
    margin: 0;
    padding: 0;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__menu {
    background: ${({ theme }) => theme.colors.blackBackgroundOpacity40};
    border-radius: 2px;
    margin-top: 8px;
  }

  .react-select__option {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.whiteText};
    font-size: ${({ theme }) => theme.fontSizes.formInputMobile};
  }

  .error {
    color: ${({ theme }) => theme.colors.red};
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.formError};
    height: 20px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    margin-top: 3px;
    text-align: right;
  }

  .dropdown-arrow {
    transform: rotate(0);
    transition: all 3s;
  }

  .rotate-arrow {
    transform: rotate(180deg);
  }

  @media ${forDesktop} {
    .react-select__control:hover {
      cursor: pointer;
      border-color: ${({ theme }) => theme.colors.blackBackgroundOpacity40};
    }
    .form-label {
      font-size: ${({ theme }) => theme.fontSizes.formLabelDesktop};
    }
    .react-select__control,
    .react-select__option {
      font-size: ${({ theme }) => theme.fontSizes.formInputDesktop};
    }

    .react-select__option:hover {
      background-color: transparent;
      cursor: pointer;
      font-family: ${({ theme }) => theme.fontFamilies.robotoBold};
    }

    .react-select__control {
      height: 40px;
      min-height: 40px;
    }
  }
`;

export default SelectInputStyled;
