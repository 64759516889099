import React from "react";
import BonusHistoryStyled from "./BonusHistory.style";
import Filter from "../../table/Filter/Filter";
import useBonusHistory from "./useBonusHistory";

function BonusHistory() {
  const { bonusRoomCards, filters } = useBonusHistory();

  return (
    <BonusHistoryStyled>
      <div className="filters">
        {filters.map((filter) => (
          <Filter
            item={filter}
            key={filter.id}
          />
        ))}
      </div>
      {bonusRoomCards}
    </BonusHistoryStyled>
  );
}

export default BonusHistory;
