import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { AxiosError } from "axios";
import Button from "../../../common/buttons/button/Button";
import FriendInvitationActionButtonsStyled from "./FriendInvitationActionButtons.style";
import { useRejectInvitation, useAcceptInvitation } from "../../../../hooks/useFriendInvitationAction/useFriendInvitationAction";
import httpStatusHelper from "../../../../helpers/http-status-helper";

interface ActionButtonsProps {
  invitationId: string;
  className?: string;
}

function FriendInvitationActionButtons({ invitationId, className }: ActionButtonsProps) {
  const { t } = useTranslation();
  const rejectInvitation = useRejectInvitation();
  const acceptInvitation = useAcceptInvitation();

  const onAcceptFriendInvitation = useCallback(async () => {
    try {
      const response = await acceptInvitation.mutateAsync(invitationId);
      if (!httpStatusHelper.isSuccess(response.status)) {
        const errorMessage = response.errorObject.response.data.message;
        NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
      }
    } catch (error: any) {
      console.error((error as AxiosError).response?.data);
    }
  }, [invitationId]);

  const onRejectFriendInvitation = useCallback(async () => {
    try {
      const response = await rejectInvitation.mutateAsync(invitationId);
      if (!httpStatusHelper.isSuccess(response.status)) {
        const errorMessage = response.errorObject.response.data.message;
        NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
      }
    } catch (error: any) {
      console.error((error as AxiosError).response?.data);
    }
  }, [invitationId]);

  return (
    <FriendInvitationActionButtonsStyled className={className}>
      <Button
        type="labelButton"
        label={t("common.accept")}
        variant="blue"
        onClick={() => onAcceptFriendInvitation()}
        width="95px"
      />
      <Button
        type="labelButton"
        label={t("common.reject")}
        variant="primary"
        onClick={() => onRejectFriendInvitation()}
        width="95px"
      />
    </FriendInvitationActionButtonsStyled>
  );
}

export default FriendInvitationActionButtons;
