import { ProfileResponse } from "../../API/user/userActions.type";
import { StakeType } from "../../types/stake";

export enum SocketServerEventEnum {
  MATCHMAKING_SEARCHING = "MATCHMAKING_SEARCHING",
  POTENTIAL_MATCH_CREATED = "POTENTIAL_MATCH_CREATED",
  ACCEPT_MATCH = "ACCEPT_MATCH",
  ACCEPT_MATCH_COMPLETED = "ACCEPT_MATCH_COMPLETED",
  MATCHMAKING_SUCCEEDED = "MATCHMAKING_SUCCEEDED",
  MATCHMAKING_TIMED_OUT = "MATCHMAKING_TIMED_OUT",
  MATCHMAKING_CANCELLED = "MATCHMAKING_CANCELLED",
  MATCHMAKING_FAILED = "MATCHMAKING_FAILED",
}

interface MatchmakingFailure {
  gameId: string;
  stake: StakeType;
  walletId?: string;
  bonusRoomId?: string;
}

export interface MatchmakingSearchingPayload {
  estimatedWaitMillis: number;
}

export interface PotentialMatchCreatedPayload {
  acceptanceTimeout: number;
  acceptanceRequired: boolean;
}

export interface MatchmakingSucceededPayload {
  matchId: string;
  playersData: ProfileResponse[];
  gameUri: string;
}

export interface MatchmakingTimedOutPayload extends MatchmakingFailure {}

export interface MatchmakingCancelledPayload extends MatchmakingFailure {}

export interface MatchmakingFailedPayload extends MatchmakingFailure {}
