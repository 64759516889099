import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import TimeStyled from "./Time.style";

interface TimeProps {
  date: string | number | Date;
  dateFormat: string | undefined;
  emptyPlaceholder: string;
  width?: string;
}

function Time({ date, dateFormat, emptyPlaceholder, width }: TimeProps) {
  const [parsedDate, setParsedDate] = useState<Date>();

  useEffect(() => {
    if (typeof date === "string") {
      setParsedDate(new Date(Date.parse(date as string)));
    } else if (typeof date === "number") {
      setParsedDate(new Date(date as number));
    } else if (date instanceof Date) {
      setParsedDate(date);
    }
  }, [date]);

  return <TimeStyled width={width}>{parsedDate ? format(parsedDate, dateFormat || "yyyy-MM-dd, HH:mm") : emptyPlaceholder}</TimeStyled>;
}

export default Time;
