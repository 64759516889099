/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dropdown, { ComponentOption } from "../dropdown/Dropdown";
import NotificationsIcon from "./NotificationsIcon/NotificationsIcon";
import Notification from "./Notification/Notification";
import { threeDotsIcon } from "../../../assets/images";
import NotificationsDropdownStyled from "./NotificationsDropdown.style";
import Routes from "../../../config/routes";
import { getNewNotificationsAmount, getUserNotificationsForDropdown } from "../../../API/user/userActions";
import { SupportedNotifications } from "../../../API/user/notifications.type";
import useAppQuery from "../../../hooks/useQuery/useQuery";
import useSaveOpeningTime from "../../../hooks/useSaveOpeningTime/useSaveOpeningTime";

function NotificationsDropdown() {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const { data, refetch } = useAppQuery(getUserNotificationsForDropdown());
  const { data: notificationsAmountData } = useAppQuery(getNewNotificationsAmount());
  const saveOpeningTimeMutation = useSaveOpeningTime();

  const notificationsAmount = useMemo(() => notificationsAmountData?.count || 0, [notificationsAmountData]);

  const onOpen = async () => {
    await saveOpeningTimeMutation.mutateAsync({});
  };

  const titleBar = (
    <div
      className="title-row"
      key="notifications-dropdown-title-bar"
    >
      <div className="notifications-options">
        <img
          src={threeDotsIcon}
          alt="more options"
        />
      </div>
      <div
        className="show-all"
        onClick={() => {
          navigation(Routes.NotificationPage);
        }}
      >
        <span>{t("notifications.show_all")}</span>
        <span className="arrows">{">>"}</span>
      </div>
    </div>
  );

  const createOptionsElements = () => {
    const array: ComponentOption[] = [];
    array.push({
      type: "component",
      component: titleBar,
    });
    if (data?.length === 0) {
      array.push({
        type: "component",
        component: <div className="no-notifications">{t("notifications.no_notifications")}</div>,
      });
    }
    data?.forEach((option: SupportedNotifications) =>
      array.push({
        type: "component",
        component: (
          <Notification
            notification={option}
            key={option.id}
            dataRefetch={refetch}
          />
        ),
      })
    );
    return array;
  };
  return (
    <NotificationsDropdownStyled>
      <Dropdown
        title={<NotificationsIcon notificationsAmount={notificationsAmount || 0} />}
        options={createOptionsElements()}
        borderVisible={false}
        optionsPosition="right"
        onOpenFunc={onOpen}
      />
    </NotificationsDropdownStyled>
  );
}

export default NotificationsDropdown;
