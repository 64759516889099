import React, { useState } from "react";
import "./winners.css";
import { Button } from "react-bootstrap";
import topPlayerAvatar from "../../../../../assets/images/external-tournaments/top-player-avatar.png";
import { Player } from "../finishedTournamentData";

interface FinishedTournamentWinnersProps {
  players: Player[];
}

export default function FinishedTournamentWinners({ players }: FinishedTournamentWinnersProps) {
  const [showMoreWinners, setShowMoreWinners] = useState<Boolean>(false);

  const getWinnersList = () => {
    if (showMoreWinners) {
      return players;
    }
    return players.slice(0, 5);
  };

  return (
    <div className="container">
      <div className="finished-tournament-winners">
        <p className="top-players">Zawodnicy - TOP 25</p>
        <p className="player-label">Gracz</p>
        {getWinnersList().map((player) => (
          <div
            key={player.name}
            className="player-row"
          >
            <img
              src={topPlayerAvatar}
              alt="top player avatar"
            />
            <span className="name">{player.name}</span>
          </div>
        ))}
        <div className="button-container">
          {!showMoreWinners && (
            <Button
              onClick={() => {
                setShowMoreWinners(true);
              }}
            >
              Załaduj więcej
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
