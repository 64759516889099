import React from "react";
import { ReactI18NextChild } from "react-i18next";

interface InputLabelProps {
  label: string;
  id: string;
  required: string;
  classname: string;
  type: string;
  value: string;
  name: string;
  placeholder: string;
  handlechange: () => void;
  readonly: boolean;
  children: ReactI18NextChild;
}

function InputLabel({ label, id, required, classname, type, value, name, placeholder, handlechange, readonly, children }: InputLabelProps) {
  return (
    <div className="form-group">
      <label htmlFor={id}>
        {label}
        {required && (
          <span
            className="required"
            title="required"
          >
            *
          </span>
        )}
      </label>

      {!children ? (
        <input
          type={type}
          className={`form-control ${classname}`}
          value={value}
          name={name}
          id={id}
          readOnly={readonly}
          placeholder={placeholder}
          onChange={handlechange}
        />
      ) : (
        <div style={{ position: "relative" }}>
          <input
            type={type}
            className={`form-control ${classname}`}
            value={value}
            name={name}
            id={id}
            readOnly={readonly}
            placeholder={placeholder}
            onChange={handlechange}
          />
          {children}
        </div>
      )}
    </div>
  );
}

export default InputLabel;
