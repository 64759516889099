import styled from "styled-components";
import { profileBg } from "../../assets/images";
import { forDesktop } from "../../helpers/breakpoints/viewports";

const ProfileStyled = styled.div`
  padding: 80px 15px 50px;
  background: url(${profileBg});
  background-size: cover;
  background-attachment: fixed;

  .profile-container {
    max-width: 1070px;
    margin: auto;
    margin-top: 50px;
  }

  .user-profile {
    width: 100%;
  }

  .profile-picture {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 10%;
  }

  .profile-data {
    flex-basis: 45%;
  }

  .profile-info {
    flex-basis: 45%;
  }

  .info {
    margin-bottom: 30px;
  }

  .profile-image {
    width: 100px;
    height: auto;
    margin-bottom: 30px;
  }

  .button {
    display: flex;
    justify-content: start;
    column-gap: 30px;
  }

  .bank-account {
    margin-top: 20px;
  }

  @media (${forDesktop}) {
    .user-profile {
      display: flex;
      column-gap: 40px;
      justify-content: space-between;
    }
  }
`;

export default ProfileStyled;
