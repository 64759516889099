/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import CodeInputStyled from "./codeInput.style";
import { focusWithSelectAll } from "../../../../helpers/input.helper";

export interface CodeInputInterface {
  type: "code";
  label: string;
  id: string;
  name: string; // name has to be the same as formik value key
  setValue: (field: string, value: any) => void;
  error: string;
  disabled?: boolean;
  codeLength?: number;
}

interface InputProps {
  item: CodeInputInterface;
}

const ALLOWED_CHARS_REGEXP = /[0-9]/;

const BACKSPACE_KEY = "Backspace";
const MOVE_LEFT_KEY = "ArrowLeft";
const MOVE_RIGHT_KEY = "ArrowRight";

function CodeInput({ item }: InputProps) {
  const { label, name, id, setValue, error, disabled, codeLength } = item;

  const code = new Array(codeLength || 6).fill(null);
  const [codeArray, setCodeArray] = useState(code);

  useEffect(() => {
    setValue(name, codeArray.join(""));
  }, [codeArray]);

  const onInputsChange = (e: React.ChangeEvent, index: number) => {
    const target = e.target as HTMLInputElement;
    const newCodeArray = [...codeArray];
    newCodeArray[index] = target.value;
    setCodeArray(newCodeArray);

    const previous = target.previousSibling as HTMLInputElement;
    const next = target.nextSibling as HTMLInputElement;

    if (target.value && next) {
      focusWithSelectAll(next);
    } else if (!target.value && previous) {
      // if we removed value from input, and we have previous one - simply focus it
      focusWithSelectAll(previous);
    }
  };

  const regex = new RegExp(ALLOWED_CHARS_REGEXP);
  const onKeyDownTest = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === BACKSPACE_KEY) {
      if (!e.currentTarget.value) {
        // if field is empty, then we should simply focus previous sibling without removal
        e.preventDefault();

        const previous = e.currentTarget.previousSibling as HTMLInputElement;
        if (previous) {
          focusWithSelectAll(previous);
        }
      }
    } else if (e.key === MOVE_RIGHT_KEY) {
      e.preventDefault();

      const next = e.currentTarget.nextSibling as HTMLInputElement;
      if (next) {
        focusWithSelectAll(next);
      }
    } else if (e.key === MOVE_LEFT_KEY) {
      e.preventDefault();

      const previous = e.currentTarget.previousSibling as HTMLInputElement;
      if (previous) {
        focusWithSelectAll(previous);
      }
    } else if (!regex.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <CodeInputStyled>
      <label
        className="form-label"
        htmlFor={id}
      >
        {label}
      </label>
      <div className="inputs-wrapper">
        {codeArray.map((number: number, index: number) => (
          <input
            key={`code-input-${id}-${index}`}
            className={error ? "number-box red" : "number-box"}
            onChange={(e) => onInputsChange(e, index)}
            onKeyDown={onKeyDownTest}
            maxLength={1}
            disabled={disabled}
            placeholder="-"
          />
        ))}
      </div>

      <p className="error">{error}</p>
    </CodeInputStyled>
  );
}

export default CodeInput;
