enum Routes {
  Home = "/",
  Login = "/login",
  SignUp = "/signup",
  CompleteSignUp = "/completesignup",
  Profile = "/profile",
  NotificationPage = "/notifications",
  Transactions = "/transactions",
  Kyc = "/kyc",
  CreateTournament = "/createtournament",
  HowItWorks = "/howitworks",
  Tournament = "/tournament",
  MyGames = "/mygames",
  SeriesGame = "/seriesGame",
  ChangeAccountNumber = "/changeaccountnumber",
  Bonus = "/bonus",
  ForgetPassword = "/forget",
  PlayersList = "/players",
  ComingSoon = "/coming-soon",
  CurrentTournament = "/external-tournaments-current",
  FutureTournaments = "/external-tournaments-future",
  FinishedTournaments = "/external-tournaments-finished",
}

export default Routes;
