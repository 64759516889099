import React, { useEffect, useRef, useState } from "react";
import TooltipStyled from "./Tooltip.style";

export interface TooltipProps {
  label: string;
  tooltipText: string;
  tooltipTextFinish?: string;
  onLabelClick?: () => void;
  width?: string;
  labelClassName?: string;
  tooltipClassName?: string;
  icon?: string;
}

function Tooltip({ label, tooltipText, tooltipTextFinish, onLabelClick, width, labelClassName = "", tooltipClassName = "", icon }: TooltipProps) {
  const [text, setText] = useState(tooltipText);
  const tooltipRef = useRef<HTMLButtonElement | null>(null);

  const onClick = () => {
    if (onLabelClick) {
      onLabelClick();
    }
    if (tooltipTextFinish) {
      setText(tooltipTextFinish);
    }
  };

  useEffect(() => {
    if (text === tooltipText) {
      return;
    }
    function onClickOutside(event: any) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setText(tooltipText);
      }
    }
    document.addEventListener("mousedown", onClickOutside);
    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, [tooltipRef, text]);

  return (
    <TooltipStyled width={width}>
      <button
        ref={tooltipRef}
        type="button"
        className={`tooltip-label ${labelClassName}`}
        onClick={() => onClick()}
      >
        {label}
        {icon && (
          <img
            src={icon}
            alt="icon"
            className="tooltip-icon"
          />
        )}
      </button>
      <span className={`tooltip-text ${tooltipClassName}`}>{text}</span>
    </TooltipStyled>
  );
}

export default Tooltip;
