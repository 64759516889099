import styled from "styled-components";

export type OptionsPosition = "left" | "right";

const DropdownStyled = styled.div<{ borderVisible?: boolean; optionsPosition?: OptionsPosition }>`
  position: relative;
  .dropdown-title {
    border: none;
    background-color: transparent;
  }

  .options {
    position: absolute;
    left: ${({ optionsPosition }) => (optionsPosition === "left" ? "0" : null)};
    right: ${({ optionsPosition }) => (optionsPosition === "right" ? "0" : null)};
    background-color: ${({ theme }) => theme.colors.black};
    border-color: ${({ theme }) => theme.colors.blue};
    border: ${({ borderVisible }) => (borderVisible ? "1px solid" : null)};
    border-image-slice: 1 !important;
    border-image-source: ${({ theme }) => theme.borderImages.blue};
    padding: ${({ borderVisible }) => (borderVisible ? "5px" : 0)};
    margin-top: 5px;
    width: 325px;
  }

  .option {
    color: ${({ theme }) => theme.colors.grey};
    font-size: ${({ theme }) => theme.fontSizes.caption};
    padding: 5px;
    margin: 0;
    border: none;
    background-color: transparent;
    display: block;
  }

  .option::first-letter {
    text-transform: uppercase;
  }

  .option:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export default DropdownStyled;
