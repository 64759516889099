import React from "react";
import { Button } from "react-bootstrap";
import PopularGamesStyled from "./PopularGames.style";
import PopularGamesSection from "../../common/gamesSection/GamesSection";
import useWallets from "../../../hooks/useWallets/useWallets";

function PopularGames() {
  const { depositWallet } = useWallets();

  return (
    <PopularGamesStyled>
      <div className="popular-games-section">
        <div className="section-headings">
          <h3>
            <span>Gry, które Rządzą Arena</span> - Najgorętsze Tytuły E-sportowe
          </h3>
          <p>Odkryj gry, które podbijają świat e-sportu i stań się częścią emocjonujących rywalizacji i wydarzeń.</p>
          <Button className="blue-btn headings-button">Zobacz wszystkie</Button>
        </div>
        <PopularGamesSection
          gameButtonType="game-modes"
          depositWallet={depositWallet}
        />
      </div>
    </PopularGamesStyled>
  );
}

export default PopularGames;
