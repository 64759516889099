import React from "react";
import { useTranslation } from "react-i18next";
import PageContent from "../pageContent.type";
import NotEnoughMoneyModalStyled from "./NotEnoughMoneyModal.style";
import Button from "../../components/common/buttons/button/Button";

interface NotEnoughMoneyModalProps {
  onClose: () => void;
}

function NotEnoughMoneyModal({ onClose }: NotEnoughMoneyModalProps) {
  const translations: PageContent = useTranslation().t("choosestake", { returnObjects: true });

  return (
    <NotEnoughMoneyModalStyled>
      <div className="wrapper">
        <h2 className="title">{translations.not_enough_money_title}</h2>
        <div className="description">
          <p>{translations.not_enough_money_description1}</p>
          <p>{translations.not_enough_money_description2}</p>
        </div>
        <div className="buttons">
          <Button
            type="labelButton"
            label={translations.not_enough_money_topup_button}
            width="180px"
            onClick={() => {}}
          />
          <Button
            type="labelButton"
            label={translations.not_enough_money_change_stake_button}
            onClick={onClose}
            variant="blue"
            width="180px"
          />
        </div>
      </div>
    </NotEnoughMoneyModalStyled>
  );
}

export default NotEnoughMoneyModal;
