import { Action, PaginatedAction } from "../action.type";
import { GetUserFriendsQuery, Friend, GetUserFriendsInvitationsQuery, FriendInvitation, RemoveFriendActionPayload, RemoveInvitationPayload, PublicUser, InviteFriendPayload, GetUserQuery } from "./friendActions.type";

export function getUserFriends(params?: GetUserFriendsQuery): PaginatedAction<Friend> {
  const query: Record<string, unknown> & { pageSize: number } = {
    page: params?.page || 1,
    pageSize: params?.pageSize || 10,
  };
  return {
    method: "GET",
    endpoint: "/user/friends",
    query,
  };
}

export function getUserFriendsInvitations(params?: GetUserFriendsInvitationsQuery): PaginatedAction<FriendInvitation> {
  const query: Record<string, unknown> & { pageSize: number } = {
    page: params?.page || 1,
    pageSize: params?.pageSize || 10,
    direction: params?.direction,
  };
  return {
    method: "GET",
    endpoint: "/user/friends/invitations",
    query,
  };
}

export function acceptFriendInvitation(id: string): Action<void> {
  return {
    method: "POST",
    endpoint: `/user/friends/invitations/${id}/accept`,
  };
}

export function rejectFriendInvitation(id: string): Action<void> {
  return {
    method: "POST",
    endpoint: `/user/friends/invitations/${id}/reject`,
  };
}

export function removeFriend(payload: RemoveFriendActionPayload): Action<void> {
  return {
    method: "POST",
    endpoint: `/user/friends/remove`,
    payload,
  };
}

export function removeInvitation(payload: RemoveInvitationPayload): Action<void> {
  return {
    method: "POST",
    endpoint: `/user/friends/invitations/${payload.invitationId}/remove`,
    payload,
  };
}

export function getUsers(params?: GetUserQuery): PaginatedAction<PublicUser> {
  const query: Record<string, unknown> & { pageSize: number } = {
    page: params?.page || 1,
    pageSize: params?.pageSize || 10,
    nickname: params?.nickname || undefined,
    rankFrom: params?.rankFrom || undefined,
    rankTo: params?.rankTo || undefined,
    country: params?.country || undefined,
    ignoreOffline: params?.ignoreOffline,
  };
  return {
    method: "GET",
    endpoint: "/user",
    query,
  };
}

export function inviteUser(payload: InviteFriendPayload): Action<void> {
  return {
    method: "POST",
    endpoint: "/user/friends/invite",
    payload,
  };
}
