import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./deposit.css";
import { Form, Button, InputGroup } from "react-bootstrap";
import querString from "query-string";
import Alert from "react-bootstrap/Alert";
import { userInstance } from "../../axios/axiosconfig";
import { convertAmountToOtherCurrencies } from "../../function/function";
import Routes from "../../config/routes";
import { getPageContent } from "../../helpers/content";
import PageContent from "./pageContent.type";
import useLanguage from "../../hooks/useLanguage/useLanguage";

function Deposit() {
  const navigation = useNavigate();
  const currency = localStorage.getItem("currency");
  const [amount, setAmount] = useState<number>();
  const [showStatus, setShowStatus] = useState<string>();
  const [pageContent, setPageContent] = useState<PageContent>();
  const [errMsg, setErrMsg] = useState("");

  const page = "/addmoney";
  const { shortLanguage } = useLanguage();

  useEffect(() => {
    const paymentStatus = querString.parse(window.location.search);
    if (paymentStatus.payment === "success" || paymentStatus.payment === "fail") {
      setShowStatus(paymentStatus.payment);
      setTimeout(() => {
        navigation(Routes.Home);
      }, 4000);
    }
    if (!shortLanguage) {
      return;
    }
    getPageContent(page, shortLanguage).then((data) => setPageContent(data));
  }, []);

  const payment = async (e: React.FormEvent) => {
    e.preventDefault();
    if (amount) {
      if (!currency) {
        return;
      }
      const result = await convertAmountToOtherCurrencies(amount, currency);
      const payload = {
        totalPrice: result,
      };
      const res = await userInstance.post("/payment", payload);
      if (res.data.code === 200) {
        navigation(res.data.data);
      }
    } else {
      setErrMsg(pageContent?.notification1 || "");
    }
  };

  const handlechange = (e: React.FormEvent) => {
    const target = e.target as HTMLButtonElement;
    setAmount(Number(target.value));
  };

  if (!pageContent) {
    return <div>Not Found</div>;
  }
  return (
    <div className="deposit">
      <div className="container">
        <div className="deposite-content">
          <h2>{pageContent.heading1}</h2>

          <div className="deposit-p">
            {showStatus && showStatus === "success" ? (
              <Alert
                variant="success"
                className="succ-box"
              >
                <Alert.Heading>{pageContent.heading2}</Alert.Heading>
                <p>{pageContent.redirect}</p>
              </Alert>
            ) : (
              ""
            )}
            {showStatus && showStatus === "fail" ? (
              <Alert
                variant="danger"
                className="succ-box"
              >
                <Alert.Heading>{pageContent.heading3}</Alert.Heading>
                <p>{pageContent.redirect}</p>
              </Alert>
            ) : (
              ""
            )}
          </div>
          <Form onSubmit={payment}>
            <Form.Group>
              <label htmlFor="Amount">{pageContent.label1}</label>
              <InputGroup className="mb-3">
                {errMsg === "" ? (
                  <Form.Control
                    type="number"
                    className="deposit-in"
                    value={amount}
                    id="Amount"
                    onChange={handlechange}
                    min={1}
                  />
                ) : (
                  <Form.Control
                    type="number"
                    className="deposit-in"
                    value={amount}
                    id="Amount"
                    onChange={handlechange}
                    min={1}
                  />
                )}
                <InputGroup.Append>
                  <InputGroup.Text id="basic-addon2">{currency}</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
              <p style={{ color: "white" }}>{errMsg}</p>
              {/* <Form.Group controlId='formBasicloginone'>
              <Form.Label htmlFor='Amount'>{content.label1}</Form.Label>
              {errMsg === '' ? (
                <Form.Control
                  type='number'
                  autocomplete='off'
                  className='deposit-in'
                  value={amount}
                  id='Amount'
                  onChange={handlechange}
                  min={1}
                />
              ) : (
                <>
                  <Form.Control
                    type='number'
                    autocomplete='off'
                    className='deposit-in'
                    value={amount}
                    id='Amount'
                    onChange={handlechange}
                    min={1}
                  />
                  <p style={{ color: 'white' }}>{errMsg}</p>
                </>
              )}
            </Form.Group> */}

              <div className="text-center">
                <Button
                  type="submit"
                  className="red-btn"
                >
                  {pageContent.label2}
                </Button>
              </div>
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Deposit;
