import styled from "styled-components";

const PlayersListFiltersModalStyled = styled.div`
  padding-top: 120px;

  .filters-title {
    font: ${({ theme }) => theme.fonts.mobile.popupH1};
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 16px;
  }

  .filters {
    padding: 0 32px;
  }

  .filters > div {
    padding: 16px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  }

  .filters > div:nth-last-child(1),
  .filters > div:nth-last-child(2) {
    border: none;
  }

  .countries-title {
    font: ${({ theme }) => theme.fonts.mobile.h2};
    text-transform: uppercase;
    text-align: center;
  }

  .countries-wrapper {
    display: flex;
  }

  .countries-wrapper div {
    flex-basis: 50%;
  }

  .checkbox > div {
    margin: 0;
  }

  .online-players {
    border: none;
  }

  .buttons {
    position: fixed;
    bottom: 32px;
    left: 16px;
    right: 16px;
    display: flex;
    padding: 0 16px;
    column-gap: 16px;
  }

  .buttons > button {
    flex-basis: 50%;
  }
`;

export default PlayersListFiltersModalStyled;
