import styled from "styled-components";

const WalletInfoStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: ${({ theme }) => theme.fontSizes.caption};

  & p {
    margin: 0;
  }

  .type::first-letter {
    text-transform: uppercase;
  }

  .amount {
    color: ${({ theme }) => theme.colors.green};
    font-family: ${({ theme }) => theme.fontFamilies.robotoLight};
  }
`;

export default WalletInfoStyled;
