/* eslint-disable import/no-cycle */
import React from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import { AxiosError } from "axios";
import WithdrawMoneyModalStyled from "./WithdrawMoneyModal.style";
import PageContent from "./pageContent.type";
import useAppQuery from "../../hooks/useQuery/useQuery";
import { getBankAccountNumber, withdrawMoney } from "../../API/user/userActions";
import renderFormField from "../../components/common/formComponents/render-form-field";
import Button from "../../components/common/buttons/button/Button";
import useWallets from "../../hooks/useWallets/useWallets";
import formatIBAN from "../../helpers/iban-format";
import { useModal } from "../PageModal.context";
import { MoneyInputInterface } from "../../components/common/formComponents/moneyInput/MoneyInput";
import useAppMutation from "../../hooks/useMutation/useMutation";
import httpStatusHelper from "../../helpers/http-status-helper";
import convertStringToNumber from "../../helpers/number-format";
import useLanguage from "../../hooks/useLanguage/useLanguage";

function WithdrawMoneyModal() {
  const { t } = useTranslation();
  const translations: PageContent = t("withdraw_money", { returnObjects: true });
  const { language } = useLanguage();
  const withdrawMoneyMutation = useAppMutation(withdrawMoney);

  const accountNumberQuery = useAppQuery(getBankAccountNumber());
  const wallets = useWallets();

  const { openChangeAccountNumberModal, closeModal } = useModal();

  const onFormSubmit = async (amount: string) => {
    const amountNum = convertStringToNumber(language, amount);
    if (!wallets.depositWallet?.currency || !accountNumberQuery.data?.accountNumber) {
      return;
    }
    const payload = { amount: amountNum, currency: wallets.depositWallet.currency, bankAccountNumber: accountNumberQuery.data.accountNumber };
    try {
      const response = await withdrawMoneyMutation.mutateAsync(payload);
      if (httpStatusHelper.isSuccess(response.status)) {
        NotificationManager.success(t("common.success"), "Message", 10000);
        closeModal();
        return;
      }
      const errorMessage = response.errorObject.response.data.message;
      NotificationManager.error(t([`errors.${errorMessage}`, "errors.ERROR_OCCURRED"]), "Message", 10000);
    } catch (error: any) {
      NotificationManager.error(t("errors.ERROR_OCCURRED"), "Message", 10000);
      console.error((error as AxiosError).response?.data);
    }
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.string().required(t("form_errors.required")),
  });

  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      onFormSubmit(values.amount);
    },
  });

  const formatFunc = (fieldName: string, value: string) => {
    const convertedNumber = convertStringToNumber(language, value);

    const formattedValue = new Intl.NumberFormat(language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    }).format(convertedNumber);
    formik.setFieldValue(fieldName, formattedValue);
  };

  const inputs: MoneyInputInterface[] = [
    {
      type: "money",
      label: translations.input_label || "",
      id: "withdraw-money-amount",
      placeholder: "0000.00",
      name: "amount",
      value: formik.values.amount,
      onChange: formik.handleChange,
      error: formik.errors.amount || "",
      staticTextRight: wallets.depositWallet?.currency,
      formatFunc,
      locale: language,
    },
  ];

  if (!accountNumberQuery.data) {
    openChangeAccountNumberModal({ currentNumber: undefined });
    return null;
  }

  return (
    <WithdrawMoneyModalStyled>
      <div className="withdraw-container">
        <h2 className="heading">{translations.heading}</h2>
        <p className="text">{translations.description_account_number}</p>
        <p className="account-number">{formatIBAN(accountNumberQuery.data.accountNumber)}</p>
        <p className="text">{translations.description_waiting_time}</p>
        <form
          onSubmit={formik.handleSubmit}
          className="form"
        >
          <div className="inputs">{inputs.map((input) => renderFormField(input))}</div>
          <Button
            type="labelButton"
            label={translations.button_submit}
            variant="blue"
            submitButton
          />
        </form>
        <div className="change-account">
          <span>{translations.change_account_number}</span>
          <span className="arrows">{">>"}</span>
        </div>
        <div className="wallets">
          <span className="wallet-title">{translations.deposit_account}</span>
          <span className="wallet-amount">{wallets.depositWallet?.amount?.toFixed(2)}</span>
          <span>{wallets.depositWallet?.currency}</span>
          <span className="wallet-amount">{(wallets.depositWallet?.amountInUSD || 0).toFixed(2)}</span>
          <span>USD</span>
        </div>
      </div>
    </WithdrawMoneyModalStyled>
  );
}

export default WithdrawMoneyModal;
