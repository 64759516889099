import styled from "styled-components";

const InfoBoxStyled = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: 25px;

  .info-box {
    padding-left: 20px;
    font-size: ${({ theme }) => theme.fontSizes.caption};
  }

  .info-box-title {
    font-family: ${({ theme }) => theme.fontFamilies.molot};
  }

  img {
    width: 100px;
  }
`;

export default InfoBoxStyled;
