/* eslint-disable import/no-cycle */
import React from "react";
import ColumnHeadingStyled from "./ColumnHeading.style";
import { TableInput } from "../TableRow/TableRow";
import unicodeLibrary from "../../../helpers/unicode-library";
import { Sort } from "../Filter/Filter";

interface ColumnHeadingProps<T> {
  sort?: Sort;
  setSort: (newSort: Sort | undefined) => void;
  item: TableInput<T>;
}

function ColumnHeading<T>({ sort, setSort, item }: ColumnHeadingProps<T>) {
  const field = item.component.field?.toString();

  const onSortableColumnClick = () => {
    if (!field) {
      return;
    }
    if (!sort || field !== sort.field) {
      setSort({ field, direction: "asc" });
    } else if (sort.direction === "asc") {
      setSort({ ...sort, direction: "desc" });
    } else if (sort.direction === "desc") {
      setSort(undefined);
    } else {
      setSort({ ...sort, direction: "asc" });
    }
  };

  const generateSortableColumnName = (heading: string | undefined, isSortable: boolean | undefined) => {
    if (!isSortable) {
      return <p className="column-name-icon">{heading}</p>;
    }

    let icon;
    let className = "sort-icon";

    if (!sort || field !== sort.field) {
      icon = unicodeLibrary.upDownArrow;
      className = "sort-icon-hidden";
    } else if (sort.direction === "asc") {
      icon = unicodeLibrary.upwardsArrow;
    } else if (sort.direction === "desc") {
      icon = unicodeLibrary.downwardsArrow;
    }

    return (
      <button
        type="button"
        onClick={() => onSortableColumnClick()}
        className="table-column-button"
      >
        <p className="column-name-icon">
          {heading} <span className={className}>{icon}</span>
        </p>
      </button>
    );
  };

  return (
    <ColumnHeadingStyled
      className="table-column-name"
      width={item.component.width}
    >
      {generateSortableColumnName(item.heading, item.isSortable)}
    </ColumnHeadingStyled>
  );
}

export default ColumnHeading;
