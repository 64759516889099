import { SupportedCountry } from "../API/user/userActions.type";

interface Country {
  label: string;
  value: SupportedCountry;
}

const COUNTRIES: Country[] = [
  { label: "Poland", value: SupportedCountry.Poland },
  { label: "United Kingdom", value: SupportedCountry.UnitedKingdom },
];

export const COUNTRY_CODES = [
  { code: "PL", country: SupportedCountry.Poland },
  { code: "GB", country: SupportedCountry.UnitedKingdom },
];

export default COUNTRIES;
