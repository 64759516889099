import axios from "axios";
import { server, cmsServer } from "../config/config";

// for disposal in the future
const getToken = () => {
  const token = localStorage.getItem("dod:auth");
  if (!token) {
    return null;
  }
  const parsedToken = JSON.parse(token);
  return parsedToken;
};
const token = getToken();

export const userInstance = axios.create({
  baseURL: `${server}/v1`,
  withCredentials: true,
  headers: { authorization: `Bearer ${token?.accessToken}` },
});

export const adminInstance = axios.create({
  baseURL: `${server}/admin`,
  withCredentials: true,
});

export const cmsInstance = axios.create({
  baseURL: `${cmsServer}/cms`,
  withCredentials: true,
});

// INTERCEPTORS

// temporary fix for access token issues
userInstance.interceptors.request.use((request) => {
  request.headers = { authorization: `Bearer ${getToken()?.accessToken}` };

  return request;
});
// end of temporary fix

userInstance.interceptors.response.use((response) => {
  // check if access token is invalid
  if (response.status === 401) {
    userInstance.post("/auth/refreshToken", { refreshToken: localStorage.getItem("refreshToken") || "" }).then((res) => {
      localStorage.setItem("accessToken", res.data.accessToken);
      const { method, data } = res.config;
      const url = res.request.responseURL;
      return userInstance({ method, url, data });
    });
  }
  return response;
});
