import { useEffect } from "react";

function useClickedOutsideEffect<T extends HTMLElement>(ref: React.MutableRefObject<T | null>, onClickOutside: () => void) {
  return useEffect(() => {
    function onClickOutsideListener(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }
    document.addEventListener("mousedown", onClickOutsideListener);
    return () => {
      document.removeEventListener("mousedown", onClickOutsideListener);
    };
  }, []);
}

export default useClickedOutsideEffect;
