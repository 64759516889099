import React from "react";
import { ReactComponent as EditIcon } from "../../../assets/images/profile/edit-icon.svg";
import { ReactComponent as FilterIcon } from "../../../assets/images/icons/filter-icon.svg";
import { IconVariants } from "./Icon.types";
import baseTheme from "../../../themes";

const handleColorType = (color: string | undefined) => {
  switch (color) {
    case "red":
      return baseTheme.colors.red;
    case "blue":
      return baseTheme.colors.blue;
    case "black":
      return baseTheme.colors.black;
    case "white":
    default:
      return baseTheme.colors.white;
  }
};

interface IconProps {
  variant: IconVariants;
  color?: string;
  className?: string;
  onClick?: () => void;
}

function Icon({ variant, color, className, onClick }: IconProps) {
  switch (variant) {
    case "edit":
      return (
        <EditIcon
          stroke={handleColorType(color)}
          className={className}
          onClick={() => onClick && onClick()}
        />
      );
    case "filter":
      return (
        <FilterIcon
          stroke={handleColorType(color)}
          className={className}
          onClick={() => onClick && onClick()}
        />
      );
    default:
      return null;
  }
}

export default Icon;
