import React from "react";
import "./features.css";

function FeatureBox({ header, paragraph }) {
  return (
    <div className="col-md-5">
      <div className="feature-box">
        <h3>{header}</h3>
        <p>{paragraph}</p>
      </div>
    </div>
  );
}

export default FeatureBox;
