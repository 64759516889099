import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PageContent from "../pageContent.type";
import "./registrationForm.css";
import discordIcon from "../../../../assets/images/icons/current-tournament/discord-icon.svg";
import instagramIcon from "../../../../assets/images/icons/current-tournament/instagram-icon.svg";
import twitterIcon from "../../../../assets/images/icons/current-tournament/twitter-icon.svg";
import { userInstance } from "../../../../axios/axiosconfig";
import RegistrationToast from "./registration-toast";
import UserAlreadyRegistered from "./userAlreadyRegistered";

interface RegistrationFormProps {
  gameName: string;
  tournamentId: string;
  sideImageUrl: string;
  redirectUrl: string;
  userRegistered: boolean;
  tournamentName: string;
}

interface RegistrationFormPayload {
  gameNick: string;
  discordName: string;
  promoCode: string;
}

function RegistrationForm({ gameName, tournamentId, sideImageUrl, redirectUrl, userRegistered, tournamentName }: RegistrationFormProps) {
  const translations: PageContent = useTranslation().t("currentTournament", { returnObjects: true });
  const [gameNick, setGameNick] = useState<string>("");
  const [discordName, setDiscordName] = useState<string>("");
  const [promoCode, setPromoCode] = useState<string>("");
  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const registerUser = async (e: React.FormEvent) => {
    e.preventDefault();

    const payload: RegistrationFormPayload = {
      gameNick,
      discordName,
      promoCode,
    };

    try {
      await userInstance.post(`/external-tournaments/${tournamentId}/sign-in`, payload);
      setShowToast(true);

      if (redirectUrl) {
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          window.open(redirectUrl, "_blank");
        }, 5000);
      }

      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  const openDiscordUrl = () => {
    window.open("https://discord.com/invite/ggdab", "_blank");
  };

  const openInstagramUrl = () => {
    window.open("https://www.instagram.com/ggdab_com", "_blank");
  };

  const openTwitterUrl = () => {
    window.open("https://x.com/ggdab_com", "_blank");
  };

  return userRegistered || showToast ? (
    <div>
      <UserAlreadyRegistered
        tournamentName={tournamentName}
        redirectUrl={redirectUrl}
        showModal={showModal}
      />
      <RegistrationToast show={showToast} />
    </div>
  ) : (
    <div className="registration-form">
      <div className="side-image">
        <img
          alt="side_image"
          src={sideImageUrl}
        />
      </div>
      <div className="form-wrapper">
        <h3>{translations.registrationFormIntro}</h3>
        <p>
          {translations.statuteAndRules}{" "}
          <a
            target="_blank"
            href="https://discord.com/invite/ggdab"
            rel="noreferrer"
          >
            {translations.discordGroupRulesLinkLabel}
          </a>
        </p>

        <Form onSubmit={registerUser}>
          <Form.Group>
            <div className="registration-form-inputs">
              <div>
                <label htmlFor="gameNick">
                  {translations.gameNick}
                  {gameName}
                </label>
                <Form.Control
                  type="string"
                  id="gameNick"
                  placeholder={`${translations.enter} ${translations.gameNick}${gameName}`}
                  onChange={(event) => setGameNick(event.target.value)}
                  required
                />
              </div>
              <div>
                <label htmlFor="discordName">{translations.discordNick}</label>
                <Form.Control
                  type="string"
                  id="discordName"
                  placeholder={`${translations.enter} ${translations.discordNick}`}
                  onChange={(event) => setDiscordName(event.target.value)}
                  required
                />
              </div>
              <div>
                <label htmlFor="promoCode">{translations.promoCode}</label>
                <Form.Control
                  type="string"
                  id="promoCode"
                  placeholder={`${translations.enter} ${translations.promoCode}`}
                  onChange={(event) => setPromoCode(event.target.value)}
                  required
                />
              </div>
            </div>

            <div className="join-and-follow-section">
              <p className="join-label">{translations.joinToRegister}</p>
              <p className="follow-label">{translations.followToRegister}</p>
              <button
                className="discord-btn"
                onClick={openDiscordUrl}
                type="button"
              >
                <img
                  alt="discord_button"
                  className="btn-icon"
                  src={discordIcon}
                />
                <span className="btn-label">{translations.discordGroupLabel}</span>
              </button>
              <button
                className="instagram-btn"
                onClick={openInstagramUrl}
                type="button"
              >
                <img
                  alt="instagram_icon"
                  className="btn-icon"
                  src={instagramIcon}
                />
                <span className="btn-label">{translations.instagramLabel}</span>
              </button>
              <button
                className="twitter-btn"
                onClick={openTwitterUrl}
                type="button"
              >
                <img
                  alt="twitter_icon"
                  className="btn-icon"
                  src={twitterIcon}
                />
                <span className="btn-label">{translations.twitterLabel}</span>
              </button>
              <div className="rodo">
                <input
                  type="checkbox"
                  name="rodo"
                  id="rodo"
                  required
                />
                <label htmlFor="rodo">*{translations.rodo}</label>
              </div>
              <div className="registration-button">
                <Button
                  type="submit"
                  className="red-btn"
                >
                  {translations.join}
                </Button>
              </div>
            </div>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
}

export default RegistrationForm;
