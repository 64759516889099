import styled from "styled-components";

const GallerySliderStyled = styled.div<{ isMainSlider: boolean; minWidth: string; flexGrow: number; flexShrink: number }>`
  height: ${({ isMainSlider }) => (isMainSlider ? "470px" : "250px")};
  min-width: ${({ isMainSlider, minWidth }) => (isMainSlider ? "auto" : minWidth)};
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex-shrink: ${({ flexShrink }) => flexShrink};
  width: 100%;
  flex-basis: ${({ minWidth }) => minWidth};

  .slide {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
  }

  .slide:hover {
    cursor: pointer;
  }

  .slick-slide * {
    height: 100%;
  }

  .slick-slider,
  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slide img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .tile-slider-one {
    padding-right: 0px;
  }
  .slick-dots li.slick-active button,
  .slick-dots li.slick-active button:hover,
  .slick-dots li.slick-active button:focus {
    background-color: transparent !important;
  }

  .slick-dots {
    bottom: -40px;
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots li button:before {
    color: white;
    font-size: 10px;
    opacity: 0.35;
  }

  .slick-dots li.slick-active button:before {
    font-size: 20px;
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    height: ${({ isMainSlider }) => (isMainSlider ? "200px" : "130px")};

    .slick-dots {
      bottom: -50px;
    }
  }
`;

export default GallerySliderStyled;
