/* eslint-disable import/no-cycle */
import React from "react";
import { useTranslation } from "react-i18next";
import RegistrationSuccessModalStyled from "./RegistrationSuccessModal.style";
import Button from "../../components/common/buttons/button/Button";
import { useModal } from "..";

interface PageContent {
  success_page_title: string;
  success_page_button: string;
  success_page_description: string;
}

interface RegistrationSuccessModalProps {
  onClose?: () => void;
}

function RegistrationSuccessModal({ onClose }: RegistrationSuccessModalProps) {
  const translations: PageContent = useTranslation().t("signup", { returnObjects: true });
  const { closeModal } = useModal();

  return (
    <RegistrationSuccessModalStyled>
      <div className="wrapper">
        <h1>{translations.success_page_title}</h1>
        <p className="registration-success-description">{translations.success_page_description}</p>
        <div className="text-center">
          <Button
            type="labelButton"
            variant="red"
            label={translations.success_page_button}
            onClick={() => {
              closeModal();
              onClose?.();
            }}
          />
        </div>
      </div>
    </RegistrationSuccessModalStyled>
  );
}

export default RegistrationSuccessModal;
