import React from "react";
import ModeButtonStyled from "./ModeButton.style";
import { ModeButtonVariant } from "./ModeButton.type";

export interface ModeButtonProps {
  icon: string;
  label: string;
  variant?: ModeButtonVariant;
  onClick: () => void;
  modeButtonsVisible: boolean;
  isAvailable: boolean;
}

function ModeButton({ icon, label, variant, onClick, modeButtonsVisible, isAvailable }: ModeButtonProps) {
  if (!isAvailable) {
    return null;
  }
  return (
    <ModeButtonStyled
      className={modeButtonsVisible ? "circle-hidden circle" : "circle-hidden"}
      onClick={() => onClick()}
      variant={variant}
    >
      <div className={modeButtonsVisible ? "expandable-container hover" : "expandable-container"}>
        <div className="icon-container">
          <img
            src={icon}
            alt="icon"
          />
        </div>
        <div className="hidden">{label}</div>
      </div>
    </ModeButtonStyled>
  );
}

export default ModeButton;
