import styled from "styled-components";

const RankIconStyled = styled.div`
  line-height: 12px;
  height: 12px;
  position: relative;

  .rank-icon {
    vertical-align: top;
  }

  .rank-value {
    position: absolute;
    top: 0;
    left: 0;
    font: ${({ theme }) => theme.fonts.mobile.rank};
    width: 12px;
    height: 12px;
    line-height: 12px;
    text-align: center;
    margin: 0;
  }
`;

export default RankIconStyled;
