import React from "react";
import "./smallTournamentCounter.css";
import useCountdown from "../../currentTournament/counter/countDownHook";

function SmallTournamentCounter({ startDate }: { startDate: string }) {
  const tournamentDate = new Date(startDate);
  const [days, hours, minutes, seconds] = useCountdown(tournamentDate.getTime());

  if (days + hours + minutes + seconds <= 0) {
    return null;
  }

  return (
    <div className="small-counter-wrapper">
      <div className="counter">
        <span className="counter-number">{String(days).padStart(2, "0")}</span>:<span className="counter-number">{String(hours).padStart(2, "0")}</span>:<span className="counter-number">{String(minutes).padStart(2, "0")}</span>:
        <span className="counter-number">{String(seconds).padStart(2, "0")}</span>
      </div>
    </div>
  );
}

export default SmallTournamentCounter;
