import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import "./withdraw.css";
import { userInstance } from "../../axios/axiosconfig";
import Layout from "../layout";
import Bet from "../home/Bet/Bet";
import Popup from "../home/popup";

function SeriesGame() {
  const [matched, setMatched] = useState(false);
  const roomId = null;
  const [playerOne, setPlayerOne] = useState(null);
  const playerTwo = null;
  const [bet, setBet] = useState(false);
  const [betAmount, setBetAmount] = useState(null);
  const [wallettype, setWallettype] = useState(null);

  useEffect(() => {
    const getUserProfile = async () => {
      const response = await userInstance.get("/getprofile");
      if (response.data.code === 200) {
        setPlayerOne(response.data.profile_data);
      }
    };
    getUserProfile();
  }, []);
  const handlePlay = () => {
    setBet(true);
  };
  const matchRoom = async (amount, walletType) => {
    // const res = await adminInstance.post("/getCommission");
    // const cutOff = ((res.data.rate * amount) / 100).toFixed(2);
    // const response = await userInstance.post("/getprofile");

    setBetAmount(amount);
    setBet(false);
    setWallettype(walletType);
    setMatched(true);
  };
  const CloseBetComponent = () => {
    setBet(false);
  };

  return (
    <Layout>
      <div className="withdraw">
        <div className="container">
          <div className="withdraw-content">
            {matched && (
              <Popup
                roomId={roomId}
                setMatched={setMatched}
                playerOne={playerOne}
                playerTwo={playerTwo}
                amount={betAmount}
                wallettype={wallettype}
              />
            )}
            {bet && (
              <Bet
                matchRoom={matchRoom}
                CloseBetComponent={CloseBetComponent}
              />
            )}

            <h3>Bouns Wallet amount transfer to deposit Wallet</h3>
            <p>
              1. Bonus wallet amount can not be withdrwan, It can transfer to deposit wallet only.
              <br />
              2. To transfer bonus amount to deposit amount you have to win three game with
              <br />
              same bet amount. 3. All bet amount deducted from Deposit wallet. So please set your active wallet as Deposit 4. After wining 3 games continuously with same bet amount X % of Bonus will be transfer to your deposit wallet
            </p>
            <Button
              type="button"
              className="blue-btn"
              onClick={handlePlay}
            >
              Play
            </Button>
            {"  "}
            <Button
              type="button"
              className="red-btn"
            >
              <Link to="/profile">Cancel</Link>
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SeriesGame;
