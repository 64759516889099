import styled from "styled-components";
import { forDesktop } from "../../../helpers/breakpoints/viewports";

const BonusItemActivatedStyled = styled.div`
  width: 100%;
  padding-top: 60px;
  position: relative;
  margin-bottom: 40px;

  .item-wrapper-active {
    height: 330px;
    width: 100%;
    background: linear-gradient(to left bottom, rgba(0, 0, 0, 0.8) 50%, rgba(87, 189, 245, 0.15) 50%);
    border: 1px solid ${({ theme }) => theme.colors.white};
  }

  .title-to-win-active {
    padding-top: 66px;
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.body};
    text-align: center;
    letter-spacing: 0.15em;
    margin-bottom: 7px;
  }

  .amount-to-win-wrapper {
    position: relative;
    max-width: 120px;
    margin: auto;
  }

  .amount-to-win-activated {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.h4};
    letter-spacing: 0.15em;
    padding-bottom: 11px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.blue};
    text-align: center;
  }

  .activate {
    width: 142px;
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.h3};
    line-height: 28px;
    text-align: center;
    margin: 20px auto;
  }

  .coin {
    height: 108px;
    width: 108px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .coin-img {
    width: 100%;
  }

  .won-amount {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.body};
    text-align: center;
    letter-spacing: 0.15em;
    margin-bottom: 7px;
  }

  .won-amount-bold {
    font-weight: 500;
    padding-left: 5px;
  }

  .matches-list {
    max-width: 70%;
    margin: 0 auto 19px;
  }

  .win-loose-title {
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.body};
    text-align: center;
    letter-spacing: 0.15em;
    margin-bottom: 7px;
  }

  .win-loose-date {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.body};
    text-align: center;
    letter-spacing: 0.15em;
  }

  .progress-bar {
    height: 6px;
    width: 100%;
    background-color: #d9d9d9;
    position: absolute;
    bottom: 0;
    left: 0px;
  }

  .red {
    border-color: #ff0000;
  }

  .green {
    border-color: #52ff00;
  }

  @media ${forDesktop} {
    width: 275px;
    margin-bottom: 0;
  }
`;
export default BonusItemActivatedStyled;
