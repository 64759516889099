import React from "react";
import Table from "../../table/Table";
import useGamesTable from "./useGamesTable";

interface GamesTableProps {
  pageSize?: number;
  includeFilters?: boolean;
  includeSort?: boolean;
  includePagination?: boolean;
}

function GamesTable({ includeFilters = true, includeSort = true, pageSize = 10, includePagination = true }: GamesTableProps) {
  const { data, inputs, onSort, filters, fetchNextPage, hasNextPage, isLoading } = useGamesTable({ pageSize, includeFilters, includeSort });

  return (
    <Table
      data={data}
      inputs={inputs}
      onSort={onSort}
      filters={filters}
      includePagination={includePagination}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isLoading={isLoading}
    />
  );
}

export default GamesTable;
