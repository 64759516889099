import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getUserFriendsInvitations } from "../../API/friend/friendActions";
import { FriendInvitation } from "../../API/friend/friendActions.type";
import useInfinitePaginationWithIntersect from "../../hooks/useInfinitePaginationWithIntersect/useInfinitePaginationWithIntersect";
import Friend from "./friend/Friend";
import ReceivedInvitationButtons from "./components/ReceivedInvitationButtons";
import SentInvitationButtons from "./components/SentInvitationButtons";

interface UseInvitationsAccordionProps {
  direction: "incoming" | "outgoing";
}

function useInvitationsAccordion({ direction }: UseInvitationsAccordionProps) {
  const { t } = useTranslation();

  const { items, lastElementRef } = useInfinitePaginationWithIntersect(
    getUserFriendsInvitations({
      pageSize: 4,
      direction,
    })
  );

  const generateActionButton = useCallback(
    (invitationId: string) => {
      if (direction === "incoming") {
        return <ReceivedInvitationButtons invitationId={invitationId} />;
      }

      if (direction === "outgoing") {
        return <SentInvitationButtons invitationId={invitationId} />;
      }

      return undefined;
    },
    [t]
  );

  const componentCreator = (item: FriendInvitation, index: number) => (
    <Friend
      key={`invitation-${item.invitationId}`}
      playerId={item.userId}
      username={item.nickname}
      avatarUrl={item.avatarUrl || null}
      actionButtons={generateActionButton(item.invitationId)}
      additionalOptions={[]}
      innerRef={items?.length === index + 1 ? lastElementRef : null}
      status={item.status}
      hasPendingInvitation={direction === "outgoing"}
    />
  );

  return { items, componentCreator };
}

export default useInvitationsAccordion;
