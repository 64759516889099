import React, { RefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import FriendsStyled from "./Friends.style";
import { closeIcon } from "../../assets/images";
import SearchBar from "../common/searchBar/SearchBar";
import Accordion from "../Accordion/Accordion";
import useFriendsAccordion from "./useFriendsAccordion.hook";
import useInvitationsAccordion from "./useInvitationsAccordion.hook";

interface FriendsProps {
  innerRef: RefObject<HTMLDivElement>;
}

function Friends({ innerRef }: FriendsProps) {
  const { t } = useTranslation();
  const [isSearchBarActive, setIsSearchBarActive] = useState<boolean>();

  const { friendsItems, componentCreator: createFriends } = useFriendsAccordion();
  const { items: incomingInvitations, componentCreator: createIncomingInvitations } = useInvitationsAccordion({ direction: "incoming" });
  const { items: outgoingInvitations, componentCreator: createOutgoingInvitations } = useInvitationsAccordion({ direction: "outgoing" });

  const checkIfFriendsAreOpen = () => !!(friendsItems && friendsItems.length > 0);

  const checkIfInvitationsAreOpen = () => !checkIfFriendsAreOpen() && !!(outgoingInvitations && outgoingInvitations?.length > 0);
  const checkOpenedItem = () => {
    if (checkIfFriendsAreOpen()) {
      return t("friends.friends");
    }
    if (checkIfInvitationsAreOpen()) {
      return t("friends.received_invitations");
    }
    return null;
  };

  const accordionItems = [
    {
      title: t("friends.friends"),
      notificationDot: false,
      data: friendsItems || [],
      componentCreator: createFriends,
    },
    {
      title: t("friends.received_invitations"),
      notificationDot: false,
      data: incomingInvitations || [],
      componentCreator: createIncomingInvitations,
    },
    {
      title: t("friends.sent_invitations"),
      notificationDot: false,
      data: outgoingInvitations || [],
      componentCreator: createOutgoingInvitations,
    },
  ];

  return (
    <FriendsStyled>
      <div
        className="top-bar"
        ref={innerRef}
      >
        <img
          src={closeIcon}
          className={isSearchBarActive ? "icon icon-hidden" : "icon"}
          alt=""
        />
        <img
          src={closeIcon}
          className={isSearchBarActive ? "icon icon-hidden" : "icon"}
          alt=""
        />
        <SearchBar
          checkIsActive={(val: boolean) => setIsSearchBarActive(val)}
          placeholder={t("common.nickname")}
        />
      </div>
      <Accordion
        accordionItems={accordionItems}
        openedItem={checkOpenedItem()}
      />
    </FriendsStyled>
  );
}

export default Friends;
