import styled from "styled-components";

const NotificationModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .title {
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.h4};
    margin-bottom: 45px;
    text-align: center;
  }

  .description {
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-size: ${({ theme }) => theme.fontSizes.body};
    text-align: center;
    margin-bottom: 49px;
  }
`;

export default NotificationModalStyled;
