import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FinishedTournamentData from "./finishedTournamentData.type";
import "./finishedTournaments.css";
import useAuth from "../../../hooks/useAuth/useAuth";
import { userInstance } from "../../../axios/axiosconfig";
import Routes from "../../../config/routes";
import FinishedTournament from "./finishedTournament/finishedTournament";
import Footer from "../../footer/Footer";

export default function FinishedTournaments() {
  const [finishedTournamentData, setFinishedTournamentData] = useState<FinishedTournamentData[]>();
  const navigation = useNavigate();
  const isAuth = useAuth();

  const loadFinishedTournamentsData = async () => {
    if (isAuth) {
      try {
        const tournamentsData = await userInstance.get("external-tournaments/finished");
        return tournamentsData;
      } catch (err) {
        // console.log(err);
      }
    }
    return {} as any;
  };

  useEffect(() => {
    if (!isAuth) {
      navigation(Routes.Login);
    }

    loadFinishedTournamentsData().then((tournamentsData) => {
      setFinishedTournamentData(tournamentsData.data);
    });
  }, []);

  return (
    <div className="finished-tournaments-subpage">
      <div className="finished-tournaments-header">
        <h1 className="header">Zakonczone turnieje</h1>
        <p className="header-text">Rywalizuj i triumfuj w różnorodnych turniejach e-sportowych, przeznaczonych dla graczy na każdym poziomie umiejętności.</p>
      </div>
      <div className="finished-tournaments-list">
        <div className="container">
          {finishedTournamentData?.map((tournamentData) => (
            <FinishedTournament
              key={tournamentData.id}
              tournamentData={tournamentData}
            />
          ))}
        </div>
      </div>
      <Footer noSponsors />
    </div>
  );
}
