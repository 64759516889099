const convertStringToNumber = (locale: string, value: string) => {
  const numberWithoutSpaces = value.replace(/\s/g, "");
  let formattedNumber = "";
  if (locale === "en-US" || locale === "en-GB") {
    formattedNumber = numberWithoutSpaces.replace(/[,]/g, "");
  } else {
    formattedNumber = numberWithoutSpaces.replace(/[,]/g, "."); // pl-PL and else
  }
  return Number(formattedNumber);
};

export default convertStringToNumber;
