import React from "react";
import { Badge, ProgressBar } from "react-bootstrap";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import MobileHeaderStyled from "./MobileHeader.style";
import { logoHorizontalWhiteText, logout, playerBadge, xButton } from "../../../assets/images";
import PageContent from "../pageContent.type";
import { ProfileResponse } from "../../../API/user/userActions.type";
import WalletSwitch from "../WalletSwitch/WalletSwitch";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import { authStorage } from "../../../storage/auth/auth.storage";
import Routes from "../../../config/routes";
import AuthContextValue from "../../../context/authContext/AuthContext.types";

interface MobileHeaderProps {
  profilePicture: string;
  profile: ProfileResponse | undefined;
  isAuth: AuthContextValue;
  setIsMobileHeaderCollapsed: (value: boolean) => void;
}

function MobileHeader({ profilePicture, profile, isAuth, setIsMobileHeaderCollapsed }: MobileHeaderProps) {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const translations: PageContent = t("header", { returnObjects: true });

  const onLogOut = () => {
    authStorage.setTokens(null, false);
    NotificationManager.success(translations.notification3, "Message", 4000);
    navigation(Routes.Home);
  };

  return (
    <MobileHeaderStyled>
      {!isAuth && (
        <>
          <div>
            <Link to={Routes.Home}>
              <div className="logo">
                <img
                  src={logoHorizontalWhiteText}
                  alt="logo"
                />
              </div>
            </Link>
            <div className="mobile-nav">
              <NavLink
                className="nav-link"
                to="/"
              >
                {translations.menu1}
              </NavLink>
              <NavLink
                className="nav-link"
                to="/populargames"
              >
                {translations.menu2}
              </NavLink>
              <NavLink
                className="nav-link"
                to="/external-tournaments-current"
              >
                {translations.menu3}
              </NavLink>
              <NavLink
                className="nav-link"
                to="/tournament"
              >
                {translations.menu4}
              </NavLink>
              <NavLink
                className="nav-link"
                to="/howitworks"
              >
                {translations.menu5}
              </NavLink>
              <NavLink
                className="nav-link"
                to="/bonus"
              >
                {translations.menu6}
              </NavLink>
            </div>
          </div>

          <div className="mobile-auth-buttons-container">
            <LanguageDropdown isMobile />

            <button
              type="button"
              className="login-btn"
              onClick={() => navigation(Routes.Login)}
            >
              <span>{translations.login}</span>
            </button>
            <button
              type="button"
              className="signup-btn"
              onClick={() => navigation(Routes.SignUp)}
            >
              <span>{translations.signup}</span>
            </button>
          </div>
        </>
      )}
      {profile && (
        <>
          <div>
            <div className="my-profile-info-container">
              <div
                className="my-profile-info"
                role="presentation"
              >
                <div className={`p-pic ${profile.countryOfResidence}`}>
                  <img
                    src={profilePicture}
                    alt="profile"
                    className="profile-image"
                  />
                </div>
                <div className="p-name">
                  <div className="p-nick">
                    <img
                      src={playerBadge}
                      alt="player badge"
                    />
                    <h5>{profile.nickname}</h5>
                  </div>
                  {profile.status.Banned ? (
                    <div>
                      <Badge
                        className="ac-banned"
                        variant="danger"
                      >
                        <span className="text-light"> {translations.banner}</span>
                      </Badge>
                    </div>
                  ) : (
                    <div>
                      <div className="p-level">
                        <p>Ranga: 13</p>
                        <ProgressBar now={60} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="user-type">{translations.profile_dropdown_regular_user}</div>
            </div>
            <hr />

            <WalletSwitch isMobile />

            <hr />

            <div className="mobile-nav">
              <NavLink
                className="nav-link"
                to="/profile"
              >
                {translations.profile_dropdown_my_profile}
              </NavLink>
              <NavLink
                className="nav-link"
                to="/"
              >
                {translations.menu1}
              </NavLink>
              <NavLink
                className="nav-link"
                to="/populargames"
              >
                {translations.menu2}
              </NavLink>
              <NavLink
                className="nav-link"
                to="/external-tournaments-current"
              >
                {translations.menu3}
              </NavLink>
              <NavLink
                className="nav-link"
                to="/howitworks"
              >
                {translations.profile_dropdown_how_it_works}
              </NavLink>
              <NavLink
                className="nav-link"
                to="/bonus"
              >
                {translations.menu6}
              </NavLink>
              <NavLink
                className="nav-link"
                to="/seriesGame"
              >
                {translations.profile_dropdown_faq}
              </NavLink>
            </div>
          </div>

          <div>
            <LanguageDropdown isMobile />
            <hr />

            <button
              type="button"
              className="logout-btn"
              onClick={onLogOut}
            >
              <div
                className="icon"
                style={{ maskImage: `url(${logout})`, WebkitMaskImage: `url(${logout})` }}
              />
              {translations.profile_dropdown_logout}
            </button>
          </div>
        </>
      )}

      <button
        type="button"
        className="close-btn"
        onClick={() => setIsMobileHeaderCollapsed(true)}
      >
        <img
          src={xButton}
          alt="close button"
        />
      </button>
    </MobileHeaderStyled>
  );
}

export default MobileHeader;
