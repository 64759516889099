import React, { useEffect, useState } from "react";
import { intervalToDuration, Duration } from "date-fns";
import CountdownButtonStyled from "./CountdownButton.style";
import Button from "../button/Button";
import { LabelButton } from "../Button.type";

interface CountdownButtonProps extends LabelButton {
  labelOnCooldown?: string;
  timeInSeconds: number;
  interval: number;
  onCountdownEnd?: () => void;
  startImmediately?: boolean;
  timeFormatted?: boolean;
}

function CountdownButton({ label, onClick, labelOnCooldown, timeInSeconds, interval, onCountdownEnd, startImmediately, timeFormatted, ...props }: CountdownButtonProps) {
  const [countdown, setCountdown] = useState(timeInSeconds);
  const [countdownFormatted, setCounddownFormatted] = useState<Duration>();
  const [startCountdown, setStartCountdown] = useState(startImmediately || false);
  const [buttonLabel, setButtonLabel] = useState(label);

  const zeroPad = (num: number | undefined) => {
    if (!num) {
      return "00";
    }
    return String(num).padStart(2, "0");
  };

  useEffect(() => {
    if (!startCountdown) {
      return;
    }

    const countdownInterval = setInterval(() => {
      if (countdown !== 0) {
        setCountdown((prev) => prev - 1);
        return;
      }
      setButtonLabel(labelOnCooldown || label);
      setStartCountdown(false);
      setCountdown(timeInSeconds);
      if (onCountdownEnd) {
        onCountdownEnd();
      }
    }, interval);

    const duration = intervalToDuration({
      start: 0,
      end: countdown * 1000, // miliseconds
    });
    setCounddownFormatted(duration);

    return () => clearInterval(countdownInterval);
  }, [countdown, startCountdown]);

  const onStartCountdown = (e: React.MouseEvent) => {
    setStartCountdown(true);
    if (onClick) {
      onClick(e);
    }
  };

  const createTime = () => {
    if (!startCountdown || countdown === 0) {
      return null;
    }
    if (timeFormatted) {
      return <span>{` (${zeroPad(countdownFormatted?.minutes)}:${zeroPad(countdownFormatted?.seconds)})`}</span>;
    }
    return <span className="small-second">{` (${countdown}s)`}</span>;
  };

  useEffect(() => {
    setCountdown(timeInSeconds);
  }, [timeInSeconds]);

  return (
    <CountdownButtonStyled>
      <Button
        label={
          <p className="text">
            <span>{buttonLabel}</span>
            {createTime()}
          </p>
        }
        onClick={onStartCountdown}
        {...props}
      />
    </CountdownButtonStyled>
  );
}

export default CountdownButton;
