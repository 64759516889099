import styled from "styled-components";
import { forDesktop } from "../../../helpers/breakpoints/viewports";

const SearchBarStyled = styled.div<{ isActive: boolean }>`
  height: 30px;
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: flex-end;
  width: ${({ isActive }) => (isActive ? "100%" : "10px")};
  min-width: ${({ isActive }) => (isActive ? "100%" : "10px")};
  transition: 0.6s;

  .search-input {
    flex-grow: 1;
    overflow: hidden;
  }

  .search-icon {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    width: 13px;
    height: 13px;
  }

  .search-icon-img {
    height: 100%;
  }

  .error,
  .form-label {
    display: none;
  }

  @media (${forDesktop}) {
    width: ${({ isActive }) => (isActive ? "300px" : "10px")};
    min-width: ${({ isActive }) => (isActive ? "300px" : "10px")};

    .search-icon {
      width: 17px;
      height: 17px;
    }
  }
`;

export default SearchBarStyled;
