import React from "react";
import Deposit from "../../deposit/Deposit";
import EditProfile from "../../editprofile/EditProfile";
import Faqs from "../../faqs/FAQ";
import ForgetPassword from "../../forget/ForgetPassword";
import Login from "../../login/Login";
import SignupForm from "../../signup/signup/Signup";
import ComingSoon from "../../signup/comingSoon/ComingSoon";
import Home from "../../home/home";
import Legal from "../../legal/Legal";
import Privacy from "../../legal/Privacy";
import Terms from "../../legal/Terms";
import HowItWorks from "../../howitworks/HowItWorks";
import Runningduels from "../../runningduels/runningduels";
import Features from "../../features/features";
import Populargames from "../../populargames/populargames";
import AboutUs from "../../aboutus/AboutUs";
import OurTeam from "../../ourteam/OurTeam";
import OurVision from "../../ourvision/OurVision";
import Help from "../../help/help";
import Payment from "../../payments/Payment";
import TransactionSecurity from "../../transactionsecurity/TransactionSecurity";
import ResponsibleGaming from "../../responsiblegaming/ResponsibleGaming";
import KYC from "../../kyc/kyc";
import CreateTournament from "../../createtournament/createtournament";
import EnterTournament from "../../tournament/entertournament";
import Tournamentdetails from "../../tournamentdetails/tournamentdetails";
import NotFound from "../../notFound/NotFound";
import ReCaptchaProvider from "../../../providers/reCaptchaProvider/ReCaptchaProvider";
import Oauth from "../../oauth/Oauth";
import ExternalTournaments from "../../external-tournaments/externalTournaments";

const PublicAppRoutes = [
  {
    path: "/",
    component: <Home />,
  },
  {
    path: "/login",
    component: (
      <ReCaptchaProvider>
        <Login />
      </ReCaptchaProvider>
    ),
  },
  {
    path: "/signup/:referralCode?",
    component: (
      <ReCaptchaProvider>
        <SignupForm />
      </ReCaptchaProvider>
    ),
  },
  {
    path: "/oauth",
    component: (
      <ReCaptchaProvider>
        <Oauth />
      </ReCaptchaProvider>
    ),
  },
  {
    path: "/forget",
    component: <ForgetPassword />,
  },
  {
    path: "/legal",
    component: <Legal />,
  },
  {
    path: "/terms",
    component: <Terms />,
  },
  {
    path: "/privacy",
    component: <Privacy />,
  },
  {
    path: "/howitworks",
    component: <HowItWorks />,
  },
  {
    path: "/runningduels",
    component: <Runningduels />,
  },
  {
    path: "/features",
    component: <Features />,
  },
  {
    path: "/faqs",
    component: <Faqs />,
  },
  {
    path: "/populargames",
    component: <Populargames />,
  },
  {
    path: "/about",
    component: <AboutUs />,
  },
  {
    path: "/editprofile",
    component: <EditProfile />,
  },
  {
    path: "/ourvision",
    component: <OurVision />,
  },
  {
    path: "/ourteam",
    component: <OurTeam />,
  },
  {
    path: "/help",
    component: <Help />,
  },
  {
    path: "/payment",
    component: <Payment />,
  },
  {
    path: "/transactionsecurity",
    component: <TransactionSecurity />,
  },
  {
    path: "/responsiblegaming",
    component: <ResponsibleGaming />,
  },
  {
    path: "/createtournament",
    component: <CreateTournament />,
  },
  {
    path: "/deposit",
    component: <Deposit />,
  },
  {
    path: "/kyc",
    component: <KYC />,
  },
  {
    path: "/enterTournament",
    component: <EnterTournament />,
  },
  {
    path: "/tournamentdetails",

    component: <Tournamentdetails />,
  },
  {
    path: "/coming-soon",

    component: <ComingSoon />,
  },
  {
    path: "/external-tournaments-current",

    component: <ExternalTournaments />,
  },
  {
    path: "/external-tournaments-future",

    component: <ExternalTournaments />,
  },
  {
    path: "/external-tournaments-finished",

    component: <ExternalTournaments />,
  },
  {
    path: "*",
    component: <NotFound />,
  },
];

export default PublicAppRoutes;
