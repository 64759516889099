import React from "react";
import { useTranslation } from "react-i18next";
import ShowMoreBarStyled from "./ShowMoreBar.style";

interface ShowMoreBarProps {
  title: string;
  moreText?: string;
  onClick: () => void;
}

function ShowMoreBar({ title, moreText, onClick }: ShowMoreBarProps) {
  return (
    <ShowMoreBarStyled>
      <div className="show-more">
        <div>{title}</div>
        <button
          type="button"
          className="show-more-button"
          onClick={() => onClick()}
        >
          <span>{moreText || useTranslation().t("common.more")}</span>
          <span className="expand-arrows">{">>"}</span>
        </button>
      </div>
    </ShowMoreBarStyled>
  );
}

export default ShowMoreBar;
