import styled from "styled-components";

const NotificationsDropdownStyled = styled.div`
  .title-row {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 11px 10px 15px;
  }

  .notifications-options {
    width: 14px;
    height: 14px;
    background-color: ${({ theme }) => theme.colors.darkGrey};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .notifications-options:hover {
    cursor: pointer;
  }

  .show-all {
    font-size: ${({ theme }) => theme.fontSizes.caption2};
  }

  .show-all:hover {
    cursor: pointer;
  }

  .show-all::first-letter {
    text-transform: uppercase;
  }

  .arrows {
    color: ${({ theme }) => theme.colors.blue};
    padding-left: 5px;
  }

  .no-notifications {
    font-size: ${({ theme }) => theme.fontSizes.caption2};
    text-align: center;
    padding: 4px 0 16px 0;
  }

  .no-notifications::first-letter {
    text-transform: uppercase;
  }
`;

export default NotificationsDropdownStyled;
