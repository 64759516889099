import React, { useEffect, useState } from "react";
import "./finishedTournamentDetails.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAuth from "../../../../hooks/useAuth/useAuth";
import { userInstance } from "../../../../axios/axiosconfig";
import Routes from "../../../../config/routes";
import PageContent from "../../currentTournament/pageContent.type";

import player from "../../../../assets/images/icons/current-tournament/player.svg";
import joystick from "../../../../assets/images/icons/current-tournament/joystick.svg";
import calendar from "../../../../assets/images/icons/current-tournament/calendar.svg";
import people from "../../../../assets/images/icons/current-tournament/people.svg";
import FinishedTournamentDetailsData from "./finishedTournamentData";
import Sponsors from "../../currentTournament/sponsors/sponsors";
import FinishedTournamentWinners from "./winners/winners";

interface FinishedTournamentDetailsProps {
  id?: string;
}

export default function FinishedTournamentDetails({ id }: FinishedTournamentDetailsProps) {
  const [finishedTournamentData, setFinishedTournamentData] = useState<FinishedTournamentDetailsData>();
  const isAuth = useAuth();
  const translations: PageContent = useTranslation().t("currentTournament", { returnObjects: true });
  const navigation = useNavigate();

  const parseEndDate = (endDate: string) => {
    const dateParts = endDate.split("T")[0].split("-");
  
    return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
  };

  const loadFinishedTournamentById = async () => {
    if (isAuth) {
      try {
        const tournamentData = await userInstance.get(`external-tournaments/${id}/finished`);
        return tournamentData;
      } catch (err) {
        navigation(Routes.FinishedTournaments);
      }
    }

    return {} as any;
  };

  useEffect(() => {
    if (!isAuth) {
      navigation(Routes.Login);
    }

    loadFinishedTournamentById().then((tournamentData) => {
      setFinishedTournamentData(tournamentData.data);
    });
  }, []);

  return (
    <>
      {!!finishedTournamentData && (
        <div className="finished-tournament-details">
          <img
            alt="banner"
            className="banner"
            src={finishedTournamentData.bannerImageUrl}
          />
          <div className="container">
            <div className="compete-section">
              <h3>{translations.dayOfDuel}</h3>
              <h4>{finishedTournamentData.name}</h4>
            </div>
            <div className="tournament-details">
              <div className="detail-tile">
                <div className="tile-icon">
                  <img
                    alt="joystick"
                    src={joystick}
                  />
                </div>
                <div className="tile-text">
                  <p className="label">{translations.game}</p>
                  <p className="info game-title">{finishedTournamentData.info.gameTitle}</p>
                </div>
              </div>
              <div className="detail-tile">
                <div className="tile-icon">
                  <img
                    alt="player"
                    src={player}
                  />
                </div>
                <div className="tile-text">
                  <p className="label">{translations.entryFee}</p>
                  <p className="info">{finishedTournamentData.info.fee}</p>
                </div>
              </div>
              <div className="detail-tile">
                <div className="tile-icon">
                  <img
                    alt="people"
                    src={people}
                  />
                </div>
                <div className="tile-text">
                  <p className="label">Ilość graczy</p>
                  <p className="info">{finishedTournamentData.playersCount}</p>
                </div>
              </div>
              <div className="detail-tile">
                <div className="tile-icon">
                  <img
                    alt="calendar"
                    src={calendar}
                  />
                </div>
                <div className="tile-text">
                  <p className="label">{translations.tournamentDate}</p>
                  <p className="info">{finishedTournamentData.info.dateRange}</p>
                </div>
              </div>
            </div>
          </div>

          <FinishedTournamentWinners players={finishedTournamentData.winnersList} />
          {finishedTournamentData.sponsors.length > 0 && (
            <Sponsors
              sectionHeader={translations.sponsors}
              list={finishedTournamentData.sponsors}
            />
          )}

          <div className="end-date">
            <p className="label">Turniej zakończył sie</p>
            <p className="date">{parseEndDate(finishedTournamentData.endDate)}</p>
          </div>
        </div>
      )}
    </>
  );
}
