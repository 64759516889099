import styled from "styled-components";

const ToggleInputStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .switch-label {
    font: ${({ theme }) => theme.fonts.global.text10};
    margin: 0;
  }

  .switch-label::first-letter {
    text-transform: uppercase;
  }

  .switch {
    position: relative;
    width: 20px;
    height: 10px;
    margin: 0;
  }

  .hidden-input {
    display: none;
  }

  .toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.buttonSecondary};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
    border: none;
    outline: none;
  }

  // circle
  .toggle:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    background-color: rgba(0, 0, 0, 0.6); // todo - add to colour palette
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .toggle:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
    background-color: #3bbdf5; // todo - add to colour palette
  }

  input:disabled + .toggle:before {
    background-color: ${({ theme }) => theme.colors.whiteOpacity25};
  }
`;

export default ToggleInputStyled;
