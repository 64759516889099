import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import PageContent from "../pageContent.type";
import { FilterInterface, Option } from "../../table/Filter/Filter";
import useAppQuery from "../../../hooks/useQuery/useQuery";
import { getGameAutoComplete, getUserMatches } from "../../../API/game/gameActions";
import { MatchPayload } from "../../../API/game/gameActions.type";
import useLanguage from "../../../hooks/useLanguage/useLanguage";
import useInfinitePagination from "../../../hooks/useInfinitePagination/useInfinitePagination";
import { ActionSort } from "../../../API/action.type";
import { TableInputs } from "../../table/TableRow/TableRow";

const mapGameToOption = (games: { id: string; name: string }[]): Option[] =>
  games.map((e) => ({
    label: e.name,
    value: e.id,
  }));

interface UseGamesTableProps {
  pageSize: number;
  includeFilters: boolean;
  includeSort: boolean;
}

function useGamesTable({ pageSize, includeFilters, includeSort }: UseGamesTableProps) {
  const { language } = useLanguage();
  const translations: PageContent = useTranslation().t("my_games", { returnObjects: true });

  const [filters, setFilters] = useState<Record<string, unknown>>({});
  const [sort, setSort] = useState<ActionSort>();
  const [gameFilterSearch, setGameFilterSearch] = useState<string | undefined>(undefined);

  const { data, isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfinitePagination(
    getUserMatches({
      ...filters,
      sort,
      pageSize,
    })
  );

  const isLoadingData = isLoading || isFetching || isFetchingNextPage;

  const { refetch: refetchSearch } = useAppQuery(getGameAutoComplete(language, gameFilterSearch), {
    enabled: false,
  });

  const formik = useFormik({
    initialValues: {
      gameId: "",
      type: "",
    },
    onSubmit: (values: any) => {
      setFilters(values);
    },
  });

  const formikFilters: FilterInterface[] = includeFilters
    ? [
        {
          label: translations.filter_game_label,
          id: "my-games-game",
          name: "game",
          value: formik.values.gameId,
          onChange: (option: Option | null) => formik.setFieldValue("gameId", option?.value || null),
          isAutoComplete: true,
          loadOptions: async (search) => {
            setGameFilterSearch(search);
            await refetchSearch();
            return refetchSearch().then((e) => mapGameToOption(e.data || []));
          },
        },
        {
          isAutoComplete: false,
          label: translations.filter_mode_label,
          id: "my-games-mode",
          name: "type",
          value: formik.values.type,
          onChange: (option: Option | null) => formik.setFieldValue("type", option?.value || null),
          options: [
            { label: translations.filer_mode_option_duel, value: "DUEL" },
            { label: translations.filer_mode_option_torunament, value: "TOURNAMENT" },
            { label: translations.filer_mode_option_tournamentDoD, value: "TOURNAMENT_DOD" },
            { label: translations.filer_mode_option_multiplayer, value: "MULTIPLAYER" },
            { label: translations.filer_mode_option_championship_battle, value: "CHAMPIONSHIP_BATTLE" },
          ],
        },
      ]
    : [];

  useEffect(() => {
    formik.handleSubmit();
  }, [formik.values]);

  const inputs: TableInputs<MatchPayload> = [
    {
      id: "gameName",
      heading: translations.game,
      component: {
        type: "text",
        field: "gameName",
      },
    },
    {
      id: "entryFee",
      heading: translations.entry_fee,
      component: {
        type: "moneyAmount",
        field: "entryFee",
      },
      isSortable: includeSort,
    },
    {
      id: "prizeValue",
      heading: translations.prize_value,
      component: {
        type: "moneyAmount",
        field: "prizeValue",
      },
      isSortable: includeSort,
    },
    {
      id: "time",
      heading: translations.date,
      component: {
        type: "date",
        field: "time",
      },
      isSortable: includeSort,
    },
    {
      id: "opponent",
      heading: translations.opponent,
      component: {
        type: "text",
        field: "opponent",
      },
    },
    {
      id: "result",
      heading: translations.score,
      component: {
        type: "score",
        field: "result",
      },
    },
    {
      id: "type",
      heading: translations.mode,
      component: {
        type: "text",
        field: "type",
      },
    },
  ];

  return {
    data: data?.pages.map((page) => page.items).flat() || [],
    inputs,
    onSort: setSort,
    filters: formikFilters,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingData,
  };
}

export default useGamesTable;
