/* eslint-disable import/no-cycle */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GameModesButtonStyled from "./GameModesButton.style";
import { controllerWithCable, duelIcon, multiplayerIcon } from "../../../../assets/images";
import ExpandableButton from "../ModeButton/ModeButton";
import PageContent from "../PageContent.type";
import { useModal } from "../../../../modals";
import { ModeButtonVariant } from "../ModeButton/ModeButton.type";
import { Wallet } from "../../../../API/user/userActions.type";
import Button from "../../buttons/button/Button";

interface GameModesButtonProps {
  depositWallet: Wallet | undefined;
  gameId: string;
}

function GameModesButton({ depositWallet, gameId }: GameModesButtonProps) {
  const translations: PageContent = useTranslation().t("popular_game", { returnObjects: true });
  const [modeButtonsVisible, setModeButtonsVisible] = useState(false);
  const componentRef = useRef<HTMLButtonElement>(null);

  const { openChooseStakeModal } = useModal();

  function handleClickOutside(event: any) {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setModeButtonsVisible(false);
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [componentRef]);

  const handleDuel = () => {
    if (depositWallet) {
      openChooseStakeModal({ gameId, wallet: depositWallet });
    }
  };

  const expandableButtons = [
    { icon: multiplayerIcon, label: translations.mode_create_tournament, variant: "createTournament", isAvailable: true, onClick: () => {} },
    { icon: multiplayerIcon, label: translations.mode_find_tournament, variant: "findTournament", isAvailable: true, onClick: () => {} },
    { icon: multiplayerIcon, label: translations.mode_multiplayer, variant: "multiplayer", isAvailable: true, onClick: () => {} },
    { icon: duelIcon, label: translations.mode_duel, variant: "duel", isAvailable: true, onClick: () => handleDuel() },
    { icon: multiplayerIcon, label: translations.mode_championship_battle, variant: "championshipBattle", isAvailable: true, onClick: () => {} },
  ];

  return (
    <GameModesButtonStyled modeButtonsVisible={modeButtonsVisible}>
      <div className="component-container">
        <div className="buttons">
          {expandableButtons.map((item) => (
            <ExpandableButton
              icon={item.icon}
              label={item.label}
              variant={item.variant as ModeButtonVariant}
              onClick={item.onClick}
              modeButtonsVisible={modeButtonsVisible}
              isAvailable={item.isAvailable}
              key={`game-modes-${item.label}`}
            />
          ))}
        </div>
        <Button
          innerRef={componentRef}
          type="iconButton"
          icon={controllerWithCable}
          onClick={() => {
            setModeButtonsVisible((prev) => !prev);
          }}
          variant={modeButtonsVisible ? "lightGrey" : "red"}
          className="main-icon"
          alt=""
        />
      </div>
    </GameModesButtonStyled>
  );
}

export default GameModesButton;
