import styled from "styled-components";

const CountdownButtonStyled = styled.div`
  .text {
    margin: 0;
  }
  .small-second {
    text-transform: lowercase;
  }
`;

export default CountdownButtonStyled;
