import styled from "styled-components";
import { ModeButtonVariant } from "./ModeButton.type";

const handleBackgroundColorType = (backgroundColor: string | undefined) => {
  switch (backgroundColor) {
    case "duel":
      return ({ theme }: any) => theme.colors.red;
    case "findTournament":
      return ({ theme }: any) => theme.colors.blue;
    case "createTournament":
      return ({ theme }: any) => theme.colors.darkBlue;
    case "multiplayer":
      return ({ theme }: any) => theme.colors.grey;
    case "championshipBattle":
    default:
      return ({ theme }: any) => theme.colors.black;
  }
};

const ModeButtonStyled = styled.div<{ variant?: ModeButtonVariant }>`
  .expandable-container {
    height: 50px;
    border-radius: 25px;
    background-color: ${({ variant }) => handleBackgroundColorType(variant)};
    display: flex;
    align-items: center;
    width: 50px;
    overflow: hidden;
    transition: width 0.5s;
  }

  .icon-container {
    min-width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hidden {
    font-size: ${({ theme }) => theme.fontSizes.caption};
    text-transform: uppercase;
    letter-spacing: 0.15em;
    padding-right: 15px;
    white-space: nowrap;
  }

  .hover:hover {
    cursor: pointer;
    width: 100%;
  }
`;

export default ModeButtonStyled;
