import React, { useState, useEffect } from "react";
import "./registration-modal.css";
import { Button } from "react-bootstrap";
import { notificationRingingBellIcon } from "../../../../../assets/images";
import modalCloseBtn from "../../../../../assets/images/external-tournaments/modal-close-btn.png";

export default function ExternalTournamentRegistrationModal({ redirectUrl, show }: { redirectUrl: string; show: boolean }) {
  const [showModal, setShowModal] = useState(false);

  const goToRedirectUrl = () => {
    window.open(redirectUrl, "_blank");
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <>
      {showModal && (
        <div className="blurred-background">
          <div className="external-tournament-registration-modal">
            <div className="button-wrapper">
              <img
                className="close-btn"
                alt=""
                src={modalCloseBtn}
                onClick={closeModal}
              />
            </div>
            <div className="ringbell-background">
              <img
                alt="notify_bell"
                src={notificationRingingBellIcon}
              />
            </div>
            <p className="congrats">Gratulacje!</p>
            <p className="text">Zostałeś zapisany na turniej Day of Duel Solo Build Cup</p>
            <p className="important-text">Zaraz nastąpi przekierowanie na stronę WLS.GG, gdzie możesz dokończyć zapisy</p>
            <Button
              className="red-btn"
              onClick={goToRedirectUrl}
            >
              Przejdź do WLS.GG
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
