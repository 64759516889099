import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import WalletBoxStyled from "./WalletBox.style";
import { Wallet } from "../../../API/user/userActions.type";
import PageContent from "../pageContent.type";
import useLanguage from "../../../hooks/useLanguage/useLanguage";
import Deposit from "../../deposit/Deposit";

interface WalletBoxProps {
  wallet: Wallet;
  isMobile?: boolean;
}

function WalletBox({ wallet, isMobile }: WalletBoxProps) {
  const translations: PageContent = useTranslation().t("header", { returnObjects: true });
  const { language } = useLanguage();

  const [depositshow, setDepositShow] = useState(false);
  const handleDepositShow = () => setDepositShow(true);
  const handleDepositClose = () => setDepositShow(false);

  return (
    <WalletBoxStyled>
      <div className={isMobile ? "mobile-wallet-box" : ""}>
        <h5 className="title">{wallet.type === "deposit" ? translations.wallet_deposit : translations.wallet_bonus}</h5>
        <div className={`currencies ${wallet.type === "deposit" && "deposit-color"}`}>
          <p className="wallet-amount">
            {new Intl.NumberFormat(language, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              useGrouping: true,
            }).format(Number(wallet?.amountInUSD || 0))}
            <span className="currency">USD</span>
          </p>
          <p className="wallet-amount">
            {new Intl.NumberFormat(language, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              useGrouping: true,
            }).format(Number(wallet?.amount || 0))}
            <span className="currency">{wallet.currency}</span>
          </p>
        </div>
      </div>
      {!isMobile && (
        <>
          {wallet.type === "deposit" && (
            <button
              type="button"
              className="add-money-btn"
              onClick={handleDepositShow}
            >
              {translations.top_up_funds}
            </button>
          )}

          <Modal
            dialogClassName="dod-popup"
            show={depositshow}
            onHide={handleDepositClose}
          >
            <Modal.Header closeButton />
            <Modal.Body>
              <Deposit />
            </Modal.Body>
          </Modal>
        </>
      )}
    </WalletBoxStyled>
  );
}

export default WalletBox;
