import styled from "styled-components";

const PopularGamesStyled = styled.div`
  .games {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    row-gap: 40px;
    margin: auto;
  }
`;

export default PopularGamesStyled;
