import styled from "styled-components";

const FilterSearchStyled = styled.div`
  display: flex;
  align-items: center;

  .clear-filter {
    color: ${({ theme }) => theme.colors.red};
    border: none;
    background-color: transparent;
  }

  label {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .label-text {
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fontFamilies.robotoBold};
    font-size: ${({ theme }) => theme.fontSizes.caption};
    padding: 0 9px;
    margin: 0;
  }

  .label-text::first-letter {
    text-transform: uppercase;
  }

  .react-select__control {
    padding-left: 8px;
    min-height: 0px;
    width: 150px;
    height: 30px;
    background: transparent;
    border-radius: 3px;
    border: 1px solid ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.caption};
    color: ${({ theme }) => theme.colors.grey};
    border-image-slice: 1 !important;
    border-width: 1px !important;
    border-image-source: ${({ theme }) => theme.borderImages.white};
    align-items: flex-start;
    box-shadow: none;
  }

  .react-select__control:hover,
  .react-select__control *:hover {
    cursor: pointer;
  }

  .react-select__value-container {
    height: inherit;
    padding: 0;
  }

  .react-select__indicators {
    height: inherit;
  }

  .react-select__input-container,
  .react-select__single-value {
    color: ${({ theme }) => theme.colors.grey};
  }

  .react-select__indicator {
    padding: 3px;
  }

  .react-select__indicator svg {
    width: 15px;
    height: 15px;
  }

  .react-select__indicator-separator {
    margin: 3px 0;
  }

  .react-select__menu {
    background-color: ${({ theme }) => theme.colors.background};
    margin-top: 0;
    border: 1px solid ${({ theme }) => theme.colors.white};
    border-image-slice: 1 !important;
    border-width: 1px !important;
    border-image-source: ${({ theme }) => theme.borderImages.blue};
  }

  .react-select__option {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.grey};
    font-size: ${({ theme }) => theme.fontSizes.caption};
  }

  .react-select__option::first-letter,
  .react-select__placeholder::first-letter {
    text-transform: uppercase;
  }

  .react-select__option:hover {
    background-color: transparent;
    cursor: pointer;
    font-family: ${({ theme }) => theme.fontFamilies.robotoBold};
  }
`;

export default FilterSearchStyled;
