import styled from "styled-components";
import { forDesktop } from "../../helpers/breakpoints/viewports";

const FriendsStyled = styled.div`
  .top-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 30px;
    margin-bottom: 36px;
    position: relative;
    overflow: hidden;
  }

  .icon {
    opacity: 1;
    transition: 0.3s;
    height: 10px;
    width: 10px;
  }

  .icon-hidden {
    opacity: 0;
  }

  .accordions-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  @media (${forDesktop}) {
    .icon {
      height: 15px;
      width: 15px;
    }
  } ;
`;

export default FriendsStyled;
