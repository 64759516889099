import React from "react";
import TextCardStyled from "./TextCard.style";

interface TextCardProps {
  icon: string;
  title: string;
  content: string;
}

function TextCard({ icon, title, content }: TextCardProps) {
  return (
    <TextCardStyled>
      <div className="icon">
        <img
          src={icon}
          alt="how-tournament"
        />
      </div>
      <div className="title">{title}</div>
      <div className="content">{content}</div>
    </TextCardStyled>
  );
}

export default TextCard;
