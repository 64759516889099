import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AuthenticatedAppRoutes from "./routes/AuthenticatedAppRoutes";
import PublicAppRoutes from "./routes/PublicAppRoutes";
import useAuth from "../../hooks/useAuth/useAuth";
import useProfile from "../../hooks/useProfile/useProfile";
import CompleteSignup from "../signup/completeSignup/CompleteSignup";
import Login from "../login/Login";
import ExternalTournaments from "../external-tournaments/externalTournaments";

const completeSignupRoutes = [
  {
    path: "/completeSignup",
    component: <CompleteSignup />,
  },
  {
    path: "*",
    component: <Navigate to="/completeSignup" />,
  },
];

const CurrentTournamentRoutes = [
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/external-tournaments-current",
    component: <ExternalTournaments />,
  },
  {
    path: "/external-tournaments-finished",
    component: <ExternalTournaments />,
  },
  {
    path: "external-tournaments-future",
    component: <ExternalTournaments />,
  },
  {
    path: "external-tournaments-future/:id",
    component: <ExternalTournaments />,
  },
  {
    path: "external-tournaments-finished/:id",
    component: <ExternalTournaments />,
  },
  {
    path: "*",
    component: <Navigate to="/external-tournaments-future" />,
  },
];

const DEFAULT_ROUTES = [...PublicAppRoutes];

function Router() {
  const [routesArr, setRoutesArr] = useState(DEFAULT_ROUTES);
  const auth = useAuth();
  const profile = useProfile();

  const createRoutesArray = () => {
    const array = [...DEFAULT_ROUTES];

    if (!profile?.data) {
      return array;
    }

    if (!!auth && auth.fullAccess && profile.data.nickname) {
      array.unshift(...AuthenticatedAppRoutes);
      array.unshift(...CurrentTournamentRoutes);
    } else if (!!auth && !profile.data.nickname) {
      array.length = 0;
      array.unshift(...completeSignupRoutes);
    } else if (!!auth && profile.data.nickname && !auth.fullAccess) {
      array.length = 0;
      array.unshift(...CurrentTournamentRoutes);
    }

    return array;
  };

  const checkProfile = async () => {
    const newRoutesArray = createRoutesArray();
    setRoutesArr(newRoutesArray);
  };

  useEffect(() => {
    checkProfile();
  }, [profile]);

  if (!!auth && !profile) {
    return null;
  }

  return (
    <Routes>
      {routesArr.map((route) => (
        <Route
          path={route.path}
          element={route.component}
          key={route.path}
        />
      ))}
    </Routes>
  );
}

export default Router;
