import styled from "styled-components";

const ProgressBarStyled = styled.div`
  height: 6px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #d9d9d9;
  display: flex;

  .green {
    height: 100%;
    width: calc(100% / 3);
    background-color: #52ff00;
  }

  .red {
    height: 100%;
    width: calc(100% / 3);
    background-color: #ff0000;
  }
`;

export default ProgressBarStyled;
