import styled from "styled-components";

const TableRowStyled = styled.div`
  .table-row {
    min-height: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    border-image-slice: 1 !important;
    border: 1px solid ${({ theme }) => theme.colors.white};
    border-image-source: ${({ theme }) => theme.borderImages.grey};
    margin-bottom: 20px;
    padding: 12px 0;
    transition: 1s;
  }

  .table-row:hover {
    height: fit-content;
    cursor: default;
  }

  .table-row-link:hover {
    cursor: pointer;
  }

  .table-row-link:hover .row-link-arrows {
    color: ${({ theme }) => theme.colors.blue};
  }

  .table-row > div {
    flex: 1 1 0px;
    font-size: ${({ theme }) => theme.fontSizes.caption};
    margin: 0;
    text-align: center;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding: 0 8px;
  }

  .table-row:hover > div {
    display: initial;
    cursor: default;
  }

  .table-row-link:hover > div {
    cursor: pointer;
  }

  .row-link-arrows {
    width: 37px;
    max-width: 37px;
    color: transparent;
    transition: color 0.3s;
  }
`;

export default TableRowStyled;
