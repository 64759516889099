const formatIBAN = (iban: string) => {
  const formattedString = iban
    .replace(/\s/g, "")
    .replace(/(.{4})/g, "$& ")
    .replace(/^[A-Z]{2}/g, "$& ")
    .trim();

  return formattedString;
};

export const formatIBANWithoutCountryCode = (iban: string) => {
  const tempIban = `XX${iban.replace(/^([A-Z]{2})/g, "")}`;

  return formatIBAN(tempIban).substring(2);
};

export default formatIBAN;
