import { IObservable, Observer } from "./storage.types";

export default class LocalStorage<T> implements IObservable<T | null> {
  private observers: Observer<T | null>[];

  constructor(private key: string) {
    this.observers = [];

    window.addEventListener("storage", this.handleStorageEvent);
  }

  private handleStorageEvent = (event: StorageEvent) => {
    if (event.storageArea === window.localStorage && event.key === this.key) {
      this.observers.forEach((fn) => fn.call(undefined, this.get()));
    }
  };

  get = (): T | null => {
    try {
      const jsonString = localStorage.getItem(this.key);
      return jsonString ? JSON.parse(jsonString) : null;
    } catch (error) {
      return null;
    }
  };

  set = (value: T | null): void => {
    if (!value) {
      localStorage.removeItem(this.key);
    } else {
      localStorage.setItem(this.key, JSON.stringify(value));
    }
    this.observers.forEach((fn) => fn.call(undefined, this.get()));
  };

  subscribe = (fn: Observer<T | null>): void => {
    this.observers.push(fn);
  };

  unsubscribe = (fn: Observer<T | null>): void => {
    this.observers = this.observers.filter((item) => item !== fn);
  };
}
