import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Layout from "../layout";
import TransactionsTable from "./transationsTable/TransactionsTable";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import PageTitle from "../common/PageTitle/PageTitle";
import Routes from "../../config/routes";
import TransactionsStyled from "./Transactions.style";

function UserTransaction() {
  const translations: { home: string; transactions: string } = useTranslation().t("navigation", { returnObjects: true });
  const [urlParams, setUrlParams] = useSearchParams();

  const setParams = (obj: {}) => {
    setUrlParams(obj);
  };

  return (
    <Layout>
      <TransactionsStyled>
        <div className="transactions-container">
          <Breadcrumb
            link={Routes.Home}
            linkTextCurrent={translations.transactions}
            linkTextPrevious={translations.home}
          />
          <PageTitle title={translations.transactions} />
          <TransactionsTable
            urlParams={urlParams}
            setUrlParams={setParams}
          />
        </div>
      </TransactionsStyled>
    </Layout>
  );
}

export default UserTransaction;
