import React, { useEffect, useState } from "react";
import { InfiniteData, QueryObserverResult } from "react-query";
import { PaginatedResult } from "../../../API/action.type";
import { SupportedNotifications } from "../../../API/user/notifications.type";
import DotIndicator from "../../common/dotIndicator/DotIndicator";
import useMarkNotification from "../../../hooks/useMarkNotification/useMarkNotification";

interface NotificationDotProps {
  status: "read" | "unread";
  notificationId: string;
  refetchData: () => Promise<QueryObserverResult<InfiniteData<PaginatedResult<SupportedNotifications>>, unknown>>;
  width?: string;
}

function NotificationDot({ status, notificationId, refetchData, width }: NotificationDotProps) {
  const [isRead, setIsRead] = useState(false);

  const { markAsRead: markAsReadMutation, markAsUnread: markAsUnreadMutation } = useMarkNotification();

  useEffect(() => {
    setIsRead(status === "read");
  }, [status]);

  const onClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (status === "read") {
      await markAsUnreadMutation.mutateAsync(notificationId);
    } else {
      await markAsReadMutation.mutateAsync(notificationId);
    }
    await refetchData();
  };

  return (
    <DotIndicator
      isRead={isRead}
      width={width}
      onClick={(e) => onClick(e)}
    />
  );
}

export default NotificationDot;
