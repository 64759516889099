import { useFormik } from "formik";
import debounce from "lodash.debounce";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { closeIcon, searchIcon } from "../../../assets/images";
import Input, { InputInterface } from "../formComponents/input/Input";
import SearchBarStyled from "./SearchBar.style";

interface SearchBarProps {
  checkIsActive?: (isActive: boolean) => void;
  placeholder?: string;
  setValue?: (input: string) => void;
}

function SearchBar({ checkIsActive, placeholder, setValue }: SearchBarProps) {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (checkIsActive) {
      checkIsActive(isActive);
    }
  }, [isActive]);

  const validationSchema = Yup.object().shape({
    searchText: Yup.string().required(t("form_errors.required")),
  });

  const formik = useFormik({
    initialValues: {
      searchText: "",
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: () => {
      // todo
    },
  });

  const onValueChange = useCallback(
    debounce((arg) => {
      setValue?.(arg);
    }, 400),
    []
  );

  useEffect(() => {
    onValueChange(formik.values.searchText);
  }, [formik.values]);

  const input: InputInterface = {
    type: "text",
    label: "",
    id: "search-bar-friend",
    placeholder: placeholder ? `${placeholder}...` : `${t("common.search")}...`,
    name: "searchText",
    value: formik.values.searchText,
    onChange: formik.handleChange,
    error: "",
  };

  return (
    <SearchBarStyled isActive={isActive}>
      <Input
        item={input}
        className={isActive ? "search-input" : "search-input hidden"}
      />
      <button
        type="button"
        className="search-icon"
        onClick={() => setIsActive((prev) => !prev)}
      >
        <img
          src={isActive ? closeIcon : searchIcon}
          className="search-icon-img"
          alt=""
        />
      </button>
    </SearchBarStyled>
  );
}

export default SearchBar;
