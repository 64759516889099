import React from "react";
import { useTranslation } from "react-i18next";
import OpponentNotFoundModalStyled from "./OpponentNotFoundModal.style";
import Button from "../../components/common/buttons/button/Button";
import PageContent from "../pageContent.type";
// eslint-disable-next-line import/no-cycle
import { useModal } from "../PageModal.context";
import useSocket from "../../hooks/useSocket/useSocket";
import useWallets from "../../hooks/useWallets/useWallets";
import { StakeType } from "../../types/stake";

interface OpponentNotFoundModalProps {
  gameId: string;
  stake: StakeType;
  walletId: string;
}

// todo: remove that eslint disables and make that modal working with matchmaking flow
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function OpponentNotFoundModal({ gameId, stake, walletId }: OpponentNotFoundModalProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { openChooseStakeModal } = useModal();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { joinMatchmaking } = useSocket();

  const { depositWallet } = useWallets();
  const translations: PageContent = useTranslation().t("waitingopponent", { returnObjects: true });

  const handleChangeStake = () => {
    // todo!: refactor that when we will also support bonus rooms
    openChooseStakeModal({ gameId, wallet: depositWallet! });
  };

  const handleSearchAgain = () => {
    joinMatchmaking({ gameId, stake, walletId });
  };

  return (
    <OpponentNotFoundModalStyled>
      <div className="wrapper">
        <h2 className="title">{translations.opponent_not_found_title}</h2>
        <div className="description">{translations.opponent_not_found_description}</div>
        <div className="buttons">
          <Button
            type="labelButton"
            label={translations.opponent_not_found_change_stake_button}
            width="180px"
            variant="blue"
            onClick={handleChangeStake}
          />
          <Button
            type="labelButton"
            label={translations.opponent_not_found_search_again_button}
            variant="blue"
            width="180px"
            onClick={handleSearchAgain}
          />
        </div>
      </div>
    </OpponentNotFoundModalStyled>
  );
}

export default OpponentNotFoundModal;
