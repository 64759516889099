import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TableStyled from "./Table.style";
import Filter, { FilterInterface, Sort } from "./Filter/Filter";
import ColumnHeading from "./ColumnHeading/ColumnHeading";
import PageContent from "./PageContent.type";
import Loader from "../loader/Loader";
import { Link } from "../../API/user/notifications.type";
import TableRow, { TableInputs } from "./TableRow/TableRow";
import useElementIntersect from "../../hooks/useElementIntersect/useElementIntersect";

export interface TableProps<T> {
  data: T[];
  isLoading: boolean;
  includePagination: boolean;
  inputs: TableInputs<T>;
  filters?: FilterInterface[];
  onSort: (sort?: Sort) => void;
  hasNextPage: boolean | undefined;
  fetchNextPage: () => Promise<any>;
  tableWithLinks?: boolean;
  onRowClick?: (arg: T) => void | Promise<void>;
}

function Table<T extends { id: string; link?: Link }>({ data, isLoading, includePagination, inputs, filters, onSort, hasNextPage, fetchNextPage, tableWithLinks = false, onRowClick }: TableProps<T>) {
  const translations: PageContent = useTranslation().t("table", { returnObjects: true });
  const [sort, setSort] = useState<Sort | undefined>(undefined);

  useEffect(() => {
    onSort?.(sort);
  }, [sort]);

  const checkIsFilterActive = () => {
    if (!filters) {
      return;
    }
    const activeFilter = filters.find((item) => item.value !== "");
    return !!activeFilter;
  };

  const { lastElementRef } = useElementIntersect({
    shouldBeSkipped: !hasNextPage || isLoading,
    onIntersect: () => {
      if (hasNextPage) {
        fetchNextPage();
      }
    },
  });

  const createRows = () => {
    if (!isLoading && (!data || data.length === 0)) {
      return (
        <div className="no-results">
          <p>{translations.no_results}</p>
          {checkIsFilterActive() && <p>{translations.no_results_filters}</p>}
        </div>
      );
    }

    return data.map((item, index) => (
      <TableRow
        item={item}
        lastElementRef={includePagination && data.length === index + 1 ? lastElementRef : null}
        inputs={inputs}
        tableWithLinks={tableWithLinks}
        onRowClick={onRowClick}
        key={item.id}
      />
    ));
  };

  return (
    <TableStyled>
      {filters && (
        <div className="filters">
          {filters.map((filter) => (
            <Filter
              item={filter}
              key={filter.id}
            />
          ))}
        </div>
      )}
      <div className="table-headings">
        {inputs.map((item) => (
          <ColumnHeading
            sort={sort}
            setSort={setSort}
            item={item || { heading: "", isSortable: false, component: { type: "text" } }}
            key={item.id}
          />
        ))}
        {tableWithLinks && <div className="row-link-arrows" />}
      </div>
      <div>{createRows()}</div>
      {isLoading && (
        <div className="table-loader">
          <Loader width="50px" />
        </div>
      )}
    </TableStyled>
  );
}

export default Table;
