import styled from "styled-components";

const WalletSwitchStyled = styled.div`
  .wallets-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 100%;
  }

  .wallets-info.mobile-wallet-switch {
    width: 345px;
    flex-direction: column;
    gap: 18px;
  }

  .wallets-buttons-container {
    display: flex;
    height: 100%;
  }

  .wallets-info.mobile-wallet-switch .wallets-buttons-container {
    width: 100%;
    justify-content: center;
  }

  .wallet-btn,
  .bonus-btn {
    transform: skewX(-29deg);
    border: none;
    font-size: 13px;
    font-weight: 600;
    font-family: "Roboto";
    height: 90px;
    width: 105px;
    overflow: hidden;
    background-color: #080c19;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wallets-info.mobile-wallet-switch .wallet-btn,
  .wallets-info.mobile-wallet-switch .bonus-btn {
    transform: skewX(0);
    width: 50%;
    height: 55px;
    border-radius: 5px;
  }

  .bonus-btn {
    border-left: 1px solid #48516b30;
  }

  .wallet-btn {
    border-left: 1px solid #48516b30;
    border-right: 1px solid #48516b30;
  }

  .wallet-btn span,
  .bonus-btn span {
    display: inline-block;
    transform: skewX(29deg);
    color: #48516b;
  }

  .wallets-info.mobile-wallet-switch .wallet-btn span,
  .wallets-info.mobile-wallet-switch .bonus-btn span {
    transform: skewX(0);
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }

  .bonus-btn.active {
    background-color: #fbbb33;
    border: none;
  }

  .wallet-btn.active {
    background-color: #14c77c;
    border: none;
  }

  .bonus-btn.active span,
  .wallet-btn.active span {
    color: #fff;
  }
`;

export default WalletSwitchStyled;
