/* eslint-disable import/no-cycle */
import React from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import httpStatusHelper from "../../../helpers/http-status-helper";
import { forgotPassword } from "../../../API/user/userActions";
import useAppMutation from "../../../hooks/useMutation/useMutation";
import PageContent from "../pageContent.type";
import Button from "../../common/buttons/button/Button";
import Input, { InputInterface } from "../../common/formComponents/input/Input";
import ForgetPasswordStage1Styled from "./ForgetPasswordStage1.style";
import FormErrors from "../../../helpers/formErrors.type";

interface FormValues {
  email: string;
}

interface ForgetPasswordStage1Props {
  firstStageCompleted: boolean;
  setFirstStageCompleted: (state: boolean) => void;
  email: string | undefined;
  setEmail: (email: string | undefined) => void;
}

function ForgetPasswordStage1({ firstStageCompleted, setFirstStageCompleted, email, setEmail }: ForgetPasswordStage1Props) {
  const forgotPasswordMutation = useAppMutation(forgotPassword);
  const { t } = useTranslation();

  const translations: PageContent = t("forget", { returnObjects: true });
  const errorTranslations: FormErrors = t("form_errors", { returnObjects: true });

  const onFormSubmit = async (values: FormValues) => {
    try {
      const response = await forgotPasswordMutation.mutateAsync(values);
      if (httpStatusHelper.isSuccess(response.status)) {
        NotificationManager.success(translations.success, "Message", 4000);
        setFirstStageCompleted(true);
      } else {
        const errorMessage = response.errorObject.response.data.message;
        NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
      }
    } catch (error: any) {
      console.error((error as AxiosError).response?.data);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(errorTranslations.required),
  });

  const formik = useFormik({
    initialValues: {
      email: email || "",
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: onFormSubmit,
  });

  const formInputs: InputInterface[] = [
    {
      type: "email",
      label: translations.email,
      id: "forget-email",
      placeholder: translations.emailPlaceholder,
      name: "email",
      value: formik.values.email,
      onChange: (e: React.ChangeEvent) => {
        const target = e.target as HTMLInputElement;
        setEmail(target.value);
        formik.handleChange(e);
      },
      error: formik.errors.email || "",
      disabled: firstStageCompleted ?? false,
    },
  ];

  return (
    <ForgetPasswordStage1Styled>
      <form onSubmit={formik.handleSubmit}>
        <div className="inputs">
          {formInputs.map((input) => (
            <Input
              item={input}
              key={input.id}
            />
          ))}
        </div>
        <Button
          submitButton
          type="labelButton"
          label={translations.submitButton}
          variant="blue"
          disabled={firstStageCompleted ?? false}
        />
      </form>
    </ForgetPasswordStage1Styled>
  );
}

export default ForgetPasswordStage1;
