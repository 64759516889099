import styled from "styled-components";

const MyGamesStyled = styled.div`
  .my-games-container {
    max-width: 1070px;
    margin: 100px auto;
  }
`;

export default MyGamesStyled;
