/* eslint-disable import/no-cycle */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAppMutation from "../../../hooks/useMutation/useMutation";
import Button from "../../common/buttons/button/Button";
import Input, { InputInterface } from "../../common/formComponents/input/Input";
import ForgetPasswordStage2Styled from "./ForgetPasswordStage2.style";
import { ForgotPasswordConfirmPayload } from "../../../API/user/userActions.type";
import FormErrors from "../../../helpers/formErrors.type";
import passwordRegExp from "../../../helpers/password-regex";
import PageContent from "../pageContent.type";
import { forgotPasswordConfirm } from "../../../API/user/userActions";
import httpStatusHelper from "../../../helpers/http-status-helper";
import Routes from "../../../config/routes";

interface ForgetPasswordStage2Props {
  email: string;
}

function ForgetPasswordStage2({ email }: ForgetPasswordStage2Props) {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const forgotPasswordConfirmMutation = useAppMutation(forgotPasswordConfirm);

  const translations: PageContent = useTranslation().t("forget", { returnObjects: true });
  const errorTranslations: FormErrors = t("form_errors", { returnObjects: true });

  const onFormSubmit = async (values: ForgotPasswordConfirmPayload) => {
    try {
      const response = await forgotPasswordConfirmMutation.mutateAsync(values);
      if (httpStatusHelper.isSuccess(response.status)) {
        NotificationManager.success(translations.success, "Message", 4000);
        navigation(Routes.Login);
      } else {
        const errorMessage = response.errorObject.response.data.message;
        NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
      }
    } catch (error: any) {
      NotificationManager.error(t(`errors.ERROR_OCCURRED`), "Message", 10000);
    }
  };

  const yupPasswordValidation = Yup.string().required(errorTranslations.required).min(8, errorTranslations.passwordMinChars).max(24, errorTranslations.passwordMaxChars).matches(passwordRegExp, errorTranslations.passwordInvalid);

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(errorTranslations.required),
    newPassword: yupPasswordValidation,
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], errorTranslations.passwordConfirm)
      .required(errorTranslations.required),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      onFormSubmit({
        code: values.code,
        email,
        newPassword: values.newPassword,
      });
    },
  });

  const formInputs: InputInterface[] = [
    {
      type: "text",
      label: t("common.confirmationCode") || "",
      id: "forget-password-confirmation-code",
      placeholder: ` ${t("common.confirmationCode") || ""}`,
      name: "code",
      value: formik.values.code,
      onChange: formik.handleChange,
      error: formik.errors.code || "",
    },
    {
      type: "password",
      label: translations.newPassword || "",
      id: "reset-password-password",
      placeholder: translations.newPassword || "",
      name: "newPassword",
      value: formik.values.newPassword,
      onChange: formik.handleChange,
      error: formik.errors.newPassword || "",
    },
    {
      type: "password",
      label: translations.confirmPassword || "",
      id: "reset-password-confirm-password",
      placeholder: translations.confirmPassword || "",
      name: "confirmPassword",
      value: formik.values.confirmPassword,
      onChange: formik.handleChange,
      error: formik.errors.confirmPassword || "",
    },
  ];

  return (
    <ForgetPasswordStage2Styled>
      <div className="description">
        <p>{translations.email_code_sent}</p>
        <p>{translations.email_code_sent_enter}</p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="inputs">
          {formInputs.map((input) => (
            <Input
              item={input}
              key={input.id}
            />
          ))}
        </div>
        <Button
          submitButton
          type="labelButton"
          label={translations.resetPasswordButton}
          variant="blue"
        />
      </form>
    </ForgetPasswordStage2Styled>
  );
}

export default ForgetPasswordStage2;
