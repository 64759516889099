import styled from "styled-components";
import { forDesktop } from "../../../helpers/breakpoints/viewports";

const AccordionStyled = styled.div<{ contentHeight: number }>`
  background-color: ${({ theme }) => theme.colors.blackBackgroundOpacity40};
  border-radius: 2px;
  padding: 8px 0 8px 16px;
  font-size: ${({ theme }) => theme.fontSizes.accordionMobile};
  color: ${({ theme }) => theme.colors.whiteText};

  .accordion-title {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 8px;
    border: none;
    background-color: transparent;
    text-transform: uppercase;
  }

  .accordion-icon {
    transition: 0.3s ease-in;
  }

  .accordion-icon-rotated {
    transform: rotate(90deg);
  }

  .additional-title-info {
    border-left: 1px solid ${({ theme }) => theme.colors.divider};
    height: 14px;
    display: flex;
    align-items: center;
    padding-left: 14px;
    margin-left: 14px;
  }

  .accordion-content {
    max-height: ${({ contentHeight }) => `${contentHeight}px`};
    height: ${({ contentHeight }) => `${contentHeight}px`};
    transition: 0.3s;
    padding-top: 4px;
  }

  .overflow-content {
    overflow: hidden;
  }

  .hidden {
    overflow: hidden;
    max-height: 0;
    height: 0;
    padding-top: 0;
  }

  @media (${forDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.accordionDesktop};
  }
`;

export default AccordionStyled;
