import { Action } from "../action.type";
import { GetHomePageContentResponse, GetFAQContentResponse, GetRegistrationConsentResponse } from "./cmsActions.type";

export function getHomePageContent(locale: string): Action<GetHomePageContentResponse> {
  return {
    method: "GET",
    endpoint: `/cms/homepage?locale=${locale}`,
  };
}

export function getFAQContent(locale: string): Action<GetFAQContentResponse> {
  return {
    method: "GET",
    endpoint: `/cms/faq?locale=${locale}`,
  };
}

export function getRegistrationConsent(locale: string): Action<GetRegistrationConsentResponse> {
  return {
    method: "GET",
    endpoint: `/cms/consent?locale=${locale}`,
  };
}
