import styled from "styled-components";
import { dropdownArrowIcon, polandFlag, ukFlag } from "../../../assets/images";

const ProfileDropdownStyled = styled.div`
  span.ac-banned.badge.badge-danger {
    display: block;
    width: 100%;
  }

  .p-pic img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    object-fit: cover;
    object-position: center;
    border: 1px solid #3bbdf5;
  }

  .p-pic::after {
    content: "";
    border: none;
    display: block;
    width: 19px;
    height: 19px;
    background-size: cover;
    position: absolute;
    left: 24px;
    top: 30px;
    border: 1px solid #3bbdf5;
    border-radius: 50%;
  }

  .p-pic.Poland::after {
    background-image: url(${polandFlag});
  }

  .p-pic.UnitedKingdom::after {
    background-image: url(${ukFlag});
  }

  /* .p-pic::after {
    background: url(${dropdownArrowIcon});
    content: "";
    border: none;
    display: block;
    width: 10px;
    height: 10px;
    background-size: cover;
    position: absolute;
    left: 5px;
    top: 12px;
  } */

  .profile-image {
    height: 22px;
    width: 22px;
    border-radius: 3px;
  }

  .my-profile-menu {
    display: flex;
    margin-right: 30px;
    position: relative;
    align-items: center;
    gap: 5px;
  }

  .p-name {
    padding-left: 10px;
    position: relative;
  }

  .p-name h5 {
    font-weight: 600;
    color: #fff;
    font-size: 16px;
    margin-bottom: 0;
    text-transform: capitalize;
    letter-spacing: 1.5px;
    padding-bottom: 2px;
  }

  .p-name span {
    color: #696969;
    font-size: 12px;
  }

  .p-name .dropdown .btn {
    padding: 5px;
    line-height: 0;
    background: transparent;
  }

  .p-name .dropdown-toggle::after {
    background: url(${dropdownArrowIcon});
    content: "";
    border: none;
    display: block;
    width: 12px;
    height: 8px;
    background-size: cover;
    position: absolute;
    left: 5px;
    top: 12px;
  }

  .p-name .p-nick {
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .p-name .p-nick img {
    width: 14px;
    height: 14px;
  }

  .p-level {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .p-level p {
    font-size: 10px;
    font-weight: 400;
    color: #3bbdf5;
    margin: 0;
  }

  .p-level .progress {
    width: 100%;
    height: 2px;
    border-radius: 28px;
  }

  .p-level .progress-bar {
    background-color: #3bbdf5;
  }

  /* .my-profile-menu:hover {
    cursor: pointer;
  } */

  .my-profile-menu .dropdown {
    position: absolute;
    display: inline-block !important;
    vertical-align: middle;
    right: -20px;
    top: -5px;
  }

  .my-profile-menu .dropdown button:hover {
    background: transparent;
    border-color: transparent;
  }

  .my-profile-menu .dropdown button:focus {
    background: transparent;
    border-color: transparent;
    outline: none;
    box-shadow: none;
  }

  .my-profile-menu .dropdown-menu.show {
    position: absolute;
    top: 70px;
    width: 250px;
    background: #121727;
    border-radius: 0 0 20px 20px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .my-profile-menu .dropdown-menu .user-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .my-profile-menu .dropdown-menu .user-info .profile-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
  }

  .my-profile-menu .dropdown-menu .user-info .profile-info .user-type {
    border-radius: 5px;
    background-color: #3bbdf5;
    padding: 7px 20px;
    font-size: 13px;
    font-weight: 600;
  }

  .my-profile-menu .dropdown-menu .circular-progressbar {
    width: 30px;
    height: 30px;
  }

  .my-profile-menu .dropdown-menu .circular-progressbar .CircularProgressbar-text {
    font-family: Orbitron;
    font-weight: 900;
  }

  .my-profile-menu .dropdown-menu hr {
    width: 100%;
    border: 1px solid #293451;
  }

  .my-profile-menu .dropdown-menu .dropdown-options {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
  }

  .my-profile-menu .dropdown-menu .dropdown-item {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #293451;
    padding-left: 10px;
    padding-right: 0;
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .my-profile-menu .dropdown-menu .dropdown-item:hover {
    color: #fff;
    background: transparent;
  }

  .my-profile-menu .dropdown-menu .dropdown-item:focus {
    background: transparent;
  }

  .my-profile-menu .dropdown-menu .text-red {
    color: #df3232;
  }

  .my-profile-menu .dropdown-menu .dropdown-item.text-red .icon {
    background: #df3232;
  }

  .my-profile-menu .dropdown-menu .dropdown-item .icon {
    mask-size: auto;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-position: center;
    width: 23px;
    height: 23px;
    background: #293451;
  }

  .my-profile-menu .dropdown-menu .dropdown-item:hover .icon {
    background: #fff;
  }

  /* .my-profile-menu .dropdown-menu::before {
    content: "";
    width: 100%;
    background: #019acb;
    height: 1px;
    display: block;
    position: absolute;
    top: -1px;
  } */
`;

export default ProfileDropdownStyled;
