import styled from "styled-components";

const LastGamesStyled = styled.div`
  margin: 100px auto;

  .title {
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.h1};
  }
`;

export default LastGamesStyled;
