import styled from "styled-components";

const NotEnoughMoneyModalStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .wrapper {
    width: 50%;
    height: 50%;
    color: ${({ theme }) => theme.colors.white};
  }

  .title {
    font-size: ${({ theme }) => theme.fontSizes.h4};
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    margin: 20px 0 40px;
    text-align: center;
  }

  .description {
    font-size: ${({ theme }) => theme.fontSizes.body};
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    text-align: center;
  }

  .description p {
    margin: 0;
  }

  .buttons {
    width: 390px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px auto;
  }
`;

export default NotEnoughMoneyModalStyled;
