import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { AxiosError } from "axios";
import PageContent from "./pageContent.type";
import { getUsers } from "../../API/friend/friendActions";
import httpStatusHelper from "../../helpers/http-status-helper";
import useInfinitePaginationWithIntersect from "../../hooks/useInfinitePaginationWithIntersect/useInfinitePaginationWithIntersect";
import { SupportedCountry } from "../../API/user/userActions.type";
import { useInviteUser } from "../../hooks/useFriendInvitationAction/useFriendInvitationAction";

const DEFAULT_VALUES = {
  rankFrom: 1,
  rankTo: 10,
  country: [],
  ignoreOffline: false,
};

export interface Filters {
  rankFrom: number;
  rankTo: number;
  country: SupportedCountry[];
  ignoreOffline: boolean;
}

function usePlayersList() {
  const { t } = useTranslation();
  const translations: PageContent = t("players_list", { returnObjects: true });

  const [urlParams, setUrlParams] = useSearchParams();
  const [isSearchBarActive, setIsSearchBarActive] = useState<boolean>();
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState<Filters>({
    rankFrom: Number(urlParams.get("rankFrom")) || 1,
    rankTo: Number(urlParams.get("rankTo")) || 10,
    country: (urlParams.getAll("country") as SupportedCountry[]) || [],
    ignoreOffline: urlParams.get("ignoreOffline") === "true",
  });
  const [queryData, setQueryData] = useState({
    pageSize: 10,
    nickname: searchValue,
    ...filters,
  });

  const { items, refetch, lastElementRef } = useInfinitePaginationWithIntersect(getUsers(queryData), { enabled: false });
  const inviteUserMutation = useInviteUser();

  useEffect(() => {
    refetch();
  }, [queryData]);

  useEffect(() => {
    setQueryData({ ...queryData, ...filters });
    Object.entries(filters).forEach(([key, value]) => {
      if ((Array.isArray(value) && value.length) || (!Array.isArray(value) && value)) {
        urlParams.set(key, value);
      } else {
        urlParams.delete(key);
      }
    });
    setUrlParams(urlParams);
  }, [filters]);

  useEffect(() => {
    setQueryData({ ...queryData, nickname: searchValue });
  }, [searchValue]);

  const inviteFriend = async (userId: string) => {
    try {
      const response = await inviteUserMutation.mutateAsync({ friendId: userId });
      if (httpStatusHelper.isSuccess(response.status)) {
        NotificationManager.success(translations.toaster_user_invited, "Message", 4000);
      } else {
        const errorMessage = response.errorObject.response.data.message;
        NotificationManager.error(t(`errors.${errorMessage}`), "Message", 10000);
      }
    } catch (error: any) {
      console.error((error as AxiosError).response?.data);
    }
  };

  return { users: items, lastElementRef, filters, setFilters, inviteFriend, translations, isSearchBarActive, setIsSearchBarActive, setSearchValue, defaultFiltersValues: DEFAULT_VALUES };
}

export default usePlayersList;
