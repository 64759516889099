import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Wallet } from "../../../API/user/userActions.type";
import Routes from "../../../config/routes";
import useWallets from "../../../hooks/useWallets/useWallets";
import WalletBox from "../WalletBox/WalletBox";
import PageContent from "../pageContent.type";
import WalletSwitchStyled from "./WalletSwitch.style";

function WalletSwitch({ isMobile = false }) {
  const { t } = useTranslation();

  const translations: PageContent = t("header", { returnObjects: true });

  const { pathname } = useLocation();
  const navigation = useNavigate();
  const { bonusWallet, depositWallet } = useWallets();

  const [selectedWallet, setSelectedWallet] = useState<Wallet>();

  useEffect(() => {
    if (!depositWallet || !bonusWallet) {
      setSelectedWallet(undefined);
    } else if (pathname === Routes.Bonus) {
      setSelectedWallet(bonusWallet);
    } else {
      setSelectedWallet(depositWallet);
    }
  }, [bonusWallet, depositWallet]);

  const onWalletSelect = (wallet: Wallet | undefined) => {
    setSelectedWallet(wallet);
    navigation(wallet?.type === "bonus" ? Routes.Bonus : Routes.Home);
  };

  return (
    <WalletSwitchStyled>
      <div className={`wallets-info ${isMobile ? "mobile-wallet-switch" : ""}`}>
        <div className="wallets-buttons-container">
          <button
            type="button"
            className={`bonus-btn ${selectedWallet?.type === "bonus" && "active"}`}
            onClick={() => onWalletSelect(bonusWallet)}
          >
            <span>{translations.bonus}</span>
          </button>
          <button
            type="button"
            className={`wallet-btn ${selectedWallet?.type === "deposit" && "active"}`}
            onClick={() => onWalletSelect(depositWallet)}
          >
            <span>{translations.wallet}</span>
          </button>
        </div>
        {selectedWallet && (
          <WalletBox
            wallet={selectedWallet}
            isMobile={isMobile}
          />
        )}
      </div>
    </WalletSwitchStyled>
  );
}

export default WalletSwitch;
