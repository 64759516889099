import React from "react";
import ScoreStyled from "./Score.style";

interface ScoreProps {
  score: {
    isWinner: boolean;
    result: string;
    opponentResult: string;
  };
  width?: string;
}

function Score({ score, width }: ScoreProps) {
  if (!score || !score.result || !score.opponentResult) {
    return <div />;
  }
  return (
    <ScoreStyled
      isWinner={score.isWinner}
      width={width}
    >{`${score.result} - ${score.opponentResult}`}</ScoreStyled>
  );
}

export default Score;
