import styled from "styled-components";

const GameListStyled = styled.div<{ wonMatchAnimation?: boolean }>`
  .item {
    display: flex;
    align-items: center;
  }

  .box {
    width: 16px;
    height: 16px;
    border: 1px solid;
    margin-right: 10px;
    margin: 0;
  }

  .red {
    border-color: #ff0000;
    background-color: #7b0000;
  }

  @keyframes pulse {
    0% {
      border-color: #52ff00;
      background-color: #005e09;
    }
    50% {
      border-color: transparent;
      background-color: transparent;
    }
    100% {
      border-color: #52ff00;
      background-color: #005e09;
    }
  }

  .green {
    border-color: #52ff00;
    background-color: #005e09;
  }

  .won-animation {
    animation-name: ${({ wonMatchAnimation }) => (wonMatchAnimation ? "pulse" : null)};
    animation-duration: 1s;
    animation-iteration-count: 3;
    animation-timing-function: linear;
  }

  .game-name {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.body};
    letter-spacing: 0.15em;
    padding: 0 0 0 10px;
    margin: 0;
  }

  .bold {
    font-family: ${({ theme }) => theme.fontFamilies.robotoBold};
  }
`;

export default GameListStyled;
