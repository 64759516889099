import { useNavigate } from "react-router-dom";
import { Link } from "../../API/user/notifications.type";

function useNavigateToLink(link: Link | undefined) {
  const navigation = useNavigate();

  const onNavigate = () => {
    if (!link) {
      return;
    }
    if (link.type === "default") {
      window.location.replace(link.link);
    } else if (link.type === "routing" && !link.route.includes("http")) {
      const searchParams = new URLSearchParams(link.routeParams);
      navigation(`${link.route}?${searchParams}`);
    }
  };

  return onNavigate;
}

export default useNavigateToLink;
