import styled from "styled-components";

const ROW_GAP = 8;

const calculateMaxHeight = (bottomItemsAmount: number, closedAccordionItemHeight: number) => {
  const maxHeight = bottomItemsAmount * closedAccordionItemHeight + bottomItemsAmount * ROW_GAP + 2 * ROW_GAP + closedAccordionItemHeight; // 16px padding at the bottom = 2 * ROW_GAP
  return `calc(100vh - ${maxHeight}px)`;
};

const AccordionStyled = styled.div<{ bottomItemsAmount: number; closedAccordionItemHeight: number }>`
  display: flex;
  flex-direction: column;
  row-gap: ${`${ROW_GAP}px`};

  .content {
    padding-right: 16px;
    max-height: ${({ closedAccordionItemHeight, bottomItemsAmount }) => calculateMaxHeight(bottomItemsAmount, closedAccordionItemHeight)};
    overflow: auto;
  }
`;

export default AccordionStyled;
