import React from "react";
import { Navigate } from "react-router-dom";
import Profile from "../../profile/Profile";
import PlayersListStyled from "../../playersList/PlayersList";
import TournamentPage from "../../tournament/tournamentpage";
import UserTransaction from "../../transaction/Transactions";
import Pong from "../../pong/game";
import SeriesGame from "../../seriesgame";
import WinterGame from "../../pong/winterGame";
import BonusRoomModal from "../../bonusRoom/BonusRoomModal";
import MyGames from "../../MyGames/MyGames";
import Notifications from "../../notifications/Notifications";

const AuthenticatedAppRoutes = [
  {
    path: "/profile",
    component: <Profile />,
  },
  {
    path: "/friends",
    component: (
      <Navigate
        to="/profile?friends=true"
        replace
      />
    ),
  },
  {
    path: "/friend",
    component: (
      <Navigate
        to="/profile?friends=true"
        replace
      />
    ),
  },
  {
    path: "/players",
    component: <PlayersListStyled />,
  },
  {
    path: "/seriesGame",
    component: <SeriesGame />,
  },
  {
    path: "/pong",
    component: <Pong />,
  },
  {
    path: "/winterGame",
    component: <WinterGame />,
  },
  {
    path: "/tournament",
    component: <TournamentPage />,
  },
  {
    path: "/transactions",
    component: <UserTransaction />,
  },
  {
    path: "/bonus",
    component: <BonusRoomModal />,
  },
  {
    path: "/mygames",
    component: <MyGames />,
  },
  {
    path: "/notifications",
    component: <Notifications />,
  },
];

export default AuthenticatedAppRoutes;
