/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "../layout";
import { addMoneyIcon, withdrawIcon, defaultAvatar } from "../../assets/images";
import Row from "./row/Row";
import Routes from "../../config/routes";
import ProfileStyled from "./Profile.style";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import Button from "../common/buttons/button/Button";
import useProfile from "../../hooks/useProfile/useProfile";
import { ProfileResponse } from "../../API/user/userActions.type";
import { useModal } from "../../modals";
import useAppQuery from "../../hooks/useQuery/useQuery";
import { getBankAccountNumber } from "../../API/user/userActions";
import PageContent from "./pageContent.type";
import useWallets from "../../hooks/useWallets/useWallets";
import formatIBAN from "../../helpers/iban-format";
import LastGames from "./LastGames/LastGames";
import PageTitle from "../common/PageTitle/PageTitle";
import Friends from "../friends/Friends";

function Profile() {
  const translations: PageContent = useTranslation().t("profile", { returnObjects: true });

  const { openUploadAvatarModal, openChangePasswordModal, openChangeAccountNumberModal, openWithdrawMoneyModal } = useModal();
  const [profile, setProfile] = useState<ProfileResponse | undefined>();
  const [profilePicture, setProfilePicture] = useState<string>();
  const [bankAccountNumber, setBankAccountNumber] = useState<string>();

  const [urlParams, setUrlParams] = useSearchParams();
  const friendsRef = useRef<HTMLDivElement>(null);

  const bankAccountQuery = useAppQuery(getBankAccountNumber());
  const profileQuery = useProfile();
  const wallets = useWallets();

  const createWalletString = (walletType: keyof typeof wallets) => {
    const text = `${wallets[walletType as keyof typeof wallets]?.amount?.toFixed(2)} ${wallets[walletType as keyof typeof wallets]?.currency}`;
    return text;
  };

  useEffect(() => {
    if (friendsRef?.current && urlParams.get("friends") === "true") {
      urlParams.delete("friends");
      setUrlParams(urlParams, { replace: true });
      friendsRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [urlParams, friendsRef.current]);

  useEffect(() => {
    if (bankAccountQuery.data) {
      setBankAccountNumber(formatIBAN(bankAccountQuery.data.accountNumber));
    }
  }, [bankAccountQuery.status]);

  useEffect(() => {
    if (!profileQuery || !profileQuery.data) {
      setProfile(undefined);
    }
    setProfile(profileQuery?.data);
    setProfilePicture(profileQuery?.data?.avatarUrl);
  }, [profileQuery]);

  const changeProfilePicture = async (newAvatar: string) => {
    setProfilePicture(newAvatar);
  };

  if (!profile || !profileQuery?.data) {
    return <div>User Data Not Found</div>;
  }

  return (
    <Layout>
      <ProfileStyled>
        <div className="profile-container">
          <PageTitle title={translations.heading} />
          <div className="user-profile">
            <div className="profile-picture">
              <img
                src={profilePicture || defaultAvatar}
                alt="profile"
                className="profile-image"
              />
              <div className="button">
                <Button
                  type="labelButton"
                  onClick={() =>
                    openUploadAvatarModal({
                      changeProfilePicture,
                    })
                  }
                  label={translations.upload}
                  variant="blue"
                />
              </div>
            </div>
            <div className="profile-data">
              <div className="info">
                <Row
                  title={`${translations.nick}:`}
                  value={profile.nickname}
                />
                <Row
                  title={`${translations.level}:`}
                  value=""
                />
                <Row
                  title={`${translations.ranking}:`}
                  value=""
                />
                <Row
                  title={`${translations.name}:`}
                  value={`${profile.givenName} ${profile.familyName}`}
                />
                <Row
                  title={`${translations.email}:`}
                  value=""
                />
                <Row
                  title={`${translations.country}:`}
                  value={profile.countryOfResidence}
                />
                <Row
                  title={`${translations.referal_link}:`}
                  value={
                    <a
                      href={`${window.location.origin}${Routes.SignUp}/${profile.referralCode}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {`${window.location.origin}${Routes.SignUp}/${profile.referralCode}`}
                    </a>
                  }
                />
              </div>
              <div className="button">
                <Button
                  type="labelButton"
                  onClick={() => openChangePasswordModal({})}
                  label={translations.change_password}
                  variant="blue"
                />
              </div>
            </div>
            <div className="profile-info">
              <div className="info">
                <Row
                  title={`${translations.bonus_turnover}:`}
                  value="0"
                />
                <Row
                  title={`${translations.win_percentage}:`}
                  value="0"
                />
                <Row
                  title={`${translations.total_win}:`}
                  value="0"
                />
                <Row
                  title={`${translations.duels_won}:`}
                  value="0"
                />
                <Row
                  title={`${translations.tournaments_won}:`}
                  value="0"
                />
                <Row
                  title={`${translations.bonus_account}:`}
                  value={createWalletString("bonusWallet")}
                />
                <Row
                  title={`${translations.deposit_account}:`}
                  value={createWalletString("depositWallet")}
                />
                <Row
                  title={`${translations.bank_account_number}`}
                  value={bankAccountNumber || translations.bank_account_number_complete}
                  valueIcon="edit"
                  valueColor={bankAccountNumber ? "white" : "red"}
                  iconOnClick={() => openChangeAccountNumberModal({ currentNumber: bankAccountNumber })}
                />
              </div>
              <div className="button">
                <Button
                  type="labelAndIconButton"
                  label={translations.addmoney}
                  icon={addMoneyIcon}
                  iconPosition="left"
                  alt=""
                />
                <Button
                  type="labelAndIconButton"
                  label={translations.withdrawmoney}
                  onClick={() => openWithdrawMoneyModal({})}
                  icon={withdrawIcon}
                  iconPosition="right"
                  alt=""
                />
              </div>
            </div>
          </div>
          <LastGames />
          <Friends innerRef={friendsRef} />
        </div>
      </ProfileStyled>
    </Layout>
  );
}

export default Profile;
