import styled from "styled-components";
import { howTournamentDuelBackground } from "../../assets/images";
import { forDesktop } from "../../helpers/breakpoints/viewports";

const PlayersListStyled = styled.div`
  .logo-img {
    padding: 16px;
    width: 156px;
  }

  .header {
    background-image: url(${howTournamentDuelBackground});
    height: 170px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }

  .title {
    font: ${({ theme }) => theme.fonts.mobile.h1};
    text-transform: uppercase;
    text-align: center;
    padding-top: 50px;
  }

  .filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
    column-gap: 25px;
    width: 100%;
    padding: 0 16px;
  }

  .icon {
    opacity: 1;
    transition: 0.3s;
    height: 10px;
    width: 10px;
    margin: 0;
  }

  .icon-hidden {
    opacity: 0;
  }

  .players {
    padding: 0 16px;
  }

  .no-content-info {
    padding-top: 24px;
  }

  @media (${forDesktop}) {
    .header {
      width: 100vw;
      background-size: cover;
      background-position: center 40%;
    }
  }
`;

export default PlayersListStyled;
