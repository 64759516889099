import { useCallback, useRef } from "react";

type ElementIntersectProps = {
  shouldBeSkipped: boolean;
  onIntersect: () => void;
};

const useElementIntersect = ({ shouldBeSkipped, onIntersect }: ElementIntersectProps) => {
  const observer = useRef<IntersectionObserver>();
  const lastElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (shouldBeSkipped) {
        return;
      }

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          onIntersect();
        }
      });
      if (node) observer.current.observe(node);
    },
    [shouldBeSkipped, onIntersect]
  );

  return { lastElementRef };
};

export default useElementIntersect;
