/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { useModal } from "../PageModal.context";
import UploadAvatarModalStyled from "./UploadAvatarModal.style";
import ImageInput from "../../components/common/formComponents/imageInput/ImageInput";

import { getCroppedImg } from "./canvasUtils";
import Button from "../../components/common/buttons/button/Button";
import useAppMutation from "../../hooks/useMutation/useMutation";
import { uploadAvatar } from "../../API/user/userActions";
import useProfile from "../../hooks/useProfile/useProfile";
import { userProfile } from "../../assets/images";
import RangeInput from "../../components/common/formComponents/rangeInput/RangeInput";

interface UploadAvatarModalProps {
  changeProfilePicture: (newAvatar: string) => void;
}

function UploadAvatarModal({ changeProfilePicture }: UploadAvatarModalProps) {
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [isCropVisible, setIsCropVisible] = useState(false);
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const profileQuery = useProfile();
  const uploadAvatartMutation = useAppMutation(uploadAvatar);
  const { closeModal } = useModal();

  useEffect(() => {
    if (profileQuery?.data) {
      setProfilePicture(profileQuery?.data?.avatarUrl);
    }
  }, []);

  function readFile(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const onSelectFile = async (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    if (!target.files) {
      return;
    }

    const imageDataUrl = await readFile(target.files[0]);
    setIsCropVisible(true);
    setImageSrc(imageDataUrl);
  };

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPix: any) => {
    setCroppedAreaPixels(croppedAreaPix);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImg = await getCroppedImg(imageSrc, croppedAreaPixels);
      if (!croppedImg) {
        return;
      }
      setCroppedImage(croppedImg.url);
      changeProfilePicture(croppedImg.url);
      await uploadAvatartMutation.mutateAsync({ avatar: croppedImg.file });
      closeModal();
    } catch (e) {
      console.error(e);
    }
    setIsCropVisible(false);
  }, [imageSrc, croppedAreaPixels]);

  const onZoomChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setZoom(Number(target.value));
  };

  return (
    <UploadAvatarModalStyled>
      {isCropVisible && (
        <div className="cropper-container">
          <div className="cropper">
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <RangeInput
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(e) => onZoomChange(e)}
          />
          <Button
            type="labelButton"
            label="CROP"
            onClick={showCroppedImage}
            variant="blue"
          />
        </div>
      )}
      {!isCropVisible && (
        <div className="image-output">
          <img
            src={croppedImage || profilePicture || userProfile}
            alt="profile"
            className="picture"
          />
          <form>
            <ImageInput
              label="Change your profile picture"
              onChange={onSelectFile}
              extensions="image/png, image/jpeg, image/jpg"
            />
          </form>
        </div>
      )}
    </UploadAvatarModalStyled>
  );
}

export default UploadAvatarModal;
