import React from "react";
import { notificationRingingBellIcon } from "../../../../assets/images";
import NotificationsIconStyled from "./NotificationsIcon.style";

interface NotificationsIconProps {
  notificationsAmount: number;
}

function NotificationsIcon({ notificationsAmount }: NotificationsIconProps) {
  return (
    <NotificationsIconStyled>
      <div
        className="notification-wrapper"
        data-notifications-number={notificationsAmount}
      >
        <img
          src={notificationRingingBellIcon}
          alt="notifications"
          className="notification-icon"
        />
      </div>
    </NotificationsIconStyled>
  );
}

export default NotificationsIcon;
