import styled from "styled-components";
import Input from "../input/Input.style";
import { calendarIcon } from "../../../../assets/images";
import { forDesktop } from "../../../../helpers/breakpoints/viewports";

const DateInputStyled = styled(Input)`
  .text-input::-webkit-calendar-picker-indicator {
    opacity: 1;
    width: 20px;
    height: 20px;
    background: url(${calendarIcon}) no-repeat;
    background-size: 10px;
    background-position: center;
  }

  .text-input::-webkit-calendar-picker-indicator:hover {
    cursor: pointer;
  }

  .text-input {
    color: ${({ theme }) => theme.colors.whiteOpacity25};
  }

  .text-input:invalid,
  .valid-value {
    color: ${({ theme }) => theme.colors.whiteText};
  }

  @media ${forDesktop} {
  }
`;

export default DateInputStyled;
