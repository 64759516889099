/* eslint-disable import/no-cycle */
import React, { useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import ChooseGameModalStyled from "./ChooseGameModal.style";
import PageContent from "../../components/bonusRoom/PageContent.type";
import useLanguage from "../../hooks/useLanguage/useLanguage";
import useAppQuery from "../../hooks/useQuery/useQuery";
import { getGameAutoComplete } from "../../API/game/gameActions";
import Filter, { FilterInterface, Option } from "../../components/table/Filter/Filter";
import { coin25 } from "../../assets/images";
import WalletInfo from "../../components/common/walletInfo/WalletInfo";
import Status from "./Status/Status";
import GamesSection from "../../components/common/gamesSection/GamesSection";
import { BonusRoomActivated } from "../../API/game/gameActions.type";
import NotificationsIcon from "../../components/common/notificationsDropdown/NotificationsIcon/NotificationsIcon";

interface ChooseGameModalProps {
  bonusRoom?: BonusRoomActivated;
  duelNumber?: number;
  coinImageUrl?: string;
}

function ChooseGameModal({ duelNumber, coinImageUrl, bonusRoom }: ChooseGameModalProps) {
  const translations: PageContent = useTranslation().t("bonus_room", { returnObjects: true });
  const { language } = useLanguage();

  const [gameFilterSearch, setGameFilterSearch] = useState<string | undefined>(undefined);
  const [params, setParams] = useState({
    game: null,
    sort: null,
  });

  const { refetch: searchRefetch } = useAppQuery(getGameAutoComplete(language, gameFilterSearch), {
    enabled: false,
  });

  const formik = useFormik({
    initialValues: {
      game: "",
      sort: "",
    },
    onSubmit: (values: any) => {
      setParams({
        ...params,
        ...values,
      });
    },
  });

  const mapGameToOption = (games: { id: string; name: string }[]): Option[] =>
    games.map((e) => ({
      label: e.name,
      value: e.id,
    }));

  const filters: FilterInterface[] = [
    {
      label: translations.filter_game_label,
      id: "my-games-game",
      name: "game",
      value: formik.values.game,
      onChange: (option: Option | null) => formik.setFieldValue("gameId", option?.value || null),
      isAutoComplete: true,
      loadOptions: async (search) => {
        setGameFilterSearch(search);
        return searchRefetch().then((e) => mapGameToOption(e.data || []));
      },
    },
    {
      isAutoComplete: false,
      label: translations.sort_label,
      id: "my-games-mode",
      name: "type",
      value: formik.values.sort,
      onChange: (option: Option | null) => formik.setFieldValue("type", option?.value || null),
      options: [
        { label: translations.sort_option_favourite, value: "favourite" },
        { label: translations.sort_option_popular, value: "popular" },
      ],
    },
  ];

  return (
    <ChooseGameModalStyled>
      <div className="header">
        <div className="title-container">
          <img
            src={coinImageUrl || coin25}
            alt="coin"
            className="title-coin"
          />
          <h4 className="title">{translations.title}</h4>
        </div>
        <div className="wallet-container">
          <WalletInfo type="bonus" />
          <NotificationsIcon notificationsAmount={1} />
        </div>
      </div>
      <Status
        duelNumber={duelNumber || 1}
        coinImageUrl={coinImageUrl}
        stake={{
          currency: bonusRoom!.currency,
          stake: bonusRoom!.activateFee,
        }}
      />
      <div className="filters-container">
        <p className="choose-game">{translations.choose_game}:</p>
        <div className="filters">
          {filters.map((filter) => (
            <Filter
              item={filter}
              key={filter.id}
            />
          ))}
        </div>
      </div>
      <GamesSection
        cardWidth="260px"
        gameButtonType="bonus-duel"
      />
    </ChooseGameModalStyled>
  );
}

export default ChooseGameModal;
