import styled from "styled-components";
import { waitingOpponentBackground, waitingOpponentBackground2 } from "../../assets/images";

const GameLoadingModalStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  height: 100vh;
  background-image: url(${waitingOpponentBackground});
  background-position: center;
  background-size: cover;

  .heading {
    font-size: ${({ theme }) => theme.fontSizes.h1};
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 46px;
  }

  .requirements {
    font-size: ${({ theme }) => theme.fontSizes.body};
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    width: 500px;
    text-align: center;
    margin: 0 auto 90px;
  }

  .opponents-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .versus {
    background-image: url(${waitingOpponentBackground2});
    background-size: cover;
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: 200px;
    line-height: 235px;
    align-self: flex-start;
    width: 570px;
    text-align: center;
    align-self: stretch;
    font-size: 200px;
  }

  .gamer {
    margin-top: 50px;
    text-align: center;
    position: relative;
    height: 240px;
    width: 240px;
  }

  .user-image {
    position: absolute;
    left: 0;
    animation-duration: 1s;
    animation-name: animate-fade;
    animation-delay: 1s;
    animation-fill-mode: backwards;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @keyframes animate-fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .opponents-names {
    display: flex;
    justify-content: space-between;
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.h2};
  }

  .opponents-names div {
    width: 240px;
    text-align: center;
  }

  .loading-text {
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    font-size: ${({ theme }) => theme.fontSizes.h2};
    text-align: center;
  }

  .progress-bar-border {
    border: 1px solid ${({ theme }) => theme.colors.white};
    height: 26px;
    width: 820px;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 0 4px;
  }

  .progress-bar {
    height: 18px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export default GameLoadingModalStyled;
