import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FoundOpponentModalStyled from "./FoundOpponentModal.style";
import Button from "../../components/common/buttons/button/Button";
import PageContent from "../pageContent.type";
import useSocket from "../../hooks/useSocket/useSocket";

interface FoundOpponentModalProps {
  countDownTime: number;
}

function FoundOpponentModal({ countDownTime }: FoundOpponentModalProps) {
  const translations: PageContent = useTranslation().t("waitingopponent", { returnObjects: true });

  const [countDown, setCountDown] = useState(countDownTime);
  const { rejectMatchmaking, acceptMatchmaking } = useSocket();

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDown === 0) {
        rejectMatchmaking();
        return;
      }
      setCountDown((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [countDown]);

  return (
    <FoundOpponentModalStyled>
      <div className="wrapper">
        <h2 className="title">{translations.found_opponent_title}</h2>
        <div className="description">
          <p>{translations.found_opponent_description}</p>
          <p>{translations.found_opponent_description2}</p>
        </div>
        <div className="buttons">
          <Button
            type="labelButton"
            label={`${translations.found_opponent_reject_button} (${countDown})`}
            width="180px"
            variant="red"
            onClick={() => rejectMatchmaking()}
          />
          <Button
            type="labelButton"
            label={translations.found_opponent_accept_button}
            variant="blue"
            width="180px"
            onClick={() => acceptMatchmaking()}
          />
        </div>
      </div>
    </FoundOpponentModalStyled>
  );
}

export default FoundOpponentModal;
