import styled from "styled-components";
import { mobileBackground } from "../../assets/images";

const AcceptationModalStyled = styled.div`
  padding: 32px 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(${mobileBackground});
  background-repeat: no-repeat;
  background-size: cover;

  .question {
    margin-top: 120px;
    padding: 0 64px;
    font-size: ${({ theme }) => theme.fontSizes.text12px};
    text-align: center;
  }

  .buttons {
    display: flex;
    width: 100%;
    column-gap: 16px;
  }
`;

export default AcceptationModalStyled;
