import React, { ReactNode, useMemo } from "react";
import axios from "axios";
import qs from "qs";
import ClientContext from "../../context/clientContext/ClientContext";
import useAuth from "../../hooks/useAuth/useAuth";
import { server } from "../../config/config";
import { refreshTokenInterceptor } from "./interceptors/refresh-token.interceptor";
import Routes from "../../config/routes";

interface ClientProviderProps {
  children: ReactNode;
}

function ClientProvider({ children }: ClientProviderProps): React.ReactElement {
  const auth = useAuth();

  const client = useMemo(() => {
    const axiosClient = axios.create({
      baseURL: `${server}/v1`,
      withCredentials: true,
      headers: { authorization: `Bearer ${auth?.accessToken}` },
      paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: "repeat" }),
    });

    refreshTokenInterceptor(axiosClient, () => {
      if (auth) {
        window.location.href = Routes.Login;
      }
    });
    return axiosClient;
  }, [auth]);

  return <ClientContext.Provider value={client}>{children}</ClientContext.Provider>;
}

export default ClientProvider;
