import styled from "styled-components";

const NoContentStyled = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes.text8pxLight};
  padding: 8px;
`;

export default NoContentStyled;
