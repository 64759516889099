import React, { useEffect, useState } from "react";
import "./tournamentdetails.css";
import { useTranslation } from "react-i18next";
import Table from "react-bootstrap/Table";
import queryString from "query-string";
import Layout from "../layout";
import { userInstance } from "../../axios/axiosconfig";
import { server } from "../../config/config";
import RoundContainer from "./RoundContainer";

function Tournamentdetails() {
  const { t } = useTranslation();
  const [tournament, setTournament] = useState({});
  const [roomsLevelOne, setRoomsLevelOne] = useState([]);
  const [roomsLevelTwo, setRoomsLevelTwo] = useState([]);
  const [roomsLevelThree, setRoomsLevelThree] = useState([]);
  const [roomsLevelFour, setRoomsLevelFour] = useState([]);
  const [roomsLevelFive, setRoomsLevelFive] = useState([]);
  const [joinPlayer, setJoinPlayer] = useState(null);
  const [playerNumbers, setPlayersNumber] = useState(null);

  const getTournament = async (value) => {
    const res = await userInstance.get(`/tournamentdetails/${value.tour_id}`);
    if (res.data.code === 200) {
      setTournament(res.data.data);
      setRoomsLevelFive(res.data.data.roomsLevelFive);
      setRoomsLevelFour(res.data.data.roomsLevelFour);
      setRoomsLevelThree(res.data.data.roomsLevelThree);
      setRoomsLevelTwo(res.data.data.roomsLevelTwo);
      setRoomsLevelOne(res.data.data.roomsLevelOne);
      setJoinPlayer(res.data.data.joined);
      setPlayersNumber(res.data.data.playerNumbers);
    }
  };

  const getTourId = () => {
    const values = queryString.parse(window.location.search);
    return values;
  };

  useEffect(() => {
    const value = getTourId();
    getTournament(value);
  }, []);

  const { _id, title, tournamentStart } = tournament && tournament;

  return (
    <Layout>
      {/* {user} */}
      <div className="tournamentdetails">
        <div className="container">
          <div className="layout-content">
            <div className="container-fluid flex-grow-1 container-p-y">
              <h3 className="font-weight-bold py-3 mb-0 details-tour">{t("tournamentdetails.tournament_details")}</h3>

              <div className="row">
                <div className="col-md-12">
                  <h4 className="font-weight-bold mb-2 game-name">{`Title :${title}`}</h4>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <h5 className="font-weight-bold litle-title mb-3">{t("tournamentdetails.details")}</h5>
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-4 text-muted">{t("tournamentdetails.tournamentid")}</div>
                        <div className="col-md-8">{_id}</div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-4 text-muted">{t("tournamentdetails.maxplayers")}</div>
                        <div className="col-md-8">{playerNumbers}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <h5 className="font-weight-bold litle-title mb-3">{t("tournamentdetails.info")}</h5>
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-4 text-muted">{t("tournamentdetails.start")}</div>
                        <div className="col-md-8">{tournamentStart}</div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-4 text-muted">{t("tournamentdetails.joined")}</div>
                        <div className="col-md-8">{joinPlayer}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*  Tournament Brackets */}
              <div className="row">
                <RoundContainer
                  round="Round 1"
                  rooms={roomsLevelOne}
                />
                <RoundContainer
                  round="Round 2"
                  rooms={roomsLevelTwo}
                />
                <RoundContainer
                  round="Round 3"
                  rooms={roomsLevelThree}
                />

                <div className="col-md-3">
                  <div className="card-body">
                    <div className="row mb-3">
                      {roomsLevelFour.length === 1 ? <h4>Final Round</h4> : roomsLevelFour.length === 0 ? "" : <h4>Round 4</h4>}
                      {roomsLevelFour.map((el) => (
                        <div
                          className="col-md-12"
                          style={{
                            height: "100px",
                            border: "1px solid black",
                            marginBottom: "10px",
                          }}
                        >
                          <div
                            className="row"
                            style={{ height: "50px", border: "1px solid black", padding: "1px" }}
                          >
                            {el.players.length >= 1 ? (
                              <div>
                                <div style={{ height: "22px", width: "22px", display: "inline" }}>
                                  <img
                                    src={`${server}/${el.players[0].photo}`}
                                    alt=""
                                    style={{ height: "25px", width: "25px" }}
                                  />
                                </div>
                                <div style={{ height: "26px", width: "26px", display: "inline" }}>{el.players[0].firstName}</div>
                                <div style={{ height: "24px", width: "50px", display: "inline" }}>
                                  <img
                                    src={`${server}/public/country/${el.players[0].country}.png`}
                                    style={{ height: "25px", width: "25px" }}
                                    alt=""
                                  />
                                  {`Level : ${el.players[0].Level}`}
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div style={{ height: "22px", width: "22px", display: "inline" }} />
                                <div style={{ height: "26px", width: "26px", display: "inline" }}>Name : Player 1</div>
                                <div style={{ height: "24px", width: "50px", display: "inline" }}>Coutry Level :</div>
                              </div>
                            )}
                          </div>
                          <div
                            className="row"
                            style={{ height: "50px", border: "1px solid black", padding: "1px" }}
                          >
                            {el.players.length >= 2 ? (
                              <div>
                                <div style={{ height: "22px", width: "22px", display: "inline" }}>
                                  <img
                                    src={`${server}/${el.players[1].photo}`}
                                    alt=""
                                    style={{ height: "25px", width: "25px" }}
                                  />
                                </div>
                                <div style={{ height: "26px", width: "26px", display: "inline" }}>{el.players[1].firstName}</div>
                                <div style={{ height: "24px", width: "50px", display: "inline" }}>
                                  <img
                                    src={`${server}/public/country/${el.players[1].country}.png`}
                                    style={{ height: "25px", width: "25px" }}
                                    alt=""
                                  />
                                  {`Level : ${el.players[1].Level}`}
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div style={{ height: "22px", width: "22px", display: "inline" }} />
                                <div style={{ height: "26px", width: "26px", display: "inline" }}>Name : Player 2</div>
                                <div style={{ height: "24px", width: "50px", display: "inline" }}>Coutry Level :</div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-body">
                    <div className="row mb-3">
                      {roomsLevelFive.length === 1 ? <h4>Final Round</h4> : ""}
                      {roomsLevelFive.map((el) => (
                        <div
                          className="col-md-12"
                          style={{
                            height: "100px",
                            border: "1px solid black",
                            marginBottom: "10px",
                          }}
                        >
                          <div
                            className="row"
                            style={{ height: "50px", border: "1px solid black", padding: "1px" }}
                          >
                            {el.players.length >= 1 ? (
                              <div>
                                <div style={{ height: "22px", width: "22px", display: "inline" }}>
                                  <img
                                    src={`${server}/${el.players[0].photo}`}
                                    alt=""
                                    style={{ height: "25px", width: "25px" }}
                                  />
                                </div>
                                <div style={{ height: "26px", width: "26px", display: "inline" }}>{el.players[0].firstName}</div>
                                <div style={{ height: "24px", width: "50px", display: "inline" }}>
                                  <img
                                    src={`${server}/public/country/${el.players[0].country}.png`}
                                    style={{ height: "25px", width: "25px" }}
                                    alt=""
                                  />
                                  {`Level : ${el.players[0].Level}`}
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div style={{ height: "22px", width: "22px", display: "inline" }} />
                                <div style={{ height: "26px", width: "26px", display: "inline" }}>Name : Player 1</div>
                                <div style={{ height: "24px", width: "50px", display: "inline" }}>Coutry Level :</div>
                              </div>
                            )}
                          </div>
                          <div
                            className="row"
                            style={{ height: "50px", border: "1px solid black", padding: "1px" }}
                          >
                            {el.players.length >= 2 ? (
                              <div>
                                <div style={{ height: "22px", width: "22px", display: "inline" }}>
                                  <img
                                    src={`${server}/${el.players[1].photo}`}
                                    alt=""
                                    style={{ height: "25px", width: "25px" }}
                                  />
                                </div>
                                <div style={{ height: "26px", width: "26px", display: "inline" }}>{el.players[1].firstName}</div>
                                <div style={{ height: "24px", width: "50px", display: "inline" }}>
                                  <img
                                    src={`${server}/public/country/${el.players[1].country}.png`}
                                    style={{ height: "25px", width: "25px" }}
                                    alt=""
                                  />
                                  {`Level : ${el.players[1].Level}`}
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div style={{ height: "22px", width: "22px", display: "inline" }} />
                                <div style={{ height: "26px", width: "26px", display: "inline" }}>Name : Player 2</div>
                                <div style={{ height: "24px", width: "50px", display: "inline" }}>Coutry Level :</div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <h5 className="font-weight-bold litle-title mb-3">{t("tournamentdetails.recentduels")}</h5>
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="tournament-block">
                        <Table bordered>
                          <thead>
                            <tr>
                              <th>{t("tournamentdetails.tournamentstructure")}</th>
                              <th>{t("tournamentdetails.progress")}</th>
                              <th>{t("tournamentdetails.joinedplayers")}</th>
                              <th>{t("tournamentdetails.tournamentprize")}</th>
                              <th>{t("tournamentdetails.totalrounds")}</th>
                              <th>{t("tournamentdetails.date")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Round 1</td>
                              <td>20 minutes</td>
                              <td>500 </td>
                              <td>€200</td>
                              <td>4</td>
                              <td>
                                07/02/2020
                                <span>09:25AM</span>
                              </td>
                            </tr>
                            <tr>
                              <td>Round 2</td>
                              <td>30 minutes</td>
                              <td>400</td>
                              <td>€200</td>
                              <td>4</td>
                              <td>
                                07/02/2020
                                <span>09:25AM</span>
                              </td>
                            </tr>
                            <tr>
                              <td>Round 3</td>
                              <td>40 minutes</td>
                              <td>500</td>
                              <td>€200</td>
                              <td>2</td>
                              <td>
                                07/02/2020
                                <span>09:25AM</span>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Tournamentdetails;
