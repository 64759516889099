import React from "react";
import AuthContext from "../../context/authContext/AuthContext";
import useStorage from "../../hooks/useStorage/useStorage";
import { authStorage } from "../../storage/auth/auth.storage";

import { AuthProviderProps } from "./AuthProvider.types";

function AuthProvider({ children }: AuthProviderProps): React.ReactElement {
  const authData = useStorage(authStorage);

  return <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
