import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";

interface TimerState {
  mins: number;
  seconds: number;
}

const DEFAULT_STATE: TimerState = {
  mins: 0,
  seconds: 0,
};

function Timer() {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     mins: 0,
  //     seconds: 0,
  //   };
  //   // this.Times = 0;
  // }

  const [timerState, setTimerState] = useState<TimerState>(DEFAULT_STATE);

  const startTimer = () => {
    const timex = setInterval(() => {
      setTimerState({ ...timerState, seconds: timerState.seconds + 1 });
      if (timerState.seconds > 59) {
        setTimerState({ ...timerState, seconds: 0 });
        setTimerState({ ...timerState, mins: timerState.mins + 1 });
        if (timerState.mins > 4) {
          setTimerState({ ...timerState, mins: 0 });
        }
      }
    }, 1000);
    return timex;
  };
  useEffect(() => {
    startTimer();
  });

  // componentWillUnmount = () => {
  //   clearInterval(this.Timex);
  //   this.setState({
  //     show: false
  //   })
  // }

  // cancel() {
  //   clearInterval(this.Timex);
  //   this.setState({
  //     mins: 0,
  //     seconds: 0,
  //   });
  //   this.props.socket.emit("cancel", {
  //     Userid: localStorage.getItem("Userid"),
  //   });
  //   this.props.handleTimerPopup(`close`);
  // }

  // style() {
  //   return {
  //     font: "150px",
  //     margin: "0 auto",
  //     width: "1000px",
  //     margin: "0 auto",
  //     width: "600px",
  //     font: "24px",
  //   };
  // }

  const { t } = useTranslation();
  return (
    <button
      type="button"
      id="timer"
      className="cust14 btn"
    >
      <span id="mins">{`${timerState.mins} : `}</span>
      <span id="seconds">{timerState.seconds}</span>
      <h6>{t("timer.minsec")}</h6>
    </button>
  );
}

export default Timer;
