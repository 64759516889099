/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Link } from "../../../API/user/notifications.type";
import TableRowStyled from "./TableRow.style";
import { TextComponent, DateComponent, CustomComponent, MoneyAmountComponent, ScoreComponent, SupportedComponents, TranslatedKeyComponent, TooltipComponent } from "../Table.types";
import Score from "../Score/Score";
import Text from "../Text/Text";
import MoneyAmount from "../MoneyAmount/MoneyAmount";
import Time from "../Time/Time";
import Tooltip from "../Tooltip/Tooltip";
import useNavigateToLink from "../../../hooks/useNavigateToLink/useNavigateToLink";

const EMPTY_PLACEHOLDER = "---";

const isTextComponent = <T,>(field: SupportedComponents<T>): field is TextComponent<T> => field.type === "text";
const isTranslatedKeyComponent = <T,>(field: SupportedComponents<T>): field is TranslatedKeyComponent<T> => field.type === "translatedKey";
const isDateComponent = <T,>(field: SupportedComponents<T>): field is DateComponent<T> => field.type === "date";
const isMoneyAmountComponent = <T,>(field: SupportedComponents<T>): field is MoneyAmountComponent<T> => field.type === "moneyAmount";
const isScoreComponent = <T,>(field: SupportedComponents<T>): field is ScoreComponent<T> => field.type === "score";
const isTooltipComponent = <T,>(field: SupportedComponents<T>): field is TooltipComponent<T> => field.type === "tooltip";
const isCustomComponent = <T,>(field: SupportedComponents<T>): field is CustomComponent<T> => field.type === "custom";

const buildComponent = <T extends { id: string }>(input: SupportedComponents<T>, data: T) => {
  if (isCustomComponent(input)) {
    return input.creator(data);
  }

  const { field } = input;

  if (isTranslatedKeyComponent(input)) {
    const fieldValue = data[field]?.toString();
    const text = fieldValue ? input.translations[fieldValue]?.toString() || fieldValue : "";

    return (
      <Text
        text={text}
        key={`table-row-${data.id}-${field.toString()}`}
        width={input.width}
      />
    );
  }

  if (isTextComponent(input)) {
    return (
      <Text
        text={data[field]?.toString() || ""}
        key={`table-row-${data.id}-${field.toString()}`}
        width={input.width}
      />
    );
  }

  if (isDateComponent(input)) {
    return (
      <Time
        date={data[field] as any}
        dateFormat={input.dateFormat}
        emptyPlaceholder={EMPTY_PLACEHOLDER}
        key={`table-row-${data.id}-${field.toString()}`}
        width={input.width}
      />
    );
  }

  if (isMoneyAmountComponent(input)) {
    return (
      <MoneyAmount
        money={data[field] as any}
        key={`table-row-${data.id}-${field.toString()}`}
        width={input.width}
      />
    );
  }

  if (isScoreComponent(input)) {
    return (
      <Score
        score={data[field] as any}
        key={`table-row-${data.id}-${field.toString()}`}
        width={input.width}
      />
    );
  }

  if (isTooltipComponent(input)) {
    return (
      <Tooltip
        label={data[field] as any}
        tooltipText="copy"
        tooltipTextFinish="copied"
        onLabelClick={() => navigator.clipboard.writeText(data[field] as any)}
        key={`table-row-${data.id}-${field.toString()}`}
        width={input.width}
      />
    );
  }

  return EMPTY_PLACEHOLDER;
};

export type TableInput<T> = {
  id: string;
  heading: string;
  component: SupportedComponents<T>;
  isSortable?: boolean;
};

export type TableInputs<T> = TableInput<T>[];

interface TableRowProps<T> {
  item: T;
  lastElementRef: ((node: HTMLElement | null) => void) | null;
  inputs: TableInputs<T>;
  tableWithLinks: boolean;
  onRowClick?: (arg: T) => void | Promise<void>;
}

function TableRow<T extends { id: string; link?: Link }>({ item, lastElementRef, inputs, tableWithLinks, onRowClick }: TableRowProps<T>) {
  const navigate = useNavigateToLink(item.link);

  const onRowClickFunc = () => {
    if (onRowClick) {
      onRowClick(item);
    }
    navigate();
  };

  return (
    <TableRowStyled key={`row-${item.id}`}>
      <div
        className={item.link ? "table-row table-row-link" : "table-row"}
        onClick={() => onRowClickFunc()}
        ref={lastElementRef}
      >
        {inputs.map((column) => buildComponent(column!.component, item))}
        {tableWithLinks && <div className="row-link-arrows">{">>"}</div>}
      </div>
    </TableRowStyled>
  );
}

export default TableRow;
