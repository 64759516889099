import styled from "styled-components";

const SignupFormStyled = styled.div`
  max-width: 700px;
  margin: 75px auto;

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.h1};
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    color: ${({ theme }) => theme.colors.white};
  }

  .social-login {
    display: flex;
    column-gap: 20px;
    align-items: center;
    justify-content: center;
    margin: 40px;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .column {
    width: 75%;
  }

  .form-checkboxes {
    margin-top: 40px;
  }
`;

export default SignupFormStyled;
