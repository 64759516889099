import styled from "styled-components";

const NotFoundStyled = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.h1};
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    color: ${({ theme }) => theme.colors.white};
  }

  a {
    font-size: ${({ theme }) => theme.fontSizes.h3};
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.molot};
    color: ${({ theme }) => theme.colors.grey};
    margin-bottom: 40px;
  }
`;

export default NotFoundStyled;
