import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { oauthConfirm } from "../../API/user/userActions";
import useAppMutation from "../../hooks/useMutation/useMutation";
import { authStorage } from "../../storage/auth/auth.storage";
import Loader from "../loader/Loader";
import Routes from "../../config/routes";
import httpStatusHelper from "../../helpers/http-status-helper";

function Oauth() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const confirmMutation = useAppMutation(oauthConfirm);
  const [code, setCode] = useState<string>();
  const [state, setState] = useState<string>();

  useEffect(() => {
    const query = queryString.parse(window.location.search);

    if (typeof query.code === "string" && typeof query.state === "string") {
      setCode(query.code);
      setState(query.state);
    } else {
      NotificationManager.error(t("errors.ERROR_OCCURRED"), "Message", 10000);
      navigation(Routes.Home);
    }
  }, []);

  useEffect(() => {
    async function runOauthConfirm() {
      if (!code || !state) {
        return;
      }

      try {
        const response = await confirmMutation.mutateAsync({
          code,
          state,
        });

        if (httpStatusHelper.isResponseSuccessful(response)) {
          const tokens = response.payload;
          if (!tokens?.accessToken || !tokens.refreshToken) {
            throw new Error("missing tokens");
          }
          authStorage.setTokens(tokens.accessToken, false, tokens.refreshToken);
          navigation(Routes.Home);
        } else {
          // todo: state
          const errorMessage = response.errorObject?.response.data.message;
          NotificationManager.error(t([`errors.${errorMessage}`, "errors.ERROR_OCCURRED"]), "Message", 10000);
          navigation(errorMessage === "OAUTH_MISSING_CONSENTS" ? Routes.SignUp : Routes.Home);
        }
      } catch (err) {
        NotificationManager.error(t("errors.ERROR_OCCURRED"), "Message", 10000);
        navigation(Routes.Home);
      }
    }

    // eslint-disable-next-line no-void
    void runOauthConfirm();
  }, [code, state]);

  return <Loader />;
}

export default Oauth;
