import React from "react";
import { useTranslation } from "react-i18next";
import "./currentTournamentCounter.css";
import PageContent from "../pageContent.type";
import useCountdown from "./countDownHook";

function CurrentTournamentCounter({ startDate }: { startDate: string }) {
  const tournamentDate = new Date(startDate);
  const [days, hours, minutes, seconds] = useCountdown(tournamentDate.getTime());
  const translations: PageContent = useTranslation().t("currentTournament", { returnObjects: true });

  if (days + hours + minutes + seconds <= 0) {
    return null;
  }

  return (
    <div className="counter-wrapper">
      <h3 className="counter-header">{translations.tournamentStartsIn}</h3>
      <div className="counter">
        <div className="days">
          <span className="counter-number">{String(days).padStart(2, "0")}</span>
          <span className="counter-label">{translations.days}</span>
        </div>
        <div className="hours">
          <span className="counter-number">{String(hours).padStart(2, "0")}</span>
          <span className="counter-label">{translations.hours}</span>
        </div>
        <div className="minutes">
          <span className="counter-number">{String(minutes).padStart(2, "0")}</span>
          <span className="counter-label">{translations.minutes}</span>
        </div>
        <div className="seconds">
          <span className="counter-number">{String(seconds).padStart(2, "0")}</span>
          <span className="counter-label">{translations.seconds}</span>
        </div>
      </div>
    </div>
  );
}

export default CurrentTournamentCounter;
