import React from "react";
import "./finishedTournament.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FinishedTournamentData from "../finishedTournamentData.type";
import profileImg from "../../../../assets/images/external-tournaments/tournament-info-person.png";
import trophyImg from "../../../../assets/images/external-tournaments/tournament-info-trophy.png";
import Routes from "../../../../config/routes";

interface FinishedTournamentProps {
  tournamentData: FinishedTournamentData;
}

export default function FinishedTournament({ tournamentData }: FinishedTournamentProps) {
  const navigation = useNavigate();

  const parseEndDate = (endDate: string) => {
    const x = new Date(endDate);

    return `${(x.getDate() - 1).toString().padStart(2, "0")}.${(x.getMonth() + 1).toString().padStart(2, "0")}`;
  };

  const navigateToDetails = () => {
    navigation(`${Routes.FinishedTournaments}/${tournamentData.id}`);
  };

  return (
    <div className="finished-tournament">
      <div className="info">
        <div className="name">{tournamentData.name}</div>
        <div className="game-title">{tournamentData.info.gameTitle}</div>
        <div className="info-with-icons">
          <div className="info-tile">
            <img
              className="info-icon"
              src={profileImg}
              alt="date icon"
            />
            <div className="info-text">
              <span className="label">Data</span>
              <span className="value">{tournamentData.info.dateRange}</span>
            </div>
          </div>
          <div className="info-tile">
            <img
              className="info-icon"
              src={trophyImg}
              alt="pool icon"
            />
            <div className="info-text">
              <span className="label">Pula nagród</span>
              <span className="value">{tournamentData.info.pool}</span>
            </div>
          </div>
        </div>
        <div className="button-container">
          <Button onClick={navigateToDetails}>Szczegóły</Button>
        </div>
        <p className="end-date">Zakończony {parseEndDate(tournamentData.endDate)}</p>
      </div>
      <div className="banner-container">
        <img
          src={tournamentData.bannerImageUrl}
          alt="baner"
        />
      </div>
    </div>
  );
}
