import styled from "styled-components";

const NotificationsStyled = styled.div`
  margin-top: 200px;
  .notifications-container {
    max-width: 1070px;
    margin: auto;
  }
`;

export default NotificationsStyled;
