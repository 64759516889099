import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getUserFriends } from "../../API/friend/friendActions";
import { Friend as FriendT } from "../../API/friend/friendActions.type";
import { useRemoveFriend } from "../../hooks/useFriendAction/useFriendAction";
import useInfinitePaginationWithIntersect from "../../hooks/useInfinitePaginationWithIntersect/useInfinitePaginationWithIntersect";
import { useModal } from "../../modals";
import Button from "../common/buttons/button/Button";
import Friend from "./friend/Friend";

function useFriends() {
  const { t } = useTranslation();

  const { items: friendsItems, lastElementRef } = useInfinitePaginationWithIntersect(
    getUserFriends({
      pageSize: 4,
    })
  );

  const { openAcceptationModal, closeModal } = useModal();
  const removeFriend = useRemoveFriend();

  const onRemoveFriend = useCallback(
    async (friendId: string) => {
      await removeFriend.mutateAsync({ friendId });
      closeModal();
    },
    [removeFriend, closeModal]
  );

  const generateAdditionalOptions = useCallback(
    (friendId: string) => [
      {
        label: t("friends.delete_friend"),
        onClick: () => {
          openAcceptationModal({ type: "yes-no", question: t("friends.delete_friend_confirmation"), onAcceptFunc: () => onRemoveFriend(friendId), onRejectFunc: () => closeModal() });
        },
      },
    ],
    [openAcceptationModal, t]
  );

  const generateActionButton = useMemo(
    () => (
      <Button
        type="labelButton"
        label={t("friends.challenge")}
        onClick={() => {}}
        variant="duel"
      />
    ),
    [t]
  );

  const componentCreator = (item: FriendT, index: number) => (
    <Friend
      key={`friend-${item.userId}`}
      playerId={item.userId}
      username={item.nickname}
      avatarUrl={item.avatarUrl}
      actionButtons={generateActionButton}
      additionalOptions={generateAdditionalOptions(item.userId)}
      innerRef={friendsItems?.length === index + 1 ? lastElementRef : null}
      status={item.status}
      hasPendingInvitation={false}
    />
  );

  return { friendsItems, componentCreator };
}

export default useFriends;
