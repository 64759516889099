import React from "react";
import PageTitleStyled from "./PageTitle.style";

interface PageTitleProps {
  title: string;
}

function PageTitle({ title }: PageTitleProps) {
  return (
    <PageTitleStyled>
      <div className="title">
        <h2 className="title-text">{title}</h2>
      </div>
    </PageTitleStyled>
  );
}

export default PageTitle;
