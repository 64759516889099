import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { io, Socket } from "socket.io-client";
import SocketContext from "../../context/socketContext/SocketContext";
import { SocketProviderProps } from "./SocketProvider.types";
import { server } from "../../config/config";
import useAuth from "../../hooks/useAuth/useAuth";
import { useModal } from "../../modals";
import { SocketEmitTypes } from "./SocketEmitTypes";
import { MatchmakingCancelledPayload, MatchmakingFailedPayload, MatchmakingSucceededPayload, MatchmakingTimedOutPayload, PotentialMatchCreatedPayload, SocketServerEventEnum } from "./SocketServerEvent.enum";
import { SocketClientEventEnum } from "./SocketClientEvent.enum";
import useClient from "../../hooks/useClient/useClient";
import { refreshTokenAsync } from "../clientProvider/utils/refresh-token.util";
import { JoinMatchmakingProps } from "../../context/socketContext/SocketContext.types";
import Routes from "../../config/routes";

function SocketProvider({ children }: SocketProviderProps): React.ReactElement {
  const [socket, setSocket] = useState<Socket | null>();
  const authData = useAuth();
  const apiClient = useClient();
  const navigation = useNavigate();
  const { closeModal, openWaitingOpponentModal, openGameLoadingModal, openOpponentNotFoundModal, currentModalView, openFoundOpponentModal } = useModal();

  const { t } = useTranslation();

  const getAccessToken = () => ({
    accessToken: authData?.accessToken,
  });

  const initWebsocket = () => {
    const client = io(server, {
      auth: getAccessToken(),
      reconnection: true,
      autoConnect: true,
    });
    setSocket(client);
  };

  const disconnect = () => {
    socket?.disconnect();
    setSocket(null);
  };

  const emit = ({ eventName, payload }: SocketEmitTypes) => {
    if (socket) {
      socket.emit(eventName, payload);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.auth = getAccessToken();
      socket.disconnect().connect();
    }
  }, [authData?.accessToken]);

  const joinMatchmaking = useCallback(
    (payload: JoinMatchmakingProps) =>
      emit({
        eventName: SocketClientEventEnum.JOIN_MATCHMAKING,
        payload: {
          gameId: payload.gameId,
          bonusRoomId: payload.bonusRoomId,
          walletId: payload.walletId,
          stake: payload.stake?.stake,
          stakeCurrency: payload.stake?.currency,
          fingerprint: "",
        },
      }),
    [socket]
  );

  const acceptMatchmaking = useCallback(
    () =>
      emit({
        eventName: SocketClientEventEnum.ACCEPT_MATCHMAKING,
        payload: { fingerprint: "" },
      }),
    [socket]
  );

  const rejectMatchmaking = useCallback(
    () =>
      emit({
        eventName: SocketClientEventEnum.REJECT_MATCHMAKING,
        payload: { fingerprint: "" },
      }),
    [socket]
  );

  const leaveMatchmaking = useCallback(
    () =>
      emit({
        eventName: SocketClientEventEnum.LEAVE_MATCHMAKING,
        payload: { fingerprint: "" },
      }),
    [socket]
  );

  const handleMatchmakingSearching = useCallback(() => {
    if (currentModalView.view !== "accept-opponent") openWaitingOpponentModal({ onClose: leaveMatchmaking });
  }, [currentModalView, leaveMatchmaking]);

  const handleMatchmakingCancelled = useCallback((payload: MatchmakingCancelledPayload) => {
    if (payload.bonusRoomId) {
      navigation(Routes.Bonus);
    } else {
      navigation(Routes.Home);
    }
    closeModal();
  }, []);

  const handlePotentialMatchCreated = useCallback((payload: PotentialMatchCreatedPayload) => {
    if (payload.acceptanceRequired) {
      openFoundOpponentModal({ countDownTime: 20 });
    }
  }, []);

  const handleMatchmakingSucceeded = useCallback((payload: MatchmakingSucceededPayload) => {
    openGameLoadingModal({ closeModal, playersData: payload.playersData, gameUri: payload.gameUri });
  }, []);

  const handleMatchmakingFailure = useCallback((payload: MatchmakingTimedOutPayload | MatchmakingFailedPayload) => {
    if (payload.bonusRoomId) {
      navigation(Routes.Bonus);
      closeModal();
    } else if (payload.walletId) {
      navigation(Routes.Home);
      openOpponentNotFoundModal({
        walletId: payload.walletId!,
        gameId: payload.gameId,
        stake: payload.stake,
      });
    }
  }, []);

  useEffect(() => {
    initWebsocket();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.removeAllListeners();
      socket.removeAllListeners(SocketServerEventEnum.MATCHMAKING_SEARCHING);
      socket.on(SocketServerEventEnum.MATCHMAKING_SEARCHING, handleMatchmakingSearching);

      socket.removeAllListeners(SocketServerEventEnum.POTENTIAL_MATCH_CREATED);
      socket.on(SocketServerEventEnum.POTENTIAL_MATCH_CREATED, handlePotentialMatchCreated);

      socket.removeAllListeners(SocketServerEventEnum.MATCHMAKING_SUCCEEDED);
      socket.on(SocketServerEventEnum.MATCHMAKING_SUCCEEDED, handleMatchmakingSucceeded);

      socket.removeAllListeners(SocketServerEventEnum.MATCHMAKING_TIMED_OUT);
      socket.on(SocketServerEventEnum.MATCHMAKING_TIMED_OUT, handleMatchmakingFailure);

      socket.removeAllListeners(SocketServerEventEnum.MATCHMAKING_FAILED);
      socket.on(SocketServerEventEnum.MATCHMAKING_FAILED, handleMatchmakingFailure);

      socket.removeAllListeners(SocketServerEventEnum.MATCHMAKING_CANCELLED);
      socket.on(SocketServerEventEnum.MATCHMAKING_CANCELLED, handleMatchmakingCancelled);

      socket.on("disconnect", (reason) => {
        if (reason === "io server disconnect") {
          // the disconnection was initiated by the server, you need to reconnect manually
          socket.connect();
        }
      });

      socket.on("exception", (error: any) => {
        if (error) {
          if (error.statusCode === 401) {
            return refreshTokenAsync(apiClient).finally(() => {
              NotificationManager.error(t(`errors.ERROR_OCCURRED`), "Error message", 10000);
            });
          }
          NotificationManager.error(t([`errors.${error?.message}`, `errors.ERROR_OCCURRED`]), "Error message", 10000);
        }
      });
    }
  }, [socket]);

  const socketValue = useMemo(
    () => ({
      initWebsocket,
      disconnect,
      emit,
      joinMatchmaking,
      acceptMatchmaking,
      rejectMatchmaking,
      leaveMatchmaking,
    }),
    [initWebsocket, disconnect, emit, joinMatchmaking, acceptMatchmaking, rejectMatchmaking, leaveMatchmaking]
  );

  return <SocketContext.Provider value={socketValue}>{children}</SocketContext.Provider>;
}

export default SocketProvider;
