import React, { useState, useEffect } from "react";

interface CountdownTimerProps {
  initialMinutes: number;
  initialSeconds: number;
}

function CountdownTimer({ initialMinutes, initialSeconds }: CountdownTimerProps) {
  const [minutes, setMinutes] = useState<number>(initialMinutes);
  const [seconds, setSeconds] = useState<number>(initialSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [minutes, seconds]);

  const formatTime = (time: number): string => (time < 10 ? `0${time}` : `${time}`);

  return (
    <div className="timer">
      <p className="timer-time">
        {minutes}:{formatTime(seconds)}
      </p>
      <p className="timer-text">Czas oczekiwania</p>
    </div>
  );
}

export default CountdownTimer;
