import styled from "styled-components";
import { forDesktop } from "../../../../helpers/breakpoints/viewports";
import Input from "../input/Input.style";

const codeInputStyled = styled(Input)`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .error {
    color: ${({ theme }) => theme.colors.error};
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
    font-size: ${({ theme }) => theme.fontSizes.formError};
    margin-top: 8px;
    text-align: left;
  }

  .inputs-wrapper {
    display: flex;
    column-gap: 8px;
    justify-content: space-between;
  }

  .number-box {
    width: 30px;
    height: 30px;
    background-color: ${({ theme }) => theme.colors.blackBackgroundOpacity40};
    border: 1px solid;
    border-color: ${({ theme }) => theme.colors.blackBackgroundOpacity40};
    border-radius: 2px;
    font-size: ${({ theme }) => theme.fontSizes.formInputMobile};
    color: ${({ theme }) => theme.colors.whiteText};
    text-align: center;
  }

  .number-box:focus {
    outline: none;
  }

  .red {
    border-color: ${({ theme }) => theme.colors.error};
  }

  @media ${forDesktop} {
    .number-box {
      font-size: ${({ theme }) => theme.fontSizes.formInputDesktop};
      width: 40px;
      height: 40px;
    }
  }
`;

export default codeInputStyled;
