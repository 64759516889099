import styled from "styled-components";

const TooltipStyled = styled.div<{ width?: string }>`
  position: relative;
  width: fit-content;
  max-width: ${({ width }) => width};

  .tooltip-label {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .tooltip-icon {
    margin-left: 5px;
  }

  .tooltip-text {
    visibility: hidden;

    background-color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.fontSizes.caption};
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;

    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -110%);
  }

  .tooltip-label:hover {
    cursor: pointer;
  }

  .tooltip-label:hover + .tooltip-text {
    visibility: visible;
  }
`;

export default TooltipStyled;
