import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GameLoadingModalStyled from "./GameLoadingModal.style";
import Counter from "./counter/Counter";
import useProfile from "../../hooks/useProfile/useProfile";
import { ProfileResponse } from "../../API/user/userActions.type";
import Opponents from "../Opponents/Opponents";

interface GameLoadingModalProps {
  closeModal: () => void;
  playersData: ProfileResponse[];
  gameUri?: string;
}

function GameLoadingModal({ closeModal, playersData, gameUri }: GameLoadingModalProps) {
  const translation: any = useTranslation().t("gameloading", { returnObjects: true });
  const profileQuery = useProfile();

  const [counterVisible, setCounterVisible] = useState(false);
  const [opponentPlayer] = playersData.filter((player) => player.id !== profileQuery?.data?.id);

  const onFinish = () => {
    if (gameUri) {
      window.location.href = gameUri;
    }

    closeModal();
  };

  useEffect(() => {
    setTimeout(() => setCounterVisible(true), 8000);
  }, []);

  return (
    <GameLoadingModalStyled>
      <div>
        <h2 className="heading">{translation.heading}</h2>
        <div className="requirements">
          <p>{translation.requirements}</p>
          <p>{translation.requirements2}</p>
        </div>
        <Opponents opponent={opponentPlayer} />
      </div>
      {counterVisible && (
        <Counter
          startingNumber={3}
          finishNumber={0}
          timeInterval={1000}
          onFinish={onFinish}
        />
      )}
    </GameLoadingModalStyled>
  );
}

export default GameLoadingModal;
