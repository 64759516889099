import React from "react";
import "./sponsors.css";

interface SponsorsProps {
  sectionHeader: string;
  list: {
    imageUrl: string;
    name: string;
  }[];
}

export default function Sponsors({ sectionHeader, list }: SponsorsProps) {
  return (
    <div className="sponsors">
      <div className="container">
        <h2>{sectionHeader}</h2>
        <div className="sponsor-logos">
          {!!list &&
            list.map((sponsor) => (
              <img
                className="sponsor-logo"
                key={sponsor.name}
                src={sponsor.imageUrl}
                alt={sponsor.name}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
